import { hostURL } from 'apiServices/http.service'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Subject } from 'rxjs'
import { Attachment } from 'types/attachment'

export default function useUpload() {
  const xhr = useRef<XMLHttpRequest>()

  const progress = useMemo(() => new Subject<number>(), [])

  const [attachment, setAttachment] = useState<Attachment>()
  const [uploading, setUploading] = useState(false)
  const [error, setError] = useState<string>()

  const upload = useCallback((file: File) => {
    xhr.current?.abort?.()

    setUploading(true)
    setError(null)

    const data = new FormData()

    data.append('file', file)

    const request = new XMLHttpRequest()
    xhr.current = request

    request.open('POST', `${ hostURL }/attachments/temp`) 
    request.setRequestHeader('Authorization', `Bearer ${ localStorage.getItem('sessionToken') }`)

    request.upload.addEventListener('progress', e => {
      progress.next(e.loaded / e.total)
    })

    request.addEventListener('load', () => {
      if (request.status === 201) {
        progress.next(1)

        setAttachment({
          fileName: file.name,
          fileSize: file.size,
          fileType: file.type,
          id: request.response,
          temp: true
        })
      } else if (request.status === 422) {
        try {
          setError(JSON.parse(request.response).message)
        } catch (e) {
          setError('Unexpected error')
        }
      }

      setUploading(false)
    })

    request.send(data)

    return () => {
      request.abort()
    }
  }, [xhr, progress])

  useEffect(() => {
    return () => {
      xhr.current?.abort?.()
    }
  }, [])

  return { progress, upload, attachment, uploading, error }
}
