import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import { merge } from 'lodash'

import eng from 'translations/en.json'
import ukr from 'translations/ua.json'
import rus from 'translations/ru.json'

const fallbackTranslations = { eng, ukr, rus }

i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
      fallbackLng: 'eng',
      debug: false,
      ns: ['headers', 'buttons', 'datetime', 'validations'],
      backend: {
        loadPath: `${ process.env.REACT_APP_HOST_URL }/translates?locale={{lng}}`,
        crossDomain: true,
        customHeaders: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        parse: (data, language: 'eng' | 'ukr' | 'rus', namespace) => {
          let json = {}

          try {
            json = JSON.parse(data).translates
          } catch (e) {
            console.error('i18n: Retrieving translations failed', namespace)
          }

          return merge(fallbackTranslations[language], json)
        }
      },
      react: {
        useSuspense: false
      }
    })

export default i18n
