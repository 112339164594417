import { put, all, takeEvery, call } from 'redux-saga/effects'
import {
  REQUEST_CONTACT_US_SUBJECTS,
  REQUEST_CREATE_FEEDBACK,
  receivedContactUsSubjects,
  createFeedbackFailed,
  requestContactUsSubjectsFailed,
  createFeedbackSuccess
} from '../actions/contactUs.action'
import { request } from '../../apiServices/http.service'
import { createFeedback, getSubjects } from '../../apiServices/contactUs.service'
import { FeedbackSubject } from '../../apiServices/models/subject.model'
import { CreateFeedbackParams } from '../../apiServices/models/types'

function* requestSubjects() {
  const { response, error } = yield call(request, getSubjects())

  if (response) {
    yield put(receivedContactUsSubjects(FeedbackSubject.mapObjectsToEntities(response.subjects)))
  } else {
    yield put(requestContactUsSubjectsFailed(error))
  }
}

function* requestCreateFeedback({ payload }: { type: string, payload: CreateFeedbackParams }) {
  const { response, error } = yield call(request, createFeedback(payload))

  if (response) {
    yield put(createFeedbackSuccess(response.message))
  } else {
    yield put(createFeedbackFailed(error))
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(REQUEST_CONTACT_US_SUBJECTS, requestSubjects),
    yield takeEvery(REQUEST_CREATE_FEEDBACK, requestCreateFeedback)
  ])
}
