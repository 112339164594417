// Common
import React, { FunctionComponent, ReactElement } from 'react'
import styles from './index.module.scss'

// Components
import Button from 'components/button'
import NavLink from '../navLink'
import Label from 'components/label'
import Icon from 'components/icon'

// Hooks
import { useNavigate } from 'react-router-dom'
import { useCallback } from 'react'

interface IComponentProps {
  tabs?: { title: string, route: string }[]
  rightTabs?: { title: string, route: string }[]
  buttons?: { title: string, onClick: () => void }[]
  backLabel?: string
}

const PageTabsHeader: FunctionComponent<IComponentProps> = ({
  tabs,
  rightTabs,
  buttons,
  backLabel
}): ReactElement => {

  const navigate = useNavigate()

  const handleBack = useCallback(() => {
    navigate(-1)
  }, [navigate])

  return (
    <div className={ styles['header'] }>
      { backLabel &&
        <div
          className={ styles['header-back'] }
          onClick={ handleBack }
        >
          <Icon
            className={ styles['header-back-icon'] }
            name='full-arrow-left'
            size={ 10 }
          />

          <Label appearance='heading' text={ backLabel } />
        </div>
      }

      {
        tabs?.map(tab => (
          <NavLink
            className={ styles['header-tab'] }
            to={ tab.route }
            key={ tab.route }
          >
            { tab.title }
          </NavLink>
        ))
      }

      <div className={ styles['header-divider'] } />

      {
        rightTabs?.map(tab => (
          <NavLink
            className={ styles['header-tab'] }
            to={ tab.route }
            key={ tab.route }
          >
            { tab.title }
          </NavLink>
        ))
      }

      {
        buttons?.map(({ title, onClick: handleClick }) => (
          <Button
            key={ title }
            className={ styles['header-button'] }
            appearance='gold'
            onClick={ handleClick }
          >
            { title }
          </Button>
        ))
      }
    </div>
  )
}

export default PageTabsHeader
