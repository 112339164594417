// Common
import React, { FunctionComponent, ReactElement, useEffect } from 'react'
import styles from './index.module.scss'
import { Navigate, Route, Routes } from 'react-router-dom'

// Components
import CoverImage from '../coverImage/coverImage'
import SideBar from '../sideBar/sideBar'
import Events from '../../events/events'
import EventsList from '../../events/list'
import EventPage from '../../events/eventPage'
import Calendar from '../../calendar'
import CalendarMonth from '../../calendarMonth'
import Media from '../../media'
import Albums from '../../media/albums'
import MediaTracks from '../../media/tracks'
import Blog from '../../blog'
import NavLink from 'components/navLink'

// Hooks
import { useSelector } from 'redux/store'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// Actions
import actions from 'redux/actions/users.action-creators'

const UserProfile: FunctionComponent = (): ReactElement => {
  const dispatch = useDispatch()
  const [t] = useTranslation()
  const { userId } = useParams()
  const profile = useSelector(({ users }) => users.currentItem)

  useEffect(() => {
    dispatch(actions.readOne(userId))

    return () => {
      dispatch(actions.clear())
    }
  }, [userId, dispatch])

  // TODO Implement placeholder
  if (!profile) return null

  return (
    <div className={ styles['profile-container'] }>
      <div className={ styles['cover-image-container'] }>
        <CoverImage coverImageUrl={ profile.coverImageUrl } />
      </div>

      <div className={ styles['profile'] }>
        <div className={ styles['profile-side-bar'] }>
          <SideBar user={ profile } />
        </div>

        <div className={ styles['profile-body'] }>
          <div className={ styles['profile-body-header'] }>
            <NavLink to='events' >{ t('buttons.events') }</NavLink>
            <NavLink to='media' >{ t('buttons.media') }</NavLink>
            <NavLink to='blog' >{ t('buttons.blog') }</NavLink>
          </div>

          <Routes>
            <Route path='events/*' element={ <Events /> }>
              <Route index={ true } element={ <EventsList /> } />
              <Route path=':eventId' element={ <EventPage /> } />
            </Route>
            <Route path='calendar' element={ <Calendar /> } />
            <Route path='calendar/:year/:month/:day' element={ <CalendarMonth /> } />
            <Route path='media/*' element={ <Media /> } >
              <Route path='gallery' element={ <Albums /> } />
              <Route path='tracks' element={ <MediaTracks /> } />
              <Route path='*' element={ <Navigate to='gallery' /> } />
              <Route path='' element={ <Navigate to='gallery' /> } />
            </Route>
            <Route path='blog' element={ <Blog /> } />
            <Route path='*' element={ <Navigate to='events?future' /> } />
          </Routes>
        </div>
      </div>
    </div>
  )
}

UserProfile.displayName = 'UserProfile'

export default UserProfile
