import React, { FunctionComponent, ReactElement, memo } from "react";
import cn from 'classnames';
import styles from './businessInfo.module.scss';

// Hooks
import { useTranslation } from "react-i18next";

// Types
import { GeneralLocation } from 'types/generalLocation'

interface IComponentProps {
  generalLocation: GeneralLocation
  workingHours?: unknown[]
  className?: string
}

const BusinessInfo: FunctionComponent<IComponentProps> = ({
  generalLocation,
  className
}): ReactElement => {
  const [t] = useTranslation();

  return (
      <div className={ cn(styles['business-info'], className) }>
        <div className={ styles['business-info-group'] }>
          <span>{ t('LOCATION') }</span>
          <div />
          <span className={ styles['business-info-group-item'] }>{ generalLocation?.address || '' }</span>
        </div>

        <div className={ styles['business-info-group'] }>
          <span>{ t('HOURS') }</span>
        </div>
      </div>
  )
}

export default memo(BusinessInfo);
