import { request } from 'apiServices/http.service'
import { BehaviorSubject } from 'rxjs'
import { Filters } from 'types/reduxCRUD'
import { BaseRestService } from './base-rest.service'
import { isEqual } from 'lodash'
import { RestItem } from 'types/rest-item.interface'

export abstract class BaseRestInfiniteScrollService<T extends RestItem, TFilters extends Filters> extends BaseRestService<T, TFilters> {
  protected _searchCurrentPage = new BehaviorSubject<number>(0)
  protected _searchHasMore = new BehaviorSubject<boolean>(true)

  async requestMany(filters: TFilters) {
    if(!this._searchHasMore.value || this._searchPending.value || this._searchError.value) return

    this._searchPending.next(true)

    filters.page = this._searchCurrentPage.value + 1

    if (!isEqual({ ...this._searchLastFilters.value, page: -1 }, { ...filters, page: -1 })) {
      this._searchItems.next([])
      filters.page = 1
    }

    const { response, error } = await request({
      url: await this.buildUrl(),
      params: {
        ...filters,
        page: filters.page || 1,
      }
    })

    if (response) {
      this._searchHasMore.next(filters.page * filters.perPage < response?.count)
      this._searchTotal.next(response?.count || 0)
      this._searchCurrentPage.next(filters.page)
      this._searchLastFilters.next(filters)
      this._searchPending.next(false)
      this._searchError.next(null)
      this.setItems(response)
    } else {
      this.handleError(error)
      this._searchError.next(error)
    }
  }

  clear() {
    super.clear()
    this._searchCurrentPage.next(0)
    this._searchHasMore.next(true)
  }

  protected setItems(response) {
    const currentItems = this._searchItems.value
    const items = this.getItemsFromResponse(response).map(this.buildItem)
    this._searchItems.next([...currentItems, ...items])
  }
}
