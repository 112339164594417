import React, { FunctionComponent, ReactElement, useEffect } from 'react'
import styles from './index.module.scss'
import { useSelector } from '../../../../redux/store'
import { useDispatch } from 'react-redux'
import { requestPolicy } from '../../../../redux/actions/admin/policy'


export const PrivacyPolicy: FunctionComponent<any> = (): ReactElement => {
  const dispatch = useDispatch()
  const privacy = useSelector(state => state.admin.policy)

  useEffect(() => {
    dispatch(requestPolicy())
  }, [])

  return (
    <>
      <div
        className={ styles['privacy-policy'] }
      >
        <h1 className={ styles['privacy-policy-heading'] }>Privacy Policy</h1>
        <div dangerouslySetInnerHTML={ { __html: privacy.body } }/>
        <div className={ styles['privacy-policy-updated'] }>Last updated: { privacy.updatedAt?.toDateString() }</div>
      </div>
    </>
  )
}
