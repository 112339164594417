// Common
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { usePopper } from "react-popper";
import { useController } from "react-hook-form";
import useDropDown from "../../../hooks/useDropDown";
import cn from 'classnames';
import styles from "../index.module.scss";

// Components
import AnimatedContainer from 'components/animatedContainer'
import SingleDropDownTrigger from "./single-drop-down-trigger";
import SingleOption from "./single-option";


// Types
import { OptionType } from '../types/option';
import { ISingleDropDown } from "../types/dropdown";

const SingleDropDown: FunctionComponent<ISingleDropDown> = ({
  options,
  placeholder,
  name,
  excludeSelectedOption = false,
  showBy = 'title',
  title,
  required,
  control,
  theme = 'light',
  defaultValue,
  className
}) => {
  const popoverRef = useRef<HTMLDivElement>(null);
  const [triggerRef, setTriggerRef] = useState<HTMLDivElement>(null)
  const [popover, setPopover] = useState<HTMLDivElement>(null)

  const {
    styles: { popper: popperStyles },
    attributes: { popper: popperAttributes }
  } = usePopper(triggerRef, popover, { placement: 'bottom' })

  const [opened, trigger] = useDropDown(popoverRef);

  const { field: { value: selectedOption, onChange }, fieldState: { error }} = useController({
    name,
    control,
    rules: {
      required
    },
    defaultValue: defaultValue || ''
  });

  useEffect(() => {
    return () => {
      opened && trigger();
    }
  }, []);

  // UseEffect is needed because of usePopper uses state object instead of ref object
  useEffect(() => {
    popoverRef.current = popover;
  }, [popover])


  const handleSelect = useCallback((option: OptionType) => {
    onChange(option)
    trigger()
  }, [trigger, onChange]);

  return (
      <>
        <div
            className={ cn(
                styles['drop-down'],
                styles[theme],
                { [styles['expanded']]: opened },
                { [styles['error']]: error },
                className
            ) }
        >
          { title &&
            <span className={ styles['drop-down-title'] }>{ title }</span>
          }

          <SingleDropDownTrigger
              trigger={ trigger }
              placeholder={ placeholder }
              selectedOption={ selectedOption }
              showBy={ showBy }
              ref={ setTriggerRef }
          />

          { options.length !== 0 &&
            <AnimatedContainer show={ opened }>
              <div
                className={ styles['drop-down-options'] }
                ref={ setPopover }
                style={ popperStyles }
                { ...popperAttributes }
              >
                {
                  options.map((option: OptionType) =>
                    <SingleOption
                        option={ option }
                        selected={ selectedOption?.value === option.value }
                        onSelect={ handleSelect }
                        excludeSelectedOption={ excludeSelectedOption }
                        key={ option.value }
                        showBy={ showBy }
                    />
                  )
                }
              </div>
            </AnimatedContainer>
          }
        </div>
      </>
  )
};

export default SingleDropDown;
