import { object, string, InferType, mixed } from 'yup'
import i18n from 'i18n'
import { yupResolver } from '@hookform/resolvers/yup'
import { Attachment } from 'types/attachment'
import { hostURL } from 'apiServices/http.service'
import { ValidationMode } from 'react-hook-form'
import { User } from './user.model'

const trackValidationSchema = object({
  title: string().required(() => i18n.t('validations.is_required')),
  image: mixed<Attachment>()
    .required(() => i18n.t('validations.is_required'))
    .test(
      'fileSize',
      () => i18n.t('validations.file_too_large', { size: '50.0 mb' }),
      value => !value?.fileSize || value.fileSize <= 50000000
    )
    .test(
      'fileType',
      () => i18n.t('validations.is_image'),
      value => !value?.fileType || (
        value.fileType === 'image/jpeg' ||
        value.fileType === 'image/bmp' ||
        value.fileType === 'image/png'
      )
    )
    .test(
      'id',
      () => i18n.t('validations.is_required'),
      value => !!value?.id
    ),
  audio: mixed<Attachment>()
    .required(() => i18n.t('validations.is_required'))
    .test(
      'fileSize',
      () => i18n.t('validations.file_too_large', { size: '50.0 mb' }),
      value => !value?.fileSize || value.fileSize <= 50000000
    )
    .test(
      'fileType',
      () => i18n.t('validations.is_audio'),
      value => !value?.fileType || (
        value.fileType === 'audio/mpeg' ||
        value.fileType === 'audio/mp4'
      )
    )
    .test(
      'id',
      () => i18n.t('validations.is_required'),
      value => !!value?.id
    )
})

export class Track implements InferType<typeof trackValidationSchema> {
  audio: Attachment
  audioUrl: string
  author: string
  duration: number
  id: number
  user: User
  image: Attachment
  imageUrl: string
  title: string

  constructor(data?) {
    this.id = data?.id
    this.title = data?.title
    this.author = 'Lulu Wilson'
    this.duration = data?.duration
    this.user = data?.user && new User(data.user)

    if (data?.image) {
      this.image = data.image
    } else {
      this.image = {
        fileName: data?.imageFileName,
        fileSize: data?.imageFileSize,
        fileType: data?.imageFileType,
        id: data?.id,
        temp: false,
      }
    }

    if (data?.audio) {
      this.audio = data.audio
    } else {
      this.audio = {
        fileName: data?.audioFileName,
        fileSize: data?.audioFileSize,
        fileType: data?.audioFileType,
        id: data?.id,
        temp: false,
      }
    }

    this.imageUrl = `${ hostURL }/attachments/${ this.user?.id }/${ this.id }/${ this.id }/${ this.image.fileName }`
    this.audioUrl = `${ hostURL }/attachments/${ this.user?.id }/${ this.id }/${ this.id }/${ this.audio.fileName }`
  }

  asForm() {
    return {
      defaultValues: {
        id: this.id,
        title: this.title,
        image: this.image,
        audio: this.audio,
        duration: this.duration
      },
      mode: 'onChange' as keyof ValidationMode,
      resolver: yupResolver(trackValidationSchema)
    }
  }

  static fromFormData(data) {
    return new Track(data)
  }

  asPayloadJSON() {
    return {
      audioFileName: this.audio.fileName,
      audioFileSize: this.audio.fileSize,
      audioFileType: this.audio.fileType,
      audioId: this.audio.id.toString(),
      audioTemp: this.audio.temp,
      imageFileName: this.image.fileName,
      imageFileSize: this.image.fileSize,
      imageFileType: this.image.fileType,
      imageId: this.image.id.toString(),
      imageTemp: this.image.temp,
      title: this.title,
      duration: Math.round(this.duration),
    }
  }
}

export interface TracksFilters {
  page?: number,
  perPage: number,
}
