// Common
import React, { FunctionComponent, ReactElement } from 'react'
import { getAudioBuffer, getContext } from './utils'
import { Observable, from } from 'rxjs'

// Components
import Waveform from './waveform'

// Hooks
import { useRef, useEffect, useState, useCallback } from 'react'

// Types
import WaveStyle from './types/waveStyle'

interface IComponentProps {
  url: string
  height: number
  progress: Observable<number>
  responsive: boolean
  waveStyle: WaveStyle
  width: number
  onPositionChange: (position: number) => void
}

const Wrapper: FunctionComponent<IComponentProps> = ({
  url,
  height,
  progress,
  responsive,
  waveStyle,
  width,
  onPositionChange
}): ReactElement => {
  const dragging = useRef(false)

  const resizing = useRef(null)

  const wrapper = useRef<HTMLDivElement>()

  const [size, setSize] = useState({ width, height })

  const doResize = useCallback(() => {
    clearTimeout(resizing.current)

    const timeout = setTimeout(() => {
      const newHeight = responsive ? wrapper.current.offsetHeight : height
      const newWidth = responsive ? wrapper.current.offsetWidth : width

      if (newHeight !== size.height || newWidth !== size.width) {
        setSize({ height: newHeight, width:  newWidth })
      }
    }, 300)
  
    resizing.current = timeout
  }, [resizing, size, height, width, responsive])

  useEffect(() => {
    if (!responsive) return

    window.addEventListener('resize', doResize, false)

    return () => {
      window.removeEventListener('resize', doResize)
    }
  }, [responsive, doResize])

  const getMousePosition = useCallback(e => {
    const x = e.nativeEvent.offsetX

    if (e.nativeEvent.target.id === 'posMarker') {
      return x * wrapper.current.offsetWidth
    }

    return x
  }, [wrapper])

  const handleMouseDown = useCallback(e => {
    dragging.current = true

    if (onPositionChange) {
      onPositionChange(getMousePosition(e) / wrapper.current.offsetWidth)
    }
  }, [wrapper, dragging, onPositionChange, getMousePosition])

  const handleMouseMove = useCallback(e => {
    if (dragging.current && onPositionChange) {
      onPositionChange(getMousePosition(e) / wrapper.current.offsetWidth)
    }
  }, [wrapper, getMousePosition, onPositionChange])

  const handleMouseUp = useCallback(e => {
    dragging.current = false

    if (onPositionChange) {
      onPositionChange(getMousePosition(e) / wrapper.current.offsetWidth)
    }
  }, [wrapper, getMousePosition, onPositionChange])

  const [buffer, setBuffer] = useState<AudioBuffer>()

  useEffect(() => {
    if (!url) return

    const context = getContext()

    const subscription = from(getAudioBuffer(url, context))
      .subscribe(buffer => setBuffer(buffer))

    return () => subscription.unsubscribe()
  }, [url])

  return (
    <div
      ref={ wrapper }
      style={ {
        height: responsive ? '100%' : height + 'px',
        position: 'relative',
        width: responsive ? '100%' : width + 'px'
      } }
      onMouseDown={ handleMouseDown }
      onMouseMove={ handleMouseMove }
      onMouseUp={ handleMouseUp }
    >
      <Waveform
        buffer={ buffer }
        height={ size.height }
        waveStyle={ waveStyle }
        width={ size.width }
        progress={ progress }
      />
    </div>
  )
}

export default Wrapper
