import React, { FunctionComponent, ReactElement } from "react";
import styles from './delimiter.module.scss';
import cn from 'classnames';

interface IComponentProps {
  className?: string
}
const Delimiter: FunctionComponent<IComponentProps> = ({ className }): ReactElement => {
  return (
      <div className={ cn(styles['delimiter'], className) } />
  )
}

export default Delimiter;
