// Common
import React, { FunctionComponent, ReactElement } from 'react'
import styles from './index.module.scss'

// Types
import { Upload } from 'types/upload'

// Hooks
import { useRef } from 'react'
import useSubscribe from 'hooks/useSubscribe'

// Components
import Icon from 'components/icon'

interface IComponentProps {
  upload: Upload
}

const UploadComponent: FunctionComponent<IComponentProps> = ({ upload }): ReactElement => {

  const progressElementRef = useRef<HTMLDivElement>()

  useSubscribe(upload.progress, value => {
    if (progressElementRef.current) {
      progressElementRef.current.style.width = `${ value * 100 }%`
    }
  }, [])

  return (
    <div className={ styles['upload'] }>
      <div
        className={ styles['upload-progress'] }
        ref={ progressElementRef }
      />

      <div className={ styles['upload-inner'] }>
        <Icon
          className={ styles['upload-icon'] }
          name='upload'
          size={ 16 }
        />
        <div className={ styles['upload-filename'] }>{ upload.fileName }</div>
        <Icon
          className={ styles['upload-trash'] }
          name='trash-basket'
          size={ 16 }
        />
      </div>
    </div>
  )
}

export default UploadComponent
