import { object, string, InferType, mixed } from 'yup'
import i18n from 'i18n'
import { yupResolver } from '@hookform/resolvers/yup'
import { ValidationMode } from 'react-hook-form'
import { Attachment } from 'types/attachment'
import { hostURL } from 'apiServices/http.service'
import { User } from './user.model'

const photoSchema = object({
  description: string(),
  albumId: string(),
  eventId: string(),
  image: mixed<Attachment>()
    .required(() => i18n.t('validations.is_required'))
    .test(
      'fileSize',
      () => i18n.t('validations.file_too_large', { size: '50.0 mb' }),
      value => value && value.fileSize <= 50000000
    )
    .test(
      'fileType',
      () => i18n.t('validations.is_image'),
      value => value && (
        value.fileType === 'image/jpeg' ||
        value.fileType === 'image/bmp' ||
        value.fileType === 'image/png'
      )
    )
    .test(
      'id',
      () => i18n.t('validations.is_required'),
      value => !!value?.id
    )
})

export class Photo implements InferType<typeof photoSchema> {
  albumId?: string
  description: string
  id: number
  user: User
  image: Attachment
  imageUrl: string
  eventId?: string

  constructor(data?) {
    this.description = data?.description
    this.id = data?.id
    this.albumId = data?.attachable_id || data?.albumId
    this.user = data?.user && new User(data.user)
    this.eventId = data?.attachable_id || data?.eventId

    if (data?.image) {
      this.image = data.image
    } else {
      this.image = {
        fileName: data?.imageFileName,
        fileSize: data?.imageFileSize,
        fileType: data?.imageFileType,
        id: data?.id,
        temp: false,
      }
    }

    this.imageUrl = `${ hostURL }/attachments/${ this.user?.id }/${ this.id }/${ this.id }/${ this.image.fileName }`
  }

  asForm() {
    return {
      defaultValues: {
        id: this.id,
        description: this.description,
        albumId: this.albumId,
        image: this.image,
        eventId: this.eventId
      },
      mode: 'onChange' as keyof ValidationMode,
      resolver: yupResolver(photoSchema)
    }
  }

  static fromFormData(data) {
    return new Photo(data)
  }

  asPayloadJSON() {
    return {
      description: this.description,
      imageFileName: this.image.fileName,
      imageFileSize: this.image.fileSize,
      imageFileType: this.image.fileType,
      imageId: this.image.id.toString(),
      imageTemp: this.image.temp,
      albumId: this.albumId,
      eventId: this.eventId
    }
  }
}

export interface PhotoFilters {
  page?: number,
  perPage: number,
  albumId?: string | number,
  eventId?: string | number
}
