// Hooks
import { useCallback, useMemo, useState, useEffect } from 'react'
import { usePopper } from 'react-popper'
import useOutsideClick from 'hooks/useOutsideClick'
import { Placement } from '@popperjs/core'

const defaults = { placement: 'bottom-start' }

export default function usePopover(userConfig: { placement?: Placement }) {
  const { placement } = useMemo(() => (Object.assign({}, defaults, userConfig)), [userConfig])

  const [opened, setOpened] = useState(false)

  const [referenceElement, setReferenceElement] = useState(null)

  const [popperElement, setPopperElement] = useState(null)

  const open = useCallback(() => {
    setOpened(true)
  }, [])

  const close = useCallback(() => {
    setOpened(false)
  }, [])

  const { styles, attributes } = usePopper(
    referenceElement,
    popperElement,
    { placement }
  )

  const handleOutsideClick = useCallback(() => {
    setOpened(false)
  }, [])

  useOutsideClick(popperElement, handleOutsideClick)

  useEffect(() => {
    const closeOnEscapeKey = event => event.key === 'Escape' && close()

    document.body.addEventListener('keydown', closeOnEscapeKey)

    return () => {
      document.body.removeEventListener('keydown', closeOnEscapeKey)
    }
  }, [close])

  return {
    opened,
    handleOpen: open,
    close,
    setReferenceElement,
    setPopperElement,
    popoverStyles: styles,
    attributes
  }
}
