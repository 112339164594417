// Common
import React, { FunctionComponent, ReactElement } from 'react'
import cn from 'classnames'
import styles from './navigationPanel.module.scss'

// Components
import { NavLink } from 'react-router-dom'
// import LanguagesDropdown from 'components/languagesDropdown/languagesDropdown'
import ProfileDropDown from '../profileDropDown/profileDropDown'
import Icon from 'components/icon'
import { Logo } from 'assets'
import AnimatedContainer from 'components/animatedContainer'
import Notifications from 'containers/Authenticated/components/notifications'
import Chat from '../chat'

// Hooks
import { useTranslation } from 'react-i18next'
import usePopover from 'hooks/usePopover'
import { useCallback, useMemo } from 'react'
import { useInject } from 'hooks/useInject'
import { useControlledPopover } from 'hooks/useControlledPopover'
import { useSubscribeValue } from 'hooks/useSubscribeValue'

// Services
import { ChatStateService } from 'services/chat-state.service'
import { NotificationsService } from 'services/notifications.service'
import { MessagesService } from 'services/mesages.service'

interface IComponentProps {
  year?: number
  onExpand?: (event) => null
}

const NavigationPanel: FunctionComponent<IComponentProps> = (): ReactElement => {
  const { t } = useTranslation()

  const {
    opened: notificationsOpened,
    setReferenceElement: notificationsSetReferenceElement,
    handleOpen: handleNotificationsOpen,
    setPopperElement: notificationsSetPopperElement,
    popoverStyles: { popper: notificationsPopperStyles },
    attributes: { popper: notificationsPopperAttributes }
  } = usePopover({ placement: 'bottom-end' })

  const chatStateService = useInject(ChatStateService)

  const {
    opened: chatOpened,
    setReferenceElement: chatSetReferenceElement,
    handleOpen: handleChatOpen,
    setPopperElement: chatSetPopperElement,
    popoverStyles: { popper: chatPopperStyles },
    attributes: { popper: chatPopperAttributes }
  } = useControlledPopover({ placement: 'bottom-end', control: chatStateService.opened })

  const setPopoversReference = useCallback((element: HTMLDivElement) => {
    chatSetReferenceElement(element),
    notificationsSetReferenceElement(element)
  }, [chatSetReferenceElement, notificationsSetReferenceElement])

  const notificationsService = useInject(NotificationsService)
  const messagesService = useInject(MessagesService)

  const unreadNotificationsCount = useSubscribeValue(notificationsService.unreadNotificationsCount)
  const unreadMessagesCount = useSubscribeValue(messagesService.unreadMessagesCount)

  const navLinks = useMemo(() => [
    { to: '/events?future', header: t('headers.nav-events') },
    { to: '/calendar', header: t('headers.nav-calendar') },
    { to: '/media', header: t('headers.nav-gallery') },
    { to: '/blog', header: t('headers.nav-blog') },
    { to: '/settings', header: t('headers.nav-settings') },
    { to: '/community', header: t('headers.nav-users') },
  ], [t])

  return (
    <>
      <nav
        className={ styles['nav'] }
        ref={ setPopoversReference }
      >
        <Logo className={ cn(styles['nav-item'], styles['logo']) } />

        { navLinks.map(({ to, header }, index) => (
          <NavLink
            key={ index }
            className={ ({ isActive }) => cn(styles['nav-item'], { [styles['active']]: isActive }) }
            to={ to }
          >
            { header }
          </NavLink>
        )) }

        <div className={ cn(styles['nav-divider']) } />

        <Icon
          className={ cn(styles['nav-item'], styles['icon']) }
          name='chat'
          appearance='interactive'
          width={ 22 }
          height={ 22 }
          size={ 42 }
          badge={ unreadMessagesCount }
          active={ chatOpened }
          onClick={ handleChatOpen }
        />

        <Icon
          className={ cn(styles['nav-item'], styles['icon']) }
          name='notifications'
          appearance='interactive'
          width={ 18 }
          height={ 25 }
          size={ 42 }
          badge={ unreadNotificationsCount }
          active={ notificationsOpened }
          onClick={ handleNotificationsOpen }
        />

        <Icon
          className={ cn(styles['nav-item'], styles['icon']) }
          name='search'
          appearance='interactive'
          width={ 20 }
          height={ 20 }
          size={ 42 }
        />

        <Icon
          className={ cn(styles['nav-item'], styles['icon']) }
          name='filter'
          appearance='interactive'
          width={ 20 }
          height={ 20 }
          size={ 42 }
        />

        <Icon
          className={ cn(styles['nav-item'], styles['icon']) }
          name='location'
          appearance='interactive'
          width={ 20 }
          height={ 20 }
          size={ 42 }
        />

        {/* <LanguagesDropdown
          className={ styles['nav-language'] }
        /> */}

        <ProfileDropDown />

        <AnimatedContainer
          show={ notificationsOpened }
          ref={ notificationsSetPopperElement }
          className={ styles['popover'] }
          style={ notificationsPopperStyles }
          { ...notificationsPopperAttributes }
        >
          <Notifications />
        </AnimatedContainer>

        <AnimatedContainer
          show={ chatOpened }
          ref={ chatSetPopperElement }
          className={ styles['popover'] }
          style={ chatPopperStyles }
          { ...chatPopperAttributes }
        >
          <Chat />
        </AnimatedContainer>
      </nav>
    </>
  )
}

export default NavigationPanel
