// Common
import React, { FunctionComponent, ReactElement, useEffect } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import styles from './index.module.scss'

// Pages
import Home from '../UnAuthenticated/pages/Home/home'
import AuthorizationPage from '../UnAuthenticated/pages/Authorization'

// Components
import NavigationPanel from '../UnAuthenticated/components/navigationPanel/navigationPanel'
import { PrivacyPolicy } from './Policy'
import { useDispatch } from 'react-redux'
import { requestAdminCheck } from '../../redux/actions/admin/auth'

const AdminComponent: FunctionComponent = (): ReactElement => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(requestAdminCheck())
  }, [])

  return(
    <>
      <NavigationPanel />

      <div className={ styles['admin-page'] }>
        <Routes>
          <Route path='privacy' Component={ PrivacyPolicy } />
          <Route path='/authorization/*' element={ <AuthorizationPage /> } />
          <Route path='*' element={ <Navigate to='privacy' /> }></Route>
        </Routes>
      </div>
    </>
  )
}

export default AdminComponent
