// Common
import { hostURL } from 'apiServices/http.service'
import io, { Socket } from 'socket.io-client'
import { Injector } from 'types/injector.class'

// Type
import { filter, map, Subject } from 'rxjs'

// Services
import { ProfileService } from './profile.service'

// Types
import { Injectable } from 'types/injectable.interface'
import { WSMessage, wsMessages } from 'types/ws-message.type'

export class WebsocketsService implements Injectable {

  private profileService: ProfileService
  private socket: Socket
  private messages = new Subject<[WSMessage, unknown]>()

  constructor(
    injector: Injector,
  ) {
    this.profileService = injector.get(ProfileService)
  }

  emit(event: string, data: object) {
    this.socket.emit(event, data)
  }

  get(key: WSMessage) {
    return this.messages
      .pipe(
        filter(([type]) => type === key),
        map(([, value]) => value)
      )
  }

  async attach() {
    const currentUser = await this.profileService.getAsync()

    this.socket = io(hostURL.origin, {
      transports: ['websocket'],
      query: { userId: currentUser.id },
      extraHeaders: {
        Authorization: `Bearer ${ localStorage.getItem('sessionToken') }`
      }
    })

    wsMessages.forEach(messageType => {
      this.socket.on(messageType, data => {
        this.messages.next([messageType, data])
      })
    })
  }

  detach() {
    this.socket?.disconnect?.()
  }
}
