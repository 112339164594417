import { useState, RefObject, useCallback, useEffect, useRef } from "react";

type UseDropDownType = [ boolean, () => void ]

export default function useDropdown(containerRef: RefObject<HTMLDivElement>): UseDropDownType {
  const [opened, setOpened] = useState(false)
  const initial = useRef(true)

  useEffect(() => {
    if (initial.current) {
      initial.current = false;
      return
    }

    if (!opened) return;

    const clickOutside = ({ target }) => {
      if (containerRef?.current?.contains(target)) return;

      document.removeEventListener('mousedown', clickOutside);
      setOpened(false);
    };
    document.addEventListener('mousedown', clickOutside);

    return (() => {
      document.removeEventListener('mousedown', clickOutside);
    })
  }, [opened, containerRef])

  const trigger = useCallback(() => {
    setOpened(opened => !opened)
  }, [])

  return [opened, trigger]
}
