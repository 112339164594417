import { hostURL } from 'apiServices/http.service'
import { Post, PostFilters } from 'models/post.model'
import { Injector } from 'types/injector.class'
import { BaseRestInfiniteScrollService } from './base-rest-infinite-scroll.service'
import { ProfileService } from './profile.service'

export class PostsService extends BaseRestInfiniteScrollService<Post, PostFilters> {
  protected profileService: ProfileService

  protected entityName = 'Post'

  constructor(
    injector: Injector,
  ) {
    super(injector)
    this.profileService = injector.get(ProfileService)
  }

  async buildUrl() {
    const profile = await this.profileService.getAsync()

    return new URL(`${ hostURL }/users/${ profile.id }/blogs`)
  }

  buildItem(item: object) {
    return new Post(item)
  }

  getItemFromResponse(response: object) {
    return response['blog']
  }

  getItemsFromResponse(response: object) {
    return response['blogs']
  }
}

