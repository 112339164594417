// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabs_tabs__jlzYX {
  border: 0.3rem solid rgba(52, 6, 56, 0.8);
  border-radius: 3.4rem;
  display: flex;
  flex-direction: row;
  overflow: hidden;
}
.tabs_tabs-item__tZLSa {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-transform: uppercase;
  border-right: 0.3rem solid rgba(52, 6, 56, 0.8);
  font-family: Futura;
  font-size: 1.6rem;
  font-weight: 500;
}
.tabs_tabs-item__tZLSa.tabs_active__zugOC {
  font-weight: 700;
}
.tabs_tabs-item__tZLSa:last-child {
  border-right: 0;
}
.tabs_tabs__jlzYX.tabs_light__\\+wHpI {
  height: 5.6rem;
  border-color: #7D5780;
}
.tabs_tabs__jlzYX.tabs_light__\\+wHpI .tabs_tabs-item__tZLSa {
  flex: 1 1;
  border-color: #7D5780;
  color: #FFFFFF;
}
.tabs_tabs__jlzYX.tabs_light__\\+wHpI .tabs_tabs-item__tZLSa.tabs_active__zugOC {
  background-color: #7D5780;
}
.tabs_tabs__jlzYX.tabs_dark__q75IH {
  height: 6.8rem;
  width: fit-content;
}
.tabs_tabs__jlzYX.tabs_dark__q75IH .tabs_tabs-item__tZLSa {
  width: 17rem;
  color: #FCF9FB;
}
.tabs_tabs__jlzYX.tabs_dark__q75IH .tabs_tabs-item__tZLSa.tabs_active__zugOC {
  background-color: rgba(52, 6, 56, 0.8);
}`, "",{"version":3,"sources":["webpack://./src/components/tabs/index.module.scss"],"names":[],"mappings":"AAAA;EACE,yCAAA;EACA,qBAAA;EACA,aAAA;EACA,mBAAA;EACA,gBAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;EACA,yBAAA;EACA,+CAAA;EACA,mBAAA;EACA,iBAAA;EACA,gBAAA;AACJ;AACI;EACE,gBAAA;AACN;AAEI;EACE,eAAA;AAAN;AAIE;EACE,cAAA;EACA,qBAAA;AAFJ;AAII;EACE,SAAA;EACA,qBAAA;EACA,cAAA;AAFN;AAIM;EACE,yBAAA;AAFR;AAOE;EACE,cAAA;EACA,kBAAA;AALJ;AAOI;EACE,YAAA;EACA,cAAA;AALN;AAOM;EACE,sCAAA;AALR","sourcesContent":[".tabs {\n  border: .3rem solid rgba(52, 6, 56, 0.8);\n  border-radius: 3.4rem;\n  display: flex;\n  flex-direction: row;\n  overflow: hidden;\n\n  &-item {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    cursor: pointer;\n    text-transform: uppercase;\n    border-right: .3rem solid rgba(52, 6, 56, 0.8);\n    font-family: Futura;\n    font-size: 1.6rem;\n    font-weight: 500;\n\n    &.active {\n      font-weight: 700;\n    }\n\n    &:last-child {\n      border-right: 0;\n    }\n  }\n\n  &.light {\n    height: 5.6rem;\n    border-color: #7D5780;\n\n    .tabs-item {\n      flex: 1;\n      border-color: #7D5780;\n      color: #FFFFFF;\n \n      &.active {\n        background-color: #7D5780;\n      }\n    }\n  }\n\n  &.dark {\n    height: 6.8rem;\n    width: fit-content;\n\n    .tabs-item {\n      width: 17rem;\n      color: #FCF9FB;\n\n      &.active {\n        background-color: rgba(52, 6, 56, 0.8);\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabs": `tabs_tabs__jlzYX`,
	"tabs-item": `tabs_tabs-item__tZLSa`,
	"active": `tabs_active__zugOC`,
	"light": `tabs_light__+wHpI`,
	"dark": `tabs_dark__q75IH`
};
export default ___CSS_LOADER_EXPORT___;
