import { Service } from '../types/reduxCRUD'
import { RequestParams } from './models/types'
import { hostURL } from './http.service'
import { User, UsersFilters } from '../models/user.model'
import { camelToSnakeCase } from '../helpers/snakeToCamelCase'
import { isNil } from 'helpers/base'

const service: Service<User> = {
  getItems(filters: UsersFilters): RequestParams {
    const url = new URL(`${ hostURL }/users`)

    return {
      url,
      params: {
        page: filters.page,
        per_page: filters.perPage,
        role: filters.role,
        name: filters.name,
        sort_column: camelToSnakeCase(filters.sortColumn || ''),
        sort_type: filters.sortType,
        friends: isNil(filters.friends) ? undefined : !!filters.friends,
        followers: isNil(filters.followers) ? undefined : !!filters.followers,
        followings: isNil(filters.followings) ? undefined : !!filters.followings,
      }
    }
  },
  getItem(userId): RequestParams {
    const url = new URL(hostURL + `/users/${ userId }`)
    return { url }
  },
  upsertItem(): RequestParams {
    throw new Error('API not available')
  },
  deleteItem(): RequestParams {
    throw new Error('API not available')
  }
}

export default service
