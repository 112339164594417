// Common
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from "react";
import { useFieldArray } from "react-hook-form";
import cn from 'classnames';
import { usePopper } from "react-popper";
import useDropDown from "../../../hooks/useDropDown";
import styles from "../index.module.scss";

// Components
import MultiselectOption from "./multiselect-option";
import AnimatedContainer from 'components/animatedContainer'
import MultiselectDropDownTrigger from "./multiselect-drop-down-trigger";

// Types
import { IMultiselectDropDown } from "../types/dropdown";
import { OptionType } from "../types/option";

const MultiselectDropDown: FunctionComponent<IMultiselectDropDown> = ({
  options,
  placeholder,
  name,
  showBy = 'title',
  title,
  theme = 'light',
  className,
  control
}) => {
  const popoverRef = useRef<HTMLDivElement>(null);
  const [triggerRef, setTriggerRef] = useState<HTMLDivElement>(null)
  const [popover, setPopover] = useState<HTMLDivElement>(null)

  const {
    styles: { popper: popperStyles },
    attributes: { popper: popperAttributes }
  } = usePopper(triggerRef, popover, { placement: 'bottom' })

  const [opened, trigger] = useDropDown(popoverRef);

  const { fields, remove, append } = useFieldArray({ control, name, keyName: 'key' })

  useEffect(() => {
    return () => {
      opened && trigger();
    }
  }, []);

  // UseEffect is needed because of usePopper uses state object instead of ref object
  useEffect(() => {
    popoverRef.current = popover;
  }, [popover])

  const handleSelect = useCallback((option) => () => {
    const optionIndex = (fields as unknown as OptionType[]).findIndex(({ value }) => value === option.value)

    optionIndex === -1 ? append(option) : remove(optionIndex)
  }, [fields, append, remove])

  return (
      <div
          className={ cn(
              styles['drop-down'],
              styles[theme],
              { [styles['expanded']]: opened },
              className
          ) }
      >
        { title &&
          <span className={ styles['drop-down-title'] }>{ title }</span>
        }

        <MultiselectDropDownTrigger
            trigger={ trigger }
            placeholder={ placeholder }
            selectedOptions={ fields as unknown as OptionType[] }
            showBy={ showBy }
            ref={ setTriggerRef }
        />

        { options.length !== 0 &&
          <AnimatedContainer show={ opened }>
            <div
              className={ styles['drop-down-options'] }
              ref={ setPopover }
              style={ popperStyles }
              { ...popperAttributes }
            >
              {
                options.map((option: OptionType) =>
                  <MultiselectOption
                      option={ option }
                      onSelect={ handleSelect }
                      checked={ !!(fields as unknown as OptionType[]).find(({ value }) => value === option.value) }
                      key={ option.value }
                      showBy={ showBy }
                  />
                )
              }
            </div>
          </AnimatedContainer>
        }
      </div>
  )
};

export default MultiselectDropDown;
