import { NotificationType } from 'types/notification-type'

export class Notification {
  id: number
  read: boolean
  type: NotificationType
  createdAt: Date
  avatar: string

  constructor(data?) {
    this.id = data?.id
    this.read = data?.read
    this.type = data?.type
    this.createdAt = new Date(data?.createdAt)

    this.avatar = null // TODO implement notification avatar
  }
}

export interface NotificationsFilters {
  page?: number,
  perPage: number
}
