// Common
import { object, string, boolean, date } from 'yup'
import i18n from '../i18n'

// Types
import { Country } from './country.model'

// Helpers
import { objToCamelCase } from '../helpers/snakeToCamelCase'


// eslint-disable-next-line @typescript-eslint/no-unused-vars
const locationValidationSchema = object({
  // country: string().required(() => i18n.t('validations.is_required')),
  city: string().required(() => i18n.t('validations.is_required')),
  address: string(),
  active: boolean(),
  startDate: date().required(() => i18n.t('validations.is_required')),
  endDate: date().required(() => i18n.t('validations.is_required')),
  title: string()
})

export class Location {
  id?: number
  country: Country
  city: string
  address?: string
  active: boolean
  startDate: Date
  endDate: Date
  title?: string

  constructor(object) {
    const camelCasedObject = objToCamelCase(object)

    this.id = camelCasedObject.id
    this.country = camelCasedObject.country ? new Country(camelCasedObject.country) : null;
    this.city = camelCasedObject.city;
    this.address = camelCasedObject.address
    this.active = camelCasedObject.active;
    this.startDate = camelCasedObject.startDate ? new Date(camelCasedObject.startDate) : null;
    this.endDate = camelCasedObject.endDate ? new Date(camelCasedObject.endDate) : null;
    this.title = camelCasedObject.title;
  }

  static createItem() {
    return new Location({ country: null, city: '', active: false, date: '', title: '' })
  }
}
