// Common
import React, { FunctionComponent, ReactElement, useMemo } from 'react'
import styles from './events.module.scss';
import { Outlet, Routes, Route, useSearchParams, useParams } from 'react-router-dom'
import { isNil } from 'lodash'

// Components
import Delimiter from '../../components/delimiter/delimiter'
import EventPageHeader from './headers/eventPageHeader'
import EventsListHeader from './headers/eventsListHeader'

const Events: FunctionComponent = (): ReactElement => {
  const { userId } = useParams()
  const [searchParams] = useSearchParams()
  const filters = useMemo(() => ({
    future: !isNil(searchParams.get('future')),
    past: !isNil(searchParams.get('past')),
    pending: !isNil(searchParams.get('pending')),
    userId
  }), [searchParams, userId])

  return (
    <>
      <Routes>
        <Route
          index={ true }
          element={
            <>
              <EventsListHeader />
              <Delimiter className={ styles['delimiter'] } />
            </>
          }
        />
        <Route
          path='*'
          element={
            <>
              <EventPageHeader />
              <div className={ styles['indent'] } />
            </>
          }
        />
      </Routes>

      <Outlet context={ filters } />
    </>
  )
}

export default Events
