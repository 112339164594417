// Hooks
import { useCallback, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'redux/store'
import { isEqual } from 'lodash'

// Hooks
import { useInfiniteScroll } from './useInfiniteScroll'

// Types
import { RootReducerState } from 'redux/reducers/root.reducer'
import { ActionCreators, Filters, State } from 'types/reduxCRUD'

export function useEntitiesList<T, TFilters extends Filters>({
  actions,
  selector,
  filters,
  containerId
}: {
  actions: ActionCreators<T, TFilters>,
  selector: (state: RootReducerState) => State<T>,
  filters: TFilters,
  containerId: string
}) {
  const dispatch = useDispatch()

  const { items, hasMore, pending, reset, error } = useSelector(selector)

  useEffect(() => {
    return () => {
      dispatch(actions.clear())
    }
  }, [actions, dispatch])

  const loadMore = useCallback(() => {
    if(!hasMore || pending || error) return

    dispatch(actions.read({ ...filters, perPage: filters.perPage }))
  }, [pending, hasMore, dispatch, error, actions, filters])

  const previousFilters = useRef(filters)

  useEffect(() => {
    if (isEqual(previousFilters.current, filters)) { return }

    previousFilters.current = filters

    dispatch(actions.clear())
  }, [dispatch, filters, actions])

  const scrollContainerRef = useRef(document.getElementById(containerId))

  useInfiniteScroll({
    containerRef: scrollContainerRef,
    loadMore,
    reset
  })

  return { items }
}
