import { BaseModel } from "./base.model";

export default class Language extends BaseModel {
  id: number;
  title: string;
  iso2: string;
  iso3: string;


  constructor(object) {
    super(null);
    this.id = object.id;
    this.title = object.title;
    this.iso2 = object.iso2;
    this.iso3 = object.iso3;
  }
}
