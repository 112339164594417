import { put, all, call, takeLatest } from 'redux-saga/effects'
import { REQUEST_POLICY, receivedPolicy, requestPolicyFailed, REQUEST_SAVE_POLICY } from '../../actions/admin/policy'
import { request } from '../../../apiServices/http.service'
import { getPolicy, updatePolicy } from '../../../apiServices/admin/policy.service'
import { Policy } from '../../../types/policy'


export function* requestPolicy() {
  const { response, error } = yield call(request, getPolicy())

  if (response) {
    yield put(receivedPolicy(new Policy(response.policy)))
  } else {
    put(requestPolicyFailed(error))
  }
}

export function* savePolicy({ type, data }) {
  const { response, error } = yield call(request, updatePolicy(data))

  if (response) {
    yield put(receivedPolicy(new Policy(response.policy)))
  } else {
    put(requestPolicyFailed(error))
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(REQUEST_POLICY, requestPolicy),
    yield takeLatest(REQUEST_SAVE_POLICY, savePolicy)
  ])
}
