import React, { FunctionComponent, memo, ReactElement } from "react";
import cn from 'classnames';
import styles from "./aboutMe.module.scss";

// Hooks
import { useTranslation } from "react-i18next";

interface IComponentProps {
  aboutMe: string
  className?: string
}

const AboutMe: FunctionComponent<IComponentProps> = ({
  aboutMe,
  className
}): ReactElement => {
  const [t] = useTranslation();

  return (
      <div className={ cn(styles['about-me'], className) }>
        <span className={ styles['about-me-title'] }>{ t('headers.general_info') }</span>
        <span className={ styles['about-me-body'] }>{ aboutMe }</span>
      </div>
  )
}

export default memo(AboutMe);
