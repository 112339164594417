// Common
import React, { FunctionComponent, ReactElement } from 'react'
import styles from './profileAvatar.module.scss'

// Hooks
import useUpload from 'hooks/useUpload'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

// Components
import { AvatarPlaceholder, CameraIcon } from 'assets'

// Actions
import { requestUpdateProfile } from 'redux/actions/profile.action'

// Types
import { Attachment } from 'types/attachment'

interface IComponentProps {
  avatarUrl: string
  editable?: boolean
}

const ProfileAvatar: FunctionComponent<IComponentProps> = ({ avatarUrl, editable = true }): ReactElement => {
  const dispatch = useDispatch()
  const fileToUpload = useRef<File>()
  const [newAvatarUrl, setNewAvatarUrl] = useState<string>(null)
  const { upload, attachment } = useUpload()
  const [avatarLoaded, setAvatarLoaded] = useState(true)

  const processFile = useCallback((file: File) => {
    if (!file) { return }

    upload(file)
    fileToUpload.current = file
    setNewAvatarUrl(URL.createObjectURL(file))
    setAvatarLoaded(true)
  }, [upload, fileToUpload])

  const handleChange = useCallback(event => {
    processFile(event?.target?.files?.[0])
  }, [processFile])

  const handleError = useCallback(() => {
    setAvatarLoaded(false)
  }, [avatarLoaded])

  useEffect(() => {
    if (!attachment) { return }

    const avatar: Attachment = {
      fileName: attachment.fileName,
      temp: true,
      fileSize: attachment.fileSize,
      fileType: attachment.fileType,
      id: attachment.id
    }
    dispatch(requestUpdateProfile({ avatar }))
  }, [attachment, dispatch])

  return (
      <div className={ styles['profile-avatar'] }>
        { (avatarUrl || newAvatarUrl) && avatarLoaded &&
            <img
              className={ styles['profile-avatar-image'] }
              src={ newAvatarUrl || avatarUrl }
              alt='Avatar'
              onError={ handleError }
            />
        }

        { /* TODO change to the placeholder from design */ }
        { (!avatarLoaded || !(avatarUrl || newAvatarUrl)) &&
          <AvatarPlaceholder className={ styles['profile-avatar-placeholder'] } />
        }

        { editable &&
          <label className={ styles['profile-avatar-trigger'] }>
            <input
                onChange={ handleChange }
                type='file'
                name='avatar'
                accept='image/*'
                capture='user'
            />
            <CameraIcon className={ styles['profile-avatar-trigger-icon'] } />
          </label>
        }
      </div>
  )
}

export default ProfileAvatar
