import {
  RECEIVED_LANGUAGES_LIST,
  REQUEST_LANGUAGES_LIST_FAILED
} from "../actions/language.action";
import Language from "../../apiServices/models/language.model";

export interface State {
  data: Language[],
  error: string
}
const initialState: State = {
  data: [],
  error: null
};

export default function languageReducer(
  state: State = initialState,
  action: { type: string, languages: Language[], currentLanguage: Language, error: string }
): Partial<State> {
  switch (action.type) {
    case RECEIVED_LANGUAGES_LIST:
      return { ...state, data: action.languages, error: null }

    case REQUEST_LANGUAGES_LIST_FAILED:
      return { ...state, error: action.error };

    default:
      return state;
  }
}
