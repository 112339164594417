// Common
import React, { FunctionComponent, ReactElement } from 'react'
import styles from './index.module.scss'
import cn from 'classnames'

// Helpers
import { getMonthName, beginningOfDay, endOfDay, getWeekDayName } from 'helpers/date'

// Components
import { Calendar } from 'react-calendar'
import Icon from 'components/icon'

// Types
import { CalendarEvent } from 'types/calendarEvent'

// Hooks
import { useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

interface IComponentProps {
  year?: number;
  ranges?: CalendarEvent[],
  onExpand?: (month: Date) => void;
}

const colors = ['#2FB400'] // '#E03434', '#BA9B5A']

const CalendarYearComponent: FunctionComponent<IComponentProps> = ({
  year,
  onExpand,
  ranges
}): ReactElement => {
  const { t, i18n } = useTranslation()

  const months = useMemo(() => {
    const currentYear = year || new Date().getFullYear();
    return Array.from({ length: 12 }, (i, index) => new Date(currentYear, index, 1))
  }, [year])

  const handleExpand = useCallback((month) => () => {
    onExpand && onExpand(month)
  }, [onExpand])

  const renderWeekday = useMemo(() => (locale, date) => {
    const key = getWeekDayName(date.getDay())

    if (!i18n.exists(key)) { return '' }

    return t(key).charAt(0)
  }, [t, i18n])

  const renderMonth = useMemo(() => month => {
    const key = getMonthName(month.getMonth())

    if (!i18n.exists(key)) { return '' }

    return t(key)
  }, [t, i18n])

  const fullRanges = useMemo(() => (
    (ranges || [])
      .filter(range => !!range.from && !!range.to)
      .map(({ from, to }) => ({ from: beginningOfDay(from), to: endOfDay(to) }))
  ), [ranges])

  const styleInRanges = useCallback((date) => (
    fullRanges
      .map(({ from, to }, index) => {
        if (date.getTime() < from.getTime() || date.getTime() > to.getTime()) {
          return null
        }

        const start =
          date.getFullYear() === from.getFullYear() &&
          date.getMonth() === from.getMonth() &&
          date.getDate() === from.getDate()

        const end =
          date.getFullYear() === to.getFullYear() &&
          date.getMonth() === to.getMonth() &&
          date.getDate() === to.getDate()

        let className

        if (start && end) {
          className = styles['start-end']
        } else if (start) {
          className = styles['start']
        } else if (end) {
          className = styles['end']
        } else {
          className = styles['in']
        }

        return {
          className,
          style: { borderColor: colors[index % colors.length] }
        }
      })
      .filter(range => !!range)
  ), [fullRanges])

  return (
    <div className={ styles['months'] }>
      {
        months.map((month, index) => (
          <div
            key={ index }
            className={ styles['month'] }
          >
            <div className={ styles['month-name'] }>
              { renderMonth(month) }
            </div>

            <Calendar
              className={ styles['calendar'] }
              value={ month }
              formatShortWeekday={ renderWeekday }
              showNavigation={ false }
              showNeighboringMonth={ false }
              tileContent={ ({ date }) => (
                styleInRanges(date).map(({ className, style }, index) => (
                  <div
                    key={ index }
                    className={ cn(styles['range'], className) }
                    style={ style }
                  />
                ))
              ) }
            />

            <Icon
              name='expand'
              className={ styles['month-expand'] }
              onClick={ handleExpand(month) }
              size={ 20 }
            />
          </div>
        ))
      }
      <div className={ styles['filler'] } />
      <div className={ styles['filler'] } />
      <div className={ styles['filler'] } />
    </div>
  );
}

export default CalendarYearComponent
