// Common
import React, { FunctionComponent, ReactElement, useCallback, useEffect } from 'react'

// Hooks
import { useRef } from 'react'
import { useInfiniteScroll2 } from 'hooks/useInfiniteScroll2'
import { useInject } from 'hooks/useInject'
import { useSubscribeValue } from 'hooks/useSubscribeValue'

// Components
import Event from 'containers/Authenticated/pages/events/event'

// Services
import { EventsService } from 'services/events.service'

interface IComponentProps {}

const ListComponent: FunctionComponent<IComponentProps> = (): ReactElement => {
  const eventsService = useInject(EventsService)

  const events = useSubscribeValue(eventsService.items, [])

  useEffect(() => {
    return () => eventsService.clear()
  }, [eventsService])

  const loadMore = useCallback(() => {
    eventsService.requestMany({ perPage: 20 })
  }, [eventsService])

  const scrollContainerRef = useRef(document.getElementById('root'))

  useInfiniteScroll2({ containerRef: scrollContainerRef, loadMore })

  return (
    <>
      { events.map((event, index) => <Event key={ index } event={ event } />) }
    </>
  )
}

export default ListComponent
