// Common
import React, { FunctionComponent, ReactElement } from 'react'
import styles from './index.module.scss'
import cn from 'classnames'

// Types
import { PerformerUser } from "../../../../models/performerUser.model";

// Components
import Performer from '../performer'

// Hooks
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

interface IComponentProps {
  users: PerformerUser[],
  buttons?: ReactElement<{ className, onClick: (user: PerformerUser) => (event) => void }>[]
}
export const UsersListComponent: FunctionComponent<IComponentProps> = ({ users, buttons = null }) => {
  const navigate = useNavigate()
  const handleClickUser = useCallback((id) => navigate('/users/' + id), [navigate])

  return (
    <div className={ styles['users-list'] }>
      { users.map(user =>
        <div
          className={ styles['users-list-item'] }
          key={ user.id }
        >
          <Performer performer={ user } onPerformerClick={ handleClickUser } />

          <div className={ styles['users-list-item-actions'] }>
            { (buttons).map(action =>
              React.isValidElement(action)
                ? React.cloneElement(action, {
                  className: cn(action.props.className, styles['users-list-item-actions-item']),
                  onClick: action.props.onClick?.(user) as any
                })
                : action
            ) }
          </div>
        </div>
      ) }
    </div>
  )
}

export default UsersListComponent
