import { hostURL } from "./http.service";
import { RequestParams } from "./models/types";
import { User } from "../models/user.model";
import { ProfileImage } from "../types/profileImage";
import { Filters, Service } from '../types/reduxCRUD'
import { Location } from 'models/location.model'

export function getProfile(): RequestParams {
  const url = new URL(hostURL + '/profile');
  return { url }
}

export function updateProfile(updateParams: Partial<User>): RequestParams {
  const url = new URL(hostURL + '/users')
  const body = User.asPayloadJson(updateParams)

  return {
    url,
    body,
    method: 'PUT'
  }
}

export function updateProfileImage(image: string | Blob, type: ProfileImage): RequestParams {
  const url = new URL(hostURL + '/update_profile_images')
  const body = new FormData()

  body.append(type, image)

  return {
    url,
    body,
    method: 'PUT'
  }
}

export const profileLocationsService: Service<Location> = {
  getItems(filters: Filters): RequestParams {
    const url = new URL(`${ hostURL }/users/current_locations`)

    return {
      url,
      params: {
        page: filters.page,
        per_page: filters.perPage
      },
      withLocale: true
    }

  },
  upsertItem(location) {
    console.log("&&&&&&&&&&&&&&&&&&&&&&&&", location);
    const url = new URL(`${ hostURL }/users/current_locations/${ location.id ?? '' }`)

    const body = new FormData()
    body.set('start_date', location.startDate.toISOString())
    body.set('end_date', location.endDate.toISOString())
    body.set('title', location.title)
    body.set('country_id', location.country.id.toString())
    body.set('city', location.city)
    body.set('address', location.address)
    body.set('active', location.active.toString())

    return {
      url,
      body,
      method: location.id ? 'PUT' : 'POST'
    }
  },
  deleteItem({ id }: Location): RequestParams {
    const url = new URL(`${ hostURL }/users/current_locations/${ id }`)

    return {
      url,
      method: 'DELETE'
    }
  },
  getItem(id: string | number): RequestParams {
    const url = new URL(`${ hostURL }/users/current_locations/${ id }`)
    return {
      url
    }
  }
}
