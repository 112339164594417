import { hostURL } from 'apiServices/http.service'
import { Injectable } from 'types/injectable.interface'
import { Upload } from 'types/upload'

export class UploadsService implements Injectable {
  private uploads: Upload[] = []

  attach() {
    // console.log('attach')
  }

  add(uploads: Upload[]) {
    this.uploads = [...this.uploads, ...uploads]

    uploads.forEach(upload => {
      this.upload(upload)
    })
  }

  remove(uploads: Upload[]) {
    const ids = uploads.map(({ uid }) => uid)

    this.uploads = this.uploads.filter(({ uid }) => !ids.includes(uid))

    this.uploads
      .filter(({ uid }) => ids.includes(uid))
      .forEach(upload => {
        upload.progress.complete()
        upload.xhr?.abort?.()
      })
  }

  private async upload(upload: Upload) {
    if (upload.status) { return }

    upload.status = 'uploading'

    const data = new FormData()

    data.append('file', upload.file)

    upload.xhr = new XMLHttpRequest()

    upload.xhr.open('POST', `${ hostURL }/attachments/temp`) 
    upload.xhr.setRequestHeader('Authorization', `Bearer ${ localStorage.getItem('sessionToken') }`)

    upload.xhr.upload.addEventListener('progress', e => {
      upload.progress.next(e.loaded / e.total)
    })

    upload.xhr.addEventListener('load', () => {
      if (upload.xhr.status === 201) {
        upload.status = 'succeeded'
        upload.id = upload.xhr.response
      } else {
        try {
          upload.error = JSON.parse(upload.xhr.response).message
        } catch (e) {
          upload.error = upload.xhr.response
        }

        upload.status = 'failed'
      }

      upload.progress.next(1)
    })

    upload.xhr.send(data)
  }
}
