// Common
import React, { FunctionComponent, ReactElement, ReactNode, useCallback } from 'react'
import { createPortal } from 'react-dom'
import styles from './index.module.scss'
import cn from 'classnames'

// Helpers
import { uuidv4 } from 'helpers/base'

// Hooks
import { useState, useLayoutEffect, useEffect } from 'react'
import useTimeout from 'hooks/useTimeout'

interface IComponentProps {
  onClose?: (event) => void,
  isOpen: boolean,
  padding?: boolean
  children?: ReactNode
}

const Modal: FunctionComponent<IComponentProps> = ({
  onClose,
  children,
  isOpen,
  padding = true
}): ReactElement => {
  const [wrapperElement, setWrapperElement] = useState(null)

  const [visible, setVisible] = useState(false)

  const [runTimeout] = useTimeout(250)

  useLayoutEffect(() => {
    const wrapperId = uuidv4()

    const element = document.createElement('div')
    element.setAttribute('id', wrapperId)
    document.body.appendChild(element)

    setWrapperElement(element);

    return () => {
      element.parentNode.removeChild(element)
    }
  }, []);

  useEffect(() => {
    const closeOnEscapeKey = event => (event.key === 'Escape' && onClose ? onClose(event) : null)

    document.body.addEventListener('keydown', closeOnEscapeKey)

    return () => {
      document.body.removeEventListener('keydown', closeOnEscapeKey)
    }
  }, [onClose])

  useEffect(() => {
    if (isOpen) {
      setVisible(true)
    } else {
      runTimeout(() => setVisible(false))
    }
  }, [isOpen, runTimeout])

  const handleClickThrough = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
  }, [])

  if (!visible || !wrapperElement) return null;

  return createPortal(
    <div
      className={ cn(styles['container'], { [styles['out']]: !isOpen }) }
      onClick={ handleClickThrough }
    >
      <div
        className={ cn(
          styles['modal'],
          {
            [styles['out']]: !isOpen,
            [styles['with-padding']]: padding
          }
        ) }
      >
        { children }
      </div>
    </div>
    ,
    wrapperElement
  )
}

export default Modal
