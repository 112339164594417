import React, { FunctionComponent } from "react";
import styles from './underlinedTextx.module.scss';
import cn from 'classnames';

interface IComponentProps {
  text,
  onClick?: (event) => void
  className?: string
}

const UnderlinedText: FunctionComponent<IComponentProps> = ({ text, onClick: handleClick, className }): React.ReactElement => (
    <span
      className={ cn(styles['underline'], className ?? null) }
      onClick={ handleClick }
    >
      { text }
    </span>
);


export default React.memo(UnderlinedText);
