import React, { FunctionComponent, ReactElement, ReactNode } from 'react'
import styles from './index.module.scss'
import cn from 'classnames'

// Headers
import { useEffect, useState, useCallback } from 'react'

interface IComponentProps {
  initialIndex?: number
  className?: string
  onSelect?: (tabIndex: number, tabName: string) => void
  appearance?: 'light' | 'dark'
  tabs: { title: string, key: string }[]
  children?: ReactNode
}

const Tabs: FunctionComponent<IComponentProps> = ({
  initialIndex,
  onSelect,
  className,
  appearance,
  tabs
}: IComponentProps): ReactElement => {

  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    if (initialIndex !== null && initialIndex !== undefined) {
      setCurrentIndex(initialIndex)
    }
  }, [initialIndex])

  const handleClick = useCallback((index, key) => () => {
    if (currentIndex === index) { return }

    onSelect(index, key)
    setCurrentIndex(index)
  }, [onSelect, currentIndex]);

  return (
    <div className={ cn(styles['tabs'], styles[appearance], className) } >
      {
        tabs.map(({ title, key }, index) => (
          <div
            key={ key }
            className={ cn(styles['tabs-item'], { [styles['active']]: index === currentIndex }) }
            onClick={ handleClick(index, key) }
          >
            { title }
          </div>
        ))
      }
    </div>
  )
}

Tabs.displayName = 'Tabs'

export default Tabs

