import React, { ChangeEventHandler, CSSProperties, FunctionComponent, useCallback } from 'react'
import cn from 'classnames';
import styles from './controlledCheckBox.module.scss';
import { CheckMark } from "../../assets";

interface IComponentProps {
  checked: boolean
  label: string
  name: string
  className?: string
  style?: CSSProperties
  required?: boolean
  onSelect: (res: { [name: string]: boolean }, event: ChangeEventHandler) => void
}
export const ControlledCheckBox: FunctionComponent<IComponentProps> = ({
  label,
  checked,
  className,
  style,
  onSelect,
  ...props
}): React.ReactElement => {
  const handleSelect = useCallback((event) => {
    onSelect({ [event.target.name]: event.target.checked }, event)
  }, [onSelect])

  return (
      <label
          className={ cn(
              styles['check-box'],
              className
          ) }
          style={ style }
      >
        <input
            type='checkbox'
            checked={ checked }
            { ...props }
          onChange={ handleSelect }
        />
        <CheckMark className={ styles['check-box-mark'] } />
        <span>{ label }</span>
      </label>
  )
};

export default ControlledCheckBox;
