import React,  { FunctionComponent, ReactElement } from 'react'
import styles from './cancelAccount.module.scss'

export const CancelAccount: FunctionComponent<any> = (): ReactElement => {
  return (
    <div className={ styles['cancel-account'] }>
      <h1>Request to Delete Account and Data</h1>
      <br/>
      <div>We take your privacy seriously and are committed to ensuring that your personal data is protected. If you
        wish to delete your account and all associated data, please follow the instructions below.
      </div>
      <br/>
      <h2>How to Submit a Request to Delete Your Account and Data:</h2>
      <br/>
      <ul><h3>Send a Request via Email:</h3></ul>
      <li>Send an email to: <a href='mailto:support@cloobin.com'>support@cloobin.com</a> from associated email to your account you would like to delete</li>
      <li>In the subject line, include: Request to Delete Account</li>
      <li>In the body of the email, include your name, phone number and any other information that may help us identify your account.
      </li>
    </div>
  )
}
