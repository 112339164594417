import { RECEIVED_GENRES, REQUEST_GENRES_FAILED } from "../actions/genres.action";
import Genre from "../../apiServices/models/genre.model";

export interface State {
  data: Genre[],
  error: string
}

const initialState: State = {
  data: [],
  error: null
};

export default function genresReducer(
  state: State = initialState,
  action: { type: string, genres: Genre[], error: string }
): Partial<State> {
  switch (action.type) {
    case RECEIVED_GENRES:
      return { ...state, data: action.genres, error: null }
    case REQUEST_GENRES_FAILED:
      return { ...state, error: action.error };

    default:
      return state;
  }
}
