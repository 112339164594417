// Common
import React, { FunctionComponent, ReactElement } from 'react'
import styles from './index.module.scss'
import { format } from 'date-fns'

// Components
import Icon from 'components/icon'
import Image from 'components/image'

// Models
import { Notification } from 'models/notification.model'

// Hooks
import { useCallback, useEffect } from 'react'
import useContextMenu from 'hooks/useContextMenu'
import { useTranslation } from 'react-i18next'
import { useInject } from 'hooks/useInject'

// Services
import { NotificationsService } from 'services/notifications.service'

interface IComponentProps {
  notification: Notification,
  index: number
}

const NotificationComponent: FunctionComponent<IComponentProps> = ({ notification, index }): ReactElement => {
  const [t] = useTranslation()

  const notificationsService = useInject(NotificationsService)

  const deleteNotification = useCallback(() => {
    notificationsService.delete(notification)
  }, [notificationsService, notification])

  const contextMenu = useContextMenu([
    { title: 'Delete', action: deleteNotification }
  ])

  useEffect(() => {
    if (!notification.read) {
      notificationsService.upsert(notification, { reload: false })
      notification.read = true
    }
  }, [notificationsService, notification])

  return (
    <div
      className={ styles['item'] }
      key={ index }
    >
      <Image
        className={ styles['item-image'] }
        src={ notification.avatar }
      />

      <div className={ styles['item-info'] } >
        <div className={ styles['item-info-title'] } >
          { t(`notifications.${ notification.type }.title`) }
        </div>

        <div className={ styles['item-info-description'] } >
          { t(`notifications.${ notification.type }.description`) }
        </div>

        <div className={ styles['filler'] } />

        <div className={ styles['item-info-meta'] } >
          <div className={ styles['filler'] } />
          { format(notification.createdAt, 'dd LLL yyyy, hh:mm a') }
        </div>

        <Icon
          name='kebab'
          className={ styles['item-info-icon'] }
          size={ 16 }
          { ...contextMenu }
        />
      </div>
    </div>
  )
}

export default NotificationComponent
