// Common
import React, { FunctionComponent, ReactElement } from 'react'
import styles from './index.module.scss'
import { PlayerContext } from 'contexts/player.context'
import cn from 'classnames'

// Components
import Icon from 'components/icon'
import Image from 'components/image'

// Models
import { Track, TracksFilters } from 'models/track.model'

// Hooks
import { useCallback, useContext, useState } from 'react'
import useContextMenu from 'hooks/useContextMenu'
import useSubscribe from 'hooks/useSubscribe'
import { useInject } from 'hooks/useInject'

// Services
import { TracksService } from 'services/tracks.service'

interface IComponentProps {
  track: Track,
  filters: TracksFilters,
  index: number,
}

const TrackComponent: FunctionComponent<IComponentProps> = ({ track, filters, index }): ReactElement => {
  const [active, setActive] = useState<boolean>()
  const [playing, setPlaying] = useState<boolean>()
  const [progress, setProgress] = useState<number>(0)
  const [time, setTime] = useState<string>('00:00')

  const tracksService = useInject(TracksService)

  const deleteTrack = useCallback(() => {
    tracksService.delete(track)
  }, [tracksService, track])

  const contextMenu = useContextMenu([
    { title: 'Delete', action: deleteTrack }
  ])

  const state = useContext(PlayerContext)

  const handlePlay = useCallback(() => {
    state.filters.next({ ...filters, page: index })
  }, [state, filters, index])

  const handlePlayPause = useCallback(() => {
    state.triggerPlayPause.next()
  }, [state])

  const handleNextTrack = useCallback(() => {
    state.triggerNextTrack.next(1)
  }, [state])

  const handlePreviousTrack = useCallback(() => {
    state.triggerNextTrack.next(-1)
  }, [state])

  useSubscribe(state.currentId, id => {
    setActive(track.id === id)
  }, [track])

  useSubscribe(state.playing, value => {
    active && setPlaying(value)
  }, [active])

  useSubscribe(state.progress, value => {
    active && setProgress(value)
  }, [active])

  useSubscribe(state.time, value => {
    active && setTime(value)
  }, [active])

  return (
    <div className={ cn(styles['container'], active && styles['active']) }>
      <div className={ styles['item'] }>
        { !active && (
          <Icon
            name='play'
            className={ styles['item-play'] }
            onClick={ handlePlay }
          />
        ) }

        { active && (
          <Image
            src={ track.imageUrl }
            className={ styles['item-icon'] }
          />
        ) }

        <div className={ styles['item-title'] }>
          <div className={ styles['item-title-text'] }>
            { track.title }
          </div>

          <div className={ styles['item-title-subtitle'] }>
            { track.author }
          </div>
        </div>

        <div className={ styles['item-filler'] } />

        <div className={ styles['item-time'] }>
          { active ? `${ time } / ${ track.duration }` : track.duration }
        </div>

        { active && (
          <>
            <Icon
              name='rewind-left'
              className={ styles['item-icon'] }
              size={ 20 }
              onClick={ handlePreviousTrack }
            />

            <Icon
              name={ playing ? 'pause' : 'play' }
              className={ cn(styles['item-icon'], styles['play']) }
              size={ 20 }
              onClick={ handlePlayPause }
            />

            <Icon
              name='rewind-right'
              className={ styles['item-icon'] }
              size={ 20 }
              onClick={ handleNextTrack }
            />
          </>
        ) }

        { !active && (
          <div
            className={ styles['item-details'] }
            { ...contextMenu }
          >
            <Icon
              name='kebab'
              className={ styles['item-details-icon'] }
              size={ 16 }
              clickThrough={ true }
            />
          </div>
        ) }
      </div>

      { active && (
        <div className={ styles['progress'] } >
          <div className={ styles['progress-bar'] } style={ { width: `${ progress * 100 }%` } } />
        </div>
      ) }
    </div>
  )
}

export default TrackComponent
