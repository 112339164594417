// Common
import React, { FunctionComponent, ReactElement } from 'react'
import styles from './index.module.scss'

// Components
import Icon from 'components/icon'
import Image from 'components/image'
import Modal from 'components/modal'
import AlbumForm from '../albumForm'

// Models
import { Album } from 'models/album.model'

// Hooks
import { useCallback, useState } from 'react'
import useContextMenu from 'hooks/useContextMenu'
import { useNavigate } from 'react-router-dom'

// Services
import { AlbumsService } from 'services/albums.service'
import { useInject } from 'hooks/useInject'

interface IComponentProps {
  album: Album
}

const AlbumComponent: FunctionComponent<IComponentProps> = ({ album }): ReactElement => {
  const navigate = useNavigate()

  const albumsService = useInject(AlbumsService)

  const deleteAlbum = useCallback(() => {
    albumsService.delete(album)
  }, [albumsService, album])

  const handleOpen = useCallback(() => {
    navigate('/media/gallery/' + album.id)
  }, [navigate, album])

  const [formOpen, setFormOpen] = useState(false)

  const handleOpenForm = useCallback((opened: boolean) => () => {
    setFormOpen(opened)
  }, [])

  const contextMenu = useContextMenu([
    { title: 'Edit', action: handleOpenForm(true) },
    { title: 'Delete', action: deleteAlbum }
  ])

  return (
    <div
      className={ styles['item'] }
      onClick={ handleOpen }
    >
      <Image
        className={ styles['item-image'] }
        src={ album.imageUrl }
      />

      <div className={ styles['item-info'] } >
        <div className={ styles['item-info-title'] } >
          { album.title }
        </div>

        <div className={ styles['item-info-description'] } >
          { album.description }
        </div>

        <div className={ styles['filler'] } />

        <div className={ styles['item-info-meta'] } >
          10 files
          <div className={ styles['filler'] } />
          10 Jan 2020
        </div>
      </div>

      <Icon
        name='kebab'
        className={ styles['item-kebab-icon'] }
        size={ 16 }
        { ...contextMenu }
      />

      <Modal
        isOpen={ formOpen }
        onClose={ handleOpenForm(false) }
      >
        <AlbumForm
          onClose={ handleOpenForm(false) }
          album={ album }
        />
      </Modal>
    </div>
  )
}

export default AlbumComponent
