// Common
import { hostURL } from 'apiServices/http.service'
import { Injector } from 'types/injector.class'

// Types
import { BaseRestInfiniteScrollService } from './base-rest-infinite-scroll.service'
import { ChatMessage, ChatMessagesFilters } from 'models/chat-message'
import { Upload } from 'types/upload'

// RX
import { BehaviorSubject, debounceTime, Subject, takeUntil } from 'rxjs'

// Services
import { WebsocketsService } from './websockets.service'
import { ProfileService } from './profile.service'

export class MessagesService extends BaseRestInfiniteScrollService<ChatMessage, ChatMessagesFilters> {

  private websocketsService: WebsocketsService
  private profileService: ProfileService
  private alive = new Subject<void>()
  private _beforeMessageReceived = new Subject<void>()
  private _messageReceived = new Subject<void>()
  private _unreadCount = new BehaviorSubject<number>(0)

  public unreadMessagesCount = this._unreadCount.asObservable()
  public messageReceived = this._messageReceived.pipe(debounceTime(300))
  public beforeMessageReceived = this._beforeMessageReceived.pipe()

  protected entityName = 'Message'

  constructor(
    injector: Injector,
  ) {
    super(injector)
    this.websocketsService = injector.get(WebsocketsService)
    this.profileService = injector.get(ProfileService)

    this.websocketsService.get('message')
      .pipe(takeUntil(this.alive))
      .subscribe(value => {
        const message = new ChatMessage(value)

        if (message.roomId !== this._searchLastFilters.value?.roomId) { return }

        this._beforeMessageReceived.next()

        const currentItems = [...this._searchItems.value]

        if (currentItems.length === this._searchLastFilters.value.perPage * this._searchLastFilters.value.page) {
          currentItems.shift()
          this._searchHasMore.next(true)
          this._searchTotal.next(this._searchTotal.value + 1)
        }

        this._searchItems.next([...currentItems, message])

        this._messageReceived.next()
      })

    this.websocketsService.get('unreadMessagesCount')
      .pipe(takeUntil(this.alive))
      .subscribe((value: number) => {
        this._unreadCount.next(value)
      })
  }

  detach() {
    this.alive.next()
    this.alive.complete()
  }

  async buildUrl() {
    return new URL(`${ hostURL }/chat-messages`)
  }

  buildItem(item: object) {
    return new ChatMessage(item)
  }

  getItemFromResponse(response: object) {
    return response['message']
  }

  getItemsFromResponse(response: object) {
    return response['messages']
  }

  async requestOne() {
    throw new Error('API not available')
  }

  async delete() {
    throw new Error('API not available')
  }

  async upsert() {
    throw new Error('API not available')
  }

  async send(text: string, roomId: number, uploads: Upload[]) {
    super.upsert(
      new ChatMessage({ text: text, roomId, uploads }),
      { reload: false, showToast: false }
    )
  }

  protected setItems(response) {
    const currentItems = this._searchItems.value
    const items = this.getItemsFromResponse(response).map(this.buildItem).reverse()
    this._searchItems.next([...items, ...currentItems])
  }

  async markAsRead(message: ChatMessage) {
    if (!message.reactions.length) { return } // Data lost on backend

    const user = await this.profileService.getAsync()

    const reaction = message.reactions.find(({ userId }) => userId === user.id)

    if (!reaction.read) {
      super.upsert(message, { reload: false })
      message.reactions = message.reactions.map(reaction => reaction.userId === user.id ? { ...reaction, read: true } : reaction)
    }
  }
}
