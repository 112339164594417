import { getCurrentLanguage } from '../helpers/language'
import { RequestParams } from './models/types'

export const hostURL = getHostByEnvURL()

export async function request({
  url,
  headers: extraHeaders,
  withLocale = false,
  params,
  body,
  method
}: RequestParams) {
  if (withLocale) {
    const language = getCurrentLanguage()
    url.searchParams.set('locale', language?.iso3?.toLowerCase() || 'eng')
  }

  if (params) {
    Object.entries(params).forEach(([key, value]) => {
      if (value !== undefined) {
        url.searchParams.set(key, value.toString())
      }
    })
  }

  const headers = {
    ...(extraHeaders || {}),
    'Session-Token': localStorage.getItem('sessionToken'),
    Authorization: `Bearer ${ localStorage.getItem('sessionToken') }`
  }

  if (body && !(body instanceof FormData)) {
    headers['Content-Type'] = 'application/json'
    headers['Accept'] = 'application/json'
    body = JSON.stringify(body)
  }

  try {
    const response = await fetch(url, { method, headers, body: (body as BodyInit), credentials: 'include' })

    const contentType = response.headers.get('content-type')

    if (!response) return { error: { message: 'Did not receive a response' }}

    if (!contentType?.includes('application/json')) {
      return {
        error: {
          status: response.status,
          message: response['message']
        }
      }
    }

    const parsedResponse = await response.json()

    if (response.status < 400) {
      return { response: parsedResponse }
    } else if (response.status === 403) {
      return { error: { needVerify: true, ...parsedResponse }}
    } else {
      return { error: parsedResponse }
    }
  } catch (error) {
    console.warn(error)
    return { error }
  }
}

function getHostByEnvURL() {
  return new URL(process.env.REACT_APP_HOST_URL || 'https://cloobin-backend.fly.dev/api/v1')
}
