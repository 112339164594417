import { Service } from '../types/reduxCRUD'
import { hostURL } from './http.service'
import { RequestParams } from './models/types'
import { User, UsersFilters } from '../models/user.model'

export const blackListService: Service<User, UsersFilters> = {
  getItems({ page, perPage, userId }) {
    return {
      url: new URL(`${ hostURL }/users/black_lists`),
      params: { page, per_page: perPage, userId }
    }
  },
  upsertItem({ id }): RequestParams {
    // const url = new URL(`${ hostURL }/users/black_lists/${ id }`)
    // TODO The URL above should be correct. Waiting for back-end fixes
    const url = new URL(`${ hostURL }/users/black_lists`)

    return {
      url,
      params: { id },
      method: 'POST'
    }
  },
  deleteItem({ id: userId }): RequestParams {
    const url = new URL(`${ hostURL }/users/black_lists/${ userId }`)

    return {
      url,
      method: 'DELETE'
    }
  },
  getItem() {
    throw new Error('API not available')
  },
}

export const friendsService: Service<User, UsersFilters> = {
  getItems({ page, perPage, userId }) {
    return {
      url: new URL(`${ hostURL }/users/subscribes`),
      params: { page, per_page: perPage, userId }
    }
  },
  upsertItem({ id }): RequestParams {
    // const url = new URL(`${ hostURL }/users/friends/${ id }`)
    // TODO The URL above should be correct. Waiting for back-end fixes
    const url = new URL(`${ hostURL }/users/subscribes`)

    return {
      url,
      params: { id },
      method: 'POST'
    }
  },
  deleteItem({ id: userId }): RequestParams {
    const url = new URL(`${ hostURL }/users/subscribes/${ userId }`)

    return {
      url,
      method: 'DELETE'
    }
  },
  getItem() {
    throw new Error('API not available')
  },
}

export const funsService: Service<User, UsersFilters> = {
  getItems({ page, perPage, userId }) {
    return {
      url: new URL(`${ hostURL }/users/funs`),
      params: { page, per_page: perPage, userId }
    }
  },
  upsertItem(): RequestParams {
    throw new Error('API not available')
  },
  deleteItem(): RequestParams {
    throw new Error('API not available')
  },
  getItem() {
    throw new Error('API not available')
  },
}
