// Common
import React, { FunctionComponent, KeyboardEvent, ReactElement } from 'react'
import styles from './index.module.scss'
import cn from 'classnames'

// Types
import { ChatRoom } from 'models/chat-room'
import { Upload } from 'types/upload'

// Hooks
import { useInject } from 'hooks/useInject'
import { useCallback, useRef, useMemo, useState } from 'react'
import { useSubscribeValue } from 'hooks/useSubscribeValue'
import { useOnDestroy } from 'hooks/useOnDestroy'

// Services
import { ChatStateService } from 'services/chat-state.service'
import { MessagesService } from 'services/mesages.service'
import { ProfileService } from 'services/profile.service'
import { UploadsService } from 'services/uploads.service'

// Components
import Icon from 'components/icon'
import MessagesListComponent from '../messages-list'
import UploadComponent from './upload'

interface IComponentProps {
  room: ChatRoom
}

const MessagesComponent: FunctionComponent<IComponentProps> = ({ room }): ReactElement => {

  const chatStateService = useInject(ChatStateService)
  const messagesService = useInject(MessagesService)
  const profileService = useInject(ProfileService)
  const uploadsService = useInject(UploadsService)
  
  const [hovered, setHovered] = useState(false)

  const dragOver = useRef(0)

  const [uploads, setUploads] = useState<Upload[]>([])

  const inputRef = useRef<HTMLInputElement>()

  const profile = useSubscribeValue(profileService.profile)

  const participants = useMemo(() => (
    room.participants
      .filter(({ id }) => id !== profile?.id)
      .map(({ email }) => email ).join(', ')
  ), [room, profile])

  const handleBack = useCallback(() => {
    chatStateService.setRoom(null)
  }, [chatStateService])

  const handleSend = useCallback(() => {
    if (!inputRef.current.value.trim() && !uploads.length) { return }

    if (uploads.some(({ status }) => status === 'uploading' )) { return }

    messagesService.send(inputRef.current.value, room.id, uploads)

    inputRef.current.value = ''
    uploadsService.remove(uploads)
    setUploads([])
  }, [messagesService, uploadsService, room, uploads])

  const handleKeyUp = useCallback((event: KeyboardEvent<HTMLInputElement>) => {
    if (event.code !== 'Enter') { return }

    handleSend()
  }, [handleSend])

  const processFiles = useCallback((files: FileList) => {
    if (!files) { return }

    const newUploads = Array.from(files).map(file => new Upload(file))

    uploadsService.add(newUploads)

    setUploads(uploads => [...uploads, ...newUploads])
  }, [uploadsService])

  const handleDrop = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    dragOver.current = 0
    setHovered(false)

    processFiles(event.dataTransfer?.files)

    event.stopPropagation()
    event.preventDefault()
  }, [processFiles])

  const handleChange = useCallback(event => {
    processFiles(event?.target?.files)
  }, [processFiles])

  const handleDragEnter = useCallback(() => {
    dragOver.current += 1
    setHovered(dragOver.current > 0)
  }, [dragOver])

  const handleDragLeave = useCallback(() => {
    dragOver.current -= 1
    setHovered(dragOver.current > 0)
  }, [dragOver])

  const handleDragOver = useCallback((event: React.DragEvent<HTMLDivElement | HTMLLabelElement>) => {
    event.preventDefault()
  }, [])

  useOnDestroy(() => {
    uploadsService.remove(uploads)
  }, [uploadsService, uploads])

  return (
    <div
      className={ cn(styles['container'], { [styles['dragover']]: hovered }) }
      onDrop={ handleDrop }
      onDragOver={ handleDragOver }
      onDragEnter={ handleDragEnter }
      onDragLeave={ handleDragLeave }
    >
      <div className={ styles['header'] } >
        <Icon
          className={ styles['header-back-icon'] }
          name='full-arrow-left'
          size={ 10 }
          onClick={ handleBack }
        />

        <div className={ styles['header-title'] } >
          <div className={ styles['header-title-participants'] } >
            { participants }
          </div>
          <div className={ styles['header-title-last-seen'] } >
            Last seen at 12:43
          </div>
        </div>

        <div className={ styles['filler'] } />

        <Icon
          className={ styles['header-kebab-icon'] }
          name='kebab'
          size={ 16 }
        />
      </div>

      <MessagesListComponent room={ room } />

      { uploads.map((upload, index) => <UploadComponent key={ index } upload={ upload } />) }

      <div className={ styles['footer'] }>
        <input
          ref={ inputRef }
          className={ styles['footer-input'] }
          placeholder='Type something to send…'
          onKeyUp={ handleKeyUp }
        />

        <label className={ styles['footer-icon-container'] }>
          <input
            type='file'
            className={ styles['inputfile'] }
            onChange={ handleChange }
          />
          <Icon
            className={ styles['footer-icon'] }
            name='attachment'
            size={ 16 }
            clickThrough={ true }
          />
        </label>

        <Icon
          className={ cn(styles['footer-icon-container'], styles['footer-icon']) }
          name='smile'
          size={ 16 }
        />

        <Icon
          className={ cn(styles['footer-icon-container'], styles['footer-icon']) }
          name='send'
          size={ 16 }
          onClick={ handleSend }
        />
      </div>
    </div>
  )
}

export default MessagesComponent
