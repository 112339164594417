// Common
import React, { FunctionComponent, ReactElement } from 'react'
import styles from './index.module.scss';

// Components
import PageTabsHeader from 'components/pageTabsHeader'
import PostForm from './postForm'
import Modal from 'components/modal'
import Delimiter from '../../components/delimiter/delimiter'
import Post from './post'

// Services
import { PostsService } from 'services/posts.service'

// Hooks
import { useMemo, useCallback, useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import useIsOwnProfile from 'hooks/useIsOwnProfile'
import { useInfiniteScroll2 } from 'hooks/useInfiniteScroll2'
import { useInject } from 'hooks/useInject'
import { useSubscribeValue } from 'hooks/useSubscribeValue'

const Posts: FunctionComponent = (): ReactElement => {
  const [t] = useTranslation()
  const isOwnProfile = useIsOwnProfile()

  const tabs = useMemo(() => {
    return [
      { title: t('headers.blog_my'), route: '/blog/my' },
      { title: t('headers.blog_friends'), route: '/blog/friends' },
    ]
  }, [t])

  const [postFormOpen, setPostFormOpen] = useState(false)

  const handleOpenPostForm = useCallback((opened: boolean) => () => {
    setPostFormOpen(opened)
  }, [])

  const postsService = useInject(PostsService)

  const posts = useSubscribeValue(postsService.items, [])

  useEffect(() => {
    return () => postsService.clear()
  }, [postsService])

  const loadMore = useCallback(() => {
    postsService.requestMany({ perPage: 20 })
  }, [postsService])

  const scrollContainerRef = useRef(document.getElementById('root'))

  useInfiniteScroll2({ containerRef: scrollContainerRef, loadMore })

  return (
    <>
      {
        isOwnProfile
          ? <PageTabsHeader
            tabs={ tabs }
            buttons={ [{ title: t('buttons.post_add'), onClick: handleOpenPostForm(true) }] }
            />
          : <Delimiter className={ styles['delimiter'] } />
      }

      { posts.map((post, index) => <Post key={ index } post={ post } />) }

      <Modal
        isOpen={ postFormOpen }
        onClose={ handleOpenPostForm(false) }
      >
        <PostForm
          onClose={ handleOpenPostForm(false) }
        />
      </Modal>
    </>
  )
}

export default Posts
