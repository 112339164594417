import React, { FunctionComponent, ReactElement, useCallback, useState } from 'react';
import { IAccordionProps } from "../accordion/accordion";


interface IComponentProps {
  children: ReactElement[];
}

const AccordionsGroup: FunctionComponent<IComponentProps> = ({ children }): ReactElement => {
  const [expandedItemId, setExpandedItemId] = useState('');

  const onClick = useCallback((event) => {
    setExpandedItemId(
        expandedItemId === event.currentTarget.id ?
            ''
            :
            event.currentTarget.id
    )
  }, [setExpandedItemId, expandedItemId]);

  return (
      <>
        {
          React.Children.map(children, (item: ReactElement<IAccordionProps>) => React.isValidElement(item) ?
            React.cloneElement(item,
            {
              isExpandedFromProps: expandedItemId.toString() === item.props.id.toString(),
              onClick
            })
            :
            item
          )
        }
      </>
  )
};

export default AccordionsGroup;
