// Common
import React, { FunctionComponent, ReactElement, useCallback, useEffect } from 'react'
import styles from './contacts.module.scss'
import { object, string } from 'yup'
import i18n from 'i18n'
import { yupResolver } from '@hookform/resolvers/yup'

// Hooks
import { useDispatch } from 'react-redux'
import { useSelector } from 'redux/store'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

// Components
import DropDown from 'components/dropdown/single-dropdown/single-dropdown'
import Button from 'components/button'
import Input from 'components/input'
import Label from 'components/label'
import Textarea from 'components/textarea'

// Types
import { OptionType } from 'components/dropdown/types/option'

// Actions
import { createFeedback, requestContactUsSubjects } from 'redux/actions/contactUs.action'

const requestSchema = object({
  message: string().required(() => i18n.t('validations.is_required'))
})

interface IComponentProps {

}

const Contacts: FunctionComponent<IComponentProps> = (): ReactElement => {
  const [t, i18n] = useTranslation()
  const dispatch = useDispatch()

  const { subjects } = useSelector(({ contactUs  }) => contactUs)

  const { control, reset: resetForm, handleSubmit: handleFormSubmit } = useForm({
    resolver: yupResolver(requestSchema)
  });

  useEffect(() => {
    dispatch(requestContactUsSubjects());
    i18n.on('languageChanged', requestContactUsSubjects);

    return (() => {
      i18n.off('languageChanged', requestContactUsSubjects)
    })
  }, [dispatch, i18n])

  const handleSubmit = useCallback((data) => {
    const subject = subjects.find(({ id }) => id === data.subject?.value);
    dispatch(createFeedback({ ...data, subject }));
    resetForm()
  }, [subjects, dispatch, resetForm])

  return (
      <div className={ styles['contacts'] }>
        <h1>{ t('headers.contact_with_us') }</h1>
        <form className={ styles['contacts-form'] } onSubmit={ handleFormSubmit(handleSubmit) }>
          <div className={ styles['contacts-form-container'] }>
            <div className={ styles['contacts-form-container-group'] }>
              <DropDown
                  className={ styles['contacts-form-container-group-item'] }
                  options={ subjects.map(({ title, id: value }) => ({ title, value } as OptionType)) }
                  control={ control }
                  required={ t('SUBJECT_BLANK') as string }
                  placeholder={ t('placeholders.subject') }
                  name='subject'
                  title={ t('titles.subject') }
              />

              <Input
                  className={ styles['contacts-form-container-group-item'] }
                  control={ control }
                  name='fullName'
                  required={ t('FULL_NAME_BLANK') as string }
                  placeholder={ t('placeholders.enter_full_name') }
                  title={ t('titles.full_or_nick_names') }
              />

              <Input
                  className={ styles['contacts-form-container-group-item'] }
                  control={ control }
                  required={ t('EMAIL_BLANK') as string }
                  name='email'
                  placeholder={ t('placeholders.enter_email') }
                  title={ t('titles.email') }
              />
            </div>

            <div className={ styles['contacts-form-message'] }>
              <Label
                className={ styles['contacts-form-label'] }
                appearance='input'
                text={ t('headers.message') }
              />

              <Textarea
                control={ control }
                name='titles.message'
                placeholder={ t('placeholders.type_here') }
                rows={ 3 }
              />
            </div>   
          </div>
          <div className={ styles['contacts-form-actions'] }>
            <Button
                className={ styles['contacts-form-actions-button'] }
                appearance='transparent'
                type='submit'
            >
              { t('buttons.send_message') }
            </Button>
          </div>
        </form>
      </div>
  )
}

export default Contacts
