// Common
import React, { Fragment, FunctionComponent, ReactElement } from 'react'
import styles from '../settings.module.scss'
import cn from 'classnames'

// Hooks
import { useCallback, useMemo, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

// Components
import Input from 'components/input'
import Button from 'components/button'
import { PlusIcon, TrashIcon } from 'assets'

const SettingsContactInfo: FunctionComponent = (): ReactElement => {
  const { control } = useForm({})
  const [t] = useTranslation()
  const [inputRef, setInputRef] = useState(null)
  const { fields, remove, insert } = useFieldArray({ control, name: 'links', keyName: 'key' })

  const inputHeight = useMemo(() => inputRef?.offsetHeight, [inputRef])

  const handleAppend = useCallback(() =>
    insert(fields.length + 1, ''), [insert, fields]
  )

  const handleRemove = useCallback((index) => () =>
      fields.length > 1 && remove(index)
    , [remove, fields])

  return (
    <>
      <div className={ styles['settings-group-row'] }>
        <Input
          className={ styles['settings-group-row-item'] }
          control={ control }
          name='phoneNumber'
          value=''
          title={ t('titles.phone_number') }
          placeholder={ t('placeholders.enter_phone_number') }
          theme='dark'
        />

        <Input
          className={ styles['settings-group-row-item'] }
          control={ control }
          name='email'
          title={ t('titles.email_address') }
          placeholder={ t('placeholders.enter_email') }
          theme='dark'
        />

        <Input
          className={ styles['settings-group-row-item'] }
          control={ control }
          name='cloobinLink'
          title={ t('titles.cloobin_link') }
          placeholder={ t('placeholders.enter_cloobin_link') }
          theme='dark'
        />
      </div>

      <div className={ styles['settings-group-row'] }>
        <div className={ cn(
          styles['settings-group-row-item'],
          styles['multiple']
        ) }
        >
          { fields.map((link, index) =>
            <Fragment key={ index }>
              <Input
                control={ control }
                key={ link.key }
                name={ `links.${ index }.value` }
                title={ t('titles.other_links') }
                placeholder={ t('placeholders.enter_other_links') }
                theme='dark'
                ref={ setInputRef }
              />
              { index !== 0 &&
                <Button
                  className={ styles['action'] }
                  style={ { width: inputHeight + 'px' } }
                  height={ inputHeight + 'px' }
                  onClick={ handleRemove(index) }
                >
                  <TrashIcon />
                </Button>
              }
            </Fragment>
          ) }

          <Button
            className={ styles['action'] }
            style={ { width: inputHeight + 'px' } }
            height={ inputHeight + 'px' }
            onClick={ handleAppend }
          >
            <PlusIcon />
          </Button>
        </div>
      </div>
    </>
  )
}

export default SettingsContactInfo
