import { useEffect, useRef } from 'react'
import { Subject } from 'rxjs'

export function useOnDestroy(
  callback: () => void,
  dependencies: unknown[]
): void {
  const callbackRef = useRef<() => void>(callback)

  useEffect(() => {
    callbackRef.current = callback
  }, [dependencies, callback])

  const alive = useRef(new Subject<void>())

  useEffect(() => {
    const aliveSubject = alive.current

    return () => {
      aliveSubject.next()
      aliveSubject.complete()
    }
  }, [])

  useEffect(() => {
    const subscription = alive.current.subscribe(callbackRef.current)
    return () => subscription.unsubscribe()
  }, [])
}
