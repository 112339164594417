// Common
import React, { FunctionComponent, ReactElement,  } from 'react'
import cn from 'classnames'
import styles from './index.module.scss'

// Hooks
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { useSelector } from 'redux/store'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import useContextMenu from 'hooks/useContextMenu'
import { useInject } from 'hooks/useInject'
import { useSubscribeValue } from 'hooks/useSubscribeValue'
import useSubscribe from 'hooks/useSubscribe'

// Components
import Icon from 'components/icon'
import UsersInlineList from 'containers/Authenticated/components/usersInlineList'
import Delimiter from 'containers/Authenticated/components/delimiter/delimiter'
import EventMainData from './eventMainData'
import PerformersSearchForm from 'containers/Authenticated/components/performersSearchForm'
import Button from 'components/button'
import PrimaryModal from 'containers/Authenticated/components/primaryModal'
import Label from 'components/label'
import Modal from 'components/modal'
import PhotoForm from '../../media/photoForm/index'
import Photos from '../../media/photos/index'
import PageTabsHeader from 'components/pageTabsHeader'

// Types
import { InvitationStatus } from 'types/eventInvitation'

// Actions
import { eventPerformersActions } from 'redux/actions/events.action-creators'
import { PerformerUser } from 'models/performerUser.model'

// Services
import { EventsService } from 'services/events.service'

export const EventPageComponent: FunctionComponent = (): ReactElement => {
  const { eventId } = useParams()

  const dispatch = useDispatch()

  const { items: performers } = useSelector(({ eventPerformers }) => eventPerformers)
  const ownProfile = useSelector(({ profile }) => profile.profile.data)

  const [t] = useTranslation()
  const navigate = useNavigate()
 
  const eventsService = useInject(EventsService)

  const event = useSubscribeValue(eventsService.item)

  const [selectedTab, setSelectedTab] = useState<'performers' | 'photos' | 'pendingPerformers'>('performers')
  const [formOpened, setFormOpened] = useState(false)

  const deleteEvent = useCallback(() => {
    eventsService.delete(event)
  }, [eventsService, event])

  const contextMenu = useContextMenu([
    { title: 'Delete', action: deleteEvent }
  ])

  useSubscribe(eventsService.deleted, () => {
    navigate(-1)
  }, [navigate])

  useEffect(() => {
    dispatch(eventPerformersActions.read({ eventId }))
    eventsService.requestOne(parseInt(eventId))
  }, [eventsService, eventId, dispatch])

  const handleSelectTab = useCallback(tabName => () => {
    setSelectedTab(tabName)
  }, [])

  const handleFormClose = useCallback(() => {
    setFormOpened(opened => !opened)
  }, [])

  const handleSubmitForm = useCallback((formData) => {
    handleFormClose()
    navigate(`/events/${ eventId }/performers`, { state: { filters: formData, event: event }})
  }, [event, eventId, handleFormClose, navigate])

  const handleInvite = useCallback((status: InvitationStatus) => () => {
    dispatch(eventPerformersActions.upsert({ eventId, status } as PerformerUser))
  }, [eventId, dispatch])

  const [photoFormOpen, setPhotoFormOpen] = useState(false);

  const handleOpenPhotoForm = useCallback((opened: boolean) => () => {
    setPhotoFormOpen(opened);
  }, []);

  useEffect(() => {
    if (!eventId) return

    dispatch(eventPerformersActions.read({ eventId }));
  }, [dispatch, eventId]);
  return (
    !event
      // TODO implement loader from design
      ? <div>Loading...</div>
      :
      <div className={ cn(styles['event']) }>
        <EventMainData event={ event } />

        <Icon
          className={ styles['event-action'] }
          name='kebab'
          size={ 16 }
          { ...contextMenu }
        />

        { event.invitation &&
          <>
            <Delimiter className={ styles['delimiter'] } />

            <span
              className={ cn(
                styles['event-status'],
                { [styles['accepted']]: event.invitation.status === InvitationStatus.ACCEPTED },
                { [styles['rejected']]: event.invitation.status === InvitationStatus.REJECTED }
              ) }
            >
              { t(`headers.status.${ event.invitation.status }`, { value: event.user.profileName }) }
            </span>

            <div className={ styles['event-actions'] }>
              { event.invitation.status === InvitationStatus.PENDING &&
                <>
                  <Button
                    className={ styles['event-actions-button'] }
                    appearance='gold'
                    height='100%'
                    onClick={ handleInvite(InvitationStatus.ACCEPTED) }
                  >
                    { t('buttons.accept') }
                  </Button>

                  <Button
                    className={ styles['event-actions-button'] }
                    appearance='transparent'
                    onClick={ handleInvite(InvitationStatus.REJECTED) }
                  >
                    { t('buttons.reject') }
                  </Button>
                </>
              }

              <Button
                className={ styles['event-actions-button'] }
              >
                { t('buttons.chat') }
              </Button>
            </div>
          </>
        }

        <Delimiter className={ styles['delimiter'] } />

        { /* TODO Implement tabs according to design */ }
        <div className={ styles['event-tabs'] }>
          <Label
            appearance={ selectedTab === 'performers' ? 'gold' : 'gold-inactive' }
            className={ styles['event-tabs-item'] }
            onClick={ handleSelectTab('performers') }
            text={ t('buttons.performers') }
          />

          <Label
            appearance={ selectedTab === 'pendingPerformers' ? 'gold' : 'gold-inactive' }
            className={ styles['event-tabs-item'] }
            onClick={ handleSelectTab('pendingPerformers') }
            text={ t('buttons.pending_performers') }
          />

          <Label
            appearance={ selectedTab === 'photos' ? 'gold' : 'gold-inactive' }
            className={ styles['event-tabs-item'] }
            onClick={ handleSelectTab('photos') }
            text={ t('buttons.photos') }
          />

          <div className={ styles['filler'] }></div>

          { /* TODO show Add performers button if event is in pending status only */ }
          { 
            ownProfile.id === event.user.id &&
              <Button
                  className={ styles['event-tabs-container-add-performer'] }
                  appearance='gold'
                  onClick={ selectedTab === 'photos' ? 
                    handleOpenPhotoForm(true) 
                    : handleFormClose }
              >
                { selectedTab === 'photos' ? 
                    t('buttons.photo_add')
                    : t('buttons.add_performer')  }
                
              </Button>
          }
        </div>

        { selectedTab === 'performers' &&
          <UsersInlineList performers={ performers } />
        }

        { selectedTab === 'pendingPerformers' &&
          <UsersInlineList performers={ performers } />
        }

        { selectedTab === 'photos' &&
          <div>
            <div className={styles['event-page']}>
              <Modal isOpen={photoFormOpen} onClose={handleOpenPhotoForm(false)}>
                <PhotoForm
                  eventId={eventId}
                  onClose={handleOpenPhotoForm(false)}
                />
              </Modal>
              <div className={ styles['pictures'] } >
                {event && event.id &&
                  <Photos eventId={event?.id} />
                }
              </div>
            </div>
          </div>
        }

        <PrimaryModal
          isOpen={ formOpened }
          onClose={ handleFormClose }
          title={ t('headers.add_performer') }
        >
          <PerformersSearchForm onClose={ handleFormClose } onSubmit={ handleSubmitForm } />
        </PrimaryModal>
      </div>
  )
}

export default EventPageComponent
