export const REQUEST_ABOUT_US = 'REQUEST_ABOUT_US';
export const requestAboutUs = () => ({
  type: REQUEST_ABOUT_US
});

export const RECEIVED_ABOUT_US = 'RECEIVED_ABOUT_US';
export const receivedAboutUs = (body: unknown) => ({
  type: RECEIVED_ABOUT_US,
  body
});

export const REQUEST_ABOUT_US_FAILED = 'REQUEST_ABOUT_US_FAILED';
export const requestAboutUsFailed = (error: { message: string, status: number }) => ({
  type: REQUEST_ABOUT_US_FAILED,
  error
});
