// Common
import React, { FunctionComponent, ReactElement } from 'react'
import styles from './index.module.scss'

// Components
import Icon from 'components/icon'
import Input from 'components/input'
import ChatRoom from '../chatRoom'

// Hooks
import { useForm } from 'react-hook-form'
import { useInject } from 'hooks/useInject'
import { useEffect, useCallback, useRef } from 'react'
import { useInfiniteScroll } from 'hooks/useInfiniteScroll'

// Services
import { ChatRoomsService } from 'services/chat-rooms.service'
import { useSubscribeValue } from 'hooks/useSubscribeValue'

interface IComponentProps {

}

const ChatRooms: FunctionComponent<IComponentProps> = (): ReactElement => {
  const { control } = useForm({})

  const chatRoomsService = useInject(ChatRoomsService)

  const chatRooms = useSubscribeValue(chatRoomsService.items, [])

  useEffect(() => {
    return () => chatRoomsService.clear()
  }, [chatRoomsService])

  const loadMore = useCallback(() => {
    chatRoomsService.requestMany({ perPage: 20 })
  }, [chatRoomsService])

  const scrollContainerRef = useRef()

  useInfiniteScroll({
    containerRef: scrollContainerRef,
    loadMore
  })

  return (
    <div
      className={ styles['container'] }
      ref={ scrollContainerRef }
    >
      <Input
        className={ styles['search-input'] }
        control={ control }
        appearance='medium'
        theme='burgundy'
        name='query'
        leftIcon={
          <Icon
            className={ styles['search-input-icon'] }
            name='search'
            size={ 17 }
          />
        }
        rightIcon={
          <Icon
            className={ styles['search-input-kebab'] }
            name='kebab'
            size={ 16 }
          />
        }
      />

      { chatRooms.map((room, index) => <ChatRoom key={ index } index={ index } room={ room } />) }

    </div>
  )
}

export default ChatRooms
