// Common
import React, { FunctionComponent, ReactElement } from 'react'
import styles from './index.module.scss'

// Components
import ChatRooms from './chatRooms'
import Messages from './messages'

// Hooks
import { useInject } from 'hooks/useInject'

// Services
import { useSubscribeValue } from 'hooks/useSubscribeValue'
import { ChatStateService } from 'services/chat-state.service'

interface IComponentProps {

}

const Chat: FunctionComponent<IComponentProps> = (): ReactElement => {
  const chatStateService = useInject(ChatStateService)

  const room = useSubscribeValue(chatStateService.currentRoom)

  return (
    <div className={ styles['container'] } >
      { room ? <Messages room={ room } /> : <ChatRooms /> }
    </div>
  )
}

export default Chat
