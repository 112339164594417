// Common
import React, { FunctionComponent, ReactElement } from 'react';
import styles from './index.module.scss';
import TempImage from 'mocks/profile/coverPhoto.jpeg'

// Components
import Label from 'components/label'

// Headers
import { useTranslation } from 'react-i18next'

const NewsList: FunctionComponent = (): ReactElement => {

  const [t] = useTranslation()

  return (
    <>
      {
        Array.from(Array(10).keys()).map(index => (
          <div
            className={ styles['item'] }
            key={ index }
          >
            <img
              className={ styles['item-image'] }
              src={ TempImage }
              style={ { objectPosition: `${ Math.ceil(Math.random() * 100) }% ${ Math.ceil(Math.random() * 100) }%` } }
            />
            <div className={ styles['item-info'] } >
              <div className={ styles['item-info-title'] } >
                Description
              </div>

              <div className={ styles['item-info-description'] } >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
              </div>

              <div className={ styles['filler'] } />

              <div className={ styles['item-info-meta'] } >
                <Label
                  className={ styles['news-slider-body-label'] }
                  appearance='gold-strikethrough'
                  text={ t('headers.more') }
                />

                <div className={ styles['filler'] } />

                10 July 2020

                <div className={ styles['item-info-meta-label'] }>{ t('headers.time') }</div>

                10:00
              </div>
            </div>
          </div>
        ))
      }
    </>
  )
}

export default NewsList
