import { BehaviorSubject } from 'rxjs'

export class Upload {
  fileName: string
  uid = Symbol('upload')
  id?: string
  fileSize: number
  fileType: string
  duration?: number
  progress?: BehaviorSubject<number>
  error?: string
  xhr?: XMLHttpRequest
  file?: File
  status: 'uploading' | 'succeeded' | 'failed'

  constructor(file: File) {
    this.file = file
    this.fileName = file.name
    this.fileSize = file.size
    this.fileType = file.type

    this.progress = new BehaviorSubject<number>(0)
  }

  asPayloadJSON() {
    return {
      id: this.id,
      fileSize: this.fileSize,
      fileName: this.fileName,
      fileType: this.fileType
    }
  }
}
