// Common
import React, { FunctionComponent, ReactElement } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import cn from 'classnames'
import styles from './index.module.scss'

// Hooks
import useTimeout from 'hooks/useTimeout'
import { useCallback, useRef, useState } from 'react'
import { useTranslation, UseTranslationResponse } from 'react-i18next'
import { useLocation } from 'react-router'

// Components
import LoginForm from './login/login'
import RegisterForm from './register/register'
import VerificationPage from './verification/verification'
import ForgotPasswordPage from './forgotPassword/forgotPassword'
import Button from 'components/button'

// Assets
import { LoginShapes } from 'assets'

// Mocks
import { authorizationBackground } from 'mocks/authorization'
import ChangeForgotPassword from './changeForgotPassword/changeForgotPassword'

const ANIMATION_DURATION = 500;

const AuthorizationPage: FunctionComponent = (): ReactElement => {
  const { state } = useLocation()

  return (
      <div
      className={ styles['authorization-page'] }
      style={ { backgroundImage: `url(${ authorizationBackground }` } }
      >
        <Routes>
          <Route path='/' element={ <AuthorizeContainer /> } />

          { state
              ? <Route
                  path='code'
                  element={
                    <div className={ styles['authorization-page-container'] }>
                      <VerificationPage />
                    </div>
                  }
                />
              : null
          }

          <Route
            path='forgotPassword'
            element={
              <div className={ styles['authorization-page-container'] }>
                <ForgotPasswordPage />
              </div>
            }
          />

          <Route
            path='changeForgotPassword'
            element={
              <div className={ styles['authorization-page-container'] }>
                <ChangeForgotPassword />
              </div>
            }
          />

          <Route path='*' element={ <Navigate to='/' /> } />
        </Routes>

      </div>
  )
}

const AuthorizeContainer: FunctionComponent = (): ReactElement => {
  const [t]: UseTranslationResponse<string> = useTranslation();

  const mainContainerRef = useRef<HTMLDivElement>();
  const secondaryGroupRef = useRef<HTMLDivElement>();

  const [startTimeout] = useTimeout(ANIMATION_DURATION / 2);
  const [doAnimate, setDoAnimate] = useState<boolean>(false)
  const [reverse, setReverse] = useState<boolean>(false);
  const [isLoginMode, setIsLoginMode] = useState<boolean>(true);

  const handleChangeMode = useCallback(() => {
    setDoAnimate(true)
    startTimeout(() => setIsLoginMode(reverse => !reverse))
  }, [startTimeout]);

  const primaryTranslateX = useCallback(() => {
    return `translateX(${ secondaryGroupRef.current?.offsetWidth * (reverse ? -1 : 1) }px)`
  }, [secondaryGroupRef, reverse])


  const secondaryTranslateX = useCallback(() => {
    return `translateX(${ mainContainerRef.current?.offsetWidth * (reverse ? 1 : -1) }px)
     translateX(${ 100 * (reverse ? -1 : 1) }%)`
  }, [mainContainerRef, reverse])

  const handleEndAnimation = useCallback((event) => {
    if (event.target !== event.currentTarget) return;

    setDoAnimate(false);
    setReverse(reverse => !reverse)
  }, []);

  return (
      <div
          className={ cn(
              styles['authorization-page-container'],
              { [styles['transition']]: doAnimate }
          ) }
          ref={ mainContainerRef }
          style={ { flexDirection: reverse ? 'row-reverse' : 'row' } }
      >
        <div
            onTransitionEnd={ handleEndAnimation }
            className={ cn(
                styles['authorization-page-container-group'],
                styles['primary'],
                { [styles['transition']]: doAnimate }
            ) }
            style={ { transform: doAnimate && primaryTranslateX() } }
        >
          { isLoginMode ?
              <LoginForm />
              :
              <RegisterForm />
          }
        </div>

        <div
            className={ cn(
                styles['authorization-page-container-group'],
                styles['secondary'],
                { [styles['transition']]: doAnimate }
            ) }
            style={ { transform: doAnimate && secondaryTranslateX() } }
            ref={ secondaryGroupRef }
        >
          <LoginShapes className={ styles['authorization-page-container-group-mask'] } />

          <div className={ styles['authorization-page-container-group-animate'] }>
            <h2>
              { isLoginMode ? t('titles.hello_friend') : t('titles.welcome_back') }
            </h2>
            <p>
              { isLoginMode ?
                  t('titles.enter_personal_details')
                  :
                  t('titles.to_keep_connected')
              }
            </p>

            <Button
                className={ styles['authorization-page-container-group-action'] }
                appearance='transparent'
                theme='dark'
                onClick={ handleChangeMode }
            >
              { t(isLoginMode ? 'buttons.register' : 'buttons.login') }
            </Button>
          </div>
        </div>
      </div>
  )
}


export default AuthorizationPage;
