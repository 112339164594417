import { format, isThisYear, isThisMonth } from 'date-fns'
const currentYear = new Date().getFullYear()

export const getWeekDayName = (index: number) => [
  'datetime.sunday',
  'datetime.monday',
  'datetime.tuesday',
  'datetime.wednesday',
  'datetime.thursday',
  'datetime.friday',
  'datetime.saturday',
][index]

export const getMonthName = (index: number) => [
  'datetime.january',
  'datetime.february',
  'datetime.march',
  'datetime.april',
  'datetime.may',
  'datetime.june',
  'datetime.july',
  'datetime.august',
  'datetime.september',
  'datetime.october',
  'datetime.november',
  'datetime.december'
][index]

export const getMonthLabel = (t, date) =>
  `${ t(getMonthName(date.getMonth())) } ${ currentYear === date.getFullYear() ? '' : date.getFullYear() }`

export const getDateLabel = (t, date) => {
  return date
    ? `${ ('0' + date.getDate()).slice(-2) } ${ t(getMonthName(date.getMonth())) } ${ date.getFullYear() }`
    : ''
}

export const getEventDate = (t, date) => {
  return date
    ? `${ ('0' + date.getDate()).slice(-2) } ${ t(getMonthName(date.getMonth())).slice(0, 3) } ${ date.getFullYear() }, ${ t('datetime.start_at') } ${ ('0' + date.getHours()).slice(-2) }:${ ('0' + date.getMinutes()).slice(-2) }`
    : ''
}

export const monthsDiff = (date1, date2) =>
  (date1.getFullYear() - date2.getFullYear()) * 12 + date1.getMonth() - date2.getMonth()

export const beginningOfDay = (date: Date): Date =>
  new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0)

export const endOfDay = (date: Date): Date =>
  new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999)

export const formatChatDate = date => {
  const showYear = !isThisYear(date)
  const showMonth = !isThisMonth(date)
  
  let dateFormat = ''

  if (showYear) {
    dateFormat += 'yyyy '
  }

  if (showMonth) {
    dateFormat += 'MMMM '
  }

  dateFormat += 'EEEE'

  return format(date, dateFormat)
}
