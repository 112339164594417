// Common
import React, { FunctionComponent, ReactElement } from 'react'
import { calculateWaveData, drawWaveform } from './utils'
import { Observable } from 'rxjs'

// Hooks
import { useRef, useCallback, useEffect } from 'react'

// Types
import WaveStyle from './types/waveStyle'

interface IComponentProps {
  buffer: AudioBuffer
  height: number
  width: number
  waveStyle: WaveStyle
  progress: Observable<number>
}

const Waveform: FunctionComponent<IComponentProps> = ({
  buffer,
  height = 300,
  width = 500,
  waveStyle,
  progress
}): ReactElement => {
  const data = useRef<{ min: number, max: number }[]>()

  const canvas = useRef()

  const draw = useCallback((progress = 0) => {
    const quality = 3

    drawWaveform(
      data.current,
      canvas.current,
      {
        ...waveStyle,
        stepWidth: waveStyle.stepWidth * quality,
        gap: waveStyle.gap * quality,
        borderRadius: waveStyle.borderRadius * quality
      },
      height * quality,
      width * quality,
      progress
    )
  }, [data, canvas, waveStyle, height, width])

  useEffect(() => {
    if ( !buffer ) return

    const newData = calculateWaveData(
      buffer,
      width,
      waveStyle.stepWidth,
      waveStyle.gap
    )

    data.current = newData

    draw()
  }, [data, buffer, width, waveStyle, draw] )

  useEffect(() => {
    draw()

    const subscription = progress?.subscribe(progress => {
      draw(progress)
    })

    return () => {
      subscription?.unsubscribe()
    }
  }, [draw, waveStyle, progress])

  return (
    <canvas
      ref={ canvas }
      style={ { height: '100%', width: '100%' } }
    />
  )
}

export default Waveform
