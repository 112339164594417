// Common
import React, { FunctionComponent, ReactElement } from 'react'
import cn from 'classnames'
import styles from './navigationPanel.module.scss'

// Components
import { NavLink } from 'react-router-dom'
// import LanguagesDropdown from 'components/languagesDropdown/languagesDropdown'
import Button from 'components/button'
import { FilterIcon, LocationIcon, Logo } from 'assets'
import Icon from 'components/icon'

// Hooks
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useCallback } from 'react'

interface IComponentProps {
  year?: number
  onExpand?: (event) => null
}

const NavigationPanel: FunctionComponent<IComponentProps> = (): ReactElement => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleGetJoinClick = useCallback(() => {
    navigate('/authorization')
  }, [navigate])

  return (
    <nav className={ styles['nav'] }>
      <div className={ cn(styles['nav-container'], styles['left']) }>
        <Logo className={ cn(styles['nav-container-item'], styles['logo']) } />

        <NavLink
          className={ ({ isActive }) => cn(styles['nav-container-item'], { [styles['active']]: isActive }) }
          to='/'
        >
          { t('headers.home') }
        </NavLink>
        <NavLink
          className={ ({ isActive }) => cn(styles['nav-container-item'], { [styles['active']]: isActive }) }
          to='/roles'
        >
          { t('headers.roles') }
        </NavLink>
        <NavLink
          className={ ({ isActive }) => cn(styles['nav-container-item'], { [styles['active']]: isActive }) }
          to='/news'
        >
          { t('headers.nav-events') }
        </NavLink>
        <NavLink
          className={ ({ isActive }) => cn(styles['nav-container-item'], { [styles['active']]: isActive }) }
          to='/about_us'
        >
          { t('headers.about_us') }
        </NavLink>
        <NavLink
          className={ ({ isActive }) => cn(styles['nav-container-item'], { [styles['active']]: isActive }) }
          to='/FAQ'
        >
          { t('headers.faq') }
        </NavLink>
        <NavLink
          className={ ({ isActive }) => cn(styles['nav-container-item'], { [styles['active']]: isActive }) }
          to='/contacts'
        >
          { t('headers.contacts') }
        </NavLink>
      </div>

      <div className={ cn(styles['nav-container'], styles['right']) }>
        <Icon
          className={ cn(styles['nav-container-item'], styles['icon']) }
          name='search'
          width={ 20 }
          height={ 20 }
          size={ 42 }
        />

        <FilterIcon className={ cn(styles['nav-container-item'], styles['icon']) } />

        <LocationIcon className={ cn(styles['nav-container-item'], styles['icon']) } />

        {/* <LanguagesDropdown /> */}

        <Button
            className={ styles['get-join-button'] }
            onClick={ handleGetJoinClick }
        >
          { t('buttons.get_join') }
        </Button>
      </div>
    </nav>
  );
}

export default NavigationPanel

