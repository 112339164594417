// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.friendsNFuns_header__1eXF9 {
  margin-bottom: 3rem;
}
.friendsNFuns_header-tab__G-Lxu {
  margin-right: 3rem;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}
.friendsNFuns_header-tab__G-Lxu:last-child {
  margin-right: 0;
}

.friendsNFuns_body__1Gm13 {
  width: 100%;
  max-height: 50vh;
  height: 50vh;
  overflow-y: scroll;
}`, "",{"version":3,"sources":["webpack://./src/containers/Authenticated/pages/friendsNFuns/index.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;AACE;EACE,kBAAA;EACA,eAAA;EACA,yBAAA;UAAA,iBAAA;AACJ;AACI;EACE,eAAA;AACN;;AAIA;EACE,WAAA;EACA,gBAAA;EACA,YAAA;EACA,kBAAA;AADF","sourcesContent":[".header {\n  margin-bottom: 3rem;\n\n  &-tab {\n    margin-right: 3rem;\n    cursor: pointer;\n    user-select: none;\n\n    &:last-child {\n      margin-right: 0;\n    }\n  }\n}\n\n.body {\n  width: 100%;\n  max-height: 50vh;\n  height: 50vh;\n  overflow-y: scroll;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `friendsNFuns_header__1eXF9`,
	"header-tab": `friendsNFuns_header-tab__G-Lxu`,
	"body": `friendsNFuns_body__1Gm13`
};
export default ___CSS_LOADER_EXPORT___;
