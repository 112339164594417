// Common
import React, { FunctionComponent, ReactElement } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom';
import styles from './index.module.scss';

// Pages
import Home from './pages/Home/home';
import Roles from "./pages/Roles/roles";
import News from './pages/News'
import AuthorizationPage from './pages/Authorization';
import Contacts from "./pages/contacts/contacts";
import Faq from "./pages/FAQ/faq";
import AboutUs from "./pages/AboutUs/aboutUs";

// Components
import NavigationPanel from './components/navigationPanel/navigationPanel'
import { CancelAccount } from './pages/CancelAccount/cancelAccount'
import { PrivacyPolicy } from './pages/PrivacyPolicy'

const UnAuthenticatedComponent: FunctionComponent = (): ReactElement => {

  return(
    <>
      <NavigationPanel />

      <div className={ styles['unauthenticated-page'] }>
        <div className={ styles['unauthenticated-page-body-container'] }>
          <Routes>
            <Route path='/' element={ <Home /> } />
            <Route path='/roles' element={ <Roles /> } />
            <Route path='/news' element={ <News /> } />
            <Route path='/authorization/*' element={ <AuthorizationPage /> } />
            <Route path='/contacts' element={ <Contacts /> } />
            <Route path='/about_us' element={ <AboutUs /> } />
            <Route path='/faq' element={ <Faq /> } />
            <Route path='/privacy' element={ <PrivacyPolicy /> } />
            <Route path='/cancel' element={ <CancelAccount /> } />
            <Route path='*' element={ <Navigate to='/' /> }></Route>
          </Routes>
        </div>
      </div>
    </>
  )
}

export default UnAuthenticatedComponent
