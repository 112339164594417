import { hostURL } from "./http.service";
import { RequestParams } from "./models/types";

export function getAboutUs(): RequestParams {
  const url = new URL(hostURL + '/abouts');

  return {
    url,
    withLocale: true
  }
}
