import WaveStyle from './types/waveStyle'

const getBounds = (values: number[]): { min: number, max: number } => {
  const sum = values.reduce(
    (memo, i) => (memo + Math.abs(i))
  )

  const avg = Math.min(3 * sum / values.length, 1)

  return { max: avg, min: -1 * avg }
}

const getBoundArray = (wave: Float32Array, stepsCount: number, step: number): { min: number, max: number }[] => {
  let bounds = []

  for (let i = 0; i < stepsCount; i++) {
    bounds = [...bounds, getBounds(wave.slice(i * step, i * step + step) as unknown as number[])]
  }

  return bounds
}

export const calculateWaveData = (buffer: AudioBuffer, width: number, pointWidth: number, gap: number): { min: number, max: number }[] => {
  if (!buffer) return []

  const wave = buffer.getChannelData(0)

  const stepsCount = width / (pointWidth + gap)

  const step = Math.ceil(wave.length / stepsCount)

  return getBoundArray(wave, stepsCount, step)
}

const drawPoint = (
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  w: number,
  h: number,
  r: number,
  color: string
) => {
  if (w < 2 * r) r = w / 2
  if (h < 2 * r) r = h / 2

  ctx.beginPath()
  ctx.moveTo(x+r, y)
  ctx.arcTo(x+w, y,   x+w, y+h, r)
  ctx.arcTo(x+w, y+h, x,   y+h, r)
  ctx.arcTo(x,   y+h, x,   y,   r)
  ctx.arcTo(x,   y,   x+w, y,   r)
  ctx.closePath()
  ctx.fillStyle = color
  ctx.fill()
}

const drawPoints = (
  ctx: CanvasRenderingContext2D,
  bounds: { min: number, max: number }[],
  style: WaveStyle,
  maxAmp: number,
  scaleFactor = 1,
  progress = 0
) => {
  bounds.forEach((bound, i) => {
    drawPoint(
      ctx,
      i * style.stepWidth + i * style.gap,
      (1 + bound.min) * maxAmp,
      style.stepWidth,
      Math.max(1, (bound.max - bound.min) * maxAmp) * scaleFactor,
      style.borderRadius,
      Math.floor(bounds.length * progress) < i ? style.color : style.playedColor
    )
  })
}

export const drawWaveform = (
  bounds: { min: number, max: number }[],
  canvas: HTMLCanvasElement,
  waveStyle: WaveStyle,
  height = 300,
  width: number,
  progress: number
) => {
  if (!canvas || !bounds || !bounds.length) return

  const ctx = canvas.getContext('2d')

  ctx.clearRect(0, 0, canvas.width, canvas.height)

  const canvasSize = {
    height: (canvas.height = height),
    width: (canvas.width = width)
  }

  ctx.fillStyle = waveStyle.color

  const maxAmp = canvasSize.height / 2

  drawPoints(ctx, bounds, waveStyle, maxAmp, 1, progress)
}

export const getAudioBuffer = async (path, context): Promise<AudioBuffer> => {
  const response = await fetch(path, { credentials: 'include' })

  const audioData = await response.arrayBuffer()

  return new Promise((resolve) => {
    context.decodeAudioData(audioData, buffer => {
      return resolve(buffer)
    })
  })
}

export const getContext = () => {
  window.AudioContext =
    window.AudioContext ||
    window['webkitAudioContext'] ||
    window['mozAudioContext'] ||
    window['oAudioContext']
  const context = new AudioContext()
  return context
}
