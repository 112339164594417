// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pageTabsHeader_header__BAhsH {
  height: 50px;
  border-radius: 24px;
  background-color: #310C38;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.pageTabsHeader_header-tab__Ybpvx {
  margin-left: 3rem;
  cursor: pointer;
}
.pageTabsHeader_header-tab__Ybpvx:last-child {
  margin-right: 3rem;
}
.pageTabsHeader_header-divider__F7Ove {
  flex: 1 1;
}
.pageTabsHeader_header-button__9c8E7 {
  margin-right: 1rem;
}
.pageTabsHeader_header-back__jXkd9 {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 3rem;
  cursor: pointer;
  gap: 1.5rem;
}
.pageTabsHeader_header-back-icon__Hmw78 {
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/pageTabsHeader/index.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,mBAAA;EACA,yBAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;AACF;AACE;EACE,iBAAA;EACA,eAAA;AACJ;AAAI;EACE,kBAAA;AAEN;AAEE;EACE,SAAA;AAAJ;AAGE;EACE,kBAAA;AADJ;AAIE;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,eAAA;EACA,eAAA;EACA,WAAA;AAFJ;AAII;EACE,YAAA;AAFN","sourcesContent":[".header {\n  height: 50px;\n  border-radius: 24px;\n  background-color: #310C38;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n\n  &-tab {\n    margin-left: 3rem;\n    cursor: pointer;\n    &:last-child {\n      margin-right: 3rem;\n    }\n  }\n\n  &-divider {\n    flex: 1;\n  }\n\n  &-button {\n    margin-right: 1rem;\n  }\n\n  &-back {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    padding: 0 3rem;\n    cursor: pointer;\n    gap: 1.5rem;\n  \n    &-icon {\n      color: white;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `pageTabsHeader_header__BAhsH`,
	"header-tab": `pageTabsHeader_header-tab__Ybpvx`,
	"header-divider": `pageTabsHeader_header-divider__F7Ove`,
	"header-button": `pageTabsHeader_header-button__9c8E7`,
	"header-back": `pageTabsHeader_header-back__jXkd9`,
	"header-back-icon": `pageTabsHeader_header-back-icon__Hmw78`
};
export default ___CSS_LOADER_EXPORT___;
