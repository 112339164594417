import { User } from 'models/user.model'
import { ProfileImage } from 'types/profileImage'

/**
* Own profile
* */
export const REQUEST_PROFILE = 'REQUEST_PROFILE';
export const requestProfile = () => ({
  type: REQUEST_PROFILE
});

export const RECEIVED_PROFILE = 'RECEIVED_PROFILE';
export const receivedProfile = (payload: User) => ({
  type: RECEIVED_PROFILE,
  payload
});

export const REQUEST_PROFILE_FAILED = 'REQUEST_PROFILE_FAILED';
export const requestProfileFiled = (error: { message: string, status: number, needVerify: boolean }) => ({
  type: REQUEST_PROFILE_FAILED,
  error
});

export const RESET_PROFILE = 'RESET_PROFILE';
export const resetProfile = () => ({
  type: RESET_PROFILE
});

/**
* Update profile
* */
export const REQUEST_UPDATE_PROFILE = 'REQUEST_UPDATE_PROFILE';
export const requestUpdateProfile = (payload: Partial<User>) => ({
  type: REQUEST_UPDATE_PROFILE,
  payload
})

export const REQUEST_UPDATE_PROFILE_FAILED = 'REQUEST_UPDATE_PROFILE_FAILED';
export const requestUpdateProfileFailed = (error: { message: string, status: number }) => ({
  type: REQUEST_UPDATE_PROFILE_FAILED,
  error
})

export const REQUEST_UPDATE_PROFILE_IMAGE = 'REQUEST_UPDATE_PROFILE_IMAGE';
export const requestUpdateProfileImage = (image, type: ProfileImage) => ({
  type: REQUEST_UPDATE_PROFILE_IMAGE,
  payload: { image, type }
})

/**
* Errors
* */
export const RESET_PROFILE_ERRORS = 'RESET_PROFILE_ERRORS';
export const resetProfileErrors = () => ({
  type: RESET_PROFILE_ERRORS
});
