import { hostURL } from "./http.service";
import { GenreParams, RequestParams } from "./models/types";

export function getGenres(params: GenreParams): RequestParams {
  const url = new URL(hostURL + '/genres');

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  url.searchParams.set('search', params.searchQuery || '');

  return {
    url,
    withLocale: true
  }
}
