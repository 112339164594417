import React, { FunctionComponent, ReactElement, useMemo } from 'react'
import { LinkProps, useSearchParams, NavLink as Nav } from 'react-router-dom'
import Label from '../label'
import { isNil } from 'helpers/base'

interface IComponentProps extends LinkProps {
  bySearchParams?: string
}

const NavLink: FunctionComponent<IComponentProps> = ({ children, to, bySearchParams, ...props }): ReactElement => {
  const [queryParams] = useSearchParams()
  const matchQuery = useMemo(() => !isNil(queryParams.get(bySearchParams)), [queryParams, bySearchParams])

  return (
    <Nav to={ to } { ...props }>
      { ({ isActive }) => bySearchParams
        ? <Label appearance={ matchQuery ? 'gold' : 'gold-inactive' } text={ children as string } />
        : <Label appearance={ isActive ? 'gold' : 'gold-inactive' } text={ children as string } />
      }
    </Nav>
  )
}

export default NavLink
