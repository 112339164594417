// Common
import React, { FunctionComponent, ReactElement } from 'react'
import styles from './index.module.scss'

// Components
import Button from 'components/button'
import Label from 'components/label'
import Textarea from 'components/textarea'
import DateRangeInput from 'components/dateRangeInput'
import FileInput from 'components/fileInput'
import Input from 'components/input'
import RadioButton from 'components/radioButton'

// Hooks
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useSubscribeValue } from 'hooks/useSubscribeValue'
import useSubscribe from 'hooks/useSubscribe'
import { useInject } from 'hooks/useInject'

// Models
import { Event } from 'models/event.model'

// Actions
import calendarMonthActions from 'redux/actions/calendar-month-events.action-creators'

// Services
import { EventsService } from 'services/events.service'

interface IComponentProps {
  onClose?: () => void,
  event?: Event,
}

const EventForm: FunctionComponent<IComponentProps> = ({
  onClose: handleClose,
  event = new Event()
}): ReactElement => {
  const [t] = useTranslation()

  const dispatch = useDispatch()

  const eventsService = useInject(EventsService)

  const { control, handleSubmit } = useForm(event.asForm())

  const submit = useCallback((data) => {
    eventsService.upsert(Event.fromFormData(data))
  }, [eventsService])

  useSubscribe(eventsService.upserted, () => {
    dispatch(calendarMonthActions.clear())
    handleClose()
  }, [handleClose, dispatch])

  const pending = useSubscribeValue(eventsService.upserting)

  return (
    <form
      className={ styles['form'] }
      onSubmit={ handleSubmit(submit) }
    >
      <Label
        appearance='gold'
        text={ t('buttons.create_event') }
      />

      <Label
        className={ styles['label'] }
        appearance='input'
        text={ t('headers.date') }
      />

      <div className={ styles['row'] }>
        <DateRangeInput
          className={ styles['col'] }
          control={ control }
          fromName='from'
          toName='to'
          placeholder={ t('headers.select_date') }
        />

        <div className={ styles['col'] }>
          <RadioButton
            className={ styles['radio'] }
            label={ t('headers.events_one_day') }
            control={ control }
            name='type'
            value='all_day'
          />

          <RadioButton
            label={ t('headers.events_period') }
            control={ control }
            name='type'
            value='period'
          />
        </div>
      </div>

      <Label
        className={ styles['label'] }
        appearance='input'
        text={ t('headers.title') }
      />

      <Input
        control={ control }
        name='title'
        placeholder={ t('headers.title') }
        required={ true }
        theme='light'
      />

      <Label
        className={ styles['label'] }
        appearance='input'
        text={ t('headers.description') }
      />

      <Textarea
        control={ control }
        name='description'
        placeholder={ t('headers.description') }
      />

      <Label
        className={ styles['label'] }
        appearance='input'
        text={ t('headers.attachments') }
      />

      <FileInput
        control={ control }
        name='image'
      />

      <div className={ styles['additional-info'] }>
        { t('headers.events_form_additional_info') }
      </div>

      <div className={ styles['buttons'] }>
        <Button
          className={ styles['buttons-item'] }
          appearance='transparent'
          onClick={ handleClose }
        >
          { t('buttons.cancel') }
        </Button>

        <Button
          className={ styles['buttons-item'] }
          appearance='gold-big'
          type='submit'
          loading={ pending }
        >
          { t('buttons.create_event') }
        </Button>
      </div>
    </form>
  );
}

export default EventForm
