// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal_container__o8zno {
  z-index: 3;
  position: fixed;
  overflow-y: scroll;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(166, 143, 168, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  animation: modal_show__netes 0.3s ease-in-out;
}
.modal_container__o8zno.modal_out__ybiAZ {
  visibility: visible;
  animation: modal_hide__8SIvi 0.3s ease-in-out;
}

.modal_modal__f4cjD {
  width: 67rem;
  background-color: #310C38;
  border-radius: 2.4rem;
  animation: modal_in__A6SG\\+ 0.3s ease-in-out, modal_show__netes 0.3s ease-in-out;
  margin: auto;
  overflow: hidden;
}
.modal_modal__f4cjD.modal_with-padding__bN7Ip {
  padding: 5rem 6rem;
}
.modal_modal__f4cjD.modal_out__ybiAZ {
  animation: modal_out__ybiAZ 0.3s ease-in-out, modal_hide__8SIvi 0.3s ease-in-out;
}

@keyframes modal_in__A6SG\\+ {
  0% {
    transform: translateY(-500%);
  }
  100% {
    transform: translateY(0%);
  }
}
@keyframes modal_out__ybiAZ {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-1000%);
  }
}
@keyframes modal_show__netes {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes modal_hide__8SIvi {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/modal/index.module.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,eAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,YAAA;EACA,aAAA;EACA,0CAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,6CAAA;AACF;AACE;EACE,mBAAA;EACA,6CAAA;AACJ;;AAGA;EACE,YAAA;EACA,yBAAA;EACA,qBAAA;EACA,gFAAA;EACA,YAAA;EACA,gBAAA;AAAF;AAEE;EACE,kBAAA;AAAJ;AAGE;EACE,gFAAA;AADJ;;AAKA;EAAgB;IAAK,4BAAA;EAAnB;EAAkD;IAAO,yBAAA;EAGzD;AACF;AAHA;EAAiB;IAAK,yBAAA;EAOpB;EAPgD;IAAO,6BAAA;EAUvD;AACF;AATA;EAAkB;IAAK,UAAA;EAarB;EAbmC;IAAO,UAAA;EAgB1C;AACF;AAhBA;EAAkB;IAAK,UAAA;EAoBrB;EApBmC;IAAO,UAAA;EAuB1C;AACF","sourcesContent":[".container {\n  z-index: 3;\n  position: fixed; \n  overflow-y: scroll;\n  top: 0;\n  left: 0;\n  width: 100vw;\n  height: 100vh;\n  background-color: rgba(166, 143, 168, 0.2);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  animation: show .3s ease-in-out;\n\n  &.out {\n    visibility: visible;\n    animation: hide .3s ease-in-out;\n  }\n}\n\n.modal {\n  width:  67rem;\n  background-color: #310C38;\n  border-radius: 2.4rem;\n  animation: in .3s ease-in-out, show .3s ease-in-out;\n  margin: auto;\n  overflow: hidden;\n\n  &.with-padding {\n    padding: 5rem 6rem;\n  }\n\n  &.out {\n    animation: out .3s ease-in-out, hide .3s ease-in-out;\n  }\n}\n\n@keyframes in { 0% { transform: translateY(-500%) } 100% { transform: translateY(0%); } }\n@keyframes out { 0% { transform: translateY(0%) } 100% { transform: translateY(-1000%); } }\n\n@keyframes show { 0% { opacity: 0; } 100% { opacity: 1; } }\n@keyframes hide { 0% { opacity: 1; } 100% { opacity: 0; } }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `modal_container__o8zno`,
	"show": `modal_show__netes`,
	"out": `modal_out__ybiAZ`,
	"hide": `modal_hide__8SIvi`,
	"modal": `modal_modal__f4cjD`,
	"in": `modal_in__A6SG+`,
	"with-padding": `modal_with-padding__bN7Ip`
};
export default ___CSS_LOADER_EXPORT___;
