// Common
import React, { FunctionComponent, ReactElement, useCallback } from 'react';
import { UseTranslationResponse } from "react-i18next";
import styles from "./forgotPassword.module.scss";

// Components
import { Logo, MailIcon } from 'assets'
import Button from 'components/button'
import Input from 'components/input'
import AnimatedContainer from 'components/animatedContainer'

// Hooks
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'redux/store'
import { useDispatch } from 'react-redux'

// Actions
import { requestForgotPassword } from 'redux/actions/authentication.action'

const ForgotPassword: FunctionComponent = (): ReactElement => {

  const [t]: UseTranslationResponse<string> = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { control, handleSubmit } = useForm();
  const { errors } = useSelector(({ authentication }) => authentication);

  const handleBack = useCallback(() => {
    navigate(-1)
  }, [navigate]);

  const handleConfirm = useCallback(({ email }) => {
    dispatch(requestForgotPassword(email))
  }, [dispatch])

  return (
      <div className={ styles['forgot-password'] }>
        <Logo className={ styles['forgot-password-logo'] } />

        <h2>{ t('titles.forgot_password') }</h2>

        <p>{ t('titles.enter_email_to_reset_password') } </p>

        <form onSubmit={ handleSubmit(handleConfirm) } id='forgot-password-form'>
          <Input
              className={ styles['forgot-password-input'] }
              control={ control }
              title={ t('titles.email_address') }
              name='email'
              required={ t('validations.email_blank') as string }
              leftIcon={ <MailIcon /> }
          />
        </form>

        { /* TODO Make a form with error handling and highlighting */ }
        <AnimatedContainer show={ !!errors }>
          <div className={ styles['forgot-password-error-message'] }>{ errors }</div>
        </AnimatedContainer>

        <div className={ styles['forgot-password-actions'] }>
          <Button
              onClick={ handleBack }
              border={ false }
              appearance='transparent'
          >
            { t('buttons.back') }
          </Button>
          <Button
              className={ styles['forgot-password-actions-submit'] }
              appearance='transparent'
              type='submit'
              form='forgot-password-form'
          >
            { t('buttons.confirm') }
          </Button>
        </div>
      </div>
  )
}

export default ForgotPassword;
