import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styles from './spinner.module.scss';

interface IComponentProps {
  showSpinner: boolean
}

class Spinner extends PureComponent<IComponentProps, null> {
  render() {
    if (this.props.showSpinner) {
      return (
          <div className={ styles['spinnerContainer'] }>
            <div className={ styles['lds-default'] }>
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
            </div>
          </div>
      )
    } else {
      return null
    }
  }
}

const mapStateToProps = state => ({
  showSpinner: state.spinner.showSpinner
});

export default connect(mapStateToProps, null)(Spinner)
