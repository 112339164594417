import { hostURL } from './http.service'
import { RequestParams } from './models/types'
import { Service } from 'types/reduxCRUD'

// Models
import { User } from 'models/user.model'
import { FriendshipStatus } from 'types/friendship-status'

const service: Service<User> = {
  getItems(): RequestParams {
    throw new Error('API not available')
  },
  getItem(): RequestParams {
    throw new Error('API not available')
  },
  upsertItem(user: User): RequestParams {
    const url = new URL(hostURL + `/friendships`)

    return {
      url,
      method: user.friendshipStatus === FriendshipStatus.PENDING ? 'PUT' : 'POST',
      body: { friend_id: user.id }
    }
  },
  deleteItem(user: User): RequestParams {
    const url = new URL(`${ hostURL }/friendships`)

    return {
      url,
      method: 'DELETE',
      body: { friend_id: user.id }
    }
  }
}

export default service
