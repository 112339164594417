import { hostURL } from "./http.service";
import { RequestParams } from "./models/types";
import { Service } from "types/reduxCRUD";

// Models
import { Photo, PhotoFilters } from 'models/photo.model'

const service: Service<Photo> = {
  getItems(filters: PhotoFilters): RequestParams {
    const url = new URL(`${hostURL}/photos`);
  
    const params = {
      page: filters.page,
      perPage: filters.perPage,
      albumId: filters.albumId,
      eventId: filters.eventId,
    }
    Object.keys(params).forEach(key => {
      if (params[key] !== undefined) {
        url.searchParams.append(key, params[key]);
      }
    });

    return {
      url,
      params: url.searchParams,
    };
  },
  getItem(): RequestParams {
    throw new Error('API not available')
  },
  upsertItem(photo: Photo): RequestParams {
    if (!photo.albumId && !photo.eventId) {
      throw new Error('ID missing')
    }
  
    const url = new URL(`${ hostURL }/photos`)

    return {
      url,
      method: 'POST',
      body: photo.asPayloadJSON()
    }
  },
  deleteItem(photo: Photo): RequestParams {
    const url = new URL(`${ hostURL }/photos/${ photo.id }`);

    return {
      url,
      method: 'DELETE'
    }
  }
}

export default service;
