import { hostURL } from 'apiServices/http.service'
import { Notification, NotificationsFilters } from 'models/notification.model'
import { BehaviorSubject, Subject, takeUntil } from 'rxjs'
import { Injector } from 'types/injector.class'
import { BaseRestInfiniteScrollService } from './base-rest-infinite-scroll.service'
import { WebsocketsService } from './websockets.service'

export class NotificationsService extends BaseRestInfiniteScrollService<Notification, NotificationsFilters> {
  private websocketsService: WebsocketsService
  private _unreadCount = new BehaviorSubject<number>(0)
  private alive = new Subject<void>()

  protected entityName = 'Notification'

  public unreadNotificationsCount = this._unreadCount.asObservable()

  constructor(
    injector: Injector,
  ) {
    super(injector)
    this.websocketsService = injector.get(WebsocketsService)

    this.websocketsService.get('unreadNotificationsCount')
      .pipe(takeUntil(this.alive))
      .subscribe((value: number) => {
        this._unreadCount.next(value)
      })
  }

  detach() {
    this.alive.next()
    this.alive.complete()
  }

  async buildUrl() {
    return new URL(`${ hostURL }/notifications`)
  }

  buildItem(item: object) {
    return new Notification(item)
  }

  getItemFromResponse(response: object) {
    return response['notification']
  }

  getItemsFromResponse(response: object) {
    return response['notifications']
  }

  async requestOne() {
    throw new Error('API not available')
  }
}
