// Common
import { MutableRefObject } from 'react'

// Hooks
import { useCallback, useEffect, useRef } from 'react'

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

export function useInfiniteScroll2({
  containerRef,
  triggerDistance = 100,
  loadMore,
  reverse
}: {
  containerRef: MutableRefObject<HTMLElement>,
  triggerDistance?: number,
  loadMore: () => void,
  reverse?: boolean,
}) {
  const inititalLoadEmitted = useRef<boolean>(false)
  const initialPageReceived = useRef<boolean>(false)
  const scrollHeight = useRef<number>(0)
  const waitingForLoadMore = useRef(false)

  useEffect(() => {
    if (inititalLoadEmitted.current) { return }

    inititalLoadEmitted.current = true

    loadMore?.()
  }, [loadMore, inititalLoadEmitted])

  const loadMoreIfNeeded = useCallback(() => {
    if (!inititalLoadEmitted.current || !initialPageReceived.current) { return }

    if (
      !waitingForLoadMore.current &&
      reverse
        ? (
            containerRef.current.scrollTop < triggerDistance
          )
        : (
            containerRef.current.scrollHeight - triggerDistance <
            containerRef.current.scrollTop + containerRef.current.offsetHeight
          )
    ){
      waitingForLoadMore.current = true

      loadMore?.()
    }
  }, [triggerDistance, containerRef, loadMore, waitingForLoadMore, reverse])

  useEffect(() => {
    const container = containerRef.current

    const handleScroll = () => {
      loadMoreIfNeeded()
    }

    container.addEventListener('scroll', handleScroll)

    return () => container.removeEventListener('scroll', handleScroll)
  }, [loadMoreIfNeeded, containerRef])

  useEffect(() => {
    const handleMutation = () => {
      if (!initialPageReceived.current) {
        if (reverse) {
          containerRef.current.scrollTop = containerRef.current.scrollHeight
        }

        initialPageReceived.current = true
      } else {
        if (isSafari) {
          const heightDiff = containerRef.current.scrollHeight - scrollHeight.current

          if (heightDiff > 0) {
            containerRef.current.scrollTop = containerRef.current.scrollTop + heightDiff
          } 
        }
      }

      scrollHeight.current = containerRef.current.scrollHeight

      waitingForLoadMore.current = false
      loadMoreIfNeeded()
    }

    const mutationObserver = new MutationObserver(handleMutation)

    mutationObserver.observe(
      containerRef.current,
      { childList: true, subtree: true }
    )

    return () => {
      mutationObserver.disconnect()
    }
  }, [waitingForLoadMore, loadMoreIfNeeded, containerRef, reverse])
}
