// Common
import React, { FunctionComponent, ReactElement } from 'react'
import styles from './index.module.scss'

// Components
import Button from 'components/button'
import Label from 'components/label'
import AudioInput from 'components/audioInput'
import FileInput from 'components/fileInput'
import Input from 'components/input'

// Hooks
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { useInject } from 'hooks/useInject'
import useSubscribe from 'hooks/useSubscribe'
import { useSubscribeValue } from 'hooks/useSubscribeValue'

// Models
import { Track } from 'models/track.model'

// Services
import { TracksService } from 'services/tracks.service'

interface IComponentProps {
  onClose?: () => void,
  track?: Track,
}

const TrackForm: FunctionComponent<IComponentProps> = ({
  onClose: handleClose,
  track = new Track()
}): ReactElement => {
  const [t] = useTranslation()

  const tracksService = useInject(TracksService)

  const { control, handleSubmit } = useForm(track.asForm())

  const submit = useCallback((data) => {
    tracksService.upsert(Track.fromFormData(data))
  }, [tracksService])

  useSubscribe(tracksService.upserted, () => {
    handleClose()
  }, [handleClose])

  const pending = useSubscribeValue(tracksService.upserting)

  return (
    <form
      className={ styles['form'] }
      onSubmit={ handleSubmit(submit) }
    >
      <Label
        appearance='gold'
        text={ t('buttons.track_add') }
      />

      <Label
        className={ styles['label'] }
        appearance='input'
        text={ t('headers.track_name') }
      />

      <Input
        control={ control }
        name='title'
        placeholder={ t('headers.title') }
        required={ true }
        theme='light'
      />

      <Label
        className={ styles['label'] }
        appearance='input'
        text={ t('headers.image') }
      />

      <FileInput
        control={ control }
        name='image'
      />

      <Label
        className={ styles['label'] }
        appearance='input'
        text={ t('headers.track_upload') }
      />

      <AudioInput
        control={ control }
        name='audio'
        durationName='duration'
      />

      <div className={ styles['buttons'] }>
        <Button
          className={ styles['buttons-item'] }
          appearance='transparent'
          onClick={ handleClose }
        >
          { t('buttons.cancel') }
        </Button>

        <Button
          className={ styles['buttons-item'] }
          appearance='gold-big'
          type='submit'
          loading={ pending }
        >
          { t('buttons.track_add') }
        </Button>
      </div>
    </form>
  );
}

export default TrackForm
