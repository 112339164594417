import { object, string, InferType, mixed } from 'yup'
import i18n from 'i18n'
import { yupResolver } from '@hookform/resolvers/yup'
import { Attachment } from 'types/attachment'
import { hostURL } from 'apiServices/http.service'
import { ValidationMode } from 'react-hook-form'
import { Filters } from 'types/reduxCRUD'
import { User } from './user.model'

const postValidationSchema = object({
  title: string().required(() => i18n.t('validations.is_required')),
  description: string().required(() => i18n.t('validations.is_required')),
  image: mixed<Attachment>()
    .test(
      'fileSize',
      () => i18n.t('validations.file_too_large', { size: '50.0 mb' }),
      value => !value?.fileSize || value.fileSize <= 50000000
    )
    .test(
      'fileType',
      () => i18n.t('validations.is_image'),
      value => !value?.fileType || (
        value.fileType === 'image/jpeg' ||
        value.fileType === 'image/bmp' ||
        value.fileType === 'image/png'
      )
    )
    .test(
      'id',
      () => i18n.t('validations.is_required'),
      value => !!value?.id
    )
})

export class Post implements InferType<typeof postValidationSchema> {
  description: string
  id: number
  user: User
  image: Attachment
  title: string
  imageUrl: string
  createdAt: Date

  constructor(data?) {
    this.description = data?.description
    this.id = data?.id
    this.title = data?.title
    this.user = data?.user && new User(data.user)
    this.createdAt = new Date(data?.createdAt)

    if (data?.image) {
      this.image = data.image
    } else {
      this.image = {
        fileName: data?.imageFileName,
        fileSize: data?.imageFileSize,
        fileType: data?.imageFileType,
        id: data?.id,
        temp: false,
      }

      this.imageUrl = `${ hostURL }/attachments/${ this.user?.id }/${ this.id }/${ this.id }/${ this.image.fileName }`
    }
  }

  asForm() {
    return {
      defaultValues: {
        description: this.description,
        id: this.id,
        image: this.image,
        title: this.title
      },
      mode: 'onChange' as keyof ValidationMode,
      resolver: yupResolver(postValidationSchema)
    }
  }

  asPayloadJSON() {
    return {
      description: this.description,
      imageFileSize: this.image.fileSize,
      imageFileName: this.image.fileName,
      imageFileType: this.image.fileType,
      imageId: this.image.id.toString(),
      imageTemp: this.image.temp,
      title: this.title,
    }
  }

  static fromFormData(data) {
    return new Post(data)
  }
}

export interface PostFilters extends Filters {
  page?: number,
  perPage?: number
}
