import React, { FunctionComponent, memo } from "react";
import cn from 'classnames';
import styles from './generalInfo.module.scss';

// Hooks
import { useTranslation } from "react-i18next";

interface IComponentProps {
  email: string
  phoneNumber: string | number
  links: string[]
  className?: string
}

const GeneralInfo: FunctionComponent<IComponentProps> = ({
  email,
  className,
  phoneNumber,
  links
}) => {
  const [t] = useTranslation();

  return (
      <div
          className={ cn(
              styles['general-info'],
              className
          ) }
      >
        <div className={ styles['general-info-group'] }>
          <span>{ t('EMAIL') }</span>
          <span className={ styles['general-info-group-item'] }>{ email }</span>
        </div>

        <div className={ styles['general-info-group'] }>
          <span>{ t('PHONE') }</span>
          <span className={ styles['general-info-group-item'] }>{ phoneNumber }</span>
        </div>

        <div className={ styles['general-info-group'] }>
          <span>{ t('LINKS') }</span>
          <div className={ styles['general-info-group-item'] }>
            {
              links.map(link =>
                <span className={ styles['general-info-group-item-item'] } key={ link }>{ link }</span>
              )
            }
          </div>
        </div>
      </div>
  )
}

export default memo(GeneralInfo);
