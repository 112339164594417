import { RECEIVED_FAQ, REQUEST_FAQ_FAILED } from "../actions/faq.action";
import FAQ from "../../apiServices/models/faq.model";

export interface State {
  data: FAQ[],
  error: string
}

const initialState: State = {
  data: [],
  error: null
};

export default function faqReducer(
  state: State = initialState,
  action: { type: string, faq: FAQ[], error: string }
): Partial<State> {
  switch (action.type) {
    case RECEIVED_FAQ:
      return { ...state, data: action.faq, error: null }
    case REQUEST_FAQ_FAILED:
      return { ...state, error: action.error };

    default:
      return state;
  }
}
