// Common
import React, { FunctionComponent, ReactElement } from 'react'

// Components
import PageTabsHeader from 'components/pageTabsHeader'
import UserComponent from './user'

// Hooks
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useEntitiesList } from 'hooks/useEntitiesList'
import { useParams } from 'react-router-dom'

// Actions
import actions from 'redux/actions/community.action-creators'

// Types
import { Role } from 'types/role'
import { User, UsersFilters } from 'models/user.model'

const Users: FunctionComponent = (): ReactElement => {
  const [t] = useTranslation()

  const tabs = useMemo(() => {
    return [
      { title: t('headers.performers'), route: '/community/performer' },
      { title: t('headers.businesses'), route: '/community/business' },
      { title: t('headers.regulars'), route: '/community/regular' },
    ]
  }, [t])

  const rightTabs = useMemo(() => {
    return [
      { title: t('headers.friends'), route: '/community/friends' },
      { title: t('headers.followers'), route: '/community/followers' },
      { title: t('headers.following'), route: '/community/followings' },
    ]
  }, [t])

  const { type } = useParams()

  const filters = useMemo<UsersFilters>(() => {
    const result: UsersFilters = { perPage: 10 }

    if ((Object.values(Role) as string[]).includes(type)) {
      result.role = type as Role
    } else if (type === 'friends') {
      result.friends = true
    } else if (type === 'followers') {
      result.followers = true
    } else if (type === 'followings') {
      result.followings = true
    }

    return result
  }, [type])

  const { items: users } = useEntitiesList<User, UsersFilters>({
    actions,
    containerId: 'root',
    filters,
    selector: ({ community }) => community
  })

  return (
    <>
      <PageTabsHeader tabs={ tabs } rightTabs={ rightTabs } />

      { users.map((user, index) => <UserComponent key={ index } user={ user } />) }
    </>
  )
}

export default Users
