import { Role } from "../../apiServices/models/role.model";

export const REQUEST_ROLES = 'REQUEST_ROLES';
export const requestRoles = () => ({
  type: REQUEST_ROLES
});

export const RECEIVED_ROLES = 'RECEIVED_ROLES';
export const receivedRoles = (roles: Role[]) => ({
  type: RECEIVED_ROLES,
  roles
});

export const REQUEST_ROLES_FAILED = 'REQUEST_ROLES_FAILED';
export const requestRolesFiled = (error: { message: string, status: number }) => ({
  type: REQUEST_ROLES_FAILED,
  error
});
