import { useEffect, useState } from 'react'
import { Observable } from 'rxjs'

export function useSubscribeValue<T>(
  observable: Observable<T>,
  initialValue?: T
): T {
  const [value, setValue] = useState<T>(initialValue)

  useEffect(() => {
    if (!observable) { return }

    const subscription = observable.subscribe(value => {
      setValue(value)
    })

    return () => subscription.unsubscribe()
  }, [observable])

  return value
}
