// Hooks
import { useEffect } from 'react'

export default function useOutsideClick(element: HTMLDivElement, callback: () => void) {
  useEffect(() => {
    if (!element) return

    const clickOutside = ({ target }) => {
      if (!element.contains(target)) {
        callback()
      }
    }

    document.addEventListener('mousedown', clickOutside);

    return () => document.removeEventListener('mousedown', clickOutside)
  }, [element, callback])
}
