import { useEffect } from 'react'
import { Observable } from 'rxjs'

export default function useSubscribe<T>(
  observable: Observable<T>,
  callback: (value: T) => void,
  dependencies: unknown[]
): void {
  useEffect(() => {
    if (!observable) { return }

    const subscription = observable.subscribe(callback)

    return () => subscription.unsubscribe()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [observable, ...dependencies])
}
