import { SignInUserParams, SignUpUserParams } from "../../apiServices/models/types";

export const REQUEST_SIGN_UP = 'REQUEST_SIGN_UP';
export const requestSignUp = (payload: SignUpUserParams) => ({
  type: REQUEST_SIGN_UP,
  payload
});

export const REQUEST_SIGN_UP_FAILED = 'REQUEST_SIGN_UP_FAILED';
export const requestSignUpFailed = (error: { message: string, status: number }) => ({
  type: REQUEST_SIGN_UP_FAILED,
  error
});

export const REQUEST_SIGN_IN = 'REQUEST_SIGN_IN';
export const requestSignIn = (payload: SignInUserParams) => ({
  type: REQUEST_SIGN_IN,
  payload
});

export const REQUEST_SIGN_IN_FAILED = 'REQUEST_SIGN_IN_FAILED';
export const requestSignInFailed = (error) => ({
  type: REQUEST_SIGN_IN_FAILED,
  error
});

export const RESET_AUTHENTICATION_ERRORS = 'RESET_AUTHENTICATION_ERRORS';
export const resetAuthenticationErrors = () => ({
  type: RESET_AUTHENTICATION_ERRORS
});

export const REQUEST_SIGN_OUT = 'REQUEST_SIGN_OUT';
export const requestSignOut = () => ({
  type: REQUEST_SIGN_OUT
});

export const REQUEST_SIGN_OUT_FAILED = 'REQUEST_SIGN_OUT_FAILED';
export const requestSignOutFailed = (error) => ({
  type: REQUEST_SIGN_OUT_FAILED,
  error
});

export const REQUEST_FORGOT_PASSWORD = 'REQUEST_FORGOT_PASSWORD';
export const requestForgotPassword = (email: string) => ({
  type: REQUEST_FORGOT_PASSWORD,
  email
})

export const REQUEST_FORGOT_PASSWORD_FAILED = 'REQUEST_FORGOT_PASSWORD_FAILED'
export const requestForgotPasswordFailed = (error: { message: string, status: number }) => ({
  type: REQUEST_FORGOT_PASSWORD_FAILED,
  error
})

export const REQUEST_CHANGE_FORGOT_PASSWORD = 'REQUEST_CHANGE_FORGOT_PASSWORD'
export const requestChangeForgotPassword = (payload: { password: string, token: string }) => ({
  type: REQUEST_CHANGE_FORGOT_PASSWORD,
  payload
})

export const REQUEST_CHANGE_FORGOT_PASSWORD_FAILED = 'REQUEST_CHANGE_FORGOT_PASSWORD_FAILED'
export const requestChangeForgotPasswordFailed = (error: { message: string, status: number }) => ({
  type: REQUEST_FORGOT_PASSWORD_FAILED,
  error
})

export const REQUEST_VERIFICATION = 'REQUEST_VERIFICATION';
export const requestVerification = (payload: { code: string, email: string, password: string }) => ({
  type: REQUEST_VERIFICATION,
  payload
})

export const REQUEST_VERIFICATION_FAILED = 'REQUEST_VERIFICATION_FAILED';
export const requestVerificationFailed = (error: { message: string, status: number }) => ({
  type: REQUEST_VERIFICATION_FAILED,
  error
})

export const REQUEST_RESEND_VERIFICATION_CODE = 'REQUEST_RESEND_VERIFICATION_CODE'
export const requestResendVerificationCode = (payload: { userName: string }) => ({
  type: REQUEST_RESEND_VERIFICATION_CODE,
  payload
})

export const REQUEST_RESEND_VERIFICATION_CODE_FAILED = 'REQUEST_RESEND_VERIFICATION_CODE_FAILED'
export const requestResendVerificationCodeFailed = (error) => ({
  type: REQUEST_RESEND_VERIFICATION_CODE_FAILED,
  error
})
