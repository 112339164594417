// Common
import React, { FunctionComponent } from 'react'
import styles from './index.module.scss'
import { PlayerContext } from 'contexts/player.context'

// RX
import { fromEvent, throttleTime, map } from 'rxjs'

// Helpers
import { zeroPad } from 'helpers/number'

// Hooks
import { useEffect, useState, useCallback, useRef, useContext } from 'react'
import useSubscribe from 'hooks/useSubscribe'

// Components
import Icon from 'components/icon'
import Slider from 'components/slider'

// Types
import { Track } from 'models/track.model'

interface IComponentProps {
  track: Track,
  onPreviousTrack: () => void,
  onNextTrack: () => void,
  onClosePlayer: () => void,
}

const ControlsComponent: FunctionComponent<IComponentProps> = ({
  track,
  onPreviousTrack: handlePreviousTrack,
  onNextTrack: handleNextTrack,
  onClosePlayer,
}): React.ReactElement => {
  const [paused, setPaused] = useState<boolean>(true)
  const [volume, setVolume] = useState(.5)
  const [duration, setDuration] = useState<string>()
  const [currentTime, setCurrentTime] = useState<string>('0:00')
  const [position, setPosition] = useState<number>(0)

  const audio = useRef<HTMLAudioElement>()

  const state = useContext(PlayerContext)

  const handlePlayPause = useCallback(() => {
    state.triggerPlayPause.next()
  }, [state])

  useSubscribe(state.triggerPlayPause, () => {
    audio.current.paused ? audio.current.play() : audio.current.pause()
  }, [audio])

  useEffect(() => {
    if (!state.progress) { return }

    const subscription = state.progress
      .subscribe(value => {
        setPosition(value)
      })

    return () => {
      subscription.unsubscribe()
    }
  }, [state])

  useEffect(() => {
    if (!state.progress) { return }

    const subscription = fromEvent<number>(audio.current, 'timeupdate')
      .pipe(
        throttleTime(700),
        map(() => audio.current.currentTime)
      )
      .subscribe(time => {
        state.progress.next(time / audio.current.duration)
        state.time.next(`${ zeroPad(Math.floor(time / 60), 2) }:${ zeroPad(Math.floor(time % 60), 2) }`)
        setCurrentTime(`${ Math.floor(time / 60) }:${ zeroPad(Math.floor(time % 60), 2) }`)
      })

    return () => {
      subscription.unsubscribe()
    }
  }, [audio, state])

  useEffect(() => {
    const element = audio.current

    const playPauseCallback = () => {
      setPaused(element.paused)
      state.playing.next(!element.paused)
    }

    const durationCallback = () => {
      setDuration(`${ Math.floor(element.duration / 60) }:${ zeroPad(Math.floor(element.duration % 60), 2) }`)
    }

    element.addEventListener('play', playPauseCallback)
    element.addEventListener('pause', playPauseCallback)
    element.addEventListener('loadedmetadata', durationCallback)

    return () => {
      element.removeEventListener('play', playPauseCallback)
      element.removeEventListener('pause', playPauseCallback)
      element.removeEventListener('loadedmetadata', durationCallback)
    }
  }, [state, audio])

  const handlePositionChange = useCallback((value: number) => {
    audio.current.currentTime = audio.current.duration * value
  }, [audio])

  const handleVolumeChange = useCallback(value => {
    audio.current.volume = value
    setVolume(value)
  }, [audio])

  useEffect(() => {
    audio.current.currentTime = 0
  }, [track, audio])

  return (
    <div className={ styles['container'] }>
      <audio ref={ audio } src={ track?.audioUrl } />

      <img
        className={ styles['image'] }
        src={ track.imageUrl }
      />

      <div className={ styles['info'] }>
        <div className={ styles['info-author'] }>{ track?.author }</div>
        <div className={ styles['info-title'] }>{ track?.title }</div>
      </div>

      <Icon
        name='shuffle'
        className={ styles['icon'] }
        size={ 24 }
      />

      <Icon
        name='rewind-left'
        className={ styles['icon'] }
        size={ 24 }
        onClick={ handlePreviousTrack }
      />

      <Icon
        name={ paused ? 'play' : 'pause' }
        className={ styles['icon'] }
        onClick={ handlePlayPause }
        size={ 48 }
      />

      <Icon
        name='rewind-right'
        className={ styles['icon'] }
        size={ 24 }
        onClick={ handleNextTrack }
      />

      <Icon
        name='repeat'
        className={ styles['icon'] }
        size={ 24 }
      />

      <div className={ styles['duration'] }>{ currentTime }</div>

      <Slider
        className={ styles['progress-slider'] }
        value={ position }
        onChange={ handlePositionChange }
      />

      <div className={ styles['duration'] }>{ duration }</div>

      <Icon
        name={ `volume${ Math.round(volume * 2) }` as 'volume0' }
        className={ styles['icon'] }
        size={ 24 }
      />

      <Slider
        className={ styles['volume-slider'] }
        value={ volume }
        onChange={ handleVolumeChange }
        emitOnMove={ true }
      />

      <Icon
        name='close'
        className={ styles['icon'] }
        size={ 24 }
        onClick={ onClosePlayer }
      />
    </div>
  )
}

export default ControlsComponent
