// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.post_item__SKY6w {
  border-radius: 2.4rem;
  background-color: #310C38;
  margin-top: 2rem;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.post_item-title__kua7T {
  color: #FCF9FB;
  font-family: Montserrat;
  font-size: 1.8rem;
  font-weight: bold;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
}
.post_item-title-icon__6Cgxh {
  width: 1.6rem;
  height: 1.6rem;
  color: #A68FA8;
  cursor: pointer;
}
.post_item-date__YY1EL {
  margin-top: 0.2rem;
  opacity: 0.9;
  color: #A68FA8;
  font-family: Montserrat;
  font-size: 1.3rem;
  font-weight: 500;
}
.post_item-image__KeW5T {
  margin-top: 1.4rem;
  object-fit: cover;
  height: 25.4rem;
  border-radius: 0.5rem;
}
.post_item-description__pySOz {
  margin-top: 1.4rem;
  opacity: 0.9;
  color: #FCF9FB;
  font-family: Montserrat;
  font-size: 1.3rem;
  font-weight: 500;
}

.post_filler__cU3VU {
  flex: 1 1;
}`, "",{"version":3,"sources":["webpack://./src/containers/Authenticated/pages/blog/post/index.module.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,yBAAA;EACA,gBAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,eAAA;AACF;AACE;EACE,cAAA;EACA,uBAAA;EACA,iBAAA;EACA,iBAAA;EACA,yBAAA;EACA,aAAA;EACA,mBAAA;AACJ;AACI;EACE,aAAA;EACA,cAAA;EACA,cAAA;EACA,eAAA;AACN;AAGE;EACC,kBAAA;EACC,YAAA;EACA,cAAA;EACA,uBAAA;EACA,iBAAA;EACA,gBAAA;AADJ;AAIE;EACC,kBAAA;EACC,iBAAA;EACA,eAAA;EACA,qBAAA;AAFJ;AAKE;EACE,kBAAA;EACA,YAAA;EACA,cAAA;EACA,uBAAA;EACA,iBAAA;EACA,gBAAA;AAHJ;;AAOA;EACE,SAAA;AAJF","sourcesContent":[".item {\n  border-radius: 2.4rem;\n  background-color: #310C38;\n  margin-top: 2rem;\n  padding: 3rem;\n  display: flex;\n  flex-direction: column;\n  cursor: pointer;\n\n  &-title {\n    color: #FCF9FB;\n    font-family: Montserrat;\n    font-size: 1.8rem;\n    font-weight: bold;\n    text-transform: uppercase;\n    display: flex;\n    flex-direction: row;\n\n    &-icon {\n      width: 1.6rem;\n      height: 1.6rem;\n      color: #A68FA8;\n      cursor: pointer;\n    }\n  }\n\n  &-date {\n  \tmargin-top: .2rem;\n    opacity: 0.9;\n    color: #A68FA8;\n    font-family: Montserrat;\n    font-size: 1.3rem;\n    font-weight: 500;\n  }\n\n  &-image {\n  \tmargin-top: 1.4rem;\n    object-fit: cover;\n    height: 25.4rem;\n    border-radius: .5rem;\n  }\n\n  &-description {\n    margin-top: 1.4rem;\n    opacity: 0.9;\n    color: #FCF9FB;\n    font-family: Montserrat;\n    font-size: 1.3rem;\n    font-weight: 500;\n  }\n}\n\n.filler {\n  flex: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `post_item__SKY6w`,
	"item-title": `post_item-title__kua7T`,
	"item-title-icon": `post_item-title-icon__6Cgxh`,
	"item-date": `post_item-date__YY1EL`,
	"item-image": `post_item-image__KeW5T`,
	"item-description": `post_item-description__pySOz`,
	"filler": `post_filler__cU3VU`
};
export default ___CSS_LOADER_EXPORT___;
