import React, { FunctionComponent, ReactElement, memo } from "react";
import cn from 'classnames';
import styles from "./names.module.scss";

interface IComponentProps {
  typeOfUser: string
  profileName: string
  genres: { title: string }[]
  className?: string
}

const Names: FunctionComponent<IComponentProps> = ({
  typeOfUser,
  profileName,
  genres,
  className
}): ReactElement => {
  return (
      <div className={ cn(styles['names'], className) }>
        <span>{ typeOfUser }</span>
        <h4 className={ styles['names-user-name'] }>{ profileName }</h4>
        <span className={ styles['names-genre'] }>{ (genres || []).map(({ title }) => title).join(', ') }</span>
      </div>
  )
}

export default memo(Names);
