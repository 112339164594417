// Common
import React, { FunctionComponent, ReactElement } from 'react'
import styles from './index.module.scss'
import cn from 'classnames'
import { format } from 'date-fns'

// Types
import { ChatMessage } from 'models/chat-message'

// Hooks
import { useEffect } from 'react'
import { useInject } from 'hooks/useInject'
import { useSubscribeValue } from 'hooks/useSubscribeValue'

// Services
import { ProfileService } from 'services/profile.service'
import { MessagesService } from 'services/mesages.service'

// Components
import Image from 'components/image'
import AttachmentComponent from './attachment'

interface IComponentProps {
  message: ChatMessage
}

const MessageComponent: FunctionComponent<IComponentProps> = ({ message }): ReactElement => {

  const profileService = useInject(ProfileService)
  const messagesService = useInject(MessagesService)

  const profile = useSubscribeValue(profileService.profile)

  useEffect(() => {
    messagesService.markAsRead(message)
  }, [messagesService, message])

  return (
    <div
      className={ cn(styles['container'], { [styles['mine']]: message.sender.id === profile?.id }) }
    >
      <div className={ styles['sender'] }>
        <Image
          className={ styles['avatar'] }
          src={ message.sender.avatarUrl }
        />

        <div className={ styles['date'] }>
          { format(message.createdAt, 'hh:mm') }
        </div>
      </div>

      <div className={ styles['body'] }>
        { message.text }
        <div className={ styles['attachments'] } >
          { message.attachments.map((attachment, index) =>
            <AttachmentComponent key={ index } message={ message } attachment={ attachment } />
          ) }
        </div>
      </div>
    </div>
  )
}

export default MessageComponent
