// Common
import React, { FunctionComponent, ReactElement, useCallback } from 'react'
import { UseTranslationResponse } from 'react-i18next'
import styles from './changeForgotPassword.module.scss'

// Components
import { Logo, PasswordIcon } from 'assets'
import Button from 'components/button'
import Input from 'components/input'
import AnimatedContainer from 'components/animatedContainer'

// Hooks
import { useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'redux/store'
import { useDispatch } from 'react-redux'

// Actions
import { requestChangeForgotPassword } from 'redux/actions/authentication.action'

const ChangeForgotPassword: FunctionComponent = (): ReactElement => {

  const [t]: UseTranslationResponse<string> = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const { control, handleSubmit, setError } = useForm()
  const { errors } = useSelector(({ authentication }) => authentication)
  const handleBack = useCallback(() => {
    navigate(-1)
  }, [navigate])

  const handleConfirm = useCallback(({ password, checkPassword }) => {
    if (password !== checkPassword) {
      setError('checkPassword', { type: 'notMatch', message: t('PASSWORD_NOT_MATCHED') })
      return
    }
    const token = searchParams.get('token')
    if (!token) {
      setError('checkPassword', { type: 'emptyToken', message: t('EMPTY_TOKEN') })
      return
    }
    console.info(token, password)
    dispatch(requestChangeForgotPassword({ password, token }))
  }, [dispatch, searchParams, setError, t])

  return (
      <div className={ styles['forgot-password'] }>
        <Logo className={ styles['forgot-password-logo'] } />

        <h2>{ t('CHANGE_FORGOT_PASSWORD') }</h2>

        <p>{ t('ENTER_NEW_PASSWORD') } </p>

        <form onSubmit={ handleSubmit(handleConfirm) } id='change-forgot-password-form'>
          <Input
            className={ styles['forgot-password-input'] }
            control={ control }
            title={ t('NEW_PASSWORD') }
            name='password'
            password={ true }
            required={ t('PASSWORD_BLANK') as string }
            leftIcon={ <PasswordIcon /> }
          />

          <Input
            className={ styles['forgot-password-input'] }
            control={ control }
            title={ t('CONFIRM_NEW_PASSWORD') }
            name='checkPassword'
            password={ true }
            required={ t('PASSWORD_BLANK') as string }
            leftIcon={ <PasswordIcon /> }
          />
        </form>

        { /* TODO Make a form with error handling and highlighting */ }
        <AnimatedContainer show={ !!errors }>
          <div className={ styles['forgot-password-error-message'] }>{ errors }</div>
        </AnimatedContainer>

        <div className={ styles['forgot-password-actions'] }>
          <Button
            onClick={ handleBack }
            border={ false }
            appearance='transparent'
          >
            { t('buttons.BACK') }
          </Button>
          <Button
            className={ styles['forgot-password-actions-submit'] }
            appearance='transparent'
            type='submit'
            form='change-forgot-password-form'
          >
            { t('buttons.CONFIRM') }
          </Button>
        </div>
      </div>
  )
}

export default ChangeForgotPassword
