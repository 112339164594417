import { put, all, takeEvery, call } from 'redux-saga/effects'
import { REQUEST_GENRES, receivedGenres, requestGenresFiled } from '../actions/genres.action'
import { setSpinnerState } from '../actions/spinner.action'
import { request } from '../../apiServices/http.service'
import { getGenres } from '../../apiServices/genres.service'

import Genre from '../../apiServices/models/genre.model'


import { GenreParams } from '../../apiServices/models/types'

function* requestGenres({ params }: { type: string, params: GenreParams }) {
  const { response, error } = yield call(request, getGenres(params))
  if (response) {
    yield put(receivedGenres(Genre.mapObjectsToEntities(response.genres)))
  } else {
    yield put(requestGenresFiled(error))
  }

  yield put(setSpinnerState(false))
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(REQUEST_GENRES, requestGenres)
  ])
}
