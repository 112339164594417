// Common
import { MutableRefObject } from 'react'

// Hooks
import { useCallback, useEffect, useRef } from 'react'

export function useInfiniteScroll({
  containerRef,
  triggerDistance = 200,
  loadMore,
  reset
}: {
  containerRef: MutableRefObject<HTMLElement>,
  triggerDistance?: number,
  loadMore: () => void,
  reset?: number,
}) {
  const inititalLoadEmitted = useRef<number>(-1)
  const lastManualScrollPosition = useRef<number>(0)
  const containerHeight = useRef<number>(0)
  const waitingForLoadMore = useRef(false)

  // Initial load
  useEffect(() => {
    if (reset === inititalLoadEmitted.current) { return }

    inititalLoadEmitted.current = reset

    loadMore?.()
  }, [loadMore, inititalLoadEmitted, reset])

  const loadMoreIfNeeded = useCallback(() => {
    containerHeight.current = containerRef.current.scrollTop

    if (
      !waitingForLoadMore.current &&
      containerRef.current.scrollHeight - triggerDistance <
      containerRef.current.scrollTop + containerRef.current.offsetHeight
    ){
      waitingForLoadMore.current = true
      loadMore?.()
    }
  }, [triggerDistance, containerRef, loadMore, waitingForLoadMore])

  useEffect(() => {
    const container = containerRef.current

    const handleScroll = () => {
      lastManualScrollPosition.current = containerRef.current.scrollTop
      loadMoreIfNeeded()
    }

    container.addEventListener('scroll', handleScroll)

    return () => container.removeEventListener('scroll', handleScroll)
  }, [loadMoreIfNeeded, containerRef])

  useEffect(() => {
    const handleMutation = () => {
      waitingForLoadMore.current = false
      loadMoreIfNeeded()
    }

    const mutationObserver = new MutationObserver(handleMutation)

    mutationObserver.observe(
      containerRef.current,
      { childList: true, subtree: true }
    )

    return () => {
      mutationObserver.disconnect()
    }
  }, [waitingForLoadMore, loadMoreIfNeeded, containerRef])

  return {
    lastManualScrollPosition,
    containerHeight
  }
}
