// Common
import React, { FunctionComponent, useEffect } from 'react'
import cn from 'classnames'
import styles from './index.module.scss'
import { Control } from 'react-hook-form'

// Hooks
import { useController } from 'react-hook-form'
import { useState, useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import useUpload from 'hooks/useUpload'
import useSubscribe from 'hooks/useSubscribe'

// Components
import Icon from 'components/icon'

// Types
import { Attachment } from 'types/attachment'

interface IComponentProps {
  name: string;
  control: Control
  className?: string;
  placeholder?: string;
}

const FileInput: FunctionComponent<IComponentProps> = ({
  name,
  control,
  className,
  placeholder,
}): React.ReactElement => {
  const [t] = useTranslation()

  const fileToUpload = useRef<File>()

  const {
    field: { onChange, value },
    fieldState: { error }
  } = useController({ name, control })

  const dragOver = useRef(0)

  const progressBar = useRef<HTMLDivElement>()

  const [hovered, setHovered] = useState(false)

  const { progress, upload, attachment, uploading, error: uploadError } = useUpload()

  useEffect(() => {
    if ((!error || error.type === 'id') && fileToUpload.current) {
      upload(fileToUpload.current)
      fileToUpload.current = null
    }
  }, [error, fileToUpload, upload])

  const processFile = useCallback((file: File) => {
    if (!file) { return }

    fileToUpload.current = file

    const attachment: Attachment = {
      fileName: file.name,
      temp: true,
      fileSize: file.size,
      fileType: file.type
    }

    onChange(attachment)
  }, [onChange])

  useEffect(() => {
    attachment && onChange(attachment)
  }, [onChange, attachment])

  useSubscribe(progress, (value) => {
    if (progressBar.current) {
      progressBar.current.style.width = `${ value * 100 }%`
    }
  }, [progress])

  const handleDrop = useCallback((event: React.DragEvent<HTMLLabelElement>) => {
    dragOver.current = 0
    setHovered(false)

    if (event.dataTransfer && event.dataTransfer.files) {
      processFile(event.dataTransfer.files[0])
    }

    event.stopPropagation()
    event.preventDefault()
  }, [dragOver, processFile])

  const handleChange = useCallback(event => {
    processFile(event?.target?.files?.[0])
  }, [processFile])

  const handleDragOver = useCallback((event: React.DragEvent<HTMLLabelElement>) => {
    event.preventDefault()
  }, [])

  const handleDragEnter = useCallback(() => {
    dragOver.current += 1
    setHovered(dragOver.current > 0)
  }, [dragOver])

  const handleDragLeave = useCallback(() => {
    dragOver.current -= 1
    setHovered(dragOver.current > 0)
  }, [dragOver])

  return (
    <label
      className={ cn(styles['container'], { [styles['dragover']]: hovered }, className) }
      onDrop={ handleDrop }
      onDragOver={ handleDragOver }
      onDragEnter={ handleDragEnter }
      onDragLeave={ handleDragLeave }
    >
      <input
        type='file'
        className={ styles['inputfile'] }
        onChange={ handleChange }
      />

      <div className={ styles['progress-container'] }>
        <div
          className={ styles['progress'] }
          ref={ progressBar }
        />
      </div>

      <div className={ styles['titles'] }>
        <Icon
          name='upload'
          className={ styles['upload-icon'] }
          width={ 30 }
          height={ 24 }
        />

        <div className={ styles['title'] }>
          { value?.fileName || placeholder || t('headers.upload_files') }
        </div>
      </div>

      { (uploadError || (error && !uploading)) &&
        <div className={ styles['error'] }>{ uploadError || error?.message }</div>
      }
    </label>
  )
}

export default FileInput
