// Common
import React, { FunctionComponent, ReactElement } from 'react'

// Hooks
import { useEffect, useCallback, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'redux/store'
import { useInfiniteScroll } from 'hooks/useInfiniteScroll'

// Action
import actions from 'redux/actions/photos.action-creators'

// Components
import Photo from '../photo'
import Modal from 'components/modal'
import PhotoModal from '../photoModal'

interface IComponentProps {
  albumId?: number
  eventId?: number
}

const Photos: FunctionComponent<IComponentProps> = ({ albumId, eventId }): ReactElement => {
  /**
   * Load Photos
   */

  const dispatch = useDispatch()

  const { items: photos, hasMore, pending, reset, error } = useSelector(({ photos }) => photos)

  useEffect(() => {
    return () => {
      dispatch(actions.clear())
    }
  }, [dispatch])

  const loadMore = useCallback(() => {
    if(!hasMore || pending || error) return

    dispatch(actions.read({ perPage: 10, albumId, eventId }))
  }, [pending, hasMore, albumId, eventId, dispatch, error])

  const scrollContainerRef = useRef(document.getElementById('root'))

  useInfiniteScroll({
    containerRef: scrollContainerRef,
    loadMore,
    reset
  })

  /**
   * Photo Modal
   */

  const [photoModalOpened, setPhotoModalOpened] = useState(false)
  const [photoIndex, setPhotoIndex] = useState(0)

  const handleOpenPhotoModal = useCallback((opened: boolean, index?: number) => () => {
    setPhotoModalOpened(opened)
    setPhotoIndex(index)
  }, [])

  return (
    <>
      {
        photos.map((photo, index) => (
          <Photo
            key={ index }
            photo={ photo }
            onClick={ handleOpenPhotoModal(true, index) } 
          />
        ))
      }

      <Modal
        isOpen={ photoModalOpened }
        onClose={ handleOpenPhotoModal(false) }
        padding={ false }
      >
        <PhotoModal
          eventId={ eventId }
          albumId={ albumId }
          initialIndex={ photoIndex }
          onClose={ handleOpenPhotoModal(false) }
        />
      </Modal>
    </>
  )
}

export default Photos
