// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accordion_accordion__WO2To {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  background-color: #282638;
  padding: 25px 30px 25px 20px;
  border-radius: 23px;
  font-size: 22px;
  font-family: "Futura-Bold", sans-serif;
  font-weight: bold;
  color: #fbfcfd;
  line-height: 1.68;
  transition: height 0.3s ease-in-out;
}
.accordion_accordion-header__4a8gk {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.accordion_accordion-body__RVTvO {
  height: 0;
  font-size: 18px;
  line-height: 1.78;
  font-weight: normal;
  font-family: "Futura-Medium", sans-serif;
  opacity: 0.8;
}
.accordion_accordion__WO2To .accordion_arrow__uJh6o {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: solid 2px #837e8d;
  background-color: #3b3a49;
  transition: transform 0.3s ease-in-out;
}
.accordion_accordion__WO2To .accordion_arrow__uJh6o.accordion_expanded__blhFu {
  transform: rotate(180deg);
}
.accordion_accordion__WO2To .accordion_arrow__uJh6o svg {
  width: 13px;
  height: 7px;
}
.accordion_accordion__WO2To .accordion_arrow__uJh6o svg g {
  fill: #b092ed;
}
.accordion_accordion__WO2To.accordion_expanded__blhFu .accordion_accordion-body__RVTvO {
  height: auto;
  padding-top: 40px;
}`, "",{"version":3,"sources":["webpack://./src/components/accordion/accordion.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,gBAAA;EACA,sBAAA;EACA,yBAAA;EACA,4BAAA;EACA,mBAAA;EACA,eAAA;EACA,sCAAA;EACA,iBAAA;EACA,cAAA;EACA,iBAAA;EACA,mCAAA;AACF;AACE;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,eAAA;AACJ;AAEE;EACE,SAAA;EACA,eAAA;EACA,iBAAA;EACA,mBAAA;EACA,wCAAA;EACA,YAAA;AAAJ;AAGE;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,yBAAA;EACA,yBAAA;EACA,sCAAA;AADJ;AAGI;EACE,yBAAA;AADN;AAII;EACE,WAAA;EACA,WAAA;AAFN;AAGM;EACE,aAAA;AADR;AAOI;EACE,YAAA;EACA,iBAAA;AALN","sourcesContent":[".accordion {\n  width: 100%;\n  height: auto;\n  display: flex;\n  overflow: hidden;\n  flex-direction: column;\n  background-color: #282638;\n  padding: 25px 30px 25px 20px;\n  border-radius: 23px;\n  font-size: 22px;\n  font-family: 'Futura-Bold', sans-serif;\n  font-weight: bold;\n  color: #fbfcfd;\n  line-height: 1.68;\n  transition: height .3s ease-in-out;\n\n  &-header {\n    width: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    cursor: pointer;\n  }\n\n  &-body {\n    height: 0;\n    font-size: 18px;\n    line-height: 1.78;\n    font-weight: normal;\n    font-family: 'Futura-Medium', sans-serif;\n    opacity: .8;\n  }\n\n  .arrow {\n    width: 24px;\n    height: 24px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border-radius: 50%;\n    border: solid 2px #837e8d;\n    background-color: #3b3a49;\n    transition: transform .3s ease-in-out;\n\n    &.expanded {\n      transform: rotate(180deg);\n    }\n\n    svg {\n      width: 13px;\n      height: 7px;\n      g {\n        fill: #b092ed;\n      }\n    }\n  }\n\n  &.expanded {\n    .accordion-body {\n      height: auto;\n      padding-top: 40px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"accordion": `accordion_accordion__WO2To`,
	"accordion-header": `accordion_accordion-header__4a8gk`,
	"accordion-body": `accordion_accordion-body__RVTvO`,
	"arrow": `accordion_arrow__uJh6o`,
	"expanded": `accordion_expanded__blhFu`
};
export default ___CSS_LOADER_EXPORT___;
