import { hostURL, request } from 'apiServices/http.service'
import { User } from 'models/user.model'
import { BehaviorSubject } from 'rxjs'
import ApiError from 'types/apiError'
import { Injectable } from 'types/injectable.interface'

export class ProfileService implements Injectable {
  private _profile = new BehaviorSubject<User>(null)
  private _error = new BehaviorSubject<ApiError>(null)

  public profile = this._profile.asObservable()

  attach() {
    // console.log('attach')
  }

  async getAsync() {
    if (this._profile.value) {
      return this._profile.value
    }

    const { response, error } = await request({ url: new URL(hostURL + '/profile') })

    if (response) {
      this._profile.next(new User(response))
    } else {
      this._error.next(error)
    }

    return this._profile.value
  }
}
