import { Injectable } from './injectable.interface'
import { InjectionToken } from './injection-token.type'

export class Injector {
  private instances: Map<InjectionToken, Injectable> = new Map()

  get(constructor: new (injector: Injector) => Injectable, injectionToken?: InjectionToken) {
    const token = injectionToken || constructor.name

    if (!this.instances[token]) {
      this.instances[token] = new constructor(this)

      this.instances[token].attach?.()
    }

    return this.instances[token]
  }
}
