// Common
import React, { FunctionComponent, memo } from 'react';
import styles from '../index.module.scss';

// Types
import { OptionType } from "../types/option";
import ControlledCheckBox from "../../controlledCheckBox/controlledCheckBox";

interface IComponentProps {
  option: OptionType
  checked: boolean;
  onSelect: (option: OptionType) => () => void
  showBy: 'title' | 'value'
}
const MultiselectOption: FunctionComponent<IComponentProps> = ({
  option,
  checked,
  showBy,
  onSelect: handleSelect
}) => {

  return (
      <ControlledCheckBox
        className={ styles['drop-down-options-item'] }
        name={ option.value }
        label={ showBy === 'title' ? option.title : option.value }
        checked={ checked }
        onSelect={ handleSelect(option) }
      />
  )
}

export default memo(MultiselectOption);
