// Common
import React, { FunctionComponent, ReactElement } from 'react'
import styles from './index.module.scss'
import { Navigate, Route, Routes } from 'react-router-dom'

// Components
import CoverImage from '../coverImage/coverImage'
import SideBar from '../sideBar/sideBar'
import Events from '../../events/events'
import EventsList from '../../events/list'
import EventPage from '../../events/eventPage'
import PerformersSearch from '../../performersSearch'
import Settings from '../../settings/settings'
import Calendar from '../../calendar'
import CalendarMonth from '../../calendarMonth'
import Media from '../../media'
import Albums from '../../media/albums'
import AlbumPage from '../../media/albumPage'
import MediaTracks from '../../media/tracks'
import Blog from '../../blog'
import Users from '../../users'
import PostPage from '../../blog/postPage'

// Hooks
import { useSelector } from 'redux/store'

const OwnProfile: FunctionComponent = (): ReactElement => {
  const profile = useSelector(({ profile: { profile }}) => profile.data)

  return (
    <div className={ styles['profile-container'] }>
      <div className={ styles['cover-image-container'] }>
        <Routes>
          <Route
            path='settings'
            element={ <CoverImage coverImageUrl={ profile.coverImageUrl } editable={ true } /> }
          />
          <Route
            path='*'
            element={ <CoverImage coverImageUrl={ profile.coverImageUrl } /> }
          />
        </Routes>
      </div>

      <div className={ styles['profile'] }>
        <div className={ styles['profile-side-bar'] }>
          <Routes>
            <Route
              path='settings'
              element={ <SideBar user={ profile } settingsMode={ true } /> }
            />
            <Route
              path='*'
              element={ <SideBar user={ profile } /> }
            />
          </Routes>
        </div>

        <div className={ styles['profile-body'] }>
          <Routes>
            <Route path='/events/*' element={ <Events /> }>
              <Route index={ true } element={ <EventsList /> } />
              <Route path=':eventId' element={ <EventPage /> } />
              <Route path=':eventId/performers' element={ <PerformersSearch /> } />
            </Route>
            <Route path='/settings' element={ <Settings /> } />
            <Route path='/calendar' element={ <Calendar /> } />
            <Route path='/calendar/:year/:month/:day' element={ <CalendarMonth /> } />
            <Route path='/media/*' element={ <Media /> } >
              <Route path='gallery' element={ <Albums /> } />
              <Route path='tracks' element={ <MediaTracks /> } />
              <Route path='*' element={ <Navigate to='gallery' /> } />
              <Route path='' element={ <Navigate to='gallery' /> } />
            </Route>
            <Route path='media/gallery/:id' element={ <AlbumPage /> } />
            <Route path='/blog/:type' element={ <Blog /> } />
            <Route path='/blog/:type/:id' element={ <PostPage /> } />
            <Route path='/blog' element={ <Navigate to='/blog/my' /> } />
            <Route path='/community/:type' element={ <Users /> } />
            <Route path='/community' element={ <Navigate to='/community/performer' /> } />
            <Route path='*' element={ <Navigate to='/calendar' /> } />
          </Routes>
        </div>
      </div>
    </div>
  )
}

OwnProfile.displayName = 'OwnProfile'

export default OwnProfile
