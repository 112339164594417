// Common
import React, { FunctionComponent, ReactElement } from 'react'
import cn from 'classnames'
import styles from './index.module.scss'

// Types
import { Event as EventModel } from 'models/event.model'
import { InvitationStatus } from 'types/eventInvitation'

// Components
import Icon from 'components/icon'
import Modal from 'components/modal'
import EventForm from '../eventForm'
import Image from 'components/image'

// Hooks
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useContextMenu from 'hooks/useContextMenu'
import { useInject } from 'hooks/useInject'

// Services
import { EventsService } from 'services/events.service'

interface IComponentProps {
  event: EventModel;
  className?: string;
}

export const EventComponent: FunctionComponent<IComponentProps> = ({
  event,
  className
}): ReactElement => {
  const [t] = useTranslation()

  const navigate = useNavigate()

  const eventsService = useInject(EventsService)

  const deleteEvent = useCallback(() => {
    eventsService.delete(event)
  }, [eventsService, event])

  const handleOpen = useCallback(() => {
    navigate(`/events/${ event.id }`)
  }, [navigate, event])

  const [formOpen, setFormOpen] = useState(false)

  const handleOpenForm = useCallback((opened: boolean) => () => {
    setFormOpen(opened)
  }, [])

  const contextMenu = useContextMenu([
    { title: 'Edit', action: handleOpenForm(true) },
    { title: 'Delete', action: deleteEvent }
  ])

  const handleOwnerClick = useCallback((clickEvent) => {
    clickEvent.stopPropagation()
    navigate('/users/' + event.user.id)
  }, [event, navigate])

  return (
    <>
      <div
        className={ cn( styles['event'], className ) }
        onClick={ handleOpen }
      >
        { event?.invitation?.status === InvitationStatus.PENDING &&
          <div className={ styles['event-badge'] }>{ t('headers.new') }</div>
        }

        <Image
          className={ styles['event-image'] }
          src={ event.imageUrl }
        />

        <div className={ styles['event-data'] }>
          <div className={ styles['event-data-container'] }>
            <h4 className={ styles['event-data-container-title'] }>{ event.title }</h4>

            <span
              className={ styles['event-data-container-owner'] }
              onClick={ handleOwnerClick }
            >
            { event.user.profileName }
            </span>

            { /* TODO need to investigate how to truncate long description with ellipses */ }
            <p className={ styles['event-data-container-description'] }>{ event.description }</p>
          </div>
          { /* TODO need to create a converter of date/time */ }
          <span className={ styles['event-data-date'] }>{ event.from?.toDateString() } start at { event.from?.toLocaleTimeString() }</span>
        </div>

        <Icon
          name='kebab'
          className={ styles['event-action'] }
          size={ 16 }
          { ...contextMenu }
        />
      </div>

      <Modal
        isOpen={ formOpen }
        onClose={ handleOpenForm(false) }
      >
        <EventForm
          onClose={ handleOpenForm(false) }
          event={ event }
        />
      </Modal>
    </>
  )
}

export default EventComponent
