// Common
import React, { FunctionComponent, ReactElement, CSSProperties } from 'react'
import styles from './index.module.scss'
import cn from 'classnames'

// Helpers
import { getWeekDayName, beginningOfDay, endOfDay } from 'helpers/date'

// Components
import { Calendar } from 'react-calendar'

// Hooks
import { useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

// Types
import { CalendarEvent } from 'types/calendarEvent'

const colors = ['#2FB400'] // '#E03434', '#BA9B5A']

interface IComponentProps {
  month?: Date
  onClickDay?: (day: Date) => void
  style?: CSSProperties
  className?: string,
  ranges?: CalendarEvent[]
}

const CalendarMonth: FunctionComponent<IComponentProps> = ({
  month,
  onClickDay: handleClickDay,
  style,
  className,
  ranges
}): ReactElement => {
  const { t, i18n } = useTranslation()

  const fullRanges = useMemo(() => (
    (ranges || [])
      .filter(range => !!range.from && !!range.to)
      .map(({ from, to }) => ({ from: beginningOfDay(from), to: endOfDay(to) }))
  ), [ranges])

  const styleInRanges = useCallback((date) => (
    fullRanges
      .map(({ from, to }, index) => {
        if (date.getTime() < from.getTime() || date.getTime() > to.getTime()) {
          return null
        }

        const start =
          date.getFullYear() === from.getFullYear() &&
          date.getMonth() === from.getMonth() &&
          date.getDate() === from.getDate()

        const end =
          date.getFullYear() === to.getFullYear() &&
          date.getMonth() === to.getMonth() &&
          date.getDate() === to.getDate()

        let className

        if (start && end) {
          className = styles['start-end']
        } else if (start) {
          className = styles['start']
        } else if (end) {
          className = styles['end']
        } else {
          className = styles['in']
        }

        return {
          className,
          style: { borderColor: colors[index % colors.length] }
        }
      })
      .filter(range => !!range)
  ), [fullRanges])

  const renderWeekday = useMemo(() => (locale, date) => {
    const key = getWeekDayName(date.getDay())

    if (!i18n.exists(key)) { return '' }

    return t(key).charAt(0)
  }, [t, i18n])

  return (
    <div
      className={ cn(styles['month'], className) }
      style={ style }
    >
      <Calendar
        key={ month.getMonth() }
        className={ styles['calendar'] }
        value={ month }
        formatShortWeekday={ renderWeekday }
        showNavigation={ false }
        showNeighboringMonth={ false }
        onClickDay={ handleClickDay }
        tileContent={ ({ date }) => (
          styleInRanges(date).map(({ className, style }, index) => (
            <div
              key={ index }
              className={ cn(styles['range'], className) }
              style={ style }
            />
          ))
        ) }
      />
    </div>
  );
}

export default CalendarMonth;
