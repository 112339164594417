export const aboutUsContent = `
What are we doing?<br>
Where are we going?<br>
What’s around here?
<br>
Cloobin provides the answer to all these questions! 
<br>
Our platform is designed with you and your own individual style in mind. Cloobin is your invitation to your ideal party! Let us help you find you the perfect night out, no matter where you are! At Cloobin, our mission is to connect you with fun and exciting experiences by revolutionizing how you make plans.  
<br>
We are reinventing entertainment. 
<br>
Our mission was to create a one-of-a-kind platform that allows all aspects of the music industry to connect with each other. We partner with various artists, producers, promotors, clubs, lounges, bars, festivals, DJ’s, singers, bands, dancers, MC’s, and restaurants and provide them with a customizable platform where they can freely connect and work together to benefit everyone involved. 
<br>
Cloobin stands for the idea of unity. Our goal is to take the global music industry and make it accessable to anyone, anywhere, anytime. Whether you are an artist, club owner or fan, Cloobin is made for you. 
<br>
Register today and join our global entertainment community!
`
