import React, { useEffect, useRef } from 'react'
import { Editor } from '@tinymce/tinymce-react'
// TinyMCE so the global var exists
import 'tinymce/tinymce';
// DOM model
import 'tinymce/models/dom/model'
// Theme
import 'tinymce/themes/silver';
// Toolbar icons
import 'tinymce/icons/default';
// Editor styles
import 'tinymce/skins/ui/oxide/skin';

import 'tinymce/plugins/advlist';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/autosave';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/code';
import 'tinymce/plugins/codesample';
import 'tinymce/plugins/directionality';
import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/help';
import 'tinymce/plugins/help/js/i18n/keynav/en';
import 'tinymce/plugins/image';
import 'tinymce/plugins/importcss';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/media';
import 'tinymce/plugins/nonbreaking';
import 'tinymce/plugins/pagebreak';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/quickbars';
import 'tinymce/plugins/save';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/table';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/visualchars';
import 'tinymce/plugins/wordcount';

// importing plugin resources
import 'tinymce/plugins/emoticons/js/emojis';

export default function BundledEditor({ body, onChange }: any) {
  const editorRef = useRef<Editor>(null)

  return (
    <Editor
      apiKey={ process.env.REACT_APP_TINY_EDITOR_KEY ?? 'z5azhn8s6mllrmx9pzr90o6nit4agev3njf1r09z7yflf7p6' }
      onInit={ (_evt, editor) => editorRef.current = editor }
      init={ {
        plugins: 'anchor autolink charmap link lists table visualblocks',
        toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link table | align lineheight | numlist bullist indent outdent',
        tinycomments_mode: 'embedded',
        tinycomments_author: 'Author name',
        height: '100%',
        width: '100%'
      } }
      value={ body }
      onEditorChange={ onChange }
    />
  )
}
