// Common
import React, { FunctionComponent } from 'react'
import styles from './index.module.scss'
import cn from 'classnames'

// RX
import { fromEvent, tap, debounceTime } from 'rxjs'

// Hooks
import { useEffect, useState, useCallback, useRef } from 'react'

interface IComponentProps {
  value: number,
  onChange: (value: number) => void,
  className: string,
  emitOnMove?: boolean
}

const SliderComponent: FunctionComponent<IComponentProps> = ({
  value,
  onChange,
  className,
  emitOnMove
}): React.ReactElement => {
  const [dragging, setDragging] = useState(false)
  const [consuming, setConsuming] = useState(true)
  const [position, setPosition] = useState(0)
  const container = useRef<HTMLDivElement>()

  useEffect(() => {
    consuming && setPosition(value)
  }, [value, consuming])

  useEffect(() => {
    if (!dragging) { return }

    const bounding = container.current.getBoundingClientRect()

    const subscription = fromEvent<MouseEvent>(document, 'mousemove')
      .subscribe(e => {
        bounding.left

        const newValue = Math.min(Math.max(0, e.clientX - bounding.left), bounding.width) / bounding.width
        setPosition(newValue)

        emitOnMove && onChange(newValue)
      })

    return () => {
      subscription.unsubscribe()
    }
  }, [dragging, container, onChange, emitOnMove])

  useEffect(() => {
    if (!dragging) { return }

    const bounding = container.current.getBoundingClientRect()

    const subscription = fromEvent<MouseEvent>(document, 'mouseup')
      .pipe(
        tap(e => {
          const newValue = Math.min(Math.max(0, e.clientX - bounding.left), bounding.width) / bounding.width
          setPosition(newValue)
          onChange(newValue)
          setDragging(false)
        }),
        debounceTime(700)
      )
      .subscribe(() => {
        setConsuming(true)
      })

    return () => {
      subscription.unsubscribe()
    }
  }, [dragging, onChange])

  const handleMouseDown = useCallback(() => {
    setDragging(true)
    setConsuming(false)
  }, [])

  return (
    <div
      className={ cn(styles['container'], className) }
      ref={ container }
    >
      <div
        className={ cn(styles['control'], consuming && styles['consuming']) }
        style={ { left: `${ position * 100 }%` } }
        onMouseDown={ handleMouseDown }
      />
    </div>
  )
}

export default SliderComponent
