export function objToCamelCase<T>(obj: T): T {
  if (!obj) {
    return
  }

  return Object
    .entries(obj)
    .reduce((memo, [key, value]) => ({
      ...memo,
      [isCamelCase(key) ? key : snakeToCamelCase(key)]: value
    }), {} as T)
}

const isCamelCase = (str: string) => !str.match(/[\s_-]/g)

export const snakeToCamelCase = (str: string): string =>
  str.toLowerCase().replace(/([-_][a-z])/g, group =>
    group
      .toUpperCase()
      .replace('-', '')
      .replace('_', '')
  )

export const camelToSnakeCase = (str: string): string =>
  str?.replace(/[A-Z]/g, letter => `_${ letter.toLowerCase() }`)
