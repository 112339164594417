// Common
import React, { Component } from 'react'
import styles from './home.module.scss'

// Components
import CarouselSlide from 'components/carousel/slide/carouselSlide'
import Carousel from 'components/carousel'
import Icon from 'components/icon'

// Mocks
import { slides } from 'mocks/carousel.mock'

class Home extends Component<unknown, unknown> {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render(): React.ReactElement<unknown, string | React.JSXElementConstructor<unknown>> {
    return(
        <div className={ styles['home'] }>
          <Carousel withArrows={ false }>
            {
              slides.map((slide, index) => {
                return(
                    <CarouselSlide key={ index }>
                      <div className={ styles['slide-container'] }>
                        <img src={ slide.image } alt='' />
                        <div className={ styles['slide-container-mask'] } />

                        <h1>{ slide.title }</h1>

                        <div className={ styles['slide-container-date'] }>
                          <Icon
                            className={ styles['slide-container-date-icon'] }
                            name='calendar'
                            width={ 28 }
                            height={ 28 }
                            size={ 28 }
                          />
                          <span>{ slide.date.toLocaleString() }</span>
                        </div>

                        <p className={ styles['slide-container-description'] }>
                          { slide.description }
                        </p>
                      </div>
                    </CarouselSlide>
                )
              })
            }
          </Carousel>
        </div>
    )
  }
}

export default Home
