// Common
import React, { FunctionComponent, ReactElement } from 'react'
import styles from './index.module.scss'

// Components
import Button from 'components/button'
import Label from 'components/label'
import FileInput from 'components/fileInput'
import Input from 'components/input'
import Textarea from 'components/textarea'

// Hooks
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { useInject } from 'hooks/useInject'
import useSubscribe from 'hooks/useSubscribe'

// Models
import { Album } from 'models/album.model'

// Services
import { AlbumsService } from 'services/albums.service'
import { useSubscribeValue } from 'hooks/useSubscribeValue'

interface IComponentProps {
  onClose?: () => void,
  album?: Album,
}

const AlbumForm: FunctionComponent<IComponentProps> = ({
  onClose: handleClose,
  album = new Album()
}): ReactElement => {
  const [t] = useTranslation()

  const albumsService = useInject(AlbumsService)

  const { control, handleSubmit } = useForm(album.asForm())

  const submit = useCallback((data) => {
    albumsService.upsert(Album.fromFormData(data))
  }, [albumsService])

  useSubscribe(albumsService.upserted, () => {
    handleClose()
  }, [handleClose])

  const pending = useSubscribeValue(albumsService.upserting)

  return (
    <form
      className={ styles['form'] }
      onSubmit={ handleSubmit(submit) }
    >
      <Label
        appearance='gold'
        text={ album.id ? t('buttons.album_edit') : t('buttons.album_add') }
      />

      <Label
        className={ styles['label'] }
        appearance='input'
        text={ t('headers.album_name') }
      />

      <Input
        control={ control }
        name='title'
        placeholder={ t('headers.title') }
        required={ true }
        theme='light'
      />

      <Label
        className={ styles['label'] }
        appearance='input'
        text={ t('headers.description') }
      />

      <Textarea
        control={ control }
        name='description'
        placeholder={ t('headers.description') }
      />

      <Label
        className={ styles['label'] }
        appearance='input'
        text={ t('headers.image') }
      />

      <FileInput
        control={ control }
        name='image'
      />

      <div className={ styles['buttons'] }>
        <Button
          className={ styles['buttons-item'] }
          appearance='transparent'
          onClick={ handleClose }
        >
          { t('buttons.cancel') }
        </Button>

        <Button
          className={ styles['buttons-item'] }
          appearance='gold-big'
          type='submit'
          loading={ pending }
        >
          { album.id ? t('buttons.album_edit') : t('buttons.album_add') }
        </Button>
      </div>
    </form>
  )
}

export default AlbumForm
