// Common
import React, { FunctionComponent, ReactElement } from 'react'
import styles from './index.module.scss'
import { format } from 'date-fns'

// Components
import Icon from 'components/icon'
import PageTabsHeader from 'components/pageTabsHeader'
import Image from 'components/image'

// Hooks
import { useCallback, useEffect } from 'react'
import useContextMenu from 'hooks/useContextMenu'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useInject } from 'hooks/useInject'
import { useSubscribeValue } from 'hooks/useSubscribeValue'
import useSubscribe from 'hooks/useSubscribe'

// Services
import { PostsService } from 'services/posts.service'

const PostPageComponent: FunctionComponent = (): ReactElement => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const { id } = useParams()

  const postsService = useInject(PostsService)

  const post = useSubscribeValue(postsService.item)

  const deletePost = useCallback(() => {
    postsService.delete(post)
  }, [postsService, post])

  useSubscribe(postsService.upserted, () => {
    navigate(-1)
  }, [navigate])

  const contextMenu = useContextMenu([
    { title: 'Delete', action: deletePost }
  ])

  useEffect(() => {
    postsService.requestOne(parseInt(id))
  }, [postsService, id])

  return (
    <>
      <PageTabsHeader
        backLabel={ t('buttons.back_to_blog') }
      />

      <div className={ styles['meta'] } >
        <Image
          className={ styles['meta-image'] }
          src={ post?.imageUrl }
        />

        <div className={ styles['meta-info'] } >
          <div className={ styles['meta-info-title'] } >
            { post?.title } 
          </div>

          <div className={ styles['meta-info-date'] } >
            { post?.createdAt && format(post.createdAt, 'dd LLL yyyy') }
          </div>
        </div>

        <Icon
          name='kebab'
          className={ styles['meta-kebab-icon'] }
          size={ 16 }
          { ...contextMenu }
        />
      </div>

      <div className={ styles['description'] } >
        <div className={ styles['description-text'] } >
          { post?.description }
        </div>
      </div>
    </>
  )
}

export default PostPageComponent
