import React, { FunctionComponent, ReactElement, useCallback, useEffect, useRef, useState } from "react";
import Picker from 'react-timekeeper';
import styles from './timePicker.module.scss';
import { usePopper } from "react-popper";
import useDropDown from "../../hooks/useDropDown";
import { Control, useController } from "react-hook-form";
import cn from 'classnames';
import { SmallArrow } from "../../assets";

interface IComponentProps {
  control: Control
  name: string
  defaultValue?: string
  required?: boolean | string
}
const TimePicker: FunctionComponent<IComponentProps> = ({
  control,
  name,
  required,
  defaultValue
}): ReactElement => {
  const [triggerRef, setTriggerRef] = useState<HTMLDivElement>(null);
  const [popover, setPopover] = useState<HTMLDivElement>(null)
  const popoverRef = useRef<HTMLDivElement>(null)

  const [show, handleTrigger] = useDropDown(popoverRef)
  const {
    styles: { popper: popperStyles },
    attributes: { popper: popperAttributes }
  } = usePopper(triggerRef, popover, { placement: 'right' })
  const { field: { value, onChange }, fieldState: { error, isDirty }} = useController({
    name,
    control: control,
    rules: { required },
    defaultValue: defaultValue || '12:00 AM'
  });

  useEffect(() => {
    popoverRef.current = popover;
  }, [popover])

  const handleTimePicked = useCallback(({ formatted12: time }) => {
    onChange(time.toUpperCase())
    handleTrigger();
  }, [handleTrigger, onChange])

  // Don't need a doneButton by design. doneButton callback is needed to prevent re-render in timepicker
  const doneButton = useCallback(() => null, []);

  return (
      <>
        <div
            className={ cn(
                styles['trigger'],
                { [styles['dirty']]: isDirty },
                { [styles['error']]: error }
            ) }
            ref={ setTriggerRef }
            onClick={ handleTrigger }
        >
          { value &&
            <span className={ styles['trigger-value'] }>{ value }</span>
          }

          <SmallArrow className={ styles['trigger-icon'] } />
        </div>
        { show &&
          <div
              className={ styles['time-picker'] }
              ref={ setPopover }
              style={ popperStyles }
              { ...popperAttributes }
          >
            <Picker
                time={ value }
                switchToMinuteOnHourSelect={ true }
                closeOnMinuteSelect={ true }
                onDoneClick={ handleTimePicked }
                doneButton={ doneButton }
            />
          </div>
        }
      </>
  )
}

export default TimePicker;
