import { hostURL } from "./http.service";
import { CreateFeedbackParams, RequestParams } from "./models/types";

export function getSubjects(): RequestParams {
  const url = new URL(hostURL + '/subjects');

  return {
    url,
    withLocale: true
  }
}

export function createFeedback({ email, fullName, subject, message }: CreateFeedbackParams): RequestParams {
  const url = new URL(hostURL + '/feedbacks');
  const body = new FormData();

  body.append('email', email);
  body.append('full_name', fullName);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  body.append('subject_id', subject?.id);
  body.append('message', message);

  return {
    url,
    body,
    method: "POST"
  }
}
