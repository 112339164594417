// Common
import React, { FunctionComponent, ReactElement } from 'react'
import styles from './index.module.scss'
import { PlayerContext } from 'contexts/player.context'
import { isNil } from 'lodash'

// Components
import Controls from './controls'

// Hooks
import { useCallback, useEffect, useMemo, useState, useContext } from 'react'
import { useSelector } from 'redux/store'
import { useDispatch } from 'react-redux'
import useSubscribe from 'hooks/useSubscribe'

// Actions
import actions from 'redux/actions/player.action-creators';

// Types
import { TracksFilters } from 'models/track.model'

interface IComponentProps {}

const PlayerComponent: FunctionComponent<IComponentProps> = (): ReactElement => {
  const dispatch = useDispatch()

  const [filters, setFilters] = useState<TracksFilters>(null)
  const [index, setIndex] = useState<number>(null)

  useEffect(() => {
    setIndex(filters?.page)
  }, [filters])

  const { items: tracks, pending, totalCount, error } = useSelector(({ player }) => player)

  useEffect(() => {
    return () => {
      dispatch(actions.clear())
    }
  }, [dispatch])

  const handleNextTrack = useCallback((direction: 1 | -1) => () => {
    setIndex(index => (index + direction + totalCount) % totalCount)
  }, [totalCount])

  useEffect(() => {
    if (isNil(index) || !filters || error || pending || !!tracks[index]) { return }

    dispatch(actions.read({ ...filters, page: index + 1, perPage: 1 }))
  }, [error, pending, index, tracks, filters, dispatch])

  const track = useMemo(() => {
    return tracks[index]
  }, [tracks, index])

  const state = useContext(PlayerContext)

  useSubscribe(state.filters, filters => {
    setFilters(filters)
    state.triggerPlayPause.next()
  }, [state])

  useSubscribe(state.triggerNextTrack, direction => {
    handleNextTrack(direction)()
  }, [handleNextTrack])

  useEffect(() => {
    state.currentId.next(track?.id)
  }, [state, track])

  const onClosePlayer = useCallback(() => {
    setIndex(null)
    dispatch(actions.clear())
  }, [dispatch])

  if (!track) { return null }

  return (
    <div className={ styles['container'] }>
      <Controls
        track={ track }
        onNextTrack={ handleNextTrack(1) }
        onPreviousTrack={ handleNextTrack(-1) }
        onClosePlayer={ onClosePlayer } // Pass the onClosePlayer function as a prop
      />
    </div>
  )
}

export default PlayerComponent
