// Common
import React, { FunctionComponent, ReactElement, useState } from 'react'
import styles from './index.module.scss'

// Types
import { FriendshipStatus } from 'types/friendship-status'

// Components
import Image from 'components/image'
import Button from 'components/button'

// Models
import { User } from 'models/user.model'

// Hooks
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useCallback, useEffect } from 'react'
import { useSelector } from 'redux/store'
import { useInject } from 'hooks/useInject'
import useSubscribe from 'hooks/useSubscribe'

// Actions
import friendshipsActions from 'redux/actions/friendships.action-creators'
import friendsActions from 'redux/actions/friends.action-creators'
import followersActions from 'redux/actions/followers.action-creators'

// Services
import { ChatRoomsService } from 'services/chat-rooms.service'
import { ChatStateService } from 'services/chat-state.service'

interface IComponentProps {
  user: User
}

const UserComponent: FunctionComponent<IComponentProps> = ({ user }): ReactElement => {
  const dispatch = useDispatch()

  const [t] = useTranslation()

  const profile = useSelector(({ profile: { profile }}) => profile.data)

  const [relationship, setRelationship] = useState({
    friendshipStatus: user.friendshipStatus,
    friendshipInitiatorId: user.friendshipInitiatorId
  })

  const [following, setFollowing] = useState(user.following)

  useEffect(() => {
    setRelationship({
      friendshipStatus: user.friendshipStatus,
      friendshipInitiatorId: user.friendshipInitiatorId
    })
  }, [user])

  const handleAddToFriends = useCallback(() => {
    dispatch(friendshipsActions.upsert(user))
    setRelationship({ friendshipStatus: FriendshipStatus.PENDING, friendshipInitiatorId: profile.id })
  }, [user, profile, dispatch])

  const handleRevokeFriendRequest = useCallback(() => {
    dispatch(friendsActions.deleteItem(user))
    setRelationship({ friendshipStatus: null, friendshipInitiatorId: null })
  }, [user, dispatch])

  const handleAcceptFriendRequest = useCallback(() => {
    dispatch(friendshipsActions.upsert(user))
    setRelationship({ friendshipStatus: FriendshipStatus.ACCEPTED, friendshipInitiatorId: user.id })
  }, [user, dispatch])

  const handleDeclineFriendRequest = useCallback(() => {
    dispatch(friendshipsActions.deleteItem(user))
    setRelationship({ friendshipStatus: null, friendshipInitiatorId: null })
  }, [user, dispatch])

  const handleRemoveFromFriends = useCallback(() => {
    dispatch(friendsActions.deleteItem(user))
    setRelationship({ friendshipStatus: null, friendshipInitiatorId: null })
  }, [user, dispatch])

  const handleFollow = useCallback(() => {
    dispatch(followersActions.upsert(user))
    setFollowing(true)
  }, [user, dispatch])

  const handleUnfollow = useCallback(() => {
    dispatch(followersActions.deleteItem(user))
    setFollowing(false)
  }, [user, dispatch])

  const chatRoomsService = useInject(ChatRoomsService)

  const handleCreateChatRoom = useCallback(() => {
    chatRoomsService.create(user)
  }, [chatRoomsService, user])

  const chatStateService = useInject(ChatStateService)

  useSubscribe(chatRoomsService.upserted, () => {
    chatStateService.opened.next(true)
  }, [chatStateService])

  return (
    <div className={ styles['item'] } >
      <Image
        className={ styles['item-image'] }
        src={ user.avatarUrl }
      />

      <div className={ styles['item-info'] } >
        <div className={ styles['item-info-title'] } >
          { user.email }

          <div className={ styles['filler'] } />

        </div>

        <div className={ styles['item-info-description'] } >
          { user.profileName || 'no profile name' }
        </div>
      </div>

      <div className={ styles['filler'] } />

      <div className={ styles['item-buttons'] } >
        { !relationship.friendshipStatus && (
          <Button
            appearance='gold'
            onClick={ handleAddToFriends }
          >
            { t('buttons.add_to_friends') }
          </Button>
        ) }

        { relationship.friendshipStatus === FriendshipStatus.PENDING && relationship.friendshipInitiatorId === profile.id && (
          <Button
            appearance='gold'
            onClick={ handleRevokeFriendRequest }
          >
            { t('buttons.revoke_friend_request') }
          </Button>
        ) }

        { relationship.friendshipStatus === FriendshipStatus.PENDING && relationship.friendshipInitiatorId !== profile.id && (
          <>
            <Button
              appearance='gold'
              onClick={ handleAcceptFriendRequest }
            >
              { t('buttons.accept_friend_request') }
            </Button>
            <Button
              appearance='gold'
              onClick={ handleDeclineFriendRequest }
            >
              { t('buttons.decline_friend_request') }
            </Button>
          </>
        ) }

        { relationship.friendshipStatus === FriendshipStatus.ACCEPTED && (
          <>
            <Button
              appearance='gold'
              onClick={ handleRemoveFromFriends }
            >
              { t('buttons.remove_from_friends') }
            </Button>

            <Button
              appearance='gold'
              onClick={ handleCreateChatRoom }
            >
              { t('headers.message') }
            </Button>
          </>
        ) }

        { following && (
          <Button
            appearance='gold'
            onClick={ handleUnfollow }
          >
            { t('buttons.unfollow') }
          </Button>
        ) }

        { !following && (
          <Button
            appearance='gold'
            onClick={ handleFollow }
          >
            { t('buttons.follow') }
          </Button>
        ) }
      </div>
    </div>
  )
}

export default UserComponent
