import React, { FunctionComponent, ReactElement } from "react";
import styles from "../settings.module.scss"

// Components
import SettingsBusinessGeneralInfo from "./settings.business.generalInfo";
import SettingsBusinessWorkingSchedule from "./settings.business.workingSchedule";
import SettingsContactInfo from "../common/settings.contactInfo";
import SettingsGenres from "../common/settings.genres";
import SettingsAboutMe from "../common/settings.aboutMe";
import Delimiter from "../../../components/delimiter/delimiter";

const SettingsBusiness: FunctionComponent = (): ReactElement => {

  return (
      <>
        <SettingsBusinessGeneralInfo />

        <Delimiter className={ styles['delimiter'] } />

        <SettingsBusinessWorkingSchedule />

        <Delimiter className={ styles['delimiter'] } />

        <SettingsContactInfo />

        <Delimiter className={ styles['delimiter'] } />

        <SettingsGenres />

        <Delimiter className={ styles['delimiter'] } />

        <SettingsAboutMe />
      </>
  )
}

export default SettingsBusiness;
