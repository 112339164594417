import { hostURL } from 'apiServices/http.service'
import { BaseRestInfiniteScrollService } from './base-rest-infinite-scroll.service'
import { Album, AlbumFilters } from 'models/album.model'

export class AlbumsService extends BaseRestInfiniteScrollService<Album, AlbumFilters> {

  protected entityName = 'Album'

  async buildUrl() {
    return new URL(`${ hostURL }/photo-albums`)
  }

  buildItem(item: object) {
    return new Album(item)
  }

  getItemFromResponse(response: object) {
    return response['album']
  }

  getItemsFromResponse(response: object) {
    return response['albums']
  }
}
