// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.photo_item__SRxwJ {
  height: 11rem;
  width: 11rem;
  border-radius: 1.069rem;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.photo_item-image__25ayJ {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.photo_item-actions__ZYNK- {
  position: absolute;
  bottom: -2.8rem;
  left: 0;
  right: 0;
  height: 2.8rem;
  background-color: rgba(73, 30, 77, 0.5);
  transition: bottom 0.3s ease-in-out;
  padding: 0 1rem;
  color: #FCF9FB;
  font-family: Montserrat;
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.3rem;
}
.photo_item-actions-heart__quOUU {
  color: #e03434;
}
.photo_item-actions-messages__Ebe9W {
  color: #ba9b5a;
}
.photo_item__SRxwJ:hover .photo_item-actions__ZYNK- {
  bottom: 0;
}

.photo_filler__lYuve {
  flex: 1 1;
}`, "",{"version":3,"sources":["webpack://./src/containers/Authenticated/pages/media/photo/index.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,YAAA;EACA,uBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;AACF;AACE;EACE,YAAA;EACA,WAAA;EACA,iBAAA;AACJ;AAEE;EACE,kBAAA;EACA,eAAA;EACA,OAAA;EACA,QAAA;EACA,cAAA;EACA,uCAAA;EACA,mCAAA;EACA,eAAA;EACA,cAAA;EACA,uBAAA;EACA,iBAAA;EACA,gBAAA;EACA,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,WAAA;AAAJ;AAEI;EACE,cAAA;AAAN;AAGI;EACE,cAAA;AADN;AAMI;EACE,SAAA;AAJN;;AASA;EACE,SAAA;AANF","sourcesContent":[".item {\n  height: 11rem;\n  width: 11rem;\n  border-radius: 1.069rem;\n  position: relative;\n  overflow: hidden;\n  cursor: pointer;\n\n  &-image {\n    height: 100%;\n    width: 100%;\n    object-fit: cover;\n  }\n\n  &-actions {\n    position: absolute;\n    bottom: -2.8rem;\n    left: 0;\n    right: 0;\n    height: 2.8rem;\n    background-color: rgba(73, 30, 77, .5);\n    transition: bottom .3s ease-in-out;\n    padding: 0 1rem;\n    color: #FCF9FB;\n    font-family: Montserrat;\n    font-size: 1.2rem;\n    font-weight: 500;\n    letter-spacing: 0;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    gap: .3rem;\n\n    &-heart {\n      color: #e03434;\n    }\n\n    &-messages {\n      color: #ba9b5a;\n    }\n  }\n\n  &:hover {\n    .item-actions {\n      bottom: 0;\n    }\n  }\n}\n\n.filler {\n  flex: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `photo_item__SRxwJ`,
	"item-image": `photo_item-image__25ayJ`,
	"item-actions": `photo_item-actions__ZYNK-`,
	"item-actions-heart": `photo_item-actions-heart__quOUU`,
	"item-actions-messages": `photo_item-actions-messages__Ebe9W`,
	"filler": `photo_filler__lYuve`
};
export default ___CSS_LOADER_EXPORT___;
