// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user_item__nJeBg {
  height: 8rem;
  border: 0.05rem solid #A68FA8;
  border-radius: 2.5rem;
  background-color: #491E4D;
  margin: 1.6rem 0 0;
  padding: 1.4rem 1.9rem 1.4rem 1.6rem;
  display: flex;
  flex-direction: row;
}
.user_item-image__ZUoQm {
  height: 5rem;
  width: 5rem;
  border-radius: 2.5rem;
  object-fit: cover;
}
.user_item-info__\\+aALj {
  padding-left: 2rem;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.user_item-info-title__p1qXo {
  color: #B99A5E;
  font-family: Montserrat;
  font-size: 1.4rem;
  font-weight: 600;
  display: flex;
  flex-direction: row;
}
.user_item-info-description__NsV7v {
  padding-top: 0.8rem;
  padding-right: 3rem;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  opacity: 0.9;
  color: #FCF9FB;
  font-family: Montserrat;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.5rem;
}
.user_item-buttons__P4Eti {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.user_filler__2hkKr {
  flex: 1 1;
}`, "",{"version":3,"sources":["webpack://./src/containers/Authenticated/pages/users/user/index.module.scss"],"names":[],"mappings":"AACA;EACE,YAAA;EACA,6BAAA;EACA,qBAAA;EACA,yBAAA;EACA,kBAAA;EACA,oCAAA;EACA,aAAA;EACA,mBAAA;AAAF;AAEE;EACE,YAAA;EACA,WAAA;EACA,qBAAA;EACA,iBAAA;AAAJ;AAGE;EACE,kBAAA;EACA,SAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;AADJ;AAGI;EACE,cAAA;EACA,uBAAA;EACA,iBAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;AADN;AAII;EACE,mBAAA;EACA,mBAAA;EACA,oBAAA;EACA,qBAAA;EACA,4BAAA;EACA,gBAAA;EACA,YAAA;EACA,cAAA;EACA,uBAAA;EACA,iBAAA;EACA,gBAAA;EACA,mBAAA;AAFN;AAME;EACE,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,uBAAA;EACA,SAAA;AAJJ;;AAQA;EACE,SAAA;AALF","sourcesContent":["\n.item {\n  height: 8rem;\n  border: 0.05rem solid #A68FA8;\n  border-radius: 2.5rem;\n  background-color: #491E4D;\n  margin: 1.6rem 0 0;\n  padding: 1.4rem 1.9rem 1.4rem 1.6rem;\n  display: flex;\n  flex-direction: row;\n\n  &-image {\n    height: 5rem;\n    width: 5rem;\n    border-radius: 2.5rem;\n    object-fit: cover;\n  }\n\n  &-info {\n    padding-left: 2rem;\n    flex: 1;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n\n    &-title {\n      color: #B99A5E;\n      font-family: Montserrat;\n      font-size: 1.4rem;\n      font-weight: 600;\n      display: flex;\n      flex-direction: row;\n    }\n\n    &-description {\n      padding-top: .8rem;\n      padding-right: 3rem;\n      display: -webkit-box;\n      -webkit-line-clamp: 4;\n      -webkit-box-orient: vertical;  \n      overflow: hidden;\n      opacity: 0.9;\n      color: #FCF9FB;\n      font-family: Montserrat;\n      font-size: 1.2rem;\n      font-weight: 500;\n      line-height: 1.5rem;\n    }\n  }\n\n  &-buttons {\n    overflow: hidden;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: center;\n    gap: 2rem;\n  }\n}\n\n.filler {\n  flex: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `user_item__nJeBg`,
	"item-image": `user_item-image__ZUoQm`,
	"item-info": `user_item-info__+aALj`,
	"item-info-title": `user_item-info-title__p1qXo`,
	"item-info-description": `user_item-info-description__NsV7v`,
	"item-buttons": `user_item-buttons__P4Eti`,
	"filler": `user_filler__2hkKr`
};
export default ___CSS_LOADER_EXPORT___;
