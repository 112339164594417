// Common
import React, { FunctionComponent, ReactElement, ReactNode } from 'react'
import styles from './index.module.scss'

// Components
import Label from 'components/label'
import Modal from 'components/modal'

interface IComponentProps {
  isOpen: boolean
  onClose: (event: MouseEvent) => void
  title: string
  children?: ReactNode
}

const PrimaryModal: FunctionComponent<IComponentProps> = ({
  children,
  isOpen,
  onClose: handleClose,
  title,
}): ReactElement => {

  return (
    <Modal
      isOpen={ isOpen }
      onClose={ handleClose }
    >
      <Label
        className={ styles['label'] }
        appearance='gold'
        text={ title }
      />

      { children }
    </Modal>
  )
}

export default PrimaryModal
