import { put, all, call, takeLatest } from 'redux-saga/effects'
import { REQUEST_ADMIN_CHECK, requestAdminCheckFailed, successAdminCheck } from '../../actions/admin/auth'
import { request } from '../../../apiServices/http.service'
import { verification } from '../../../apiServices/admin/auth.service'
import navigate from '../../../types/navigateSubject'


export function* requestVerification() {
  const { response, error } = yield call(request, verification())
  console.info(response, error)
  if (response) {
    yield put(successAdminCheck())
  } else {
    put(requestAdminCheckFailed(error))
    navigate.next(['/', { replace: true }])
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(REQUEST_ADMIN_CHECK, requestVerification),

  ])
}
