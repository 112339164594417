// Common
import React, { FunctionComponent, ReactElement } from 'react'
import styles from '../settings.module.scss'

// Hooks
import { useEffect, useRef } from 'react'
import { useSelector } from 'redux/store'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useForm, useWatch } from 'react-hook-form'

// Actions
import { requestUpdateProfile } from 'redux/actions/profile.action'

// Components
import CheckBox from 'components/checkBox/checkBox'

const SettingsPersonal: FunctionComponent = (): ReactElement => {
  const dispatch = useDispatch()
  const [t] = useTranslation();

  const user = useSelector(({ profile: { profile }}) => profile.data)

  const { control } = useForm<unknown>({ defaultValues: {
      showPhoneNumber: user.showPhoneNumber,
      showEmail: user.showEmail,
      privateParty: user.privateParty,
      weddingParty: user.weddingParty
    }})
  const updatedProps = useWatch({ control })

  const initial = useRef<boolean>(true)

  useEffect(() => {
    if (initial.current) {
      initial.current = false
      return
    }

    dispatch(requestUpdateProfile(updatedProps))
  }, [updatedProps, dispatch])

  return (
    <form
      className={ styles['personal-settings'] }
    >
      <span className={ styles['personal-settings-label'] }>{ t('headers.personal_settings') }</span>

      <CheckBox
        className={ styles['personal-settings-check-box'] }
        control={ control }
        name='showPhoneNumber'
        label={ t('buttons.show_phone_number') }
      />

      <CheckBox
        className={ styles['personal-settings-check-box'] }
        control={ control }
        name='showEmail'
        label={ t('buttons.show_email') }
      />

      <CheckBox
        className={ styles['personal-settings-check-box'] }
        control={ control }
        name='privateParty'
        label={ t('buttons.available_private_party') }
      />

      <CheckBox
        className={ styles['personal-settings-check-box'] }
        control={ control }
        name='weddingParty'
        label={ t('buttons.available_wedding_party') }
      />
    </form>
  )
}

export default SettingsPersonal
