import { useCallback, useEffect, useRef } from 'react'

type UseTimeoutType = [ (callback: () => void) => void, () => void ]

export default function useTimeout(delay: number): UseTimeoutType {
  const timeOutId = useRef<ReturnType<typeof setTimeout>>()

  const abort = useCallback(() => {
    if (timeOutId.current) clearTimeout(timeOutId.current)
  }, [timeOutId])

  const run = useCallback((callback) => {
    abort()

    timeOutId.current = setTimeout(() => {
      callback && callback()
    }, delay)
  }, [delay, timeOutId, abort])

  useEffect(() => {
    return () => abort()
  }, [abort])

  return [run, abort]
}
