// Common
import React, { FunctionComponent, ReactElement } from 'react'

// Components
import Album from '../album'

// Hooks
import { useEffect, useCallback, useRef } from 'react'
import { useInfiniteScroll2 } from 'hooks/useInfiniteScroll2'
import { useInject } from 'hooks/useInject'
import { useSubscribeValue } from 'hooks/useSubscribeValue'

// Services
import { AlbumsService } from 'services/albums.service'

const Albums: FunctionComponent = (): ReactElement => {

  const albumsService = useInject(AlbumsService)

  const albums = useSubscribeValue(albumsService.items, [])

  useEffect(() => {
    return () => albumsService.clear()
  }, [albumsService])

  const loadMore = useCallback(() => {
    albumsService.requestMany({ perPage: 20 })
  }, [albumsService])

  const scrollContainerRef = useRef(document.getElementById('root'))

  useInfiniteScroll2({ containerRef: scrollContainerRef, loadMore })

  return (
    <>
      { albums.map((album, index) => <Album key={ index } album={ album } />) }
    </>
  )
}

export default Albums
