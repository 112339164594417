import React, { FunctionComponent, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import styles from './index.module.scss';
import cn from 'classnames';

// Components
import Icon from 'components/icon';
import Image from 'components/image';

// Hooks
import useContextMenu from 'hooks/useContextMenu';
import { useSelector } from 'redux/store';
import { useDispatch } from 'react-redux';

// Actions
import actions from 'redux/actions/photos-modal.action-creators';

interface IComponentProps {
  onClose?: () => void;
  initialIndex?: number;
  albumId?: number | string;
  eventId?: number | string; // Ensure eventId is included and has the correct type
}

const PhotoModalComponent: FunctionComponent<IComponentProps> = ({
  onClose,
  initialIndex = 0, // Default value to prevent potential undefined errors
  albumId,
  eventId,
}): ReactElement => {
  const dispatch = useDispatch();

  const { items: photos, pending, totalCount, error } = useSelector(({ photosModal }) => photosModal);

  useEffect(() => {
    return () => {
      dispatch(actions.clear());
    };
  }, [dispatch]);

  const contextMenu = useContextMenu([{ title: 'Close', action: onClose }]);

  const [currentIndex, setCurrentIndex] = useState(initialIndex);

  const handleNextSlide = useCallback(
    (direction: 1 | -1) => () => {
      setCurrentIndex((index) => (index + direction + totalCount) % totalCount);
    },
    [totalCount]
  );

  useEffect(() => {
    if (pending || !!photos[currentIndex] || error) { return }

    dispatch(actions.read({ page: currentIndex + 1, perPage: 1, albumId, eventId }))
  }, [pending, currentIndex, photos, albumId, eventId, dispatch, error])

  const photo = useMemo(() => {
    return photos[currentIndex]
  }, [photos, currentIndex])

  return (
    <div className={ styles['container'] }>
      <div className={ styles['photo'] }>
        <Image
          className={ styles['photo-image'] }
          src={ photo?.imageUrl }
        />

        <Icon
          onClick={ handleNextSlide(-1) }
          name='chevron-left'
          className={ cn(styles['photo-arrow-icon'], styles['back']) }
          size={ 30 }
        />

        <Icon
          onClick={ handleNextSlide(1) }
          name='chevron-right'
          className={ styles['photo-arrow-icon'] }
          size={ 30 }
        />
      </div>
      <div className={ styles['comments'] }></div>

      <Icon
        name='kebab'
        className={ styles['kebab-icon'] }
        size={ 16 }
        { ...contextMenu }
      />
    </div>
  )
}

export default PhotoModalComponent
