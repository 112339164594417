import { request } from 'apiServices/http.service'
import { BehaviorSubject, Subject } from 'rxjs'
import { Injectable } from 'types/injectable.interface'
import { Injector } from 'types/injector.class'
import { Filters } from 'types/reduxCRUD'
import toast from 'react-hot-toast'
import { isNil } from 'helpers/base'
import ApiError from 'types/apiError'
import { RestItem } from 'types/rest-item.interface'

export abstract class BaseRestService<T extends RestItem, TFilters extends Filters> implements Injectable {
  protected injector: Injector

  protected abstract entityName: string

  protected _getOnePending = new BehaviorSubject<boolean>(false)
  protected _getOneError = new BehaviorSubject<ApiError>(null)
  protected _getOneItem = new BehaviorSubject<T>(null)
  public item = this._getOneItem.asObservable()

  protected _upsertPending = new BehaviorSubject<boolean>(false)
  protected _upsertError = new BehaviorSubject<ApiError>(null)
  protected _upserted = new Subject<void>()
  public upserted = this._upserted.asObservable()
  public upserting = this._upsertPending.asObservable()

  protected _deletePending = new BehaviorSubject<boolean>(false)
  protected _deleteError = new BehaviorSubject<ApiError>(null)
  protected _deleted = new Subject<void>()
  public deleted = this._deleted.asObservable()

  protected _searchPending = new BehaviorSubject<boolean>(false)
  protected _searchError = new BehaviorSubject<ApiError>(null)
  protected _searchItems = new BehaviorSubject<T[]>([])
  protected _searchTotal = new BehaviorSubject<number>(0)
  protected _searchLastFilters = new BehaviorSubject<TFilters>(null)
  public items = this._searchItems.asObservable()

  constructor(injector: Injector) {
    this.injector = injector
  }

  attach() {
    // console.log('attached')
  }

  detach() {
    // console.log('detached')
  }

  abstract buildUrl(): Promise<URL>

  abstract buildItem(item: object): T

  getItemFromResponse(response: object): object {
    return response['item']
  }

  getItemsFromResponse(response: object): unknown[] {
    return response['items']
  }

  async requestOne(id: number) {
    this._getOnePending.next(true)

    const { response, error } = await request({
      url: new URL(`${ await this.buildUrl() }/${ id }`),
    })

    if (response) {
      const item = this.buildItem(this.getItemFromResponse(response))
      this._getOneItem.next(item)
    } else {
      this.handleError(error)
      this._getOneError.next(error)
    }
  }

  async requestMany(filters: TFilters) {
    if(this._searchPending.value || this._searchError.value) return

    this._searchPending.next(true)

    if (isNil(filters.page)) {
      filters.page = 1
    }

    const { response, error } = await request({
      url: await this.buildUrl(),
      params: {
        page: filters.page || 1,
        per_page: filters.perPage,
        perPage: filters.perPage,
      }
    })

    if (response) {
      const items = this.getItemsFromResponse(response).map(this.buildItem)

      const currentItems = this._searchItems.value

      const newItems = [...currentItems]

      if (currentItems?.length !== response?.count) {
        newItems.length = response?.count
      }

      for (let i = 0; i < Math.min(filters.perPage, response?.count); i++) {
        newItems[filters.page - 1 + i] = items[i]
      }

      this._searchItems.next(newItems)
      this._searchTotal.next(response?.count || 0)
      this._searchLastFilters.next(filters)
      this._searchPending.next(false)
      this._searchError.next(null)
    } else {
      this.handleError(error)
      this._searchError.next(error)
    }
  }

  async delete(item: T) {
    this._deletePending.next(true)

    const { response, error } = await request({
      url: new URL(`${ await this.buildUrl() }/${ item.id }`),
      method: 'DELETE'
    })

    if (response) {
      toast.success(`${ this.entityName } deleted.`, { position: 'bottom-right' })

      if (this._searchLastFilters.value) {
        this.clear()
        this.requestMany({ ...this._searchLastFilters.value, page: null })
      }

      this._deleted.next()
    } else {
      this.handleError(error)
      this._deleteError.next(error)
    }

    this._deletePending.next(false)
  }

  async upsert(item: T, config: { reload: boolean, showToast?: boolean } = { reload: true, showToast: true }) {
    this._upsertPending.next(true)

    const { response, error } = await request({
      url: new URL(`${ await this.buildUrl() }/${ item.id ?? '' }`),
      method: item.id ? 'PUT' : 'POST',
      body: item?.asPayloadJSON?.()
    })

    if (response) {
      config.showToast && toast.success(
        `${ this.entityName } ${ item.id ? 'updated' : 'created' }.`,
        { position: 'bottom-right' }
      )

      if (config?.reload && this._searchLastFilters.value) {
        this.clear()
        this.requestMany({ ...this._searchLastFilters.value, page: null })
      }

      this._upserted.next()
    } else {
      this.handleError(error)
      this._upsertError.next(error)
    }

    this._upsertPending.next(false)
  }

  clear() {
    this._getOnePending.next(false)
    this._getOneError.next(null)
    this._getOneItem.next(null)

    this._upsertPending.next(false)
    this._upsertError.next(null)

    this._deletePending.next(false)
    this._deleteError.next(null)

    this._searchPending.next(false)
    this._searchError.next(null)
    this._searchItems.next([])
    this._searchTotal.next(0)
    this._searchLastFilters.next(null)
  }

  protected handleError(error) {
    console.log(typeof error, error)

    toast.error(
      error?.errors?.message ||
      error?.message?.map?.(i => i.constraints ? Object.values(i.constraints).join(', ') : '' ).join(', ') ||
      'Something went wrong.',
      { position: 'bottom-right' }
    )
  }
}
