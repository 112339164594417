export class Policy {
  createdAt: Date
  updatedAt: Date
  body: string

  constructor(data) {
    this.body = data.body
    this.createdAt = new Date(data.createdAt)
    this.updatedAt = new Date(data.updatedAt)
  }
}
