// Common
import React, { FunctionComponent, ReactElement } from 'react'
import styles from './index.module.scss'
import { hostURL } from 'apiServices/http.service'
import { zeroPad } from 'helpers/number'

// RX
import { Subject, fromEvent, map, throttleTime } from 'rxjs'

// Types
import { Attachment } from 'types/attachment'
import { ChatMessage } from 'models/chat-message'

// Hooks
import { useMemo, useCallback, useState, useRef, useEffect } from 'react'

// Components
import Image from 'components/image'
import Icon from 'components/icon'
import Waveform from 'components/waveform'

interface IComponentProps {
  attachment: Attachment,
  message: ChatMessage
}

const AttachmentComponent: FunctionComponent<IComponentProps> = ({ attachment, message }): ReactElement => {

  const isImage = useMemo(() => {
    return ['image/jpeg', 'image/bmp', 'image/png'].includes(attachment.fileType)
  }, [attachment])

  const isAudio = useMemo(() => {
    return 'audio/mpeg' === attachment.fileType
  }, [attachment])

  const url = useMemo(() => {
    return `${ hostURL }/attachments/${ message.sender.id }/${ message.id }/${ message.id }/${ attachment.fileName }`
  }, [message, attachment])

  const handleDownload = useCallback(() => {
    const link = document.createElement('a')
    link.download = attachment.fileName
    link.href = url
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }, [url, attachment])

  const audio = useRef<HTMLAudioElement>()
  const [paused, setPaused] = useState<boolean>(true)
  const position = useRef(new Subject<number>())
  const [currentTime, setCurrentTime] = useState<string>('0:00')

  useEffect(() => {
    if (!audio.current) { return }

    const element = audio.current

    const playPauseCallback = () => {
      setPaused(element.paused)
    }

    element.addEventListener('play', playPauseCallback)
    element.addEventListener('pause', playPauseCallback)

    return () => {
      element.removeEventListener('play', playPauseCallback)
      element.removeEventListener('pause', playPauseCallback)
    }
  }, [audio])

  const handlePlayPause = useCallback(() => {
    audio.current.paused ? audio.current.play() : audio.current.pause()
    setPaused(audio.current.paused)
  }, [])

  useEffect(() => {
    if (!audio.current) { return }

    const subscription = fromEvent<number>(audio.current, 'timeupdate')
      .pipe(
        throttleTime(700),
        map(() => audio.current.currentTime)
      )
      .subscribe(time => {
        position.current.next(time / audio.current.duration)
        setCurrentTime(`${ Math.floor(time / 60) }:${ zeroPad(Math.floor(time % 60), 2) }`)
      })

    return () => {
      subscription.unsubscribe()
    }
  }, [audio])

  const handlePositionChange = useCallback((value: number) => {
    if (!audio.current) { return }
    console.log(audio.current)
    console.log('position change ', audio.current.currentTime, audio.current.duration * value, audio.current.duration)
    audio.current.currentTime = audio.current.duration * value
  }, [])

  const waveStyle = useMemo(() => ({
    color: '#350339',
    playedColor: 'white',
    stepWidth: 1,
    gap: 1,
    borderRadius: 1
  }), [])

  return (
    <>
      { isImage &&
        <div className={ styles['image'] }>
          <Image
            className={ styles['image-content'] }
            src={ url }
          />

          <div className={ styles['image-icons'] }>
            <Icon
              className={ styles['image-icons-download'] }
              name='download'
              size={ 30 }
              onClick={ handleDownload }
            />
          </div>
        </div>
      }

      { isAudio &&
        <div className={ styles['audio'] }>
          <audio ref={ audio } src={ url } />

          <Icon
            name={ paused ? 'play' : 'pause' }
            className={ styles['audio-play-icon'] }
            onClick={ handlePlayPause }
            size={ 20 }
          />

          <div className={ styles['audio-info'] } >
            <Waveform
              url={ url }
              width={ 155 }
              height={ 11 }
              responsive={ false }
              waveStyle={ waveStyle }
              progress={ position.current }
              onPositionChange={ handlePositionChange }
            />

            { currentTime }
          </div>

          <Icon
            className={ styles['audio-download'] }
            name='download'
            size={ 20 }
            onClick={ handleDownload }
          />
        </div>
      }

      { (!isImage && !isAudio) &&
        <div className={ styles['other'] }>
          <div className={ styles['other-info'] } >
            { attachment.fileName }
          </div>

          <Icon
            className={ styles['other-download'] }
            name='download'
            size={ 20 }
            onClick={ handleDownload }
          />
        </div>
      }
    </>
  )
}

export default AttachmentComponent
