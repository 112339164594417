// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.player_container__FRBik {
  width: 100%;
  height: 100%;
  padding: 0 2.7rem 0 4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.player_container__FRBik .player_duration__jNM17 {
  opacity: 0.9;
  color: #FCF9FB;
  font-family: Montserrat;
  font-size: 1.3rem;
  font-weight: 500;
  letter-spacing: 0;
  width: 4.5rem;
}`, "",{"version":3,"sources":["webpack://./src/components/audioInput/player/index.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,wBAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,8BAAA;AACF;AACE;EACE,YAAA;EACA,cAAA;EACA,uBAAA;EACA,iBAAA;EACA,gBAAA;EACA,iBAAA;EACA,aAAA;AACJ","sourcesContent":[".container {\n  width: 100%;\n  height: 100%;\n  padding: 0 2.7rem 0 4rem;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n\n  .duration {\n    opacity: 0.9;\n    color: #FCF9FB;\n    font-family: Montserrat;\n    font-size: 1.3rem;\n    font-weight: 500;\n    letter-spacing: 0;\n    width: 4.5rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `player_container__FRBik`,
	"duration": `player_duration__jNM17`
};
export default ___CSS_LOADER_EXPORT___;
