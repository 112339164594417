// Common
import React, { ReactNode, FunctionComponent, ReactElement, CSSProperties } from 'react'
import styles from './index.module.scss'
import cn from 'classnames'

// Hooks
import { useCallback } from 'react'

// Components
import Icon from 'components/icon'

interface IComponentProps {
  type?: 'button' | 'reset' | 'submit'
  onClick?: (event) => void
  className?: string
  style?: CSSProperties
  height?: string
  appearance?: 'transparent' | 'gold' | 'gold-big'
  theme?: 'light' | 'dark'
  disabled?: boolean
  border?: boolean
  loading?: boolean
  form?: string
  withArrow?: boolean
  children?: ReactNode
}

export const Button: FunctionComponent<IComponentProps> = ({
  className,
  type = 'button',
  style,
  height,
  appearance,
  theme = 'light',
  disabled,
  border = true,
  form,
  loading,
  onClick,
  children,
  withArrow
}): ReactElement => {
  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    if (loading) { return }
    onClick?.(event)
  }, [loading, onClick])

  return (
    <button
      className={
        cn(
          styles['button'],
          styles[appearance],
          styles[theme],
          { [styles['no-border']]: !border },
          className,
        )
      }
      onClick={ handleClick }
      style={ { ...style, height } }
      type={ type }
      disabled={ disabled || loading }
      form={ form }
    >
      { loading ? 'Loading...' : children }

      { withArrow &&
        <Icon
          name='arrow-button'
          className={ styles['button-arrow'] }
          width={ 56.3 }
          height={ 35.3 }
        />
      }
    </button>
  );
}

export default Button;
