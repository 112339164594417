import {
  RECEIVED_PROFILE,
  REQUEST_PROFILE_FAILED,
  RESET_PROFILE,
  RESET_PROFILE_ERRORS,
} from '../actions/profile.action'
import { User } from "../../models/user.model";

export interface State {
  data: User
  errors: string
}

const initialState: State = {
  data: undefined,
  errors: null
};

export default function profileReducer(
  state: Partial<State> = initialState,
  action: { type: string, payload: User, error: { errors: { message: string }[] } }
): Partial<State> {
  switch (action.type) {
    case RECEIVED_PROFILE:
      return { ...state, data: action.payload };

    case REQUEST_PROFILE_FAILED:
      return {
        ...state,
        errors: action.error?.errors?.[0]?.message || action.error?.errors?.[0] as unknown as string,
        data: null
      };

    case RESET_PROFILE:
      return { ...state, data: null }

    case RESET_PROFILE_ERRORS:
      return { ...state, errors: null }

    default:
      return state
  }
}
