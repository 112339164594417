// Common
import React, { FunctionComponent, ReactElement } from 'react';
import cn from 'classnames';
import styles from '../index.module.scss';

// Types
import { OptionType } from "../types/option";

// Hooks
import { useCallback } from 'react'

interface IComponentProps {
  option: OptionType
  selected?: boolean;
  onSelect: (value: OptionType) => void;
  excludeSelectedOption?: boolean;
  showBy: 'title' | 'value'
}

const SingleOption: FunctionComponent<IComponentProps> = ({
  option,
  selected,
  onSelect,
  excludeSelectedOption,
  showBy
}): ReactElement => {
  const handleSelect = useCallback(option => () => {
    onSelect(option)
  }, [onSelect])

  if (excludeSelectedOption && selected) return null;

  return (
      <div
          className={ cn(
              styles['drop-down-options-item'],
              { [styles['active']]: selected }
          ) }
          onClick={ handleSelect(option) }
          key={ option.value }
      >
        <div className={ styles['drop-down-options-item-mask'] } />
        { option.icon &&
        <div className={ styles['drop-down-options-item-icon'] }>
          { option.icon }
        </div>
        }
        <span>
          { showBy ==='title' ? option?.title : option?.value }
        </span>
      </div>
  )
}

export default SingleOption;
