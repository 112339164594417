import React, { ReactElement } from 'react';
import { backgrounds } from "../../mocks/roleImages.mock";
import { BaseModel } from "./base.model";

export class Role extends BaseModel {
  id: number;
  name: string;
  title: string;
  description: string;
  policy_body?: string;
  terms_body?: string;
  toShow?: boolean;
  icon_url?: string;
  info_icon_url?: string;
  background_url?: string;
  background_preview_url?: string;
  order?: number;
  role_permission?: {
    to_show_map: boolean;
    is_show_nick_name: boolean;
    is_show_work_name: boolean;
    is_email_confirmation: boolean;
    is_phone_confirmation: boolean;
    to_show_social_signin: boolean;
    is_show_phone: boolean;
  };

  value: string;
  icon?: ReactElement;
  background?: string;

  constructor(object) {
    super(null);
    this.id = object.id;
    this.name = object.name;
    this.title = object.title;
    this.description = object.description;
    this.policy_body = object.policy_body;
    this.terms_body = object.terms_body;
    this.toShow = object.toShow;
    this.icon_url = object.icon_url;
    this.info_icon_url = object.info_icon_url;
    this.background_url = object.background_url;
    this.background_preview_url = object.background_preview_url;
    this.order = object.order;
    this.role_permission = object.role_permission;

    this.value = this.name;
    this.icon = object.info_icon_url ?
        React.createElement("img", { src: object.info_icon_url, alt: '', width: '100%', height: '100%' })
        :
        null;
    this.background = !object.background_url
        ? backgrounds[this.value]
        : null
  }
}
