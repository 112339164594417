import { ActionTypes, Payload, State } from 'types/reduxCRUD'

export default function generateCRUDReducer<Entity, Filters>(at: ActionTypes) {
  const initialState: State<Entity> = {
    items: [],
    hasMore: true,
    totalCount: 0,
    currentPage: 0,
    reset: 0,
    currentItem: null,
    error: null,
    pending: false,
    filters: {}
  }

  return (
    state: State<Entity> = initialState,
    { type: actionType, payload }: { type: keyof typeof at, payload: Payload<Entity, Filters> }
  ): State<Entity> => {
    switch (actionType) {
      case at.CLEAR:
        return {
          ...initialState,
          reset: Math.random()
        }
      case at.READ_PENDING:
      case at.READ_ONE_PENDING:
      case at.UPSERT_PENDING:
      case at.DELETE_PENDING:
        return {
          ...state,
          error: null,
          pending: true
        }
      case at.READ_SUCCEEDED:
        return {
          ...state,
          items: payload.items,
          hasMore: payload.hasMore,
          totalCount: payload.totalCount,
          currentPage: payload.currentPage,
          pending: false,
          error: null,
          filters: payload.filters
        }
      case at.READ_ONE_SUCCEEDED:
        return {
          ...state,
          currentItem: payload.item,
          pending: false,
          error: null
        }
      case at.READ_FAILED:
      case at.READ_ONE_FAILED:
      case at.UPSERT_FAILED:
      case at.DELETE_FAILED:
        return {
          ...state,
          error: payload.error,
          pending: false
        }
      case at.UPSERT_SUCCEEDED:
      case at.DELETE_SUCCEEDED:
        return {
          ...state,
          pending: false,
          error: null
        }
      default:
        return state
    }
  }
}
