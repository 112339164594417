// Common
import React, { FunctionComponent, useMemo } from 'react'
import styles from './index.module.scss'
import { fromEvent, Observable, throttleTime, map, Subject } from 'rxjs'
import { hostURL } from 'apiServices/http.service'

// Helpers
import { zeroPad } from 'helpers/number'

// Types
import { Attachment } from 'types/attachment'

// Hooks
import { useEffect, useState, useCallback, useRef } from 'react'

// Components
import Icon from 'components/icon'
import Waveform from 'components/waveform'

interface IComponentProps {
  file: Attachment,
  onDuration: (duration: number) => void
}

const PlayerComponent: FunctionComponent<IComponentProps> = ({ file, onDuration }): React.ReactElement => {

  const [url, setUrl] = useState<string>()

  const [paused, setPaused] = useState<boolean>(true)

  const [duration, setDuration] = useState<string>()

  const [currentTime, setCurrentTime] = useState<string>('00:00')

  const [progress, setProgress] = useState<Observable<number>>()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [position, setPosition] = useState(new Subject<number>())

  const audio = useRef<HTMLAudioElement>()

  useEffect(() => {
    if (!file) { return }

    if (file.temp) {
      setUrl(`${ hostURL }/attachments/temp/${ file.id }`)
    } else {
      setUrl(`${ hostURL }/attachments/${ file.id }/${ file.id }/${ file.fileName }`)
    }
  }, [file])

  const handlePlayPause = useCallback(() => {
    audio.current.paused ? audio.current.play() : audio.current.pause()
  }, [audio])

  useEffect(() => {
    const subject = fromEvent<number>(audio.current, 'timeupdate')
      .pipe(
        throttleTime(700),
        map(() => audio.current.currentTime)
      )

    setProgress(subject.pipe(map(time => time / audio.current.duration)))

    const subscription = subject
      .subscribe(time => {
        setCurrentTime(`${ zeroPad(Math.floor(time / 60), 2) }:${ zeroPad(Math.floor(time % 60), 2) }`)
      })

    return () => {
      subscription.unsubscribe()
    }
  }, [audio])

  useEffect(() => {
    const element = audio.current

    const playPauseCallback = () => {
      setPaused(element.paused)
    }

    const durationCallback = () => {
      setDuration(`${ zeroPad(Math.floor(element.duration / 60), 2) }:${ zeroPad(Math.floor(element.duration % 60), 2) }`)
    }

    element.addEventListener('play', playPauseCallback)
    element.addEventListener('pause', playPauseCallback)
    element.addEventListener('loadedmetadata', durationCallback)

    return () => {
      element.removeEventListener('play', playPauseCallback)
      element.removeEventListener('pause', playPauseCallback)
      element.removeEventListener('loadedmetadata', durationCallback)
    }
  }, [audio])

  const waveStyle = useMemo(() => ({
    color: '#FCF9FB',
    playedColor: '#BA9B5A',
    stepWidth: 3,
    gap: 3,
    borderRadius: 3
  }), [])

  const handlePositionChange = useCallback((value: number) => {
    position.next(value)
  }, [position])

  useEffect(() => {
    const subscription = position
      .pipe(throttleTime(500))
      .subscribe(value => {
        audio.current.currentTime = audio.current.duration * value
      })

    return () => {
      subscription.unsubscribe()
    }
  }, [position, audio])

  const handleInit = useCallback(() => {
    onDuration?.(audio.current.duration)
  }, [onDuration])

  return (
    <>
      <audio
        ref={ audio }
        src={ url }
        onLoadedMetadata={ handleInit }
      />

      <div className={ styles['container'] }>
        <div className={ styles['duration'] }>{ currentTime }</div>

        <Waveform
          url={ url }
          width={ 315 }
          height={ 45 }
          responsive={ false }
          waveStyle={ waveStyle }
          progress={ progress }
          onPositionChange={ handlePositionChange }
        />

        <div className={ styles['duration'] }>{ duration }</div>

        <Icon
          name={ paused ? 'play' : 'pause' }
          className={ styles['item-play'] }
          onClick={ handlePlayPause }
        />
      </div>
    </>
  )
}

export default PlayerComponent
