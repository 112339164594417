import { BehaviorSubject, Subject } from 'rxjs'
import { TracksFilters } from 'models/track.model'

export class PlayerState {
  public filters = new BehaviorSubject<TracksFilters>(null)
  public progress = new BehaviorSubject<number>(0)
  public time = new BehaviorSubject<string>('00:00')
  public currentId = new BehaviorSubject<number | string>(null)
  public playing = new BehaviorSubject<boolean>(false)
  public triggerPlayPause = new BehaviorSubject<void>(null)
  public triggerNextTrack = new Subject<1 | -1>()
}
