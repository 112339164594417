import React, { Component, PropsWithChildren } from 'react';
import styles from './carouselSlide.module.scss';

export default class CarouselSlide extends Component<PropsWithChildren<unknown>> {
  constructor(props: PropsWithChildren<unknown>) {
    super(props);
  }

  render(): React.ReactElement<unknown, string | React.JSXElementConstructor<unknown>> {
    return (
      <div className={
        [
          styles['slide'],
        ].join(' ')
      }
      >
        { this.props.children }
      </div>
    )
  }
}
