import React, { CSSProperties, FunctionComponent } from 'react';
import cn from 'classnames';
import styles from './checkBox.module.scss';
import { Control, useController } from "react-hook-form";
import { CheckMark } from "../../assets";

interface IComponentProps {
  defaultValue?: boolean
  name: string
  label: string
  className?: string
  style?: CSSProperties
  required?: boolean | string
  control: Control
}
export const CheckBox: FunctionComponent<IComponentProps> = ({
  name,
  label,
  defaultValue,
  control,
  required,
  className,
  style,
  ...props
}): React.ReactElement => {

  const { field, fieldState: { error }} = useController({
    name,
    control,
    rules: {
      required
    },
    defaultValue: defaultValue || false
  });

  return (
      <label
          className={ cn(
              styles['check-box'],
              { [styles['error']]: error },
              className
          ) }
          style={ style }
      >
        <input
            type='checkbox'
            checked={ field.value }
            { ...props }
            { ...field }
        />
        <CheckMark className={ styles['check-box-mark'] } />
        <span>{ label }</span>
      </label>
  )
};

export default CheckBox;
