import { Service } from '../types/reduxCRUD'
import { Country } from '../models/country.model'
import { hostURL } from './http.service'

const service: Service<Country> = {
  getItems(filters) {
    const url = new URL(`${ hostURL }/countries`)

    return {
      url,
      params: {
        page: filters.page,
        per_page: filters.perPage
      },
      withLocale: true
    }
  },
  getItem() {
    throw new Error('API not available')
  },
  upsertItem() {
    throw new Error('API not available')
  },
  deleteItem() {
    throw new Error('API not available')
  }
}

export default service
