import Genre from "../../apiServices/models/genre.model"
import { GenreParams } from "../../apiServices/models/types";

export const REQUEST_GENRES = 'REQUEST_GENRES';
export const requestGenres = (params: GenreParams) => ({
  type: REQUEST_GENRES
});

export const RECEIVED_GENRES = 'RECEIVED_GENRES';
export const receivedGenres = (genres: Genre[]) => ({
  type: RECEIVED_GENRES,
  genres
});

export const REQUEST_GENRES_FAILED = 'REQUEST_GENRES_FAILED';
export const requestGenresFiled = (error: { message: string, status: number }) => ({
  type: REQUEST_GENRES_FAILED,
  error
});
