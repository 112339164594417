// Common
import React, { FunctionComponent, ReactElement } from 'react'
import styles from './index.module.scss'

// Components
import Icon from 'components/icon'
import CalendarYear from 'components/calendarYear'

// Hooks
import { useNavigate } from 'react-router-dom'
import { useCallback, useState, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'redux/store'

// Actions
import actions from 'redux/actions/calendar-events.action-creators'

const CalendarComponent: FunctionComponent = (): ReactElement => {
  const navigate = useNavigate()

  const [year, setYear] = useState(new Date().getFullYear());

  const handleChangeYear = useCallback(direction => () => {
    setYear(year + direction)
  }, [year, setYear])

  const handleOpenMonthCalendar = useCallback((month: Date) => {
    navigate(`${ month.getFullYear() }/${ month.getMonth() + 1 }/${ month.getDate() }`)
  }, [navigate])

  const dispatch = useDispatch()

  const { items: events, pending, error, hasMore } = useSelector(({ calendarEvents }) => calendarEvents)

  const ranges = useMemo(() => {
    return events.map(event => ({ from: event.from, to: event.to }))
  }, [events])

  useEffect(() => {
    if (hasMore && !pending && !error) {
      dispatch(actions.read({
        perPage: 100,
        from: new Date(year, 0, 1),
        to: new Date(year + 1, 0, 0)
      }))
    }
  }, [year, dispatch, pending, error, hasMore])

  useEffect(() => {
    return () => {
      dispatch(actions.clear())
    }
  }, [dispatch])

  return (
    <>
      <div className={ styles['heading'] }>
        <div className={ styles['heading-year'] }>
          <Icon
            name='arrow-left'
            className={ styles['heading-year-icon'] }
            onClick={ handleChangeYear(-1) }
          />

          <div className={ styles['year'] }>{ year }</div>

          <Icon
            name='arrow-right'
            className={ styles['heading-year-icon'] }
            onClick={ handleChangeYear(1) }
          />
        </div>

        <Icon
          name='filter'
          appearance='interactive'
          width={ 20 }
          height={ 15 }
          size={ 36 }
        />
      </div>

      <CalendarYear
        year={ year }
        onExpand={ handleOpenMonthCalendar }
        ranges={ ranges }
      />
    </>
  )
}

export default CalendarComponent
