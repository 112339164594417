import { REQUEST_POLICY_FAILED, RECEIVED_POLICY } from '../../actions/admin/policy'

export interface State {
  body: string,
  createAt: Date;
  updatedAt: Date;
}

const initialState: State = {
  body: '<p></p>',
  createAt: null,
  updatedAt: null
}

export default function policyReducer(
  state: State = initialState,
  action: { type: string, body: string, createdAt: Date, updatedAt: Date, error: string }
): Partial<State> {
  switch (action.type) {
    case RECEIVED_POLICY:
      return { body: action.body, createAt: action.createdAt, updatedAt: action.updatedAt }
    case REQUEST_POLICY_FAILED:
      return state

    default:
      return state
  }
}
