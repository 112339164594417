import React, { FunctionComponent, ReactElement, useCallback, useState, forwardRef, LegacyRef } from 'react';
import { Control, useController } from "react-hook-form";
import cn from 'classnames';
import styles from './index.module.scss';
import { EyeIcon } from "../../assets";

interface IComponentProps {
  appearance?: 'regular' | 'medium' | 'large'
  autoFocus?: boolean;
  className?: string;
  control: Control
  disabled?: boolean;
  height?: number | string;
  leftIcon?: ReactElement;
  maxLength?: number;
  minLength?: number;
  name: string;
  password?: boolean;
  pattern?: string
  placeholder?: string;
  readOnly?: boolean;
  ref?: LegacyRef<HTMLInputElement>
  required?: boolean | string;
  rightIcon?: ReactElement;
  textAlign?: 'left' | 'right' | 'center'
  theme?: 'light' | 'dark' | 'burgundy';
  title?: string;
  titleClassName?: string;
  type?: string;
  value?: string | number;
}

const Input: FunctionComponent<IComponentProps> = forwardRef(({
  appearance = 'regular',
  className,
  control,
  leftIcon,
  name,
  password,
  placeholder,
  required,
  rightIcon,
  textAlign = 'left',
  theme = 'light',
  title,
  titleClassName,
  ...rest
}: IComponentProps, ref): React.ReactElement => {

  const { field, fieldState: { error }} = useController({
    name,
    control,
    rules: {
      required,
      maxLength: rest.maxLength,
      minLength: rest.minLength
    },
    defaultValue: rest.value || ''
  })

  const [visiblePassword, setVisiblePassword] = useState<boolean>(false);

  const handleVisibleClick = useCallback(() => {
    setVisiblePassword( prevVisiblePassword => !prevVisiblePassword)
  }, [setVisiblePassword]);

  return (
    <div className={ cn(styles['container'], className) }>
      { title &&
        <span className={ cn(
          styles['input-title'],
          titleClassName
        ) }
        >
          { title }
        </span>
      }

      <div
        className={ cn(
          styles['input'],
          styles[theme],
          styles[appearance]
        ) }
      >
        { leftIcon &&
          <div className={ cn(styles['input-icon'], styles['left']) }>
            { leftIcon }
          </div>
        }

        <input
          autoComplete='off'
          className={ styles['input-input'] }
          type={ password && !visiblePassword ? 'password' : 'text' }
          placeholder={ placeholder }
          style={ { textAlign } }
          ref={ ref }
          { ...rest }
          { ...field }
        />

        { password &&
          <div
            className={ cn(
              styles['input-icon'],
              styles['input-password-icon'],
              styles['right'],
              styles['visible-icon'],
              visiblePassword && styles['active']
            ) }
          >
            <EyeIcon onClick={ handleVisibleClick } />
          </div>
        }

        { !password && rightIcon &&
          <div
            className={ cn(
              styles['input-icon'],
              styles['right']
            ) }
          >
            { rightIcon }
          </div>
        }
      </div>

      { error &&
        <div className={ styles['error'] }>
          { error.message }
        </div>
      }
    </div>
  )
});

Input.displayName = 'Input'

export default Input
