import { put, all, takeEvery, call } from 'redux-saga/effects'
import { REQUEST_FAQ, receivedFAQ, requestFAQFailed } from '../actions/faq.action'
import { setSpinnerState } from '../actions/spinner.action'
import { request } from '../../apiServices/http.service'
import { getFAQ } from '../../apiServices/faq.service'

import FAQ from '../../apiServices/models/faq.model'
import { FAQParams } from '../../apiServices/models/types'

function* requestFAQ({ params }: { type: string, params: FAQParams }) {
  const { response, error } = yield call(request, getFAQ(params))
  if (response) {
    yield put(receivedFAQ(FAQ.mapObjectsToEntities(response.faqs)))
  } else {
    yield put(requestFAQFailed(error))
  }

  yield put(setSpinnerState(false))
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(REQUEST_FAQ, requestFAQ)
  ])
}
