import {
  REQUEST_CONTACT_US_SUBJECTS_FAILED,
  RECEIVE_CONTACT_US_SUBJECTS,
  REQUEST_CREATE_FEEDBACK_FAILED, REQUEST_CREATE_FEEDBACK_SUCCESS
} from "../actions/contactUs.action";
import { FeedbackSubject } from "../../apiServices/models/subject.model";

export interface State {
  subjects: FeedbackSubject[]
  success: { success: boolean, message: string }
  body: string
  showAppStoreIcon: boolean
  showGooglePlayIcon: boolean
  linkToAppStore: string
  linkToGooglePlay: string
  errors: string[]
}

const initialState: Partial<State> = {
  subjects: [
      new FeedbackSubject({ id: 1, title: 'Error', key: 'error' }),
      new FeedbackSubject({ id: 2, title: 'Proposal', key: 'proposal' })
  ],
  success: null,
  errors: null
};

export default function contactUsReducer(
    state: Partial<State> = initialState,
    action: { type: string, subjects?: FeedbackSubject[], errors: string[], message: string }
): Partial<State> {
  switch (action.type) {
    case RECEIVE_CONTACT_US_SUBJECTS:
      return { ...state, subjects: action.subjects }

    case REQUEST_CREATE_FEEDBACK_SUCCESS:
      return { ...state, success: { success: true, message: action.message }}

    case REQUEST_CONTACT_US_SUBJECTS_FAILED:
    case REQUEST_CREATE_FEEDBACK_FAILED:
      return { ...state, errors: action.errors };

    default:
      return state;
  }
}
