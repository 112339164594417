// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contextMenuContainer_container__u3Imt {
  z-index: 4;
}
.contextMenuContainer_container__u3Imt .contextMenuContainer_options__xLmp- {
  background-color: rgba(166, 143, 168, 0.2);
  animation: contextMenuContainer_in__1cDp8 0.3s ease-in-out, contextMenuContainer_show__yziDR 0.3s ease-in-out;
  padding: 0.6rem;
  border-radius: 1.5rem;
  background-color: #310c38;
  border: 0.1rem solid #795b7c;
}
.contextMenuContainer_container__u3Imt .contextMenuContainer_options__xLmp-.contextMenuContainer_out__UR0TY {
  visibility: visible;
  animation: contextMenuContainer_out__UR0TY 0.3s ease-in-out, contextMenuContainer_hide__7NM74 0.3s ease-in-out;
}
.contextMenuContainer_container__u3Imt .contextMenuContainer_options-item__QLTFL {
  padding: 0.6rem 1.2rem;
  color: #FCF9FB;
  font-family: Montserrat;
  font-size: 1.3rem;
  font-weight: 500;
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer;
}
.contextMenuContainer_container__u3Imt .contextMenuContainer_options-item__QLTFL:hover {
  color: #795b7c;
}

@keyframes contextMenuContainer_in__1cDp8 {
  0% {
    transform: translateY(-10%);
  }
  100% {
    transform: translateY(0%);
  }
}
@keyframes contextMenuContainer_out__UR0TY {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-10%);
  }
}
@keyframes contextMenuContainer_show__yziDR {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes contextMenuContainer_hide__7NM74 {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/contextMenuContainer/index.module.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;AACF;AACE;EACE,0CAAA;EACA,6GAAA;EACA,eAAA;EACA,qBAAA;EACA,yBAAA;EACA,4BAAA;AACJ;AACI;EACE,mBAAA;EACA,8GAAA;AACN;AAEI;EACE,sBAAA;EACA,cAAA;EACA,uBAAA;EACA,iBAAA;EACA,gBAAA;EACA,yBAAA;EACA,mBAAA;EACA,eAAA;AAAN;AAEM;EACE,cAAA;AAAR;;AAMA;EAAgB;IAAK,2BAAA;EADnB;EACiD;IAAO,yBAAA;EAExD;AACF;AAFA;EAAiB;IAAK,yBAAA;EAMpB;EANgD;IAAO,2BAAA;EASvD;AACF;AARA;EAAkB;IAAK,UAAA;EAYrB;EAZmC;IAAO,UAAA;EAe1C;AACF;AAfA;EAAkB;IAAK,UAAA;EAmBrB;EAnBmC;IAAO,UAAA;EAsB1C;AACF","sourcesContent":[".container {\n  z-index: 4;\n\n  .options {\n    background-color: rgba(166, 143, 168, 0.2);\n    animation: in .3s ease-in-out, show .3s ease-in-out;\n    padding: .6rem;\n    border-radius: 1.5rem;\n    background-color: #310c38;\n    border: .1rem solid #795b7c;\n\n    &.out {\n      visibility: visible;\n      animation: out .3s ease-in-out, hide .3s ease-in-out;\n    }\n\n    &-item {\n      padding: .6rem 1.2rem;\n      color: #FCF9FB;\n      font-family: Montserrat;\n      font-size: 1.3rem;\n      font-weight: 500;\n      text-transform: uppercase;\n      white-space: nowrap;\n      cursor:  pointer;\n\n      &:hover {\n        color: #795b7c;\n      }\n    }\n  }\n}\n\n@keyframes in { 0% { transform: translateY(-10%) } 100% { transform: translateY(0%); } }\n@keyframes out { 0% { transform: translateY(0%) } 100% { transform: translateY(-10%); } }\n\n@keyframes show { 0% { opacity: 0; } 100% { opacity: 1; } }\n@keyframes hide { 0% { opacity: 1; } 100% { opacity: 0; } }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `contextMenuContainer_container__u3Imt`,
	"options": `contextMenuContainer_options__xLmp-`,
	"in": `contextMenuContainer_in__1cDp8`,
	"show": `contextMenuContainer_show__yziDR`,
	"out": `contextMenuContainer_out__UR0TY`,
	"hide": `contextMenuContainer_hide__7NM74`,
	"options-item": `contextMenuContainer_options-item__QLTFL`
};
export default ___CSS_LOADER_EXPORT___;
