import { object, string, date, InferType, mixed } from 'yup'
import i18n from 'i18n'
import { yupResolver } from '@hookform/resolvers/yup'
import { Attachment } from 'types/attachment'
import { hostURL } from 'apiServices/http.service'
import { ValidationMode } from 'react-hook-form'
import { EventInvitation } from '../types/eventInvitation'
import { User } from './user.model'

const eventSchema = object({
  title: string().required(() => i18n.t('validations.is_required')),
  description: string().required(() => i18n.t('validations.is_required')),
  from: date().required(() => i18n.t('validations.is_required')),
  to: date().required(() => i18n.t('validations.is_required')),
  image: mixed<Attachment>()
    .test(
      'fileSize',
      () => i18n.t('validations.file_too_large', { size: '50.0 mb' }),
      value => !value?.fileSize || value.fileSize <= 50000000
    )
    .test(
      'fileType',
      () => i18n.t('validations.is_image'),
      value => !value?.fileType || (
        value.fileType === 'image/jpeg' ||
        value.fileType === 'image/bmp' ||
        value.fileType === 'image/png'
      )
    )
    .test(
      'id',
      () => i18n.t('validations.is_required'),
      value => !!value?.id
    ),
  type: string()
    .oneOf(['all_day', 'period'], 'invalid value')
    .required(() => i18n.t('validations.is_required'))
})

export class Event implements InferType<typeof eventSchema> {
  active: boolean
  description: string
  from: Date
  genres: string[]
  id: number
  image: Attachment
  imageUrl: string
  title: string
  to: Date
  type: 'all_day' | 'period'
  user: User
  invitation?: EventInvitation

  constructor(data?) {
    this.active = data?.active
    this.description = data?.description
    this.from = new Date(data?.from || Date.now())
    this.genres = data?.genres || ['Pop', 'Electro', 'A&B']
    this.id = data?.id
    this.title = data?.title
    this.to = data?.to ? new Date(data?.to) : new Date()
    this.type = data?.type
    this.user = data?.user && new User(data.user)
    this.invitation = data?.invitation

    if (data?.image) {
      this.image = data.image
    } else {
      this.image = {
        fileName: data?.imageFileName,
        fileSize: data?.imageFileSize,
        fileType: data?.imageFileType,
        id: data?.id,
        temp: false,
      }
    }

    this.imageUrl = `${ hostURL }/attachments/${ this.user?.id }/${ this.id }/${ this.id }/${ this.image.fileName }`
  }

  asForm() {
    return {
      defaultValues: {
        description: this.description,
        from: this.from,
        id: this.id,
        image: this.image,
        title: this.title,
        to: this.to,
        type: this.type,
      },
      mode: 'onChange' as keyof ValidationMode,
      resolver: yupResolver(eventSchema)
    }
  }

  static fromFormData(data) {
    return new Event(data)
  }

  asPayloadJSON() {
    return {
      description: this.description,
      from: this.from,
      imageFileName: this.image.fileName,
      imageFileSize: this.image.fileSize,
      imageFileType: this.image.fileType,
      imageId: this.image.id.toString(),
      imageTemp: this.image.temp,
      title: this.title,
      to: this.to,
    }
  }
}

export interface EventsFilters {
  page?: number,
  perPage: number,
  future?: boolean,
  past?: boolean,
  pending?: boolean,
  userId?: string | number,
  from?: Date,
  to?: Date
}
