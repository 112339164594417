// Common
import React, { FunctionComponent, ReactElement } from 'react'
import styles from './index.module.scss'

// Components
import Button from 'components/button'
import Modal from 'components/modal'
import Label from 'components/label'
import EventForm from '../../events/eventForm'
import EventComponent from './event'

// Hooks
import { useCallback, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'redux/store'

// Helpers
import { beginningOfDay, endOfDay, getDateLabel } from 'helpers/date'

interface IComponentProps {
  date: Date
}

const EventsComponent: FunctionComponent<IComponentProps> = ({ date }): ReactElement => {

  const [t] = useTranslation()

  const [formOpen, setFormOpen] = useState(false)

  const [eventToEdit, setEventToEdit] = useState()

  const handleFormOpen = useCallback((opened: boolean) => () => {
    setFormOpen(opened)
  }, [])

  const { items } = useSelector(({ calendarMonthEvents }) => calendarMonthEvents)

  const events = useMemo(() => {
    return items.filter(event  => event.from <= endOfDay(date) && event.to >= beginningOfDay(date) )
  }, [items, date])

  const handleEdit = useCallback(event => () => {
    setEventToEdit(event)
    handleFormOpen(true)()
  }, [handleFormOpen])

  const handleNew = useCallback(() => {
    setEventToEdit(undefined)
    handleFormOpen(true)()
  }, [handleFormOpen])

  return (
    <>
      <div className={ styles['container'] }>

        <div className={ styles['events'] }>
          <Label
            appearance='gold'
            text={ getDateLabel(t, date) }
          />

          <div className={ styles['events-text'] }>{ t('headers.events_placeholder', { count: events.length }) }</div>

          { events.map((event, index) => <EventComponent onEdit={ handleEdit(event) } key={ index } event={ event } />) }

          <Button
            appearance='gold'
            className={ styles['events-button'] }
            onClick={ handleNew }
            withArrow={ events.length === 0 }
          >
            { t(events.length === 0 ? 'buttons.create_event' : 'buttons.create_another_event') }
          </Button>
        </div>
      </div>

      <Modal
        isOpen={ formOpen }
        onClose={ handleFormOpen(false) }
      >
        <EventForm
          onClose={ handleFormOpen(false) }
          event={ eventToEdit }
        />
      </Modal>
    </>
  );
}

export default EventsComponent
