import { hostURL } from './http.service'
import { RequestParams } from './models/types'
import { Service } from 'types/reduxCRUD'

// Models
import { Event, EventsFilters } from 'models/event.model'
import { EventPerformersFilters, PerformerUser } from '../models/performerUser.model'
import { isNil } from '../helpers/base'

export const eventsService: Service<Event> = {
  getItems(filters: EventsFilters): RequestParams {
    const url = new URL(`${ hostURL }/events`)

    return {
      url,
      params: {
        page: filters.page,
        per_page: filters.perPage,
        future: filters.future,
        past: filters.past,
        pending: filters.pending,
        userId: filters.userId,
        to: filters.to,
        from: filters.from,
      }
    }
  },
  getItem(id: number): RequestParams {
    const url = new URL(hostURL + `/events/${ id }`)

    return {
      url
    }
  },
  upsertItem(event: Event): RequestParams {
    const url = new URL(hostURL + `/events/${ event.id ?? '' }`)

    return {
      url,
      method: event.id ? 'PUT' : 'POST',
      body: event.asPayloadJSON()
    }
  },
  deleteItem(event: Event): RequestParams {
    const url = new URL(hostURL + `/events/${ event.id }`)

    return {
      url,
      method: 'DELETE'
    }
  }
}

export const eventPerformersService: Service<PerformerUser> = {
  getItems(filters: EventPerformersFilters): RequestParams {
    const url = new URL(`${ hostURL }/events/${ filters.eventId }/performers`)

    return {
      url
    }
  },
  getItem(): RequestParams {
    throw new Error('API not available')
  },
  upsertItem(performer): RequestParams {
    const url = new URL(hostURL + `/events/${ performer.eventId }/performers`)

    return {
      url,
      method: isNil(performer.status) ? 'POST' : 'PUT',
      body: {
        userId: performer?.id,
        status: performer.status
      }
    }
  },
  deleteItem(performer: PerformerUser): RequestParams {
    throw new Error('API not available yet')
  }
}
