// Common
import React, { FunctionComponent, ReactElement } from 'react';

// Components
import PageTabsHeader from 'components/pageTabsHeader'
import { Outlet } from 'react-router-dom'
import TrackForm from './trackForm'
import AlbumForm from './albumForm'
import Modal from 'components/modal'

// Hooks
import { useMemo, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import useIsOwnProfile from 'hooks/useIsOwnProfile'

const Media: FunctionComponent = (): ReactElement => {
  const [t] = useTranslation()

  const tabs = useMemo(() => {
    return [
      { title: t('headers.gallery'), route: 'gallery' },
      { title: t('headers.tracks'), route: 'tracks' },
    ]
  }, [t])

  const { pathname } = useLocation()
  const isOwnProfile = useIsOwnProfile()

  const [albumFormOpen, setAlbumFormOpen] = useState(false)
  const [trackFormOpen, setTrackFormOpen] = useState(false)

  const handleOpenTrackForm = useCallback((opened: boolean) => () => {
    setTrackFormOpen(opened)
  }, [])

  const handleOpenAlbumForm = useCallback((opened: boolean) => () => {
    setAlbumFormOpen(opened)
  }, [])

  return (
    <>
      <PageTabsHeader
        tabs={ tabs }
        buttons={
          isOwnProfile
            ? [
              pathname === '/media/gallery'
                ? { title: t('buttons.album_add'), onClick: handleOpenAlbumForm(true) }
                : { title: t('buttons.track_add'), onClick: handleOpenTrackForm(true) }
            ]
            : null
        }
      />

      <Outlet />

      <Modal
        isOpen={ trackFormOpen }
        onClose={ handleOpenTrackForm(false) }
      >
        <TrackForm
          onClose={ handleOpenTrackForm(false) }
        />
      </Modal>

      <Modal
        isOpen={ albumFormOpen }
        onClose={ handleOpenAlbumForm(false) }
      >
        <AlbumForm
          onClose={ handleOpenAlbumForm(false) }
        />
      </Modal>
    </>
  )
}

export default Media
