// Common
import React, { FunctionComponent, ReactElement } from 'react'
import styles from './index.module.scss'
import cn from 'classnames'

// Components
import Icon from 'components/icon'
import Calendar from 'components/calendarMonth'
import Events from './events'

// Hooks
import { useParams } from 'react-router-dom'
import { useCallback, useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useSelector } from 'redux/store'
import { useNavigate } from 'react-router-dom'

// Helpers
import { getMonthName } from 'helpers/date'

// Actions
import actions from 'redux/actions/calendar-month-events.action-creators'

interface IComponentProps {

}

const CalendarMonthComponent: FunctionComponent<IComponentProps> = (): ReactElement => {
  const params = useParams()

  const navigate = useNavigate()

  const { year, month, day } = useMemo(() => ({
    year: parseInt(params.year),
    month: parseInt(params.month),
    day: parseInt(params.day)
  }), [params])

  const [t] = useTranslation()

  const [date, setDate] = useState(new Date())

  useEffect(() => {
    setDate(new Date(year, month - 1, day))
  }, [year, month, day])

  const handleChangeMonth = useCallback(direction => () => {
    navigate(`/calendar/${ date.getFullYear() }/${ date.getMonth() + direction + 1 }/${ date.getDate() }`, { replace: true })
  }, [date, navigate])

  const handleDayClick = useCallback((day: Date) => {
    navigate(`/calendar/${ day.getFullYear() }/${ day.getMonth() + 1 }/${ day.getDate() }`, { replace: true })
  }, [navigate])

  const dispatch = useDispatch()

  const { items: events, pending, reset, error, hasMore } = useSelector(({ calendarMonthEvents }) => calendarMonthEvents)

  const ranges = useMemo(() => {
    return events.map(event => ({ from: event.from, to: event.to }))
  }, [events])

  useEffect(() => {
    if (hasMore && !pending && !error) {
      dispatch(actions.read({
        perPage: 100,
        from: new Date(year, month - 1, 1),
        to: new Date(year, month, 0)
      }))
    }
  }, [year, month, reset, dispatch, pending, error, hasMore])

  useEffect(() => {
    return () => {
      dispatch(actions.clear())
    }
  }, [dispatch])

  return (
    <>
      <div className={ styles['heading'] }>
        <div className={ styles['heading-month'] }>
          <Icon
            name='arrow-left'
            className={ styles['heading-month-icon'] }
            onClick={ handleChangeMonth(-1) }
          />
          <div>{ t(getMonthName(date.getMonth())) }</div>
          <Icon
            name='arrow-right'
            className={ styles['heading-month-icon'] }
            onClick={ handleChangeMonth(1) }
          />
        </div>
        <div className={ styles['heading-events'] }>
          <Icon
            name='filter'
            appearance='interactive'
            width={ 20 }
            height={ 15 }
            size={ 36 }
          />
        </div>
      </div>

      <div className={ styles['container'] }>
        <div className={ styles['calendar'] }>
          <Calendar
            month={ date }
            onClickDay={ handleDayClick }
            ranges={ ranges }
          />

          <div className={ styles['calendar-footer'] }>
            <div className={ styles['calendar-footer-label'] }>
              <div className={ cn(styles['calendar-footer-label-indicator'], styles['green']) }></div>
              { t('headers.busy') }
            </div>
            <div className={ styles['calendar-footer-label'] }>
              <div className={ cn(styles['calendar-footer-label-indicator'], styles['yellow']) }></div>
              { t('headers.in_progress') }
            </div>
            <div className={ styles['calendar-footer-label'] }>
              <div className={ cn(styles['calendar-footer-label-indicator'], styles['red']) }></div>
              { t('headers.canceled') }
            </div>
            <div className={ styles['calendar-footer-label'] }>
              <div className={ cn(styles['calendar-footer-label-indicator'], styles['green']) }></div>
              { t('headers.closed') }
            </div>
          </div>
        </div>

        <div className={ styles['events'] }>
          <Events date={ date } />
        </div>
      </div>
    </>
  )
}

export default CalendarMonthComponent
