import { SUCCESS_ADMIN_CHECK, REJECT_ADMIN_CHECK } from '../../actions/admin/auth'

export interface State {
  admin: boolean
}

const initialState: State = {
  admin: false
}

export default function authReducer(
  state: State = initialState,
  action: { type: string, body: string, error: string }
): Partial<State> {
  switch (action.type) {
    case SUCCESS_ADMIN_CHECK:
      return { ...state, admin: true }
    case REJECT_ADMIN_CHECK:
      return { ...state, admin: false }

    default:
      return state
  }
}
