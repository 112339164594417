import Language from "../../apiServices/models/language.model";

export const REQUEST_LANGUAGES_LIST = 'REQUEST_LANGUAGES_LIST';
export const requestLanguagesList = () => ({
  type: REQUEST_LANGUAGES_LIST
});

export const RECEIVED_LANGUAGES_LIST = 'RECEIVED_LANGUAGES_LIST';
export const receivedLanguagesList = (languages: Language[]) => ({
  type: RECEIVED_LANGUAGES_LIST,
  languages
});

export const REQUEST_LANGUAGES_LIST_FAILED = 'REQUEST_LANGUAGES_LIST_FAILED';
export const requestLanguagesListFailed = (error: { message: string, status: number }) => ({
  type: REQUEST_LANGUAGES_LIST_FAILED,
  error
});
