import { FeedbackSubject } from "../../apiServices/models/subject.model";

export const REQUEST_CONTACT_US_SUBJECTS = 'REQUEST_CONTACT_US_SUBJECTS';
export const requestContactUsSubjects = () => ({
  type: REQUEST_CONTACT_US_SUBJECTS
});

export const RECEIVE_CONTACT_US_SUBJECTS = 'RECEIVE_CONTACT_US_SUBJECTS';
export const receivedContactUsSubjects = (subjects: FeedbackSubject[]) => ({
  type: RECEIVE_CONTACT_US_SUBJECTS,
  subjects
});

export const REQUEST_CONTACT_US_SUBJECTS_FAILED = 'REQUEST_CONTACT_US_SUBJECTS_FAILED';
export const requestContactUsSubjectsFailed = (error: { message: string, status: number }) => ({
  type: REQUEST_CONTACT_US_SUBJECTS_FAILED,
  error
});


export const REQUEST_CREATE_FEEDBACK = 'REQUEST_CREATE_FEEDBACK';
export const createFeedback = (payload) => ({
  type: REQUEST_CREATE_FEEDBACK,
  payload
});

export const REQUEST_CREATE_FEEDBACK_SUCCESS = 'REQUEST_CREATE_FEEDBACK_SUCCESS'
export const createFeedbackSuccess = (message: string) => ({
  type: REQUEST_CREATE_FEEDBACK_SUCCESS,
  message
})

export const REQUEST_CREATE_FEEDBACK_FAILED = 'REQUEST_CREATE_FEEDBACK_FAILED';
export const createFeedbackFailed = (error: { message: string, status: number }) => ({
  type: REQUEST_CREATE_FEEDBACK_FAILED,
  error
});
