import React, { FunctionComponent, ReactElement, useEffect } from 'react'
import styles from './index.module.scss'

// Components
import CarouselSlide from 'components/carousel/slide/carouselSlide'
import Carousel from 'components/carousel'
import Tabs from 'components/tabs'
import Icon from 'components/icon'
import Label from 'components/label'
import NewsList from './NewsList'

// Mocks
import { slides } from 'mocks/news.mock'

// Hooks
import { useState, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const News: FunctionComponent = (): ReactElement => {

  const [t] = useTranslation()

  const [currentTab, setCurrentTab] = useState('');

  const handleChangeTab = useCallback((_, currentTabName) => {
    setCurrentTab(currentTabName)
  }, [])

  useEffect(() => {
    // do something
  }, [currentTab])

  const tabs = useMemo(() => ([
    { title: t('buttons.role_business'), key: 'business' },
    { title: t('buttons.role_performer'), key: 'artist' }
  ]), [t]);

  const [minimized, setMinimized] = useState(true)

  const handleToggleMinimize = useCallback(() => {
    setMinimized(value => !value)
  }, [])

  return (
    <div className={ styles['news'] }>
      <div className={ styles['news-slider'] }>
        <div className={ styles['news-slider-carousel'] }>
          <Carousel withArrows={ true }>
            {
              slides.map((slide, index) => {
                return(
                  <CarouselSlide key={ index }>
                    <img
                      className={ styles['news-slider-carousel-image'] }
                      src={ slide.image }
                      alt=''
                    />
                  </CarouselSlide>
                )
              })
            }
          </Carousel>
        </div>

        <div className={ styles['news-slider-title'] }>Singers Name</div>

        <div className={ styles['news-slider-date'] }>
          <Icon
            className={ styles['news-slider-date-icon'] }
            name='calendar'
            width={ 28 }
            height={ 28 }
            size={ 28 }
          />

          Date of the nearest event
        </div>

        <div className={ styles['news-slider-body'] } >
          You begin with a text, you sculpt information, you chisel away what &apos;s not needed, you come to the point, make things clear, add value.
          <br />
          <br />
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.

          { !minimized && (
            <>
              Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.

              Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora i
            </>
          ) }

          <Label
            className={ styles['news-slider-body-label'] }
            appearance='gold-strikethrough'
            onClick={ handleToggleMinimize }
            text={ minimized ? t('headers.more') : t('headers.less') }
          />
        </div>
      </div>

      <div className={ styles['news-list'] }>
        <Tabs
          className={ styles['news-list-tabs'] }
          onSelect={ handleChangeTab }
          tabs={ tabs }
          appearance='light'
        />

        <NewsList />
      </div>
    </div>
  )
}

export default News

