import { hostURL } from 'apiServices/http.service'
import { BaseRestInfiniteScrollService } from './base-rest-infinite-scroll.service'
import { Track, TracksFilters } from 'models/track.model'

export class TracksService extends BaseRestInfiniteScrollService<Track, TracksFilters> {

  protected entityName = 'Track'

  async buildUrl() {
    return new URL(`${ hostURL }/tracks`)
  }

  buildItem(item: object) {
    return new Track(item)
  }

  getItemFromResponse(response: object) {
    return response['track']
  }

  getItemsFromResponse(response: object) {
    return response['tracks']
  }
}