// Common
import React, { FunctionComponent, ReactElement } from 'react'
import styles from './aboutUs.module.scss'

// Components
import { AppStoreIcon, GooglePlayIcon } from 'assets'

// Hooks
import { useTranslation } from 'react-i18next'
import { useCallback } from 'react'
import { useSelector } from 'redux/store'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'

// Actions
import { requestAboutUs } from 'redux/actions/aboutUs.action'

interface IComponentProps {
  year?: number
  onExpand?: (event) => null
}

const NavigationPanel: FunctionComponent<IComponentProps> = (): ReactElement => {
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()

  const { body, showAppStoreIcon, showGooglePlayIcon, linkToAppStore, linkToGooglePlay } = useSelector(({ aboutUs }) => aboutUs)

  const requestData = useCallback(() => {
    dispatch(requestAboutUs())
  }, [dispatch])

  useEffect(() => {
    requestData()
    i18n.on('languageChanged', requestData)

    return () => {
      i18n.off('languageChanged', requestData)
    }
  }, [i18n, requestData])

  return (
    <div className={ styles['about-us'] }>
      <h1>{ t('ABOUT_US_TITLE') }</h1>
      <div
        className={ styles['about-us-content'] }
        dangerouslySetInnerHTML={ { __html: body } }
      />
      { (showAppStoreIcon || showGooglePlayIcon) &&
        <div className={ styles['about-us-actions-group'] }>
          { showAppStoreIcon &&
            <a target='_blank' href={ linkToAppStore } rel='noopener noreferrer'>
              <AppStoreIcon className={ styles['about-us-actions-group-item'] } />
            </a>
          }
          { showGooglePlayIcon &&
            <a target='_blank' href={ linkToGooglePlay } rel='noopener noreferrer'>
              <GooglePlayIcon className={ styles['about-us-actions-group-item'] } />
            </a>
          }
        </div>
      }

      <h3>{ t('TO_VIEW_A_MORE_DETAILS') }</h3>
    </div>
  );
}

export default NavigationPanel


