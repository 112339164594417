// Common
import React, { FunctionComponent, ReactElement, useCallback } from 'react';
import { Link } from 'react-router-dom'
import cn from 'classnames';
import styles from './login.module.scss'

// Hooks
import { useDispatch } from 'react-redux';
import { useSelector } from 'redux/store'
import { useTranslation, UseTranslationResponse } from 'react-i18next'
import { useForm } from "react-hook-form";

// Components
import Input from 'components/input'
import AnimatedContainer from 'components/animatedContainer'
import Button from 'components/button'

// Actions
import { requestSignIn, resetAuthenticationErrors } from 'redux/actions/authentication.action'

// Assets
import { FacebookIcon, InstagramIcon, Logo, MailIcon, PasswordIcon, TwitterIcon } from 'assets'

const LoginForm: FunctionComponent = (): ReactElement => {
  const errors = useSelector(({ authentication: { errors }}) => errors);

  const dispatch = useDispatch();
  const [t]: UseTranslationResponse<string> = useTranslation();

  const { control, handleSubmit: handleSubmitForm } = useForm();

  const handleSubmit = useCallback(({ email, password }) => {
    dispatch(resetAuthenticationErrors());
    dispatch(requestSignIn({ email, password }));
  }, [dispatch])

  return(
      <div className={ cn(
        styles['login-form']
      ) }
      >
        <h3>{ t('titles.login_to') }</h3>
        <Logo className={ styles['login-form-logo'] } />

        <form
            id='login-form'
            className={ styles['login-form-item'] }
            onSubmit={ handleSubmitForm(handleSubmit) }
        >
          <Input
              name='email'
              title={ t('titles.email_address') }
              control={ control }
              placeholder={ t('placeholders.enter_email') }
              required={ t('validations.email_blank') as string }
              leftIcon={ <MailIcon /> }
          />
        </form>
        <div className={ styles['login-form-item'] }>
          <Input
              name='password'
              control={ control }
              title={ t('titles.password') }
              password={ true }
              placeholder={ t('placeholders.enter_password') }
              required={ t('validations.password_blank') as string }
              leftIcon={ <PasswordIcon /> }
          />
        </div>

        <p>
          { t('links.forgot_password') } <Link to='/authorization/forgotPassword'>{ t('links.press_here') }</Link>
        </p>

        { /* TODO Make a form with error handling and highlighting */ }
        <AnimatedContainer show={ !!errors }>
          <div className={ styles['login-form-error-message'] }>{ errors }</div>
        </AnimatedContainer>

        <Button
            className={ styles['login-form-action'] }
            appearance='transparent'
            type='submit'
            form='login-form'
        >
          { t('buttons.login') }
        </Button>

        <span className={ styles['login-form-login-with'] }>{ t('titles.login_with') }</span>
        <div className={ styles['login-form-socials'] }>
          <FacebookIcon />
          <InstagramIcon />
          <TwitterIcon />
        </div>
      </div>
  )
};

export default LoginForm;
