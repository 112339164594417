// Common
import React, { forwardRef, FunctionComponent, LegacyRef, memo } from 'react';
import styles from "../index.module.scss";

// Components
import { SmallArrow } from "../../../assets";

// Types
import { OptionType } from "../types/option";

interface IComponentProps {
  trigger
  selectedOptions: OptionType[]
  placeholder?
  showBy: 'title' | 'value'
  ref?: LegacyRef<HTMLDivElement>
}
const MultiselectDropDownTrigger: FunctionComponent<IComponentProps> = forwardRef((
  {
    trigger: handleTrigger,
    selectedOptions,
    placeholder,
    showBy,
  }: IComponentProps,
  ref: LegacyRef<HTMLDivElement>
) => {

  return (
      <div className={ styles['drop-down-trigger'] } onClick={ handleTrigger } ref={ ref }>
        { selectedOptions.length !== 0
            ? <span className={ styles['drop-down-trigger-selected-items'] }>
              { selectedOptions
                  .map(({ title, value }) => showBy === 'title' ? title : value)
                  .join(', ')
              }
              </span>
            : <span className={ styles['drop-down-trigger-placeholder'] }>{ placeholder }</span>
        }
        <SmallArrow className={ styles['drop-down-trigger-icon'] } />
      </div>
      )
})

MultiselectDropDownTrigger.displayName = 'MultiselectDropDownTrigger'
export default memo(MultiselectDropDownTrigger)
