import { hostURL } from 'apiServices/http.service'
import { EventInvitation, InvitationStatus } from '../types/eventInvitation'
import { Filters } from '../types/reduxCRUD'
import { Attachment } from 'types/attachment'
import { Role } from 'types/role'

export interface EventPerformersFilters extends Filters {
  eventId?: number | string
}

export class PerformerUser {
  eventId: string | number
  id: number
  invitation: EventInvitation
  status: InvitationStatus
  avatarUrl: string
  avatar: Attachment
  profileName: string
  role: Role

  constructor(raw: any) {
    this.id = raw.id
    this.invitation = raw.invitation
    this.profileName = raw?.profileName
    this.role = raw?.role

    if (raw?.avatar) {
      this.avatar = raw.avatar
    } else if (raw.avatarFileName) {
      this.avatar = {
        fileName: raw?.avatarFileName,
        fileSize: raw?.avatarFileSize,
        fileType: raw?.avatarFileType,
        id: raw?.id,
        temp: false,
      }
    }

    this.avatarUrl = this.avatar && `${ hostURL }/attachments/${ this.id }/avatar/avatar/${ this.avatar.fileName }`
  }
}
