// Common
import React, { FunctionComponent, ReactElement } from 'react'
import styles from './index.module.scss'

// Components
import UsersList from '../../components/usersList'
import Icon from 'components/icon'

// Hooks
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useSelector } from 'redux/store'
import { useDispatch } from 'react-redux'
import { useInfiniteScroll } from 'hooks/useInfiniteScroll'

// Actions
import { friendsListActions as actions } from 'redux/actions/black-list-friends.action-creator'

// Mock
import { Friends } from 'mocks/friends.mock'

// Types
import { User } from 'models/user.model'

export const WorkingListComponent: FunctionComponent = () => {
  const dispatch = useDispatch()
  const { hasMore, pending, reset } = useSelector(({ friendsList }) => friendsList)
  const containerRef = useRef(null)

  const loadMore = useCallback(() => {
    if(!hasMore || pending) return

    dispatch(actions.read({ perPage: 5 }))
  }, [pending, hasMore, dispatch])

  const handleRemove = useCallback((user: User) => () => {
    dispatch(actions.deleteItem(user))
  }, [dispatch])

  useInfiniteScroll({ containerRef, loadMore, reset })

  useEffect(() => {
    return () => {
      dispatch(actions.clear())
    }
  }, [dispatch])

  const buttons = useMemo<ReactElement[]>(() => ([
    <Icon
      width={ 20 }
      height={ 20 }
      appearance='interactive'
      name='chat'
      badge={ 1 }
      key='chat'
    />,
    <Icon
      width={ 20 }
      height={ 20 }
      appearance='interactive'
      name='notifications'
      key='notifications'
    />,
    <Icon
      width={ 20 }
      height={ 20 }
      appearance='interactive'
      name='trash-basket'
      key='trash-basket'
      onClick={ handleRemove }
    />
  ]), [handleRemove])

  return (
    <div className={ styles['body'] } ref={ containerRef }>
      <UsersList users={ Friends } buttons={ buttons } />
    </div>
  )
}

export default WorkingListComponent
