import React, { FunctionComponent, ReactElement } from "react";
import styles from "../settings.module.scss";
import { useForm } from 'react-hook-form'
import { useTranslation } from "react-i18next";

// Components
import Input from 'components/input'

const SettingsBusinessGeneralInfo: FunctionComponent = (): ReactElement => {
  const { control } = useForm()
  const [t] = useTranslation();

  return (
    <div className={ styles['settings-group'] }>
      <div className={ styles['settings-group-row'] }>
        <Input
          className={ styles['settings-group-row-item'] }
          control={ control }
          name='profileName'
          title={ t('titles.user_name') }
          placeholder={ t('placeholders.enter_user_name') }
          theme='dark'
        />

        <Input
          className={ styles['settings-group-row-item'] }
          control={ control }
          name='typeOfUser'
          title={ t('titles.type_of_business') }
          placeholder={ t('placeholders.enter_type_of_business') }
          theme='dark'
        />

        <Input
          className={ styles['settings-group-row-item'] }
          control={ control }
          name='nickName'
          title={ t('titles.business_name') }
          placeholder={ t('placeholders.enter_business_name') }
          theme='dark'
        />
      </div>

      <div className={ styles['settings-group-row'] }>
        <Input
          className={ styles['settings-group-row-item'] }
          control={ control }
          name='generalLocation.country'
          title={ t('titles.country') }
          placeholder={ t('placeholders.enter_country') }
          theme='dark'
        />

        <Input
          className={ styles['settings-group-row-item'] }
          control={ control }
          name='generalLocation.city'
          title={ t('titles.city') }
          placeholder={ t('placeholders.enter_city') }
          theme='dark'
        />

        <Input
          className={ styles['settings-group-row-item'] }
          control={ control }
          name='generalLocation.address'
          title={ t('titles.address') }
          placeholder={ t('placeholders.enter_address') }
          theme='dark'
        />
      </div>

      <div className={ styles['settings-group-row'] }>
        <Input
          className={ styles['settings-group-row-item'] }
          control={ control }
          name='generalLocation.zipCode'
          title={ t('titles.zip_code') }
          placeholder={ t('placeholders.enter_zip_code') }
          theme='dark'
        />
      </div>
    </div>
  )
}

export default SettingsBusinessGeneralInfo;
