// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.underlinedTextx_underline__8u4VY {
  color: #7740e9;
  text-decoration: underline;
  cursor: pointer;
}
.underlinedTextx_underline__8u4VY::before {
  content: " ";
}`, "",{"version":3,"sources":["webpack://./src/components/underlinedText/underlinedTextx.module.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACE,cAAA;EACA,0BAAA;EACA,eAAA;AAEF;AAAE;EACE,YAAA;AAEJ","sourcesContent":[".underline {\n  color: #7740e9;\n  text-decoration: underline;\n  cursor: pointer;\n\n  &::before {\n    content: \"\\00a0\";\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"underline": `underlinedTextx_underline__8u4VY`
};
export default ___CSS_LOADER_EXPORT___;
