import { BaseModel } from "./base.model";

export default class FAQ extends BaseModel {
  id: number | string;
  title: string;
  body: string;
  role: string;

  constructor(object) {
    super(object);
    this.id = object.id;
    this.title = object.title;
    this.body = object.description;
    this.role = object.role;
  }
}
