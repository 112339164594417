import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { rootReducer } from './reducers/root.reducer'
import rootSaga from './sagas/root.saga'
import { useSelector as useReduxSelector, TypedUseSelectorHook } from 'react-redux'

const sagaMiddleware = createSagaMiddleware();
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export default createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
)
sagaMiddleware.run(rootSaga)

export type RootState = ReturnType<typeof rootReducer>
export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector
