import { ActionTypes } from 'types/reduxCRUD'

export default function generateCRUDActionTypes(resource: string): ActionTypes {
  return {
    CLEAR: `CLEAR_${ resource }S`,

    UPSERT: `UPSERT_${ resource }`,
    UPSERT_PENDING: `UPSERT_${ resource }_PENDING`,
    UPSERT_FAILED: `UPSERT_${ resource }_FAILED`,
    UPSERT_SUCCEEDED: `UPSERT_${ resource }_SUCCEEDED`,

    READ: `READ_${ resource }S`,
    READ_PENDING: `READ_${ resource }S_PENDING`,
    READ_FAILED: `READ_${ resource }S_FAILED`,
    READ_SUCCEEDED: `READ_${ resource }S_SUCCEEDED`,

    READ_ONE: `READ_${ resource }`,
    READ_ONE_PENDING: `READ_${ resource }_PENDING`,
    READ_ONE_FAILED: `READ_${ resource }_FAILED`,
    READ_ONE_SUCCEEDED: `READ_${ resource }_SUCCEEDED`,

    DELETE: `DELETE_${ resource }`,
    DELETE_PENDING: `DELETE_${ resource }_PENDING`,
    DELETE_FAILED: `DELETE_${ resource }_FAILED`,
    DELETE_SUCCEEDED: `DELETE_${ resource }_SUCCEEDED`,
  }
}
