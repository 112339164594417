// Common
import React, { FunctionComponent, ReactElement, useCallback } from 'react'
import styles from './index.module.scss'

// Types
import { PerformerUser } from 'models/performerUser.model'

// Components
import Icon from 'components/icon'
import Performer from '../performer'

// Hooks
import { useNavigate } from 'react-router-dom'

interface IComponentProps {
  performers: PerformerUser[]
}


const UsersInlineListComponent: FunctionComponent<IComponentProps> = ({ performers }): ReactElement => {
  const navigate = useNavigate()

  const handlePerformerClick = useCallback((performerId) => {
    navigate('/users/' + performerId)
  }, [navigate])

  const handleDeleteClick = useCallback((performerId) => () => {
    console.info('performer with id', performerId, 'deleted')
  }, [])

  return(
    <div className={ styles['performers-list'] }>
      { performers.map(performer =>
        <div
          className={ styles['performers-list-item'] }
          key={ performer.id }
        >
          <Performer performer={ performer } onPerformerClick={ handlePerformerClick } />

          <div
            className={ styles['performers-list-item-trash-icon'] }
            onClick={ handleDeleteClick(performer.id) }
          >
            <Icon className={ styles['performers-list-item-trash-icon-image'] } name='trash-basket' />
          </div>
        </div>
      ) }
    </div>
  )
}
export default UsersInlineListComponent
