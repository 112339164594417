import ApiError from 'types/apiError'
import { ActionTypes, Filters, Payload } from 'types/reduxCRUD'

export default function generateCRUDActionCreators<T, TFilters extends Filters>(at: ActionTypes) {
  return {
    clear: () => ({
      type: at.CLEAR
    }),
    read: (filters: TFilters) => ({
      type: at.READ,
      payload: { filters }
    }),
    readPending: () => ({
      type: at.READ_PENDING,
    }),
    readSucceeded: (payload: Payload<T, TFilters>) => ({
      type: at.READ_SUCCEEDED,
      payload
    }),
    readFailed: (error: ApiError) => ({
      type: at.READ_FAILED,
      payload: { error }
    }),
    readOne: (id: number | string) => ({
      type: at.READ_ONE,
      payload: { id }
    }),
    readOnePending: () => ({
      type: at.READ_ONE_PENDING,
    }),
    readOneSucceeded: (payload: Payload<T, TFilters>) => ({
      type: at.READ_ONE_SUCCEEDED,
      payload
    }),
    readOneFailed: (error: ApiError) => ({
      type: at.READ_ONE_FAILED,
      payload: { error }
    }),
    upsert: (entity: T) => ({
      type: at.UPSERT,
      payload: { entity }
    }),
    upsertPending: () => ({
      type: at.UPSERT_PENDING,
    }),
    upsertSucceeded: () => ({
      type: at.UPSERT_SUCCEEDED,
    }),
    upsertFailed: (error: ApiError) => ({
      type: at.UPSERT_FAILED,
      payload: { error }
    }),
    deleteItem: (entity: T) => ({
      type: at.DELETE,
      payload: { entity }
    }),
    deletePending: () => ({
      type: at.DELETE_PENDING,
    }),
    deleteSucceeded: () => ({
      type: at.DELETE_SUCCEEDED,
    }),
    deleteFailed: (error: ApiError) => ({
      type: at.DELETE_FAILED,
      payload: { error }
    })
  }
}
