// Common
import React, { FunctionComponent, ReactElement, useCallback } from 'react'
import styles from './index.module.scss'
import cn from 'classnames'

// Components
import Label from 'components/label'
import DateRangeInput from 'components/dateRangeInput'
import Button from 'components/button'
import CheckBox from 'components/checkBox/checkBox'
import Input from 'components/input'

// Hooks
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'

interface IComponentProps {
  onSubmit: (data: unknown, event: MouseEvent) => void
  onClose: (event: MouseEvent) => void
  filters?: {
    fromDate?: Date
    toDate?: Date
    oneDay?: boolean
    period?: boolean
    country?: string
    city?: string
    performerType?: string
    musicType?: string
  }
}

const PerformersSearchForm: FunctionComponent<IComponentProps> = ({
  onClose: handleCloseForm,
  onSubmit: handleSubmitForm,
  filters: defaultValues
}): ReactElement => {
  const { control, handleSubmit } = useForm({ defaultValues })
  const [t] = useTranslation()

  const submit = useCallback((data, event) => {
    handleSubmitForm(data, event)
  }, [handleSubmitForm])

  return (
    <>
      <form
        className={ styles['form'] }
        id='performersSearchForm'
        onSubmit={ handleSubmit(submit) }
      >
        <div className={ styles['form-row'] }>
          <div className={ styles['form-row-column'] }>
            <Label
              className={ styles['label'] }
              appearance='input'
              text={ t('headers.date') }
            />

            <DateRangeInput
              control={ control }
              fromName='fromDate'
              toName='toDate'
              placeholder={ t('headers.select_date') }
            />
          </div>

          <div className={ cn(styles['form-row-column'], styles['inline']) }>
            <CheckBox name='oneDay' label={ t('headers.one_day') } control={ control } />
            <CheckBox name='period' label={ t('headers.period') } control={ control } />
          </div>
        </div>

        <div className={ styles['form-row'] }>
          <div className={ styles['form-row-column'] }>
            <Label
              className={ styles['label'] }
              appearance='input'
              text={ t('headers.country') }
            />

            <Input
              name='country'
              control={ control }
              placeholder={ t('headers.country') }
            />
          </div>
          <div className={ styles['form-row-column'] }>
            <Label
              className={ styles['label'] }
              appearance='input'
              text={ t('headers.city') }
            />

            <Input
              name='city'
              control={ control }
              placeholder={ t('headers.city') }
            />
          </div>
        </div>

        <div className={ styles['form-row'] }>
          <div className={ styles['form-row-column'] }>
            <Label
              className={ styles['label'] }
              appearance='input'
              text={ t('headers.performer_type') }
            />

            <Input
              name='performerType'
              control={ control }
              placeholder={ t('headers.performer_type') }
            />
          </div>
          <div className={ styles['form-row-column'] }>
            <Label
              className={ styles['label'] }
              appearance='input'
              text={ t('headers.music_type') }
            />

            <Input
              name='musicType'
              control={ control }
              placeholder={ t('headers.music_type') }
            />
          </div>
        </div>
      </form>

      <div className={ styles['actions'] }>
        <Button
          className={ styles['actions-item'] }
          appearance='transparent'
          onClick={ handleCloseForm }
        >
          { t('buttons.cancel') }
        </Button>
        <Button
          className={ styles['actions-item'] }
          appearance='gold'
          type='submit'
          form='performersSearchForm'
        >
          { t('buttons.search') }
        </Button>
      </div>
    </>
  )
}

export default PerformersSearchForm
