// Common
import React, { FunctionComponent, ReactElement } from 'react'
import styles from './profileDropDown.module.scss'

// Components
import { SmallArrow } from 'assets'

// Actions
import { requestSignOut } from 'redux/actions/authentication.action'

// Components
import AnimatedContainer from 'components/animatedContainer'

// Hooks
import { usePopper } from 'react-popper'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'redux/store'
import { useNavigate } from 'react-router-dom'
import useDropDown from 'hooks/useDropDown'

const ProfileDropDown: FunctionComponent = (): ReactElement => {
  const dispatch = useDispatch()
  const [t] = useTranslation()
  const navigate = useNavigate()
  const { avatarUrl } = useSelector(({ profile: { profile }}) => profile.data)

  const triggerRef = useRef<HTMLDivElement>(null)
  const [popover, setPopover] = useState<HTMLDivElement>(null)
  const popoverRef = useRef<HTMLDivElement>(null)
  const {
    styles: { popper: popperStyles },
    attributes: { popper: popperAttributes }
  } = usePopper(triggerRef.current, popover, { placement: 'bottom' })

  const [opened, handleOpenDropDown] = useDropDown(popoverRef)

  const handleAvatarClick = useCallback(() => {
    navigate('/')
  }, [navigate])

  const handleLogOut = useCallback(() => {
    dispatch(requestSignOut())
  }, [dispatch])

  useEffect(() => {
    popoverRef.current = popover
  }, [popover])

  return (
      <div className={ styles['container'] }>
        <div className={ styles['container-profile-avatar-container'] }>
          <img
            className={ styles['container-profile-avatar-container-image'] }
            src={ avatarUrl }
            alt=''
            onClick={ handleAvatarClick }
          />
        </div>

        <div
            className={ styles['container-drop-down'] }
            ref={ triggerRef }
            onClick={ handleOpenDropDown }
        >
          <SmallArrow className={ styles['container-drop-down-arrow'] } />
        </div>

        <AnimatedContainer show={ opened }>
          <div
              className={ styles['drop-down-options'] }
              ref={ setPopover }
              style={ popperStyles }
              { ... popperAttributes }
          >
            <div
                className={ styles['drop-down-options-item'] }
            >
              { t('headers.about_us') }
            </div>
            <div
                className={ styles['drop-down-options-item'] }
            >
              { t('headers.roles') }
            </div>
            <div
                className={ styles['drop-down-options-item'] }
            >
              { t('headers.contact') }
            </div>
            <div
                className={ styles['drop-down-options-item'] }
            >
              { t('headers.faq') }
            </div>
            <div
                className={ styles['drop-down-options-item'] }
                onClick={ handleLogOut }
            >
              { t('headers.log_out') }
            </div>
          </div>
        </AnimatedContainer>
      </div>
  )
}

export default ProfileDropDown
