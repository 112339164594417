import { hostURL } from 'apiServices/http.service'
import { ChatRoom, ChatRoomsFilters } from 'models/chat-room'
import { User } from 'models/user.model'
import { BaseRestInfiniteScrollService } from './base-rest-infinite-scroll.service'
import { Injector } from 'types/injector.class'
import { WebsocketsService } from './websockets.service'
import { Subject, takeUntil } from 'rxjs'

export class ChatRoomsService extends BaseRestInfiniteScrollService<ChatRoom, ChatRoomsFilters> {
  protected entityName = 'Chat Room'

  private websocketsService: WebsocketsService
  private alive = new Subject<void>()

  constructor(
    injector: Injector,
  ) {
    super(injector)
    this.websocketsService = injector.get(WebsocketsService)

    this.websocketsService.get('roomChange')
      .pipe(takeUntil(this.alive))
      .subscribe(value => {
        if (!this._searchLastFilters.value?.perPage) { return }

        const room = new ChatRoom(value)

        const items = [room, ...(this._searchItems.value.filter(({ id }) => id !== room.id ))]

        if (items.length > this._searchLastFilters.value.perPage * this._searchLastFilters.value.page) {
          items.pop()
          this._searchHasMore.next(true)
        }

        this._searchItems.next(items)
      })
  }

  detach() {
    this.alive.next()
    this.alive.complete()
  }

  async buildUrl() {
    return new URL(`${ hostURL }/chat-rooms`)
  }

  buildItem(item: object) {
    return new ChatRoom(item)
  }

  getItemFromResponse(response: object) {
    return response['chat_room']
  }

  getItemsFromResponse(response: object) {
    return response['chat_rooms']
  }

  async requestOne() {
    throw new Error('API not available')
  }

  async upsert() {
    throw new Error('API not available')
  }

  async create(user: User) {
    super.upsert(new ChatRoom({ user }), { reload: false })
  }
}
