import React, { FunctionComponent, ReactElement, useCallback, useEffect, useState } from 'react'
import styles from './index.module.scss'
import Editor from '../../Admin/components/Editor'
import Button from '../../../components/button'
import { useDispatch } from 'react-redux'
import { requestPolicy, savePolicy } from '../../../redux/actions/admin/policy'
import { useSelector } from '../../../redux/store'


export const PrivacyPolicy: FunctionComponent<any> = (): ReactElement => {
  const dispatch = useDispatch()
  const policy = useSelector(state => state.admin.policy)
  const [body, setBody] = useState(policy.body)

  const handleChange = useCallback((newBody: string) => {
    setBody(newBody)
  }, [])

  const handleSave = useCallback(() => {
    dispatch(savePolicy(body))
  }, [body])

  const handleCancel = useCallback(() => {
    setBody(policy.body)
  }, [policy])

  useEffect(() => {
    dispatch(requestPolicy())
  }, [])

  useEffect(() => {
    setBody(policy.body)
  }, [policy])

  return (
    <div className={ styles['privacy-policy'] }>
      <Editor body={ body } onChange={ handleChange } />
      <div className={ styles['privacy-policy-actions'] }>
        <Button
          onClick={ handleCancel }
        >
          Cancel
        </Button>
        <Button
          onClick={ handleSave }
          appearance='gold'
        >
          Save
        </Button>
      </div>
    </div>
  )
}
