// Hooks
import { useCallback, useMemo, useRef } from 'react'

// Services
import { subject } from 'services/contextMenu.service'

// Types
import { Config, ContextMenuOption } from 'types/contextMenu'

export default function useContextMenu(
  options: ContextMenuOption[],
  config?: Config
) {
  const originRef = useRef<HTMLDivElement>()

  const open = useCallback((event: MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()

    if (!options.length) { return }
 
    subject.next({ options, originRef })
  }, [options])

  const trigger = useMemo(() => {
    switch(config?.trigger) {
      case 'click':
        return 'onClick'
      case 'contextmenu':
        return 'onContextMenu'
      default:
        return 'onClick'
    }
  }, [config])

  return {
    [trigger]: open,
    ref: originRef
  }
}
