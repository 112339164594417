// Common
import React, { Fragment, FunctionComponent, ReactElement, useCallback } from 'react'
import styles from './index.module.scss'

// Types
import { Event as EventModel } from 'models/event.model'

// Hooks
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom'

// Components
import Image from 'components/image'

interface IComponentProps {
  event: EventModel
}

const EventMainData: FunctionComponent<IComponentProps> = ({ event }): ReactElement => {
  const navigate = useNavigate()
  const [t] = useTranslation()

  const handleClickOwner = useCallback(() => {
    navigate('/users/' + event.user.id)
  }, [])

  return (
    <>
      <div className={ styles['event-data'] }>
        <Image
          className={ styles['event-data-image'] }
          src={ event.imageUrl }
        />

        <div className={ styles['event-data-container'] }>
          <span
            className={ styles['event-data-container-owner'] }
            onClick={ handleClickOwner }
          >
            { event.user.profileName }
          </span>

          <h4 className={ styles['event-data-container-title'] }>{ event.title }</h4>

          <span className={ styles['event-data-container-address'] }>{ t('headers.address') }</span>

          { /* TODO need to create a converter of date/time */ }
          <span className={ styles['event-data-container-date'] }>
            { event.from?.toDateString() } { t('datetime.start_at') } { event.from?.toLocaleTimeString() }
          </span>

          <div className={ styles['event-data-container-genres'] }>
            { event.genres.map((genre, index, genres) =>
              <Fragment key={ index }>
                <span className={ styles['event-data-container-genres-item'] }>{ genre }</span>
                { index < genres.length - 1 && <span className={ styles['event-data-container-genres-splitter'] } /> }
              </Fragment>
            ) }
          </div>
        </div>
      </div>

      { /* TODO need to investigate how to truncate long description with ellipses */ }
      <p className={ styles['event-data-description'] }>{ event.description }</p>
    </>
  )
}

export default EventMainData
