// Common
import React, { Fragment, FunctionComponent, ReactElement } from 'react'
import styles from './index.module.scss'
import { formatChatDate } from 'helpers/date'

// Types
import { ChatRoom } from 'models/chat-room'

// Hooks
import { useInject } from 'hooks/useInject'
import { useCallback, useRef, useEffect } from 'react'
import { useSubscribeValue } from 'hooks/useSubscribeValue'
import useSubscribe from 'hooks/useSubscribe'
import { useInfiniteScroll2 } from 'hooks/useInfiniteScroll2'

// Services
import { MessagesService } from 'services/mesages.service'

// Components
import Message from '../message'

interface IComponentProps {
  room: ChatRoom
}

const MessagesListComponent: FunctionComponent<IComponentProps> = ({ room }): ReactElement => {

  const messagesService = useInject(MessagesService)

  const messages = useSubscribeValue(messagesService.items, [])

  useEffect(() => {
    return () => messagesService.clear()
  }, [messagesService])

  const loadMore = useCallback(() => {
    messagesService.requestMany({ perPage: 30, roomId: room.id })
  }, [messagesService, room])

  const scrollContainerRef = useRef<HTMLDivElement>()
  const bottomAnchor = useRef<HTMLDivElement>()

  useInfiniteScroll2({ containerRef: scrollContainerRef, loadMore, reverse: true })

  const lastScrollBottom = useRef<number>(0)

  useSubscribe(messagesService.beforeMessageReceived, () => {
    lastScrollBottom.current = scrollContainerRef.current.scrollHeight -
      scrollContainerRef.current.scrollTop -
      scrollContainerRef.current.offsetHeight
  }, [])

  useSubscribe(messagesService.messageReceived, () => {
    if (!bottomAnchor.current || lastScrollBottom.current > 50) { return }

    bottomAnchor.current.scrollIntoView({ behavior: 'smooth' })
  }, [])

  return (
    <div
      className={ styles['body'] }
      ref={ scrollContainerRef }
    >
      { messages.map((message, index) => (
        <Fragment key={ message.id }>
          { messages[index - 1]?.createdAt?.getDay?.() !== message.createdAt.getDay() && (
            <div className={ styles['body-separator'] }>
              { formatChatDate(message.createdAt) }
            </div>
          ) }

          <Message message={ message } />
        </Fragment>
      )) }

      <div ref={ bottomAnchor } />
    </div>
  )
}

export default MessagesListComponent
