import Language from "../apiServices/models/language.model";
import i18n from '../i18n';

export function getCurrentLanguage(): Language {
  let lang;
  const fallbackLang = { id: 0, title: 'English', code: 'ENG' }
  try {
    lang = JSON.parse(localStorage.getItem('currentLanguage'))
  } catch (e) {
    console.error(e)
  }

  return new Language(lang ?? fallbackLang);
}

export function setCurrentLanguage(language: Language): void {
  localStorage.setItem('currentLanguage', JSON.stringify(language));
  i18n.changeLanguage(language.iso3?.toLocaleLowerCase?.())
}
