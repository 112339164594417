// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carouselSlide_slide__3UHL9 {
  width: 100%;
  height: 100%;
  position: relative;
  flex-shrink: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/carousel/slide/carouselSlide.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,cAAA;AACF","sourcesContent":[".slide {\n  width: 100%;\n  height: 100%;\n  position: relative;\n  flex-shrink: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"slide": `carouselSlide_slide__3UHL9`
};
export default ___CSS_LOADER_EXPORT___;
