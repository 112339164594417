// Common
import React, { Fragment, FunctionComponent, ReactElement } from 'react'
import styles from './index.module.scss'
import cn from 'classnames'
import { format } from 'date-fns'

// Components
import Icon from 'components/icon'
import { ChatRoom } from 'models/chat-room'
import Image from 'components/image'

// Hooks
import { useCallback, useMemo } from 'react'
import { useInject } from 'hooks/useInject'
import { useSubscribeValue } from 'hooks/useSubscribeValue'

// Services
import { ChatStateService } from 'services/chat-state.service'
import { ProfileService } from 'services/profile.service'

interface IComponentProps {
  index: number,
  room: ChatRoom
}

const ChatRoomComponent: FunctionComponent<IComponentProps> = ({ index, room }): ReactElement => {

  const chatStateService = useInject(ChatStateService)
  const profileService = useInject(ProfileService)

  const profile = useSubscribeValue(profileService.profile)

  const handleClick = useCallback(() => {
    chatStateService.setRoom(room)
  }, [room, chatStateService])

  const participants = useMemo(() => (
    room.participants.filter(({ id }) => id !== profile?.id)
  ), [room, profile])

  return (
    <div
      className={ styles['item'] }
      key={ index }
      onClick={ handleClick }
    >
      { participants.map(({ id, avatarUrl }) => (
        <Fragment key={ id }>
          <Image
            className={ styles['item-image'] }
            src={ avatarUrl }
          />

          { index % 3 === 0 && <div className={ styles['item-online'] } /> }
        </Fragment>
      )) }

      <div className={ styles['item-info'] } >
        <div className={ styles['item-info-title'] } >
          { participants.map(({ email }) => email).join(', ') }

          <div className={ styles['filler'] } />

          <div className={ styles['item-info-time'] }>
            { format(room.lastMessageDate || room.createdAt, 'hh:mm a') }
          </div>
        </div>

        <div className={ styles['item-info-description'] } >
          <div className={ styles['item-info-description-text'] } >
            { room.lastMessageText }
          </div>

          <div className={ styles['filler'] } />

          { room.unreadCount === 0 &&
            <>
              <Icon
                name='message-seen'
                className={ styles['item-info-description-seen-icon'] }
                height={ 10 }
                width={ 13 }
                size={ 13 }
              />

              <Icon
                name='message-seen'
                className={ cn(styles['item-info-description-seen-icon'], styles['duplicate']) }
                height={ 10 }
                width={ 13 }
                size={ 13 }
              />
            </>
          }

          { room.unreadCount > 0 &&
            <div className={ styles['item-info-description-unread'] }>
              { room.unreadCount }
            </div>
          }
        </div>
      </div>

      <div className={ styles['item-actions'] }>
        <Icon
          name='kebab'
          className={ styles['item-actions-icon'] }
          size={ 16 }
        />
      </div>
    </div>
  )
}

export default ChatRoomComponent
