import { REQUEST_SIGN_UP_FAILED, REQUEST_SIGN_IN_FAILED, REQUEST_SIGN_OUT_FAILED, REQUEST_VERIFICATION_FAILED, REQUEST_FORGOT_PASSWORD_FAILED, RESET_AUTHENTICATION_ERRORS } from "../actions/authentication.action";

export interface State {
  errors: string
}

const initialState = {
  errors: null
};

export default function authenticationReducer(
  state: State = initialState,
  action
): Partial<State> {
  switch (action.type) {
    case REQUEST_SIGN_UP_FAILED:
    case REQUEST_SIGN_IN_FAILED:
    case REQUEST_SIGN_OUT_FAILED:
    case REQUEST_FORGOT_PASSWORD_FAILED:
    case REQUEST_VERIFICATION_FAILED:
      return {
        errors:
          action.error?.errors?.[0].message ||
          action.error?.message?.map?.(({ constraints }) => Object.values(constraints).join(', ') ).join(', ') ||
          action.error?.message ||
          action.error?.error
        }
    case RESET_AUTHENTICATION_ERRORS:
      return initialState;

    default:
      return state;
  }
}
