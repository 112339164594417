import { User } from './user.model'

export class ChatRoom {
  id: number
  read: boolean
  createdAt: Date
  participants: User[]
  unreadCount: number
  lastMessageDate: Date
  lastMessageText: string

  user: User // this property needed to create new chat room

  constructor(data?) {
    this.id = data?.id
    this.read = data?.read
    this.createdAt = new Date(data?.createdAt)
    this.participants = (data?.participants || []).map(participant => new User(participant))
    this.unreadCount = data?.unreadCount || 0
    this.lastMessageDate = new Date(data?.lastMessageDate)
    this.lastMessageText = data?.lastMessageText

    this.user = data?.user
  }

  asPayloadJSON() {
    return {
      participantId: this.user?.id
    }
  }
}

export interface ChatRoomsFilters {
  page?: number,
  perPage: number
}
