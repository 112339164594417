// Common
import React, { forwardRef, FunctionComponent, LegacyRef } from "react";
import styles from "../index.module.scss";

// Components
import { SmallArrow } from "../../../assets";

// Types
import { OptionType } from "../types/option";

interface IComponentProps {
  trigger: () => void
  selectedOption: OptionType
  placeholder?: string
  showBy?: 'title' | 'value'
  ref?: LegacyRef<HTMLDivElement>
}

const SingleDropDownTrigger: FunctionComponent<IComponentProps> = forwardRef((
  {
    trigger: handleTrigger,
    selectedOption,
    placeholder,
    showBy = 'title'
  }: IComponentProps,
  ref: LegacyRef<HTMLDivElement>
) => (
    <div className={ styles['drop-down-trigger'] } onClick={ handleTrigger } ref={ ref }>
      { selectedOption
          ? <div className={ styles['drop-down-trigger-selected-item'] }>
              { selectedOption.icon &&
              <div className={ styles['drop-down-trigger-selected-item-icon'] }>
                { selectedOption.icon }
              </div>
              }
              <span>
                { showBy === 'title' ? selectedOption?.title : selectedOption?.value }
              </span>
            </div>
          : <span className={ styles['drop-down-trigger-placeholder'] }>{ placeholder }</span>
      }
      <SmallArrow className={ styles['drop-down-trigger-icon'] } />
    </div>
))

SingleDropDownTrigger.displayName = 'SingleDropDownTrigger'
export default SingleDropDownTrigger
