import { RECEIVED_ROLES, REQUEST_ROLES_FAILED } from "../actions/roles.action";
import { Role } from "../../apiServices/models/role.model";

export interface State {
  data: Role[],
  error: string
}

const initialState: State = {
  data: [],
  error: null
};

export default function rolesReducer(
  state: State = initialState,
  action: { type: string, roles: Role[], error: string }
): Partial<State> {
  switch (action.type) {
    case RECEIVED_ROLES:
      return { data: action.roles };

    case REQUEST_ROLES_FAILED:
      return { ...state, error: action.error };

    default:
      return state;
  }
}
