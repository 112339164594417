import { SET_SPINNER_STATE } from "../actions/spinner.action";
const initialState: { showSpinner: boolean } = {
  showSpinner: false
};

export default function spinnerReducer(state = initialState, action: { type: string, showSpinner: boolean }) {
  switch (action.type) {

    case SET_SPINNER_STATE:
      return {
        showSpinner: action.showSpinner
      };

    default:
      return state;
  }
}
