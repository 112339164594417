// Common
import React, { FunctionComponent, ReactElement } from 'react'
import styles from './coverImage.module.scss';

// Components
import { CameraIcon } from 'assets'

// Hooks
import { useDispatch } from 'react-redux'
import { useCallback, useEffect, useRef, useState } from 'react'
import useUpload from 'hooks/useUpload'

// Types
import { Attachment } from 'types/attachment'

// Actions
import { requestUpdateProfile } from 'redux/actions/profile.action'

interface IComponentProps {
  coverImageUrl: string
  editable?: boolean
}

const CoverImage: FunctionComponent<IComponentProps> = ({ coverImageUrl, editable = false }): ReactElement => {
  const dispatch = useDispatch()
  const fileToUpload = useRef<File>()
  const [newCoverImageUrl, setNewCoverImageUrl] = useState<string>(null)
  const { upload, attachment } = useUpload()

  const processFile = useCallback((file: File) => {
    if (!file) { return }

    upload(file)
    fileToUpload.current = file
    setNewCoverImageUrl(URL.createObjectURL(file))
  }, [upload, fileToUpload])

  const handleChange = useCallback(event => {
    processFile(event?.target?.files?.[0])
  }, [processFile])

  useEffect(() => {
    if (!attachment) { return }

    const coverImage: Attachment = {
      fileName: attachment.fileName,
      temp: true,
      fileSize: attachment.fileSize,
      fileType: attachment.fileType,
      id: attachment.id
    }
    dispatch(requestUpdateProfile({ coverImage }))
  }, [attachment, dispatch])

  return (
      <div className={ styles['cover-image'] }>
        { /* TODO change to the placeholder from design */ }
        { (coverImageUrl || newCoverImageUrl) &&
          <img className={ styles['cover-image-image'] } src={ newCoverImageUrl || coverImageUrl } alt='Cover Image' />
        }

        { editable &&
          <label className={ styles['cover-image-trigger'] }>
            <input
              onChange={ handleChange }
              type='file'
              name='avatar'
              accept='image/*'
              capture='user'
            />
            <CameraIcon className={ styles['cover-image-trigger-icon'] } />
          </label>
        }
      </div>
  )
}

export default CoverImage
