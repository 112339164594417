import { objToCamelCase } from '../helpers/snakeToCamelCase'
import { Filters } from '../types/reduxCRUD'

export class Country {
  id: number
  title: string
  phoneCode: string
  iso2: string
  iso3: string

  constructor(object) {
    const camelCasedObject = objToCamelCase(object)

    this.id = camelCasedObject.id
    this.title = camelCasedObject.title
    this.phoneCode = camelCasedObject.phoneCode
    this.iso2 = camelCasedObject.alpha2Code
    this.iso3 = camelCasedObject.alpha3Code
  }
}

export interface CountriesFilters extends Filters {

}
