// Hooks
import { useCallback, useState, useEffect } from 'react'
import { usePopper } from 'react-popper'
import useOutsideClick from 'hooks/useOutsideClick'
import { Placement } from '@popperjs/core'
import { Subject } from 'rxjs'
import { useSubscribeValue } from './useSubscribeValue'

export function useControlledPopover(
  { placement , control }:
  { placement?: Placement, control: Subject<boolean> }
) {
  const [referenceElement, setReferenceElement] = useState(null)

  const [popperElement, setPopperElement] = useState(null)

  const open = useCallback(() => {
    control.next(true)
  }, [control])

  const close = useCallback(() => {
    control.next(false)
  }, [control])

  const { styles, attributes } = usePopper(
    referenceElement,
    popperElement,
    { placement: placement || 'bottom-start' }
  )

  const handleOutsideClick = useCallback(() => {
    control.next(false)
  }, [control])

  useOutsideClick(popperElement, handleOutsideClick)

  useEffect(() => {
    const closeOnEscapeKey = (event: KeyboardEvent) => event.key === 'Escape' && close()

    document.body.addEventListener('keydown', closeOnEscapeKey)

    return () => {
      document.body.removeEventListener('keydown', closeOnEscapeKey)
    }
  }, [close])

  const opened = useSubscribeValue(control)

  return {
    opened,
    handleOpen: open,
    handleClose: close,
    setReferenceElement,
    setPopperElement,
    popoverStyles: styles,
    attributes
  }
}
