import React, { FunctionComponent, ReactElement, useCallback, useEffect } from 'react'
import { useSelector } from 'redux/store'
import { useDispatch } from 'react-redux'

import cn from 'classnames';
import styles from "../settings.module.scss";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Chips from "../../../../../components/chips/chips";
import DropDown from "../../../../../components/dropdown";
import { OptionType } from '../../../../../components/dropdown/types/option'
import { requestGenres } from 'redux/actions/genres.action'

const SettingsGenres: FunctionComponent = (): ReactElement => {
  const { control, setValue } = useFormContext();
  const [t] = useTranslation();
  const selectedGenres = useWatch({ name: 'genres' });
  const dispatch = useDispatch()
  const genres = useSelector(({ genres }) => genres.data );
  
  useEffect(() => {
    console.log("%%%%%%%%%%%%%%%%%%%%%%%%%%")
    dispatch(requestGenres({}))
  }, [])

  const handleRemoveChipsValue = useCallback((removedValue: OptionType) => {
    const newValues = selectedGenres.filter(({ value }) => value !== removedValue.value)
    setValue('genres', newValues)
  }, [setValue, selectedGenres])

  return (
    <div className={ styles['settings-group-row'] }>
      <div className={ cn(
        styles['settings-group-row-item'],
        styles['inline']
      ) }
      >
        <Chips
          className={ styles['settings-group-row-item-chips'] }
          name='genres'
          values={ selectedGenres }
          onRemove={ handleRemoveChipsValue }
          InputTemplate={
            <DropDown
              options={ genres || [] }
              multiselect={ true }
              control={ control }
              name='genres'
              title={ t('titles.type_of_music') }
              placeholder={ t('placeholders.enter_type_of_music') }
              theme='dark'
            />
          }
          inputTemplateClassName={ styles['settings-group-row-item-dropdown'] }
        />
      </div>
    </div>
  )
}

export default SettingsGenres;
