import { RequestParams } from '../models/types'
import { hostURL } from '../http.service'

export function getPolicy(): RequestParams {
  const url = new URL(hostURL + '/admin/policy')

  return { url }
}

export function updatePolicy(body): RequestParams {
  const url = new URL(hostURL + '/admin/policy')

  return {
    url,
    body: { body },
    method: 'PUT'
  }
}
