// Common
import React, { FunctionComponent, ReactElement, forwardRef, LegacyRef } from 'react'
import FallbackImage from 'mocks/profile/coverPhoto.jpeg'

// Hooks
import { useCallback, useState, useEffect } from 'react'

interface IComponentProps {
  src: string
  className?: string
}

const Image: FunctionComponent<IComponentProps> = forwardRef((
  {
    src,
    className,
  }: IComponentProps,
  ref: LegacyRef<HTMLImageElement>
): ReactElement => {

  const [currentSrc, setCurrentSrc] = useState<string>(FallbackImage)
  const [style, setStyle] = useState({})

  useEffect(() => {
    setCurrentSrc(src || FallbackImage)
  }, [src])

  const handleError = useCallback(() => {
    setCurrentSrc(FallbackImage)
    setStyle({ objectPosition: `${ Math.ceil(Math.random() * 100) }% ${ Math.ceil(Math.random() * 100) }%` })
  }, [])

  return (
    <img
      className={ className }
      ref={ ref }
      src={ currentSrc }
      onError={ handleError }
      style={ style }
    />
  )
})

Image.displayName = 'Image'

export default Image
