// Common
import React, { FunctionComponent, ReactElement } from 'react'
import styles from "../settings.module.scss";
import cn from 'classnames';

// Hooks
import { useCallback, useEffect, useMemo } from 'react'
import { useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux'
import { useSelector } from 'redux/store'

// Assets
import { PlusIcon, TrashIcon } from "assets";

// Types
import { Location } from 'models/location.model'

// Components
import Input from 'components/input'
import CheckBox from "components/checkBox/checkBox";
import Button from 'components/button'
import DateRangeInput from 'components/dateRangeInput'
import Label from 'components/label'
import DropDown from 'components/dropdown'

// Actions
import countriesActions from 'redux/actions/countries.action-creators'

const SettingsPerformerLocations: FunctionComponent = (): ReactElement => {
  const dispatch = useDispatch()
  const [t] = useTranslation();

  const { control } = useFormContext()
  const { fields, remove, insert } = useFieldArray({ control, name: 'locations', keyName: 'key' })

  const countries = useSelector(({ countries }) => countries.items)
  const countriesOptions = useMemo(() => countries.map(country =>
    ({ title: country.title, value: country.iso3, id: country.id })
  ), [countries])

  const handleAppend = useCallback((index) => () =>
    insert(index + 1, Location.createItem()), [insert]
  )

  const handleRemove = useCallback((index, fields) => () =>
      fields.length > 1 && remove(index)
    , [remove])

  useEffect(() => {
    dispatch(countriesActions.read({}))

    return () => {
      dispatch(countriesActions.clear())
    }
  }, [dispatch])

  return (
    <div className={ styles['settings-group'] }>
      <div className={ styles['settings-group-row'] }>
        <Label className={ styles['settings-group-row-item'] } appearance='input' text={ t('titles.country_group') } />
        <Label className={ styles['settings-group-row-item'] } appearance='input' text={ t('titles.city_group') } />
        <Label className={ styles['settings-group-row-item'] } appearance='input' text={ t('titles.date') } />
      </div>

        { fields.map((field: Location & { key: string }, index) =>
          <div className={ styles['settings-group-row'] } key={ field.key }>
            <div className={ cn(styles['settings-group-row-item'], styles['multiple']) }>
              <CheckBox
                name={ `locations.${ index }.active` }
                label=''
                control={ control }
              />
              <DropDown
                options={ countriesOptions }
                control={ control }
                name={ `locations.${ index }.country` }
                placeholder={ t('placeholders.enter_country') }
                theme='dark'
              />
            </div>

            <Input
              className={ styles['settings-group-row-item'] }
              control={ control }
              name={ `locations.${ index }.city` }
              placeholder={ t('placeholders.enter_city') }
              theme='dark'
            />

            <div className={ cn(styles['settings-group-row-item'], styles['multiple']) }>
              <DateRangeInput
                className={ styles['settings-group-row-item-date-range-picker'] }
                control={ control }
                fromName={ `locations.${ index }.startDate` }
                toName={ `locations.${ index }.endDate` }
                placeholder={ t('placeholders.enter_date') }
              />

              <Button
                className={ styles['action'] }
                onClick={ handleAppend(index) }
              >
                <PlusIcon />
              </Button>

              <Button
                className={ styles['action'] }
                onClick={ handleRemove(index, fields) }
              >
                <TrashIcon />
              </Button>
            </div>
          </div>
        ) }
    </div>
  )
}

export default SettingsPerformerLocations;
