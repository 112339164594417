// Common
import React, { FunctionComponent } from 'react'
import styles from './index.module.scss'

// Hooks
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next'

// Components
import Label from 'components/label'
import FunsList from './funsList'
import FriendsList from './friendsList'
import WorkingList from './workingList'

export type TabType = 'friends' | 'funs' | 'workingList'
interface IComponentProps {
  initialTab?: TabType
}

export const FriendsNFunsComponent: FunctionComponent<IComponentProps> = ({ initialTab = 'friends' }) => {
  const { t } = useTranslation()
  const [activeTab, setActiveTab] = useState<TabType>(initialTab)
  const tabs = useMemo(() => [
    { title: `${ t('headers.friends') }`, value: 'friends' },
    { title: `${ t('headers.funs') }`, value: 'funs' },
    { title: `${ t('headers.working_list') }`, value: 'workingList' }
  ], [t])

  const handleChangeTab = useCallback((tab: TabType) => () => setActiveTab(tab), [])

  return (
    <>
      <div className={ styles['header'] }>
        { tabs.map(tab =>
          <Label
            className={ styles['header-tab'] }
            key={ tab.value }
            text={ tab.title }
            appearance={ tab.value === activeTab ? 'gold' : 'gold-inactive' }
            onClick={ handleChangeTab(tab.value as TabType) }
          />
        ) }
      </div>

      { activeTab === 'friends' && <FriendsList /> }
      { activeTab === 'funs' && <FunsList /> }
      { activeTab === 'workingList' && <WorkingList /> }
    </>
  )
}

export default FriendsNFunsComponent
