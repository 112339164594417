// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tracks_container__takgm {
  padding: 3rem 0;
  width: calc(100% + 6rem);
  transform: translateX(-3rem);
}`, "",{"version":3,"sources":["webpack://./src/containers/Authenticated/pages/media/tracks/index.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,wBAAA;EACA,4BAAA;AACF","sourcesContent":[".container {\n  padding: 3rem 0; // TODO fix own-profile-body padding\n  width: calc(100% + 6rem);\n  transform: translateX(-3rem);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `tracks_container__takgm`
};
export default ___CSS_LOADER_EXPORT___;
