import { hostURL } from './http.service'
import { RequestParams } from './models/types'
import { Service } from 'types/reduxCRUD'

// Models
import { Track, TracksFilters } from 'models/track.model'

const service: Service<Track> = {
  getItems(filters: TracksFilters): RequestParams {
    const url = new URL(`${ hostURL }/tracks`)

    return {
      url,
      params: {
        page: filters.page,
        per_page: filters.perPage
      }
    }
  },
  getItem(): RequestParams {
    throw new Error('API not available')
  },
  upsertItem(track: Track): RequestParams {
    const url = new URL(hostURL + `/tracks/${ track.id ?? '' }`)

    return {
      url,
      method: 'POST',
      body: track.asPayloadJSON()
    }
  },
  deleteItem(track: Track): RequestParams {
    const url = new URL(`${ hostURL }/tracks/${ track.id }`)

    return {
      url,
      method: 'DELETE'
    }
  }
}

export default service
