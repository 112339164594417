// Common
import React, { FunctionComponent, ReactElement, useCallback, useEffect } from 'react';
import styles from './register.module.scss'

// Hooks
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useSelector } from 'redux/store'
import { useTranslation, UseTranslationResponse } from 'react-i18next'

// Components
import Input from 'components/input'
import Button from 'components/button'
import Dropdown from 'components/dropdown/single-dropdown/single-dropdown'
import CheckBox from 'components/checkBox/checkBox'
import UnderlinedText from 'components/underlinedText/underlinedText'
import AnimatedContainer from 'components/animatedContainer'

// Types
import { Role } from 'apiServices/models/role.model'

// Actions
import { requestSignUp } from 'redux/actions/authentication.action'
import { requestRoles } from 'redux/actions/roles.action'

// Assets
import { FacebookIcon, InstagramIcon, MailIcon, PasswordIcon, ProfileNameIcon, TwitterIcon } from 'assets'

const RegisterForm: FunctionComponent = (): ReactElement => {
  const errors = useSelector(({ authentication: { errors }}) => errors)
  const roles: Role[] = useSelector(({ roles: { data }}) => data)

  const dispatch = useDispatch();
  const [t]: UseTranslationResponse<string> = useTranslation();

  const { control, handleSubmit: handleSubmitForm, setError } = useForm();

  useEffect(() => {
    dispatch(requestRoles())
  }, [dispatch])

  const handleSubmit = useCallback((data) => {
    if (data.password !== data.checkPassword) {
      setError('checkPassword', { type: 'notMatch', message: t('PASSWORD_NOT_MATCHED') })
      return
    }
    dispatch(requestSignUp(data));
  }, [dispatch, setError, t])

  return(
      <form
          id='register-form'
          className={ styles['register-form'] }
          onSubmit={ handleSubmitForm(handleSubmit) }
      >
        <h3>{ t('titles.create_account') }</h3>

        <Input
          className={ styles['register-form-item'] }
          name='email'
          title={ t('titles.email_address') }
          control={ control }
          placeholder={ t('placeholders.enter_email') }
          required={ t('validations.email_blank') as string }
          leftIcon={ <MailIcon /> }
        />
      
        <Input
            className={ styles['register-form-item'] }
            name='profileName'
            title={ t('titles.profile_name') }
            control={ control }
            placeholder={ t('placeholders.enter_profile_name') }
            required={t('validations.profile_name_blank') as string}
            leftIcon={ <ProfileNameIcon /> }
        />

        <Input
            className={ styles['register-form-item'] }
            name='password'
            title={ t('titles.password') }
            password={ true }
            control={ control }
            placeholder={ t('placeholders.enter_password') }
            required={ t('validations.password_blank') as string }
            leftIcon={ <PasswordIcon /> }
        />

        <Input
            className={ styles['register-form-item'] }
            name='checkPassword'
            title={ t('titles.check_password') }
            password={ true }
            control={ control }
            placeholder={ t('placeholders.enter_check_password') }
            required={ t('validations.check_password_blank') as string }
            leftIcon={ <PasswordIcon /> }
        />

        <Dropdown
            className={ styles['register-form-item'] }
            options={ roles }
            control={ control }
            title={ t('titles.choose_role') }
            placeholder={ t('placeholders.role') }
            required={ t('validations.role_blank') as string }
            name='role'
        />

        <div className={ styles['register-form-terms'] }>
          <CheckBox
              className={ styles['register-form-terms-check-box'] }
              control={ control }
              required={ true }
              name='agreeTerms'
              label={ t('labels.agreed_with') }
          />
          <UnderlinedText text={ t('links.terms_and_conditions') } />
        </div>

        { /* TODO Make a form with error handling and highlighting */ }
        <AnimatedContainer show={ !!errors }>
          <div className={ styles['register-form-error-message'] }>{ errors }</div>
        </AnimatedContainer>

        <Button
            className={ styles['register-form-action'] }
            appearance='transparent'
            type='submit'
            form='register-form'
        >
          { t('buttons.register') }
        </Button>

        <span className={ styles['register-form-login-with'] }>{ t('titles.register_with') }</span>
        <div className={ styles['register-form-socials'] }>
          <FacebookIcon />
          <InstagramIcon />
          <TwitterIcon />
        </div>
      </form>
  )
}

export default RegisterForm;
