export const REQUEST_POLICY = 'ADMIN/REQUEST_POLICY'
export const requestPolicy = () => ({
  type: REQUEST_POLICY
})

export const RECEIVED_POLICY = 'ADMIN/RECEIVED_POLICY'
export const receivedPolicy = (data: any) => ({
  type: RECEIVED_POLICY,
  ...data
})

export const REQUEST_SAVE_POLICY = 'ADMIN/REQUEST_SAVE_POLICY'
export const savePolicy = data => ({
  type: REQUEST_SAVE_POLICY,
  data
})

export const REQUEST_POLICY_FAILED = 'ADMIN/REQUEST_POLICY_FAILED'
export const requestPolicyFailed = (error: { message: string, status: number }) => ({
  type: REQUEST_POLICY_FAILED,
  error
})


