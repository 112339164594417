// Common
import React, { FunctionComponent, ReactElement, useCallback, useMemo, useEffect } from 'react';
import styles from "./verification.module.scss";

// Components
import { Logo } from 'assets'
import Button from 'components/button'
import Input from 'components/input'
import AnimatedContainer from 'components/animatedContainer'

// Actions
import { requestResendVerificationCode, requestVerification } from "redux/actions/authentication.action";

// Hooks
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation, UseTranslationResponse } from 'react-i18next'
import { useDispatch } from 'react-redux';
import { useSelector } from 'redux/store'
import { useNavigate } from 'react-router-dom'
import { useLocation } from "react-router";

const VerificationForm: FunctionComponent = (): ReactElement => {

  const [t]: UseTranslationResponse<string> = useTranslation();
  const dispatch = useDispatch();

  const { state: locationState } = useLocation()
  const navigate = useNavigate()

  const { errors } = useSelector(({ authentication }) => authentication);

  const { control, handleSubmit: handleSubmitForm, setFocus } = useForm();
  const inputs = useWatch({ control })

  const array = useMemo(() => new Array(5).fill(null), [])

  useEffect(() => {
    const needToFocus = Object.entries(inputs).find(([key, value]) => !value)
    if (needToFocus) {
      setFocus(needToFocus[0])
    }
  }, [inputs])

  const handleBack = useCallback(() => {
    navigate(-1)
  }, [navigate]);

  const handleConfirm = useCallback((data) => {
    dispatch(requestVerification({
      code: Object.entries(data).reduce((memo, [, code]) => memo + code, ''),
      email: locationState['email'],
      password: locationState['password']
    }))
  }, [dispatch, locationState])

  const handleResendCode = useCallback(() => {
    dispatch(requestResendVerificationCode({ userName: locationState.email }))
  }, [dispatch, locationState.email])

  return (
      <div className={ styles['verification'] }>
        <Logo className={ styles['verification-logo'] } />

        <h2>{ t('titles.enter_code') }</h2>

        <p>{ t('titles.we_sent_code_to') } </p>

        <form
            id='verification-form'
            className={ styles['verification-inputs'] }
            onSubmit={ handleSubmitForm(handleConfirm) }
        >
          { array.map((_, index) =>
            <Input
              className={ styles['verification-inputs-item'] }
              name={ `name${ index }` }
              control={ control }
              required={ t('EMPTY') as string }
              textAlign='center'
              maxLength={ 1 }
              key={ index }
            />
          ) }
        </form>

        { /* TODO Make a form with error handling and highlighting */ }
        <AnimatedContainer show={ !!errors }>
          <div className={ styles['verification-error-message'] }>{ errors }</div>
        </AnimatedContainer>

        <span onClick={ handleResendCode }>
          { t('buttons.resend_confirmation_code') }
        </span>

        <div className={ styles['verification-actions'] }>
          <Button
              onClick={ handleBack }
              border={ false }
              appearance='transparent'
          >
            { t('buttons.back') }
          </Button>
          <Button
              className={ styles['verification-actions-submit'] }
              appearance='transparent'
              type='submit'
              form='verification-form'
          >
            { t('buttons.confirm') }
          </Button>
        </div>
      </div>
  )
}

export default VerificationForm;
