// Common
import React from 'react'
import { PlayerContext } from 'contexts/player.context'

// Actions
import { requestProfile } from './redux/actions/profile.action'
import { requestLanguagesList } from './redux/actions/language.action'

// Types
import { PlayerState } from 'types/playerState'
import navigateSubject from 'types/navigateSubject'

// Components
import AuthenticatedComponent from './containers/Authenticated'
import AdminComponent from './containers/Admin'
import UnAuthenticatedComponent from './containers/UnAuthenticated'
import Spinner from'./components/spinner/spinner.component'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Player from 'components/player'

// Hooks
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { useSelector } from 'redux/store'
import { useNavigate } from 'react-router-dom'
import useSubscribe from 'hooks/useSubscribe'

const playerState = new PlayerState()

function OutsideNavigationHandler() {
  const navigate = useNavigate()

  useSubscribe(navigateSubject, ([path, options]) => {
    navigate(path, options)
  }, [navigate])

  return null
}

function App() {
  const dispatch = useDispatch()

  const profile = useSelector(({ profile: { profile }}) => profile.data)

  useEffect(() => {
    dispatch(requestLanguagesList())
    dispatch(requestProfile())
  }, [dispatch])

  if (profile === undefined) {
    return null
  }

  return (
    <BrowserRouter>
      <OutsideNavigationHandler />
      <PlayerContext.Provider value={ playerState }>
        <Spinner />
        <Routes>
          <Route path='/admin/*' Component={ AdminComponent } />
          <Route path='*' element={ profile?.id ? <AuthenticatedComponent /> : <UnAuthenticatedComponent /> }/>
        </Routes>
        <Player />
      </PlayerContext.Provider>
    </BrowserRouter>
  )
}

export default App
