// Common
import React, { FunctionComponent, ReactElement, useCallback } from 'react'
import { createPortal } from 'react-dom'
import cn from 'classnames'
import styles from './index.module.scss'

// Services
import { subject } from 'services/contextMenu.service'

// Helpers
import { uuidv4 } from 'helpers/base'

// Hooks
import { useState, useLayoutEffect, useEffect } from 'react'
import useTimeout from 'hooks/useTimeout'
import usePopover from 'hooks/usePopover'

// Types
import { Action, ContextMenuOption } from 'types/contextMenu'

const ContextMenuContainer: FunctionComponent = (): ReactElement => {
  const {
    opened,
    handleOpen,
    close,
    setReferenceElement,
    setPopperElement,
    popoverStyles,
    attributes
  } = usePopover({ placement: 'bottom-end' })

  const [options, setOptions] = useState<ContextMenuOption[]>([])

  useEffect(() => {
    const callback = (action: Action) => {
      if (action) {
        setReferenceElement(action.originRef.current)
        setOptions(action.options)
        handleOpen()
      } else {
        close()
      }
    }

    const subscription = subject.subscribe(callback)

    return () => subscription.unsubscribe()
  }, [handleOpen, close, setReferenceElement])

  const [wrapperElement, setWrapperElement] = useState(null)

  const [visible, setVisible] = useState(false)

  useLayoutEffect(() => {
    const wrapperId = uuidv4()

    const element = document.createElement('div')
    element.setAttribute('id', wrapperId)
    document.body.appendChild(element)

    setWrapperElement(element);

    return () => {
      element.parentNode.removeChild(element)
    }
  }, []);

  const [runTimeout, abortTimeout] = useTimeout(250)

  useEffect(() => {
    if (opened) {
      abortTimeout()
      setVisible(true)
    } else {
      runTimeout(() => {
        setVisible(false)
      })
    }
  }, [opened, runTimeout, abortTimeout])

  const handleClick = useCallback((option: ContextMenuOption) => () => {
    option?.action?.()
    close()
  }, [close])

  if (!visible || !wrapperElement) return null;

  return createPortal(
    <div
      className={ styles['container'] }
      ref={ setPopperElement }
      style={ popoverStyles.popper }
      { ...attributes.popper }
    >
      <div className={ cn(styles['options'], { [styles['out']]: !opened }) } >
        {
          options.map((option, index) => (
            <div
              key={ index }
              className={ styles['options-item'] }
              onClick={ handleClick(option) }
            >
              { option.title }
            </div>
          ))
        }
      </div>
    </div>
    ,
    wrapperElement
  )
}

export default ContextMenuContainer
