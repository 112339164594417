// Common
import React, { FunctionComponent } from 'react'
import cn from 'classnames'
import styles from './index.module.scss'
import { Control } from 'react-hook-form'

// Hooks
import { useController } from 'react-hook-form'
import { useCallback } from 'react'

// Components
import Icon from 'components/icon'

interface IComponentProps {
  className?: string
  label?: string
  name?: string
  value?: string
  control?: Control
}

const RadioButton: FunctionComponent<IComponentProps> = ({
  className,
  label,
  name,
  value,
  control
}): React.ReactElement => {
  const {
    field,
    fieldState: { error }
  } = useController({ name, control })

  const handleClick = useCallback(() => {
    field.onChange(value)
  }, [field, value])

  return (
    <div
      className={ cn(styles['container'], className) }
      onClick={ handleClick }
    >
      <Icon
        name={ field.value === value ? 'radio-checked' : 'radio' }
        className={ cn(styles['icon'], { [styles['icon-checked']]: field.value === value }) }
        clickThrough={ true }
      />

      { label }

      { error && (field.value === value || !field.value) &&
        <div className={ styles['error'] }>
          { error.message }
        </div>
      }
    </div>
  )
}

export default RadioButton
