import { ChatRoom } from 'models/chat-room'
import { BehaviorSubject } from 'rxjs'
import { Injectable } from 'types/injectable.interface'

export class ChatStateService implements Injectable {
  private _room = new BehaviorSubject<ChatRoom>(null)

  public opened = new BehaviorSubject<boolean>(false)
  public currentRoom = this._room.asObservable()

  attach?: () => void

  setRoom(room: ChatRoom) {
    this._room.next(room)
  }
}
