// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carousel_carousel__5wluu {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
.carousel_carousel-track__G5DD8 {
  width: 100%;
  height: 100%;
  display: flex;
  transition: transform 0.5s ease-in-out;
}
.carousel_carousel-arrow__3Avoq {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 2rem;
  color: yellow;
  cursor: pointer;
  height: 9rem;
  width: 9rem;
  border-radius: 4.5rem;
  background-color: #340638;
  display: flex;
  align-items: center;
  justify-content: center;
}
.carousel_carousel-arrow-icon__zRLHq {
  color: white;
}
.carousel_carousel-arrow__3Avoq.carousel_back__UjD4w {
  left: 2rem;
  right: initial;
}
.carousel_carousel-navigations__geKkB {
  position: absolute;
  bottom: 3.4rem;
  left: 50%;
  display: flex;
  transform: translateX(-50%);
}
.carousel_carousel-navigations-item__r\\+Mqv {
  background-color: white;
  border-radius: 10px;
  width: 20px;
  height: 20px;
  margin: 10px;
  transition: width 0.3s ease-in-out;
  cursor: pointer;
}
.carousel_carousel-navigations-item__r\\+Mqv:first-child {
  margin-left: 0;
}
.carousel_carousel-navigations-item__r\\+Mqv:last-child {
  margin-right: 0;
}
.carousel_carousel-navigations-item__r\\+Mqv.carousel_active__S6-KA {
  width: 92px;
  border-radius: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/carousel/index.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,gBAAA;EACA,kBAAA;AACF;AACE;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,sCAAA;AACJ;AAEE;EACE,kBAAA;EACA,QAAA;EACA,2BAAA;EACA,WAAA;EACA,aAAA;EACA,eAAA;EACA,YAAA;EACA,WAAA;EACA,qBAAA;EACA,yBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAAJ;AAEI;EACE,YAAA;AAAN;AAGI;EACE,UAAA;EACA,cAAA;AADN;AAKE;EACE,kBAAA;EACA,cAAA;EACA,SAAA;EACA,aAAA;EACA,2BAAA;AAHJ;AAKI;EACE,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;EACA,kCAAA;EACA,eAAA;AAHN;AAKM;EACE,cAAA;AAHR;AAMM;EACE,eAAA;AAJR;AAOM;EACE,WAAA;EACA,mBAAA;AALR","sourcesContent":[".carousel {\n  width: 100%;\n  height: 100%;\n  overflow: hidden;\n  position: relative;\n\n  &-track {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    transition: transform .5s ease-in-out;\n  }\n\n  &-arrow {\n    position: absolute;\n    top: 50%;\n    transform: translateY(-50%);\n    right: 2rem;\n    color: yellow;\n    cursor: pointer;\n    height: 9rem;\n    width: 9rem;\n    border-radius: 4.5rem;\n    background-color: #340638;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n    &-icon {\n      color: white;\n    }\n\n    &.back {\n      left: 2rem;\n      right: initial;\n    }\n  }\n\n  &-navigations {\n    position: absolute;\n    bottom: 3.4rem;\n    left: 50%;\n    display: flex;\n    transform: translateX(-50%);\n\n    &-item {\n      background-color: white;\n      border-radius: 10px;\n      width: 20px;\n      height: 20px;\n      margin: 10px;\n      transition: width .3s ease-in-out;\n      cursor: pointer;\n\n      &:first-child {\n        margin-left: 0;\n      }\n\n      &:last-child {\n        margin-right: 0;\n      }\n\n      &.active {\n        width: 92px;\n        border-radius: 10px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"carousel": `carousel_carousel__5wluu`,
	"carousel-track": `carousel_carousel-track__G5DD8`,
	"carousel-arrow": `carousel_carousel-arrow__3Avoq`,
	"carousel-arrow-icon": `carousel_carousel-arrow-icon__zRLHq`,
	"back": `carousel_back__UjD4w`,
	"carousel-navigations": `carousel_carousel-navigations__geKkB`,
	"carousel-navigations-item": `carousel_carousel-navigations-item__r+Mqv`,
	"active": `carousel_active__S6-KA`
};
export default ___CSS_LOADER_EXPORT___;
