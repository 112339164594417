import { hostURL } from "./http.service";
import { SignUpUserParams, SignInUserParams, RequestParams } from './models/types'

export function signUp({ email, password, role, profileName }: SignUpUserParams): RequestParams {
  const url = new URL(hostURL + '/signup');

  return {
    url,
    method: 'POST',
    body: {
      email,
      password,
      profileName,
      role_id: role?.id,
      role: role?.name
    }
  }
}

export function verification(code: string): RequestParams {
  const url = new URL(hostURL + '/verification')
  url.searchParams.append('code', code);

  return { url }
}

export function resendVerificationCode(userName: string): RequestParams {
  const url = new URL(hostURL + '/verification/resend-code')
  url.searchParams.append('userName', userName)

  return { url }
}

export function signIn({ email, password }: SignInUserParams): RequestParams {
  const url = new URL(hostURL + '/login');

  return {
    url,
    method: 'POST',
    body: { login: email, password }
  }
}

export function signOut(): RequestParams {
  const url = new URL(hostURL + '/logout');

  return {
    url,
    method: 'DELETE'
  }
}

export function forgotPassword(username: string): RequestParams {
  const url = new URL(hostURL + '/reset-password')

  const body = { username }

  return {
    url,
    method: 'POST',
    body
  }
}

export function changeForgotPassword(resetPasswordToken: string, password: string): RequestParams {
  const url = new URL(hostURL + '/change-reset-password')

  const body = { resetPasswordToken, password }

  return {
    url,
    method: 'PUT',
    body
  }
}
