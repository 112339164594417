// Common
import React, { FunctionComponent, ReactElement } from 'react'
import styles from './index.module.scss'
import { format } from 'date-fns'

// Components
import Icon from 'components/icon'
import Modal from 'components/modal'
import PostForm from '../postForm'
import Image from 'components/image'

// Models
import { Post } from 'models/post.model'

// Hooks
import { useCallback, useState } from 'react'
import useContextMenu from 'hooks/useContextMenu'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { useInject } from 'hooks/useInject'

// Services
import { PostsService } from 'services/posts.service'

interface IComponentProps {
  post: Post
}

const PostComponent: FunctionComponent<IComponentProps> = ({ post }): ReactElement => {
  const navigate = useNavigate()

  const { type } = useParams()

  const postsService = useInject(PostsService)

  const deletePost = useCallback(() => {
    postsService.delete(post)
  }, [postsService, post])

  const handleOpen = useCallback(() => {
    navigate(`/blog/${ type }/${ post.id }`)
  }, [navigate, post, type])

  const [formOpen, setFormOpen] = useState(false)

  const handleOpenForm = useCallback((opened: boolean) => () => {
    setFormOpen(opened)
  }, [])

  const contextMenu = useContextMenu([
    { title: 'Edit', action: handleOpenForm(true) },
    { title: 'Delete', action: deletePost }
  ])

  return (
    <div
      className={ styles['item'] }
      onClick={ handleOpen }
    >
      <div className={ styles['item-title'] } >
        { post.title }

        <div className={ styles['filler'] } />

        <Icon
          name='kebab'
          className={ styles['item-title-icon'] }
          size={ 16 }
          { ...contextMenu }
        />
      </div>

      <div className={ styles['item-date'] } >
        { format(post.createdAt, 'dd LLL yyyy') }
      </div>

      <Image
        className={ styles['item-image'] }
        src={ post.imageUrl }
      />

      <div className={ styles['item-description'] } >
        {  post.description }
      </div>

      <Modal
        isOpen={ formOpen }
        onClose={ handleOpenForm(false) }
      >
        <PostForm
          onClose={ handleOpenForm(false) }
          post={ post }
        />
      </Modal>
    </div>
  )
}

export default PostComponent
