// Common
import React, { FunctionComponent, ReactElement } from 'react'
import styles from './index.module.scss'

// Components
import Icon from 'components/icon'
import Image from 'components/image'
import PageTabsHeader from 'components/pageTabsHeader'
import Modal from 'components/modal'
import PhotoForm from '../photoForm'
import Photos from '../photos'

// Hooks
import { useCallback, useEffect, useState } from 'react'
import useContextMenu from 'hooks/useContextMenu'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useInject } from 'hooks/useInject'
import { useSubscribeValue } from 'hooks/useSubscribeValue'
import useSubscribe from 'hooks/useSubscribe'

// Services
import { AlbumsService } from 'services/albums.service'

const AlbumPageComponent: FunctionComponent = (): ReactElement => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const { id } = useParams()

  const albumsService = useInject(AlbumsService)

  const album = useSubscribeValue(albumsService.item)

  const deleteAlbum = useCallback(() => {
    albumsService.delete(album)
  }, [albumsService, album])

  useSubscribe(albumsService.deleted, () => {
    navigate(-1)
  }, [navigate])

  useSubscribe(albumsService.upserted, () => {
    navigate(-1)
  }, [navigate])

  useEffect(() => {
    albumsService.requestOne(parseInt(id))
  }, [albumsService, id])

  const contextMenu = useContextMenu([
    { title: 'Delete', action: deleteAlbum }
  ])

  const [photoFormOpen, setPhotoFormOpen] = useState(false)

  const handleOpenPhotoForm = useCallback((opened: boolean) => () => {
    setPhotoFormOpen(opened)
  }, [])

  return (
    <>
      <PageTabsHeader
        backLabel={ t('buttons.back_to_gallery') }
        buttons={ [
          { title: t('buttons.photo_add'), onClick: handleOpenPhotoForm(true) }
        ] }
      />

      <div className={ styles['meta'] } >
        <Image
          className={ styles['meta-image'] }
          src={ album?.imageUrl }
        />

        <div className={ styles['meta-info'] } >
          <div className={ styles['meta-info-title'] } >
            { album?.title } 
          </div>

          <div className={ styles['meta-info-date'] } >
            10 Jan 2020
          </div>
        </div>

        <Icon
          name='kebab'
          className={ styles['meta-kebab-icon'] }
          size={ 16 }
          { ...contextMenu }
        />
      </div>

      <div className={ styles['description'] } >
        <div className={ styles['description-text'] } >
          { album?.description }
        </div>
      </div>

      <div className={ styles['pictures'] } >
        { album?.id && <Photos albumId={ album.id }></Photos> }
      </div>

      <Modal
        isOpen={ photoFormOpen }
        onClose={ handleOpenPhotoForm(false) }
      >
        <PhotoForm
          albumId={ id }
          onClose={ handleOpenPhotoForm(false) }
        />
      </Modal>
    </>
  )
}

export default AlbumPageComponent
