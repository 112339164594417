// Common
import React, { FunctionComponent, ReactElement } from 'react'
import styles from './index.module.scss'

// Components
import Button from 'components/button'
import Label from 'components/label'
import FileInput from 'components/fileInput'
import Input from 'components/input'
import Textarea from 'components/textarea'

// Hooks
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { useInject } from 'hooks/useInject'
import useSubscribe from 'hooks/useSubscribe'

// Models
import { Post } from 'models/post.model'

// Services
import { PostsService } from 'services/posts.service'
import { useSubscribeValue } from 'hooks/useSubscribeValue'

interface IComponentProps {
  onClose?: () => void,
  post?: Post,
}

const PostForm: FunctionComponent<IComponentProps> = ({
  onClose: handleClose,
  post = new Post()
}): ReactElement => {
  const [t] = useTranslation()

  const postsService = useInject(PostsService)

  const { control, handleSubmit } = useForm(post.asForm())

  const submit = useCallback((data) => {
    postsService.upsert(Post.fromFormData(data))
  }, [postsService])

  useSubscribe(postsService.upserted, () => {
    handleClose()
  }, [handleClose])

  const pending = useSubscribeValue(postsService.upserting)

  return (
    <form
      className={ styles['form'] }
      onSubmit={ handleSubmit(submit) }
    >
      <Label
        appearance='gold'
        text={ post.id ? t('buttons.post_edit') : t('buttons.post_add') }
      />

      <Label
        className={ styles['label'] }
        appearance='input'
        text={ t('headers.post_name') }
      />

      <Input
        control={ control }
        name='title'
        placeholder={ t('headers.title') }
        required={ true }
        theme='light'
      />

      <Label
        className={ styles['label'] }
        appearance='input'
        text={ t('headers.description') }
      />

      <Textarea
        control={ control }
        name='description'
        placeholder={ t('headers.description') }
      />

      <Label
        className={ styles['label'] }
        appearance='input'
        text={ t('headers.image') }
      />

      <FileInput
        control={ control }
        name='image'
      />

      <div className={ styles['buttons'] }>
        <Button
          className={ styles['buttons-item'] }
          appearance='transparent'
          onClick={ handleClose }
        >
          { t('buttons.cancel') }
        </Button>

        <Button
          className={ styles['buttons-item'] }
          appearance='gold-big'
          type='submit'
          loading={ pending }
        >
          { post.id ? t('buttons.post_edit') : t('buttons.post_add') }
        </Button>
      </div>
    </form>
  )
}

export default PostForm
