import { hostURL } from './http.service'
import { RequestParams } from './models/types'
import { Service } from 'types/reduxCRUD'

// Models
import { User } from 'models/user.model'

const service: Service<User> = {
  getItems(): RequestParams {
    throw new Error('API not available')
  },
  getItem(): RequestParams {
    throw new Error('API not available')
  },
  upsertItem(user: User): RequestParams {
    const url = new URL(hostURL + `/followers`)

    return {
      url,
      method: 'POST',
      body: { following_id: user.id }
    }
  },
  deleteItem(user: User): RequestParams {
    const url = new URL(`${ hostURL }/followers`)

    return {
      url,
      method: 'DELETE',
      body: { following_id: user.id }
    }
  }
}

export default service
