// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.attachment_image__ipCuU {
  width: 13rem;
  height: 13rem;
  border: 1px solid #A68FA8;
  position: relative;
  border-radius: 0.4rem;
  overflow: hidden;
}
.attachment_image-content__-H67D {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.attachment_image-icons__sxAqJ {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.attachment_image-icons-download__eJSZu {
  color: white;
  cursor: pointer;
}

.attachment_audio__zUXi6 {
  display: flex;
  flex-direction: row;
  gap: 1.1rem;
  align-items: center;
}
.attachment_audio-play-icon__uLkV3 {
  color: #350339;
  cursor: pointer;
}
.attachment_audio-info__pqqeW {
  color: #350339;
  font-family: Montserrat;
  font-size: 0.8rem;
  letter-spacing: 0;
}
.attachment_audio-download__D2XlJ {
  color: #350339;
  cursor: pointer;
}

.attachment_other__RFL6P {
  display: flex;
  flex-direction: row;
  gap: 1.1rem;
  align-items: center;
}
.attachment_other-info__IM1x4 {
  color: #350339;
  font-family: Montserrat;
  font-size: 0.8rem;
  letter-spacing: 0;
}
.attachment_other-download__6v2H2 {
  color: #350339;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/containers/Authenticated/components/chat/message/attachment/index.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,yBAAA;EACA,kBAAA;EACA,qBAAA;EACA,gBAAA;AACF;AACE;EACE,WAAA;EACA,YAAA;EACA,iBAAA;AACJ;AAEE;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,4CAAA;AAAJ;AAEI;EACE,YAAA;EACA,eAAA;AAAN;;AAKA;EACE,aAAA;EACA,mBAAA;EACA,WAAA;EACA,mBAAA;AAFF;AAIE;EACE,cAAA;EACA,eAAA;AAFJ;AAKE;EACE,cAAA;EACA,uBAAA;EACA,iBAAA;EACA,iBAAA;AAHJ;AAME;EACE,cAAA;EACA,eAAA;AAJJ;;AAQA;EACE,aAAA;EACA,mBAAA;EACA,WAAA;EACA,mBAAA;AALF;AAOE;EACE,cAAA;EACA,uBAAA;EACA,iBAAA;EACA,iBAAA;AALJ;AAQE;EACE,cAAA;EACA,eAAA;AANJ","sourcesContent":[".image {\n  width: 13rem;\n  height: 13rem;\n  border: 1px solid #A68FA8;\n  position: relative;\n  border-radius: .4rem;\n  overflow: hidden;\n\n  &-content {\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n  }\n\n  &-icons {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translateX(-50%) translateY(-50%);\n\n    &-download {\n      color: white;\n      cursor: pointer;\n    }\n  }\n}\n\n.audio {\n  display: flex;\n  flex-direction: row;\n  gap: 1.1rem;\n  align-items: center;\n\n  &-play-icon {\n    color: #350339;\n    cursor: pointer;\n  }\n\n  &-info {\n    color: #350339;\n    font-family: Montserrat;\n    font-size: .8rem;\n    letter-spacing: 0;\n  }\n\n  &-download {\n    color: #350339;\n    cursor: pointer;\n  }\n}\n\n.other {\n  display: flex;\n  flex-direction: row;\n  gap: 1.1rem;\n  align-items: center;\n\n  &-info {\n    color: #350339;\n    font-family: Montserrat;\n    font-size: .8rem;\n    letter-spacing: 0;\n  }\n\n  &-download {\n    color: #350339;\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": `attachment_image__ipCuU`,
	"image-content": `attachment_image-content__-H67D`,
	"image-icons": `attachment_image-icons__sxAqJ`,
	"image-icons-download": `attachment_image-icons-download__eJSZu`,
	"audio": `attachment_audio__zUXi6`,
	"audio-play-icon": `attachment_audio-play-icon__uLkV3`,
	"audio-info": `attachment_audio-info__pqqeW`,
	"audio-download": `attachment_audio-download__D2XlJ`,
	"other": `attachment_other__RFL6P`,
	"other-info": `attachment_other-info__IM1x4`,
	"other-download": `attachment_other-download__6v2H2`
};
export default ___CSS_LOADER_EXPORT___;
