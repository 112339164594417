// Common
import React, { FunctionComponent, ReactElement, memo, useCallback, useMemo } from 'react'
import styles from './sideBar.module.scss'

// Hooks
import { useDispatch } from 'react-redux'
import useIsOwnProfile from 'hooks/useIsOwnProfile'
import { useTranslation } from 'react-i18next'

// Types
import { User } from 'models/user.model'
import { Role } from 'types/role'

// Components
import ProfileAvatar from './profileAvatar/profileAvatar'
import Delimiter from '../../../components/delimiter/delimiter'
import NamesComponent from './names/names'
import FriendsFuns from './friendsFuns/friendsFuns'
import BusinessInfo from './businessInfo/businessInfo'
import GeneralInfo from './generalInfo/generalInfo'
import AboutMe from './aboutMe/aboutMe'
import Button from 'components/button'
import SettingsPersonal from '../../settings/common/settings.personal'

// Actions
import { friendsListActions } from 'redux/actions/black-list-friends.action-creator'

interface IComponentProps {
  user: User
  settingsMode?: boolean
}

const SideBar: FunctionComponent<IComponentProps> = ({ user, settingsMode = false }): ReactElement => {
  const dispatch = useDispatch()
  const [t] = useTranslation()
  const isOwnProfile = useIsOwnProfile()
  const isShowPersonalSettings = useMemo(() => user.role !== Role.REGULAR, [user])

  const handleSubscribe = useCallback(() => {
    dispatch(friendsListActions.upsert(user))
  }, [user, dispatch])

  const handleUnSubscribe = useCallback(() => {
    dispatch(friendsListActions.deleteItem(user))
  }, [user, dispatch])

  return (
    <div className={ styles['side-bar'] }>
      <ProfileAvatar avatarUrl={ user.avatarUrl } editable={ settingsMode } />
      { settingsMode
        ? isShowPersonalSettings &&
        <>
          <Delimiter className={ styles['side-bar-delimiter'] } />
          <SettingsPersonal />
        </>
        : <>
          <NamesComponent
            className={ styles['side-bar-general-info'] }
            typeOfUser={ user.role }
            profileName={ user.profileName }
            genres={ user.genres }
          />

          {
            !isOwnProfile &&
              <div className={ styles['side-bar-actions'] }>
                { user.follower
                  ? <Button appearance='transparent' onClick={ handleUnSubscribe }>
                      { t('buttons.unsubscribe') }
                    </Button>
                  : <Button appearance='gold' onClick={ handleSubscribe }>
                      { t(user.role === Role.REGULAR ? 'buttons.subscribe_in_reply' : 'buttons.subscribe') }
                    </Button>
                }
              </div>
          }

          <Delimiter className={ styles['side-bar-delimiter'] } />

          <FriendsFuns
            friendsCount={ user.friendsCount }
            funsCount={ user.funsCount }
          />

          <Delimiter className={ styles['side-bar-delimiter'] } />

          <BusinessInfo
            generalLocation={ user.generalLocation }
            workingHours={ user.workingDays }
          />

          <Delimiter className={ styles['side-bar-delimiter'] } />

          <GeneralInfo
            email={ user.email }
            phoneNumber={ user.phoneNumber }
            links={ user.links }
          />

          <Delimiter className={ styles['side-bar-delimiter'] } />

          <AboutMe
            aboutMe={ user.aboutMe }
          />
          </>
      }
    </div>
  )
}

export default memo(SideBar)
