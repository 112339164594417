// Common
import React, { FunctionComponent } from 'react';
import cn from 'classnames'
import styles from './index.module.scss'
import { Control } from 'react-hook-form'

// Helpers
import { getMonthLabel, monthsDiff, getDateLabel } from 'helpers/date'

// Hooks
import { useController } from 'react-hook-form'
import { useState, useCallback } from 'react'
import usePopover from 'hooks/usePopover'
import { useTranslation } from 'react-i18next'

// Components
import AnimatedContainer from 'components/animatedContainer'
import Calendar from 'components/calendarMonth'
import Icon from 'components/icon'

interface IComponentProps {
  fromName: string;
  toName: string;
  control: Control
  className?: string;
  placeholder?: string;
}

const DateRangeInput: FunctionComponent<IComponentProps> = ({
  fromName,
  toName,
  control,
  className,
  placeholder,
}): React.ReactElement => {
  const { t } = useTranslation()

  // Popover

  const {
    opened,
    handleOpen,
    close,
    setReferenceElement,
    setPopperElement,
    popoverStyles,
    attributes
  } = usePopover({ placement: 'bottom-start' })

  // Controls

  const {
    field: fromField,
    fieldState: { error: fromError }
  } = useController({ name: fromName, control })

  const {
    field: toField,
    fieldState: { error: toError }
  } = useController({ name: toName, control })

  const [currentControl, setCurrentControl] = useState<'from' | 'to'>('from')

  const handleClickDay = useCallback(date => {
    if (currentControl === 'from') {
      fromField.onChange(date)
      toField.onChange(date)
      setCurrentControl('to')
    } else {
      if (!fromField.value || date < fromField.value.getTime()) return

      toField.onChange(date)
      setCurrentControl('from')
      close()
    }
  }, [currentControl, fromField, toField, close])

  // Calendar state

  const [fromMonth, setFromMonth] = useState(fromField.value || new Date())

  const [toMonth, setToMonth] = useState(
    toField.value && toField.value.getTime() > fromMonth.getTime()
      ? toField.value
      : new Date(fromMonth.getFullYear(), fromMonth.getMonth() + 1)
  )

  const handleChangeMonth = useCallback((control: 'from' | 'to', direction: number) => () => {
    (control === 'from' ? setFromMonth : setToMonth)(currentValue => {
      const newValue = new Date(currentValue.getFullYear(), currentValue.getMonth() + direction, 1)

      if (control === 'from') {
        return monthsDiff(toMonth, newValue) < 1 ? currentValue : newValue
      } else {
        return monthsDiff(newValue, fromMonth) < 1 ? currentValue : newValue
      }
    })
  }, [fromMonth, toMonth])

  return (
    <div
      className={ cn(className, styles['container']) }
    >
      <div ref={ setReferenceElement } style={ { height: 0 } } />

      <div
        className={ styles['input'] }
        onClick={ handleOpen }
      >
        { fromField.value
          ? `${ getDateLabel(t, fromField.value) } - ${ getDateLabel(t, toField.value) }`
          : placeholder
        }
      </div>

      <AnimatedContainer
        show={ opened }
        ref={ setPopperElement }
        className={ styles['popover'] }
        style={ popoverStyles.popper }
        { ...attributes.popper }
      >
        <div>
          <div className={ styles['popover-heading-month'] }>
            <Icon
              name='arrow-left'
              className={ styles['popover-heading-month-icon'] }
              onClick={ handleChangeMonth('from', -1) }
            />
            <div>{ getMonthLabel(t, fromMonth) }</div>
            <Icon
              name={ monthsDiff(toMonth, fromMonth) > 1 ? 'arrow-right' : null }
              className={ styles['popover-heading-month-icon'] }
              onClick={ handleChangeMonth('from', 1) }
            />
          </div>

          <Calendar
            month={ fromMonth }
            onClickDay={ handleClickDay }
            className={ styles['popover-calendar'] }
            ranges={ [{ from: fromField.value, to: toField.value || fromField.value }] }
          />
        </div>

        <div>
          <div className={ styles['popover-heading-month'] }>
            <Icon
              name={ monthsDiff(toMonth, fromMonth) > 1 ? 'arrow-left' : null }
              className={ styles['popover-heading-month-icon'] }
              onClick={ handleChangeMonth('to', -1) }
            />
            <div>{ getMonthLabel(t, toMonth) }</div>
            <Icon
              name='arrow-right'
              className={ styles['popover-heading-month-icon'] }
              onClick={ handleChangeMonth('to', 1) }
            />
          </div>

          <Calendar
            month={ toMonth }
            onClickDay={ handleClickDay }
            className={ styles['popover-calendar'] }
            ranges={ [{ from: fromField.value, to: toField.value || fromField.value }] }
          />
        </div>
      </AnimatedContainer>

      { (fromError || toError) &&
        <div className={ styles['error'] }>
          { (fromError || toError).message }
        </div>
      }
    </div>
  )
}

export default DateRangeInput
