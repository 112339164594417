// Common
import React, { FunctionComponent, ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import styles from './index.module.scss'

// Hooks
import { useLocation } from 'react-router'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useSelector } from 'redux/store'
import { useNavigate } from 'react-router-dom'

// Components
import Label from 'components/label'
import Button from 'components/button'
import Icon from 'components/icon'
import PerformersSearchForm from '../../components/performersSearchForm'
import PrimaryModal from '../../components/primaryModal'
import Delimiter from '../../components/delimiter/delimiter'
import Performer from '../../components/performer'

// Actions
import usersActions from 'redux/actions/users.action-creators'
import { eventPerformersActions } from 'redux/actions/events.action-creators'

// Types
import { Event as EventModel } from 'models/event.model'
import Chips from 'components/chips/chips'
import { OptionType } from 'components/dropdown/types/option'
import { PerformerUser } from 'models/performerUser.model'

interface IComponentProps {

}
const PerformersSearch: FunctionComponent<IComponentProps> = (): ReactElement => {
  const { state } = useLocation() as { state: { filters: unknown, event: EventModel } }
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const performers = useSelector(({ users }) => users.items)
  const [formOpened, setFormOpened] = useState(false)
  const [filters, setFilters] = useState(state.filters)
  const chipsOptions = useMemo(() =>
      Object.entries(filters)
        .reduce((memo, [key, value]) =>
          filters[key]
            ? [...memo,
              {
                value: key,
                title: value.toString()
              }
            ]
            : memo,
        []
      ),
    [filters]
  )
  const handleRemoveChipsOption = useCallback(({ value }: OptionType) => {
    setFilters(filters => ({ ...filters, [value]: '' }))
  }, [])

  const handleFormClose = useCallback(() => {
    setFormOpened(opened => !opened)
  }, [])

  const handleSubmitForm = useCallback((data) => {
    handleFormClose()
    setFilters(data)
  }, [handleFormClose])

  const handlePerformerClick = useCallback((performerId) => {
    navigate('/users/' + performerId)
  }, [navigate])

  const handleAddToListClick = useCallback((performerId) => () => {
    console.info('added to list', performerId)
  }, [])

  const handleChatClick = useCallback((performerId) => () => {
    console.info('chat clicked', performerId)
  }, [])

  const handleInviteClick = useCallback((performer) => () => {
    dispatch(eventPerformersActions.upsert({ ...performer, eventId: state.event.id }))
  }, [dispatch, state.event.id])

  useEffect(() => {
    dispatch(usersActions.read(filters))
  }, [filters, dispatch])

  return (
    <div className={ styles['performers-search'] }>
      <div className={ styles['performers-search-header'] }>
        <Label
          className={ styles['performers-search-header-label'] }
          appearance='gold'
          text={ t('headers.search_performers_result') }
        />

        <Icon
          className={ styles['performers-search-header-action'] }
          name='edit'
          onClick={ handleFormClose }
        />
        <div style={ { display: 'flex' } }>
          <Chips values={ chipsOptions } name='someName' onRemove={ handleRemoveChipsOption } />
        </div>
      </div>

      <Delimiter className={ styles['delimiter'] } />

      <div className={ styles['performers-search-body'] }>
        {
          performers.map((performer, index) =>
            <div className={ styles['performers-search-body-item'] } key={ performer.id + index }>
              <div className={ styles['performers-search-body-item-container'] }>
                <span className={ styles['performers-search-body-item-container-counter'] }>{ index + 1 }.</span>
                <Performer key={ performer.id } performer={ performer as unknown as PerformerUser } onPerformerClick={ handlePerformerClick } />
              </div>

              <div className={ styles['performers-search-body-item-container'] }>
                <Button
                  className={ styles['performers-search-body-item-container-button'] }
                  onClick={ handleAddToListClick(performer.id) }
                >
                  { t('buttons.add_to_list') }
                </Button>
                <Button
                  className={ styles['performers-search-body-item-container-button'] }
                  appearance='transparent'
                  onClick={ handleChatClick(performer.id) }
                >
                  { t('buttons.chat') }
                </Button>
                <Button
                  className={ styles['performers-search-body-item-container-button'] }
                  appearance='gold'
                  onClick={ handleInviteClick(performer) }
                >
                  { t('buttons.invite') }
                </Button>
              </div>
            </div>
          )
        }
      </div>

      <PrimaryModal
        isOpen={ formOpened }
        onClose={ handleFormClose }
        title={ t('headers.add_performer') }
      >
        <PerformersSearchForm filters={ filters } onClose={ handleFormClose } onSubmit={ handleSubmitForm } />
      </PrimaryModal>
    </div>
  )
}

export default PerformersSearch
