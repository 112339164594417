import { BaseModel } from "./base.model";

export default class Genre extends BaseModel {
  id: number | string;
  title: string;
  value: string;
  read: boolean;
  
  constructor(object) {
    super(object);
    this.id = object.id;
    this.title = object.title;
    this.value = object.title;
    this.read = object.read;
  }
}
