// Common
import React, { FunctionComponent, ReactElement } from 'react'
import styles from './index.module.scss'

// Components
import Button from 'components/button'
import Label from 'components/label'
import FileInput from 'components/fileInput'
import Textarea from 'components/textarea'

// Hooks
import { useCallback, useEffect, useRef, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { useSelector } from 'redux/store'
import { useDispatch } from 'react-redux'

// Models
import { Photo } from 'models/photo.model'

// Actions
import actions from 'redux/actions/photos.action-creators'

interface IComponentProps {
  onClose?: () => void,
  albumId?: string
  eventId?: string
}
const PhotoForm: FunctionComponent<IComponentProps> = ({
  onClose: handleClose,
  albumId,
  eventId
}): ReactElement => {
  const [t] = useTranslation()

  const submitted = useRef(false)
  const closed = useRef(false)

  const { error, pending } = useSelector(({ photos }) => photos);

  const dispatch = useDispatch();

  const photo = useMemo(() => new Photo({ albumId, eventId }), [albumId, eventId])

  const { control, handleSubmit } = useForm(photo.asForm())

  const submit = useCallback((data) => {
    submitted.current = true
    dispatch(actions.upsert(Photo.fromFormData(data)));
  }, [dispatch])

  useEffect(() => {
    if (submitted.current && !closed.current && !pending && !error) {
      closed.current = true
      dispatch(actions.clear())
      handleClose()
    }
  }, [error, pending, handleClose, submitted, closed, dispatch])

  return (
    <form
      className={ styles['form'] }
      onSubmit={ handleSubmit(submit) }
    >
      <Label
        appearance='gold'
        text={ t('buttons.photo_add') }
      />

      <Label
        className={ styles['label'] }
        appearance='input'
        text={ t('headers.description') }
      />

      <Textarea
        control={ control }
        name='description'
        placeholder={ t('headers.description') }
      />

      <Label
        className={ styles['label'] }
        appearance='input'
        text={ t('headers.image') }
      />

      <FileInput
        control={ control }
        name='image'
      />

      <div className={ styles['buttons'] }>
        <Button
          className={ styles['buttons-item'] }
          appearance='transparent'
          onClick={ handleClose }
        >
          { t('buttons.cancel') }
        </Button>

        <Button
          className={ styles['buttons-item'] }
          appearance='gold-big'
          type='submit'
          loading={ pending }
        >
          { t('buttons.photo_add') }
        </Button>
      </div>
    </form>
  );
}

export default PhotoForm
