import { hostURL } from 'apiServices/http.service'
import { BaseRestInfiniteScrollService } from './base-rest-infinite-scroll.service'
import { Event, EventsFilters } from 'models/event.model'

export class EventsService extends BaseRestInfiniteScrollService<Event, EventsFilters> {

  protected entityName = 'Event'

  async buildUrl() {
    return new URL(`${ hostURL }/events`)
  }

  buildItem(item: object) {
    return new Event(item)
  }

  getItemFromResponse(response: object) {
    return response['event']
  }

  getItemsFromResponse(response: object) {
    return response['events']
  }
}
