// Common
import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from 'react-redux';
import styles from './roles.module.scss';

// Components
import Carousel from 'components/carousel'
import CarouselSlide from 'components/carousel/slide/carouselSlide'

// Actions
import { requestRoles } from "../../../../redux/actions/roles.action";

// Types
import { Role } from "../../../../apiServices/models/role.model";

interface IComponentProps {
  roles: Role[]
  requestRoles: () => void
  i18n: WithTranslation['i18n']
}

class Roles extends Component<IComponentProps> {
  componentDidMount() {
    this.props.requestRoles();
    this.props.i18n.off('languageChanged', this.props.requestRoles)
  }

  componentWillUnmount() {
    this.props.i18n.off('languageChanged', this.props.requestRoles)
  }

  render(): React.ReactElement<unknown, string | React.JSXElementConstructor<unknown>> {
    const { roles } = this.props;

    return (
        <div className={ styles['roles'] }>
          <Carousel withArrows={ false }>
            { roles.map((role: Role) =>
              <CarouselSlide key={ role.id }>
                <div className={ styles['roles-slide-container'] }>
                  <img
                    className={ styles['roles-slide-container-background'] }
                    src={ role.background_url || role.background }
                    alt={ role.title }
                  />
                  <h1>{ role.title }</h1>

                  <div className={ styles['roles-slide-container-body'] } dangerouslySetInnerHTML={ { __html: role.description } } />
                </div>
              </CarouselSlide>
            ) }
          </Carousel>
        </div>
    )
  }
}

const mapStateToProps = ({ roles: { data }}) => ({
  roles: data
});

const mapDispatchToProps = {
  requestRoles
};

export default withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(Roles)
);
