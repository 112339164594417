// Common
import React, { FunctionComponent, ReactElement } from 'react'
import styles from './index.module.scss'

// Components
import Track from '../track'

// Hooks
import { useEffect, useCallback, useRef, useMemo } from 'react'
import { useInfiniteScroll2 } from 'hooks/useInfiniteScroll2'
import { useInject } from 'hooks/useInject'
import { useSubscribeValue } from 'hooks/useSubscribeValue'

// Services
import { TracksService } from 'services/tracks.service'

const TracksComponent: FunctionComponent = (): ReactElement => {

  const tracksService = useInject(TracksService)

  const tracks = useSubscribeValue(tracksService.items, [])

  useEffect(() => {
    return () => tracksService.clear()
  }, [tracksService])

  const filters = useMemo(() => ({
    perPage: 20
  }), [])

  const loadMore = useCallback(() => {
    tracksService.requestMany({ perPage: 20 })
  }, [tracksService])

  const scrollContainerRef = useRef(document.getElementById('root'))

  useInfiniteScroll2({ containerRef: scrollContainerRef, loadMore })

  return (
    <div className={ styles['container'] }>
      { tracks.map((track, index) => (
        <Track
          key={ index }
          track={ track }
          filters={ filters }
          index={ index }
        />)
      ) }
    </div>
  )
}

export default TracksComponent
