export const REQUEST_ADMIN_CHECK = 'ADMIN/REQUEST_ADMIN_CHECK'
export const requestAdminCheck = () => ({
  type: REQUEST_ADMIN_CHECK
})

export const SUCCESS_ADMIN_CHECK = 'ADMIN/SUCCESS_ADMIN_CHECK'
export const successAdminCheck = () => ({
  type: SUCCESS_ADMIN_CHECK,
})

export const REJECT_ADMIN_CHECK = 'ADMIN/REJECT_ADMIN_CHECK'
export const requestAdminCheckFailed = (error: { message: string, status: number }) => ({
  type: REJECT_ADMIN_CHECK,
  error
})


