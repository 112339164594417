// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Policy_privacy-policy__MC\\+re {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.Policy_privacy-policy-actions__zxFA6 {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 2rem;
  padding: 2rem;
}
.Policy_privacy-policy__MC\\+re .Policy_tox-editor-container__3q5OH .Policy_tox-promotion__Ixezb {
  display: flex;
}`, "",{"version":3,"sources":["webpack://./src/containers/Admin/Policy/index.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;AACF;AACE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;EACA,aAAA;AACJ;AAEE;EACE,aAAA;AAAJ","sourcesContent":[".privacy-policy {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n\n  &-actions {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    column-gap: 2rem;\n    padding: 2rem;\n  }\n\n  .tox-editor-container .tox-promotion {\n    display: flex;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"privacy-policy": `Policy_privacy-policy__MC+re`,
	"privacy-policy-actions": `Policy_privacy-policy-actions__zxFA6`,
	"tox-editor-container": `Policy_tox-editor-container__3q5OH`,
	"tox-promotion": `Policy_tox-promotion__Ixezb`
};
export default ___CSS_LOADER_EXPORT___;
