// Common
import React, { FunctionComponent, ReactElement, useCallback, useMemo, useState } from 'react'
import cn from 'classnames'
import styles from './eventsHeader.module.scss'
import { isNil } from 'helpers/base'

// Components
import Icon from 'components/icon'
import NavLink from 'components/navLink'
import Button from 'components/button'
import EventForm from '../eventForm'
import Modal from 'components/modal'

// Hooks
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import useIsOwnProfile from 'hooks/useIsOwnProfile'

const EventsListHeader: FunctionComponent = (): ReactElement => {
  const [t] = useTranslation()
  const [queryParams] = useSearchParams()
  const isOwnProfile = useIsOwnProfile()
  const isFutureTab = useMemo(() => !isNil(queryParams.get('future')), [queryParams]);
  const [formOpen, setFormOpen] = useState(false)

  const handleFormOpen = useCallback((opened: boolean) => () => {
    setFormOpen(opened)
  }, [])

  return (
    <>
      <div
        className={ cn(styles['header'], styles['events-list']) }
      >
        <div className={ styles['header-nav-container'] }>
          <NavLink to='?future' bySearchParams='future'>{ t('headers.future_events') }</NavLink>
          <NavLink to='?past' bySearchParams='past'>{ t('headers.past_events') }</NavLink>
          {
            isOwnProfile &&
            <NavLink to='?pending' bySearchParams='pending'>{ t('headers.pending_events') }</NavLink>
          }
        </div>

        { isOwnProfile && isFutureTab &&
          <Button
            appearance='gold'
            onClick={ handleFormOpen(true) }
          >
            { t('buttons.create_event') }
          </Button>
        }
        { isOwnProfile && !isFutureTab && <Icon className={ styles['header-icon'] } name='filter' /> }
      </div>

      <Modal
        isOpen={ formOpen }
        onClose={ handleFormOpen(false) }
      >
        <EventForm
          onClose={ handleFormOpen(false) }
        />
      </Modal>
    </>
  )
}

EventsListHeader.displayName = 'EventsListHeader'
export default EventsListHeader
