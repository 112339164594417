import { BaseModel } from "./base.model";

export class FeedbackSubject extends BaseModel{
  id: number;
  title: string;
  key: string;

  constructor(object) {
    super(null);
    this.id = object.id;
    this.title = object.title;
    this.key = object.key;
  }
}
