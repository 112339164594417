import FAQ from "apiServices/models/faq.model";
import { FAQParams } from "../../apiServices/models/types";

export const REQUEST_FAQ = 'REQUEST_FAQ';
export const requestFAQ = (params: FAQParams) => ({
  type: REQUEST_FAQ,
  params
});

export const RECEIVED_FAQ = 'RECEIVED_FAQ';
export const receivedFAQ = (faq: FAQ[]) => ({
  type: RECEIVED_FAQ,
  faq
});

export const REQUEST_FAQ_FAILED = 'REQUEST_FAQ_FAILED';
export const requestFAQFailed = (error: { message: string, status: number }) => ({
  type: REQUEST_FAQ_FAILED,
  error
});
