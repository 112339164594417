// Common
import React, { FunctionComponent, ReactElement } from 'react';
import styles from './index.module.scss';

// Components
import Icon from 'components/icon'
import { Photo } from 'models/photo.model';
import Image from 'components/image'

// Actions
import actions from 'redux/actions/photos.action-creators'

// Hooks
import useContextMenu from 'hooks/useContextMenu'
import { useCallback, useRef, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'redux/store'

interface IComponentProps {
  photo: Photo,
  onClick?: (id) => void;
}

const PhotoComponent: FunctionComponent<IComponentProps> = ({ photo, onClick: handleClick }): ReactElement => {
  const dispatch = useDispatch()

  const deleted = useRef(false)

  const deletePhoto = useCallback(() => {
    dispatch(actions.deleteItem(photo))
    deleted.current = true
  }, [deleted, photo, dispatch])

  const { error, pending } = useSelector(({ photos }) => photos);

  useEffect(() => {
    if (deleted.current && !pending && !error) {
      dispatch(actions.clear())
    }
  }, [error, pending, deleted, dispatch])

  const contextMenu = useContextMenu([
    { title: 'Open', action: handleClick },
    { title: 'Delete', action: deletePhoto }
  ], { trigger: 'contextmenu' })

  return (
    <>
      <div
        className={ styles['item'] }
        onClick={ handleClick }
        { ...contextMenu }
      >
        <Image
          className={ styles['item-image'] }
          src={ photo.imageUrl }
        />

        <div
          className={ styles['item-actions'] }
        >
          <Icon
            className={ styles['item-actions-heart'] }
            name='heart'
            size={ 12 }
          />
          342

          <div className={ styles['filler'] }></div>

          <Icon
            className={ styles['item-actions-messages'] }
            name='messages'
            size={ 16 }
          />
          39
        </div>
      </div>
    </>
  )
}

export default PhotoComponent
