// Common
import React, { FunctionComponent, ReactElement, forwardRef, LegacyRef } from 'react'
import styles from './index.module.scss'
import cn from 'classnames'

// Hooks
import { useCallback } from 'react'

import arrowButton from '!!raw-loader!assets/icons/arrow-button.svg'
import arrowLeft from '!!raw-loader!assets/icons/arrow-left.svg'
import arrowRight from '!!raw-loader!assets/icons/arrow-right.svg'
import attachment from '!!raw-loader!assets/icons/attachment.svg'
import calendar from '!!raw-loader!assets/icons/calendar.svg'
import chat from '!!raw-loader!assets/icons/chat.svg'
import chevronLeft from '!!raw-loader!assets/icons/chevron-left.svg'
import chevronRight from '!!raw-loader!assets/icons/chevron-right.svg'
import download from '!!raw-loader!assets/icons/download.svg'
import edit from '!!raw-loader!assets/icons/edit.svg'
import expand from '!!raw-loader!assets/icons/expand.svg'
import filter from '!!raw-loader!assets/icons/filter.svg'
import fullArrowLeft from '!!raw-loader!assets/icons/back.svg'
import heart from '!!raw-loader!assets/icons/heart.svg'
import kebab from '!!raw-loader!assets/icons/kebab.svg'
import location from '!!raw-loader!assets/icons/location.svg'
import messageSeen from '!!raw-loader!assets/icons/message-seen.svg'
import messages from '!!raw-loader!assets/icons/messages.svg'
import notifications from '!!raw-loader!assets/icons/notifications.svg'
import pause from '!!raw-loader!assets/icons/pause.svg'
import play from '!!raw-loader!assets/icons/play.svg'
import radio from '!!raw-loader!assets/icons/radio.svg'
import radioChecked from '!!raw-loader!assets/icons/radio-checked.svg'
import repeat from '!!raw-loader!assets/icons/repeat.svg'
import rewindLeft from '!!raw-loader!assets/icons/rewind-left.svg'
import rewindRight from '!!raw-loader!assets/icons/rewind-right.svg'
import search from '!!raw-loader!assets/icons/search.svg'
import send from '!!raw-loader!assets/icons/send.svg'
import shuffle from '!!raw-loader!assets/icons/shuffle.svg'
import smile from '!!raw-loader!assets/icons/smile.svg'
import trashBasket from '!!raw-loader!assets/icons/trash-basket.svg'
import upload from '!!raw-loader!assets/icons/upload.svg'
import volume0 from '!!raw-loader!assets/icons/volume-0.svg'
import volume1 from '!!raw-loader!assets/icons/volume-1.svg'
import volume2 from '!!raw-loader!assets/icons/volume-2.svg'
import close from '!!raw-loader!assets/icons/close-circle-svgrepo-com.svg'

const icons = {
  'arrow-button': arrowButton,
  'arrow-left': arrowLeft,
  'arrow-right': arrowRight,
  attachment,
  calendar,
  chat,
  'chevron-left': chevronLeft,
  'chevron-right': chevronRight,
  download,
  edit,
  expand,
  filter,
  'full-arrow-left': fullArrowLeft,
  heart,
  kebab,
  location,
  'message-seen': messageSeen,
  messages,
  notifications,
  pause,
  play,
  radio,
  'radio-checked': radioChecked,
  repeat,
  'rewind-left': rewindLeft,
  'rewind-right': rewindRight,
  search,
  send,
  shuffle,
  smile,
  'trash-basket': trashBasket,
  upload,
  volume0,
  volume1,
  volume2,
  close,
}

interface IComponentProps {
  active?: boolean
  name?: keyof typeof icons
  className?: string
  appearance?: 'interactive'
  height?: number
  width?: number
  size?: number
  badge?: number
  onClick?: (event) => void
  ref?: LegacyRef<HTMLDivElement>,
  clickThrough?: boolean
}

const Icon: FunctionComponent<IComponentProps> = forwardRef((
  {
    active,
    name,
    className,
    appearance,
    height,
    width,
    size = 36,
    badge,
    onClick,
    clickThrough
  }: IComponentProps,
  ref: LegacyRef<HTMLDivElement>
): ReactElement => {
  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    if (!clickThrough) {
      event.preventDefault()
      event.stopPropagation()
    }
    onClick?.(event)
  }, [onClick, clickThrough])

  if (!name) {
    return (
      <div
        className={ cn(styles['simple-container'], className) }
        style={ { cursor: 'default' } }
        ref={ ref }
      />
    )
  }

  switch (appearance) {
    case 'interactive':
      return (
        <div
          className={ cn(styles['interactive-container'], className, { [styles['active']]: active }) }
          style={ { width: `${ size/10 }rem`, height: `${ size/10 }rem` } }
          onClick={ handleClick }
          ref={ ref }
        >
          <div
            className={ styles['interactive-container-icon'] }
            style={ { width: `${ (width || size)/10 }rem`, height: `${ (height || size)/10 }rem` } }
            dangerouslySetInnerHTML={ { __html: icons[name] } }
          />
          { badge > 0 && (
            <div className={ styles['badge'] }>{ badge }</div>
          ) }
        </div>
      )
    default:
      return (
        <div
          className={ cn(styles['simple-container'], className) }
          style={ { width: `${ size/10 }rem`, height: `${ size/10 }rem` } }
          onClick={ handleClick }
          ref={ ref }
        >
          <div
            className={ styles['simple-container-icon'] }
            style={ { width: `${ (width || size)/10 }rem`, height: `${ (height || size)/10 }rem` } }
            dangerouslySetInnerHTML={ { __html: icons[name] } }
          />
        </div>
      )
  }
})

Icon.displayName = 'Icon'

export default Icon
