// Common
import React, { FunctionComponent, ReactElement } from 'react'
import { Route, Routes } from 'react-router-dom'
import styles from './index.module.scss'

// Hooks
import usePushNotification from 'hooks/usePushNotifications'

// Components
import NavigationPanel from "./components/navigationPanel/navigationPanel";
import OwnProfile from './pages/profile/ownProfile'
import UserProfile from './pages/profile/userProfile'
import { CancelAccount } from '../UnAuthenticated/pages/CancelAccount/cancelAccount'
import { PrivacyPolicy } from '../UnAuthenticated/pages/PrivacyPolicy'

const AuthenticatedComponent: FunctionComponent = (): ReactElement => {
  usePushNotification()

  return(
    <>
      <NavigationPanel />

      <div className={ styles['authenticated-page-container'] }>
        <Routes>
          <Route path='/users/:userId/*' element={ <UserProfile /> } />
          <Route path='/cancel' element={ <CancelAccount /> } />
          <Route path='/privacy' element={ <PrivacyPolicy /> } />
          <Route path='*' element={ <OwnProfile /> } />
        </Routes>
      </div>
    </>
  )
}

export default AuthenticatedComponent
