import { put, all, takeLatest, call } from 'redux-saga/effects'
import { REQUEST_ROLES, receivedRoles, requestRolesFiled } from '../actions/roles.action'
import { request } from '../../apiServices/http.service'
import { getRoles } from '../../apiServices/roles.service'
import { Role } from '../../apiServices/models/role.model'

export function* requestRoles() {
  const { response, error } = yield call(request, getRoles())
  if (response) {
    const roles = response.roles.map(role => new Role(role))
    yield put(receivedRoles(roles))
  } else {
    yield put(requestRolesFiled(error))
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(REQUEST_ROLES, requestRoles)
  ])
}
