// Common
import React, {
  FunctionComponent,
  ReactElement,
  Ref,
  useCallback,
  useEffect,
  useRef,
  useState,
  MutableRefObject
} from 'react';
import styles from './accordion.module.scss';
import { SmallArrow } from "../../assets";
import cn from 'classnames';
import useTimeout from "../../hooks/useTimeout";

export interface IAccordionProps {
  title: string;
  body?: string;
  accordionClassName?: string;
  headerClassName?: string;
  bodyClassName?: string;
  // Id of the accordion. Need in case of using accordion group
  id?: number | string;
  // Props from accordion group. No need to pass
  isExpandedFromProps?: boolean;
  onClick?: (id) => void;
}

export const Accordion: FunctionComponent<IAccordionProps> = ({
  title,
  body,
  accordionClassName = null,
  bodyClassName = null,
  headerClassName = null,
  isExpandedFromProps,
  onClick: handleClick,
  id
}): ReactElement => {

  const [isExpanded, setIsExpanded] = useState(false);
  const [accordionHeight, setAccordionHeight] = useState<string>();

  const headerHeight: MutableRefObject<number> = useRef(0);
  const accordionRef: Ref<HTMLDivElement> = useRef();
  const bodyRef: Ref<HTMLDivElement> = useRef();

  const [runTimeout] = useTimeout(300);

  useEffect(() => {
    headerHeight.current = accordionRef.current?.clientHeight;
  }, []);

  const handleExpand = useCallback(() => {
    if (isExpanded) {
      setAccordionHeight(headerHeight.current + 'px');
      runTimeout(() => setIsExpanded(prevState => !prevState))
    } else {
      setIsExpanded(prevState => !prevState);
    }
  }, [isExpanded, setIsExpanded, runTimeout]);

  useEffect(() => {
      setAccordionHeight(isExpandedFromProps || isExpanded ?
          headerHeight.current + bodyRef.current?.clientHeight + 'px'
          :
          headerHeight.current + 'px'
      )
  }, [isExpanded, isExpandedFromProps]);

  return (
      <div
          className={ cn(
              styles['accordion'],
              { [styles['expanded']]: isExpandedFromProps || isExpanded },
              accordionClassName
          ) }
          style={ { height: accordionHeight } }
          ref={ accordionRef }
      >
        <div
            className={ cn(
                styles['accordion-header'],
                headerClassName
            ) }
            onClick={ handleClick ?? handleExpand }
            id={ id as string }
        >
          <span>{ title }</span>
          <Arrow isExpanded={ isExpandedFromProps || isExpanded } />
        </div>

        <div
            className={ cn(
                styles['accordion-body'],
                bodyClassName
            ) }
            ref={ bodyRef }
        >
            {
              (isExpandedFromProps || isExpanded) &&
              body
            }
        </div>
      </div>
  )
};

const Arrow = React.memo(({ isExpanded }: { isExpanded?: boolean }) =>
    <div className={ cn(styles['arrow'], (isExpanded && styles['expanded']) ?? null) }>
      <SmallArrow />
    </div>,
    ((prevProps, nextProps) => prevProps.isExpanded === nextProps.isExpanded)
);
Arrow.displayName = 'Arrow'

export default Accordion;
