// Common
import React, { FunctionComponent, ReactElement, useCallback } from 'react'
import styles from './eventsHeader.module.scss'
import cn from 'classnames'

// Components
import Icon from 'components/icon'
import Button from 'components/button'

// Hooks
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import { useNavigate } from 'react-router-dom'
import useIsOwnProfile from 'hooks/useIsOwnProfile'

const EventPageHeader: FunctionComponent = (): ReactElement => {
  const { state } = useLocation() as { state: { event } }
  const [t] = useTranslation()
  const navigate = useNavigate()
  const isOwnProfile = useIsOwnProfile()

  const handleBack = useCallback(() => navigate(-1), [navigate])

  return (
    <div className={ cn(styles['header'], styles['event-page']) }>
      <div className={ styles['header-nav-container'] }>
        { /* TODO Implement correct push back history */ }
        <Icon
          className={ styles['header-icon'] }
          name='full-arrow-left'
          onClick={ handleBack }
        />

        <span className={ styles['header-title'] }>
          {
            state?.event
              ? `${ t('headers.back_to') } ${ state.event.title }`
              : t('headers.back_to_events_list')
          }
        </span>
      </div>

      {
        isOwnProfile &&
        <Button>{ t('buttons.publish') }</Button>
      }
    </div>
  )
}

EventPageHeader.displayName = 'EventPageHeader'
export default EventPageHeader
