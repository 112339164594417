// Common
import React, { FunctionComponent, ReactElement, useRef } from 'react'
import styles from '../settings.module.scss'

// Components
import TimePicker from 'components/timePicker/timePicker'
import Label from 'components/label'

// Hooks
import { useCallback } from 'react'
import { useFieldArray, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

// Types
import { WorkingDay, daysOfWeek } from 'types/workingDay'

const SettingsBusinessWorkingSchedule: FunctionComponent = (): ReactElement => {
  const customizeClicked = useRef<boolean>(false)
  const { control } = useForm()
  const { fields, insert, remove } = useFieldArray({ control, name: 'workingDays', keyName: 'key' })
  const values = useWatch({ control, name: 'workingDays' })

  const [t] = useTranslation()

  const handleCustomizeClick = useCallback(() => {
    if (customizeClicked.current || values.length > 1) { return }
    customizeClicked.current = true

    const [record] = values as WorkingDay[]
    remove(0)
    daysOfWeek.forEach((day, index) =>
        insert(index, { day, fromTime: record?.fromTime, toTime: record?.toTime })
    )
  }, [insert, remove, values, customizeClicked])

  return (
      <>
        { fields.map((workingDay: WorkingDay & { key: string }, index) =>
            <div className={ styles['settings-group-row'] } key={ workingDay.key }>
              <Label
                className={ styles['settings-group-row-item'] }
                appearance='gold-inactive'
                text={ t(`datetime.${ workingDay.day }`) }
              />

              <div className={ styles['settings-group-row-item'] }>
                <Label
                  text={ t('datetime.start_at') }
                  appearance='gold-inactive'
                />
                <TimePicker
                  control={ control }
                  name={ `workingDays.${ index }.fromTime` }
                />
              </div>
              <div className={ styles['settings-group-row-item'] }>
                <Label
                  text={ t('datetime.end_at') }
                  appearance='gold-inactive'
                />
                <TimePicker
                  control={ control }
                  name={ `workingDays.${ index }.toTime` }
                />
              </div>
            </div>
        ) }

        <Label
          text={ t('buttons.customize') }
          appearance='heading'
          onClick={ handleCustomizeClick }
        />
      </>
  )
}

export default SettingsBusinessWorkingSchedule;
