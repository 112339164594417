export enum InvitationStatus {
  PENDING,
  ACCEPTED,
  REJECTED
}

export interface EventInvitation {
  requestedBy: number
  status: InvitationStatus
  userId: number
  eventId: number
}
