// Common
import React, { FunctionComponent, ReactElement } from 'react'
import styles from './index.module.scss'
import cn from 'classnames'

// Components
import CarouselSlide from './slide/carouselSlide'
import Icon from 'components/icon'

// Hooks
import { useMemo, useCallback, useRef, useState, useEffect } from 'react'

const AUTOPLAY_INTERVAL = 6000;

interface IComponentProps {
  inline?: boolean
  infinity?: boolean
  autoplay?: boolean
  withArrows?: boolean
  children?: React.ReactElement<CarouselSlide>[] | React.ReactElement<CarouselSlide>
}

const Carousel: FunctionComponent<IComponentProps> = ({ infinity, autoplay, withArrows, children }): ReactElement => {
  const autoPlayTimer = useRef<ReturnType<typeof setTimeout>>(null)

  const [currentIndex, setCurrentIndex] = useState(0)

  const numberOfSlides = useMemo(() => React.Children.count(children), [children])

  const constraintIndex = useCallback(index => {
    if (infinity) {
      return (numberOfSlides + index) % numberOfSlides
    } else {
      return Math.min(Math.max(0, index), numberOfSlides)
    }
  }, [infinity, numberOfSlides])

  const handleNextSlide = useCallback((direction: 1 | -1) => () => {
    setCurrentIndex(index => constraintIndex(index + direction))
  }, [constraintIndex])

  const handleChangeSlide = useCallback(index => () => {
    setCurrentIndex(constraintIndex(index))
  }, [constraintIndex])

  useEffect(() => {
    if (infinity && autoplay) {
      autoPlayTimer.current = setInterval(() => handleNextSlide(1)(), AUTOPLAY_INTERVAL)
    }

    return () => {
      if (autoPlayTimer.current) {
        clearInterval(autoPlayTimer.current);
      }
    }
  }, [infinity, autoplay, autoPlayTimer, handleNextSlide])

  return (
    <div className={ styles['carousel'] }>
      <div
        className={ styles['carousel-track'] }
        style={ { transform: `translateX(-${ 100 * currentIndex }%)` } }
      >
        { children }
      </div>

      {
        withArrows && (infinity || currentIndex !== 0) &&
        <div
          className={ cn(styles['carousel-arrow'], styles['back']) }
          onClick={ handleNextSlide(-1) }
        >
          <Icon
            name='chevron-left'
            className={ styles['carousel-arrow-icon'] }
            size={ 30 }
          />
        </div>
      }

      {
        withArrows && (infinity || currentIndex !== numberOfSlides - 1) &&
        <div
          className={ cn(styles['carousel-arrow']) }
          onClick={ handleNextSlide(1) }
        >
          <Icon
            name='chevron-right'
            className={ styles['carousel-arrow-icon'] }
            size={ 30 }
          />
        </div>
      }

      <div className={ styles['carousel-navigations'] }>
        { Array.from(Array(numberOfSlides).keys()).map(index =>
          <div
            className={ cn(styles['carousel-navigations-item'], { [styles['active']]: index === currentIndex }) }
            key={ index }
            onClick={ handleChangeSlide(index) }
          />
        ) }
      </div>
    </div>
  );
}

Carousel.defaultProps = {
  infinity: true,
  autoplay: false,
  withArrows: true,
}

export default Carousel;
