// Common
import React, { FunctionComponent, memo } from 'react'
import cn from 'classnames';
import styles from './friendsFuns.module.scss';

// Hooks
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

// Components
import Modal from 'components/modal'
import FriendsComponent, { TabType } from '../../../../pages/friendsNFuns'

interface IComponentProps {
  friendsCount: number
  funsCount: number
  className?: string
}

const FriendsFuns: FunctionComponent<IComponentProps> = ({
  friendsCount = 0,
  funsCount = 0,
  className
}) => {
  const [t] = useTranslation();
  const [friendsFunsModal, setFriendsFunsModal] = useState<TabType>(null)

  const handleClick = useCallback((tab: TabType) => () => {
    setFriendsFunsModal(tab)
  }, [])

  const handleClose = useCallback(() => {
    setFriendsFunsModal(null)
  }, [])

  return (
    <>
      <div className={ cn(styles['friends-funs'], className) }>
        <div
          className={ styles['friends-funs-group'] }
          onClick={ handleClick('friends') }
        >
          <span>{ friendsCount }</span>
          <span className={ styles['friends-funs-group-text'] }>{ t('headers.friends') }</span>
        </div>

        <div
          className={ styles['friends-funs-group'] }
          onClick={ handleClick('funs') }
        >
          <span>{ funsCount }</span>
          <span className={ styles['friends-funs-group-text'] }>{ t('headers.funs') }</span>
        </div>
      </div>

      <Modal isOpen={ !!friendsFunsModal } onClose={ handleClose }>
        <FriendsComponent initialTab={ friendsFunsModal } />
      </Modal>
    </>
  )
}

export default memo(FriendsFuns);
