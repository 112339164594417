// Common
import React, { FunctionComponent, ReactElement } from 'react'
import styles from './faq.module.scss'

// Components
import Input from 'components/input'
import Accordion from 'components/accordion/accordion'
import AccordionsGroup from 'components/accordionsGroup/accordionsGroup'
import Tabs from 'components/tabs'
import Icon from 'components/icon'

// Hooks
import { useTranslation } from 'react-i18next'
import { useCallback, useState, useEffect, useMemo } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useSelector } from 'redux/store'
import { useDispatch } from 'react-redux'

// Actions
import { requestFAQ } from 'redux/actions/faq.action'
import { requestRoles } from 'redux/actions/roles.action'

const Faq: FunctionComponent = (): ReactElement => {
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation();

  const { control } = useForm();

  const roles = useSelector(({ roles }) => roles.data);
  const faq = useSelector(({ faq }) => faq.data);

  const [currentTab, setCurrentTab] = useState('all');
  const searchQuery = useWatch({ name: 'searchQuery', control })

  const debouncedRequestFAQ = useCallback(() => {
    dispatch(requestFAQ({
      role: roles.find(({ name }) => name === currentTab),
      searchQuery
    }))
  }, [roles, currentTab, searchQuery, dispatch])

  const getTranslatedContentContent = useCallback(() => {
    dispatch(requestRoles())
    debouncedRequestFAQ();
  }, [])

  const handleChangeTab = useCallback((_, currentTabName) => {
    if (currentTabName === currentTab) return;

    setCurrentTab(currentTabName)
  }, [currentTab])

  useEffect(() => {
    dispatch(requestFAQ({}))
    dispatch(requestRoles())
    i18n.on('languageChanged', getTranslatedContentContent)
    
    return (() => {
      i18n.off('languageChanged', getTranslatedContentContent)
    })
  }, [dispatch, getTranslatedContentContent, i18n]);


  useEffect(() => {
    debouncedRequestFAQ()
  }, [currentTab, searchQuery, debouncedRequestFAQ]);


  const tabs = useMemo(() => ([
    { title: t('buttons.role_all'), key: 'all' },
    { title: t('buttons.role_fun'), key: 'regular' },
    { title: t('buttons.role_performer'), key: 'performer' },
    { title: t('buttons.role_business'), key: 'business' }
  ]), [t]);

  return (
    <div className={ styles['faq'] }>
      <h1>{ t('headers.faq_title') }</h1>

      <Tabs
        className={ styles['faq-tabs'] }
        onSelect={ handleChangeTab }
        tabs={ tabs }
        appearance='dark'
      />

      <Input
        className={ styles['faq-search'] }
        control={ control }
        name='searchQuery'
        theme='dark'
        appearance='large'
        rightIcon={
          <Icon
            name='search'
            width={ 29 }
            height={ 29 }
            size={ 29 }
          />
        }
        placeholder={ t('headers.faq_search') }
      />

      <div className={ styles['faq-items-container'] }>
        <AccordionsGroup>
          { faq.map(item => (
              <Accordion
                title={ item.title }
                body={ item.body }
                accordionClassName={ styles['faq-items-container-item'] }
                key={ item.id }
                id={ item.id }
              />
          ))
          }
        </AccordionsGroup>
      </div>
    </div>
  );
}

export default Faq
