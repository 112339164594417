// Common
import React, { FunctionComponent, ReactElement, ReactNode } from 'react'
import styles from './index.module.scss'
import cn from 'classnames'

interface IComponentProps {
  className?: string
  appearance?: 'gold' | 'gold-inactive' | 'input' | 'gold-strikethrough' | 'heading',
  text?: string, 
  onClick?: (event) => void
  children?: ReactNode
}

const Label: FunctionComponent<IComponentProps> = ({
  className,
  appearance = 'gold',
  text,
  onClick: handleClick
}): ReactElement => {
  return (
    <div
      className={ cn(styles[appearance], className) }
      onClick={ handleClick }
    >
      <div className={ styles['line'] }>{ text }</div>

      <div className={ styles['text'] }>{ text }</div>

      { text }
    </div>
  );
}

export default Label;
