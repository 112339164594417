// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div {
  transition: opacity 0.3s ease-in-out;
}
div.animatedContainer_visible__XHFK4 {
  opacity: 1;
}
div.animatedContainer_hidden__MdlY0 {
  opacity: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/animatedContainer/index.module.scss"],"names":[],"mappings":"AAAA;EACE,oCAAA;AACF;AACE;EACE,UAAA;AACJ;AAEE;EACE,UAAA;AAAJ","sourcesContent":["div {\n  transition: opacity .3s ease-in-out;\n\n  &.visible {\n    opacity: 1;\n  }\n\n  &.hidden {\n    opacity: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"visible": `animatedContainer_visible__XHFK4`,
	"hidden": `animatedContainer_hidden__MdlY0`
};
export default ___CSS_LOADER_EXPORT___;
