// Common
import React, { FunctionComponent } from "react";
import { Control } from "react-hook-form";

// Components
import SingleDropDown from "./single-dropdown/single-dropdown";
import MultiselectDropDown from "./multiselect-dropdown/multiselect-dropdown";

// Types
import { OptionType } from "./types/option";
import { IMultiselectDropDown, ISingleDropDown } from "./types/dropdown";

interface IComponentProps {
  options: OptionType[];
  placeholder?: string;
  name: string;
  control: Control
  className?: string;
  excludeSelectedOption?: boolean;
  showBy?: 'title' | 'value';
  title?: string;
  required?: boolean | string;
  theme?: 'light' | 'dark';
  multiselect?: boolean;
  defaultValue?: OptionType | OptionType[]
}

// Imports are used for default export in other components. Named export are required for argTypes of StoryBook
export const DropDown: FunctionComponent<IComponentProps> = ({
  multiselect = false,
  excludeSelectedOption,
  ...props
}) => multiselect
    ? <MultiselectDropDown { ...props as IMultiselectDropDown } />
    : <SingleDropDown { ...props as ISingleDropDown } excludeSelectedOption={ excludeSelectedOption } />

export default DropDown;
