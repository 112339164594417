// Common
import React, { FunctionComponent, MouseEventHandler, ReactElement, useCallback } from 'react'
import styles from './index.module.scss'

// Types
import { PerformerUser } from 'models/performerUser.model'

interface IComponentProps {
  performer: PerformerUser
  onPerformerClick: (id: number, event: MouseEventHandler) => void
}

const Performer: FunctionComponent<IComponentProps> = ({ performer, onPerformerClick }): ReactElement => {
  const handlePerformerClick = useCallback((id: number) => (event) =>
    onPerformerClick(id, event),
    [onPerformerClick]
  )

 return (
   <div
     className={ styles['performer'] }
   >
     <img
       className={ styles['performer-image'] }
       src={ performer.avatarUrl }
       alt={ performer.profileName }
       onClick={ handlePerformerClick(performer.id) }
     />
     <div className={ styles['performer-data'] }>
       <span className={ styles['performer-data-name'] }>{ performer.profileName }</span>
       <span>{ performer.role }</span>
     </div>
   </div>
 )
}

export default Performer
