// Common
import React, { FunctionComponent, ReactElement } from 'react'
import styles from './index.module.scss'

// Components
import Icon from 'components/icon'
import Input from 'components/input'
import Notification from './notification'

// Hooks
import { useEffect, useCallback, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useInfiniteScroll2 } from 'hooks/useInfiniteScroll2'
import { useInject } from 'hooks/useInject'
import { useSubscribeValue } from 'hooks/useSubscribeValue'

// Services
import { NotificationsService } from 'services/notifications.service'

interface IComponentProps {

}

const Notifications: FunctionComponent<IComponentProps> = (): ReactElement => {
  const { control } = useForm({})

  const notificationsService = useInject(NotificationsService)

  const notifications = useSubscribeValue(notificationsService.items, [])

  useEffect(() => {
    return () => notificationsService.clear()
  }, [notificationsService])

  const loadMore = useCallback(() => {
    notificationsService.requestMany({ perPage: 20 })
  }, [notificationsService])

  const scrollContainerRef = useRef()

  useInfiniteScroll2({
    containerRef: scrollContainerRef,
    loadMore
  })

  return (
    <div
      className={ styles['container'] }
      ref={ scrollContainerRef }
    >
      <Input
        className={ styles['search-input'] }
        control={ control }
        appearance='medium'
        theme='burgundy'
        name='query'
        leftIcon={
          <Icon
            className={ styles['search-input-icon'] }
            name='search'
            size={ 17 }
          />
        }
        rightIcon={
          <Icon
            className={ styles['search-input-kebab'] }
            name='kebab'
            size={ 16 }
          />
        }
      />

      { notifications.map((notification, index) => <Notification key={ index } index={ index } notification={ notification } />) }
    </div>
  )
}

export default Notifications
