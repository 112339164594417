// Common
import { put, call, takeEvery, all, takeLatest } from 'redux-saga/effects'
import toast from 'react-hot-toast'

// Actions
import {
  REQUEST_SIGN_UP, REQUEST_SIGN_IN, REQUEST_SIGN_OUT, REQUEST_VERIFICATION, REQUEST_FORGOT_PASSWORD, REQUEST_RESEND_VERIFICATION_CODE,
  REQUEST_CHANGE_FORGOT_PASSWORD,
  requestSignUpFailed, requestSignInFailed, requestSignOutFailed, requestVerificationFailed, requestForgotPasswordFailed,
  requestResendVerificationCodeFailed, requestChangeForgotPasswordFailed
} from '../actions/authentication.action'
import { resetProfile } from '../actions/profile.action'

// Services
import {
  forgotPassword, signUp, signIn, signOut, verification, changeForgotPassword,
  resendVerificationCode as resendVerificationCodeService
} from '../../apiServices/authentication.service'
import { request } from '../../apiServices/http.service'

// Sagas
import { requestProfile } from './profile.saga'

// Types
import { SignUpUserParams, SignInUserParams } from '../../apiServices/models/types'
import navigate from '../../types/navigateSubject'

function* requestSignUp({ payload }: { type: string, payload: SignUpUserParams }) {
  const { response, error } = yield call(request, signUp(payload))

  if (response) {
    navigate.next(['/authorization/code', { state: { email: payload.email, password: payload.password }}])
  } else {
    yield put(requestSignUpFailed(error))
  }
}

export function* requestSignIn({ payload }: { type: string, payload: SignInUserParams }) {
  const { response, error } = yield call(request, signIn(payload))

  if (response) {
    localStorage.setItem('sessionToken', response.session_token)

    navigate.next(['/', { replace: true }])

    yield requestProfile()
  } else {
    error.needVerify
      ? navigate.next(['/authorization/code', { state: { email: payload.email, password: payload.password }}])
      : yield put(requestSignInFailed(error))
  }
}

function* requestSignOut() {
  const { response, error } = yield call(request, signOut())

  if (response) {
    yield put(resetProfile())
    localStorage.removeItem('sessionToken')
    document.cookie = 'session_token='
  } else {
    yield put(requestSignOutFailed(error))
  }
}

function* requestForgotPassword({ email }: { type: string, email: string }) {
  const { response, error } = yield call(request, forgotPassword(email))
  if (response) {
    navigate.next(['/', { replace: true }])
  } else {
    yield put(requestForgotPasswordFailed(error))
  }
}

function* requestChangeForgotPassword({ payload: { token, password }}: { type: string, payload: { token: string, password: string }}) {
  const { response, error } = yield call(request, changeForgotPassword(token, password))
  if (response) {
    navigate.next(['/login', { replace: true }])
  } else {
    yield put(requestChangeForgotPasswordFailed(error))
  }
}

function* requestVerification({ payload }: { type: string, payload: { code: string, email: string, password: string } } ) {
  const { response, error } = yield call(request, verification(payload.code))

  if (response) {
    if (response.session_token) { //  new API
      localStorage.setItem('sessionToken', response.session_token)
      navigate.next(['/', { replace: true }])
      yield requestProfile()
    } else { // old API
      yield requestSignIn({ type: '', payload  })
    }

  } else {
    yield put(requestVerificationFailed(error))
  }
}

function* resendVerificationCode({ payload }: { type: string, payload: { userName: string }}) {
  const { response, error } = yield call(request, resendVerificationCodeService(payload.userName))

  if (response) {
    toast.success('Code has sent to your email', { position: 'bottom-right' })
  } else {
    yield requestResendVerificationCodeFailed(error)
  }

}

export default function* rootSaga() {
  yield all([
    yield takeEvery(REQUEST_SIGN_UP, requestSignUp),
    yield takeEvery(REQUEST_SIGN_IN, requestSignIn),
    yield takeEvery(REQUEST_SIGN_OUT, requestSignOut),
    yield takeEvery(REQUEST_FORGOT_PASSWORD, requestForgotPassword),
    yield takeEvery(REQUEST_CHANGE_FORGOT_PASSWORD, requestChangeForgotPassword),
    yield takeEvery(REQUEST_VERIFICATION, requestVerification),
    yield takeLatest(REQUEST_RESEND_VERIFICATION_CODE, resendVerificationCode),
  ])
}
