// Common
import React, { FunctionComponent, ReactElement, useCallback } from 'react'
import cn from 'classnames';
import styles from './chips.module.scss';

// Types
import { OptionType } from "../dropdown/types/option";

// Components
import Button from '../button'
import Icon from '../icon'

interface IComponentProps {
  values: OptionType[]
  name: string
  onRemove: (removedValue: OptionType, event) => void
  height?: string
  InputTemplate?: ReactElement
  className?: string
  inputTemplateClassName?: string
}

export const Chips: FunctionComponent<IComponentProps> = ({
  values,
  height,
  onRemove,
  InputTemplate,
  className,
  inputTemplateClassName
}): ReactElement => {
  const handleRemove = useCallback((removedValue) => (event) => {
    onRemove(removedValue, event)
  }, [onRemove])

  return (
    <div className={ cn(
      styles['chips'],
      className
    ) }
    >
      <div className={ cn(
        styles['chips-input-template-container'],
        inputTemplateClassName
      ) }
      >
        { InputTemplate }
      </div>

      { values?.map(option =>
        <div className={ styles['chips-chip'] } key={ option.value }>
          <span className={ styles['chips-chip-title'] }>{ option.title }</span>
          <Button
            className={ styles['chips-chip-action'] }
            onClick={ handleRemove(option) }
            height={ height }
          >
            <Icon className={ styles['chips-chip-action-icon'] } name='trash-basket' />
          </Button>
        </div>
      ) }
    </div>
  )
}

export default Chips;
