import React, { useState, useEffect, FunctionComponent, forwardRef, LegacyRef, CSSProperties, ReactNode } from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

// Hooks
import useTimeout from 'hooks/useTimeout'

interface IComponentProps {
  show: boolean,
  animationDuration?: number
  className?: string
  transitionTimingFunction?: string
  style?: CSSProperties
  ref?: LegacyRef<HTMLDivElement>
  children?: ReactNode
}

const AnimatedContainer: FunctionComponent<IComponentProps> = forwardRef((
  {
    show,
    children,
    className,
    animationDuration = 300,
    transitionTimingFunction,
    style
  }: IComponentProps,
  ref: LegacyRef<HTMLDivElement>
) => {
  const [innerChildren, setInnerChildren] = useState(null);
  const [runTimeout, abortTimeout] = useTimeout(animationDuration);

  useEffect(() => {
    abortTimeout();

    if (show) {
     setInnerChildren(children)
    } else {
      runTimeout(() => {
        setInnerChildren(null)
      })
    }
  }, [show, children, abortTimeout, runTimeout]);

  return (
    <div
      className={ cn(
        show ? styles['visible'] : styles['hidden'],
        className
      ) }
      style={
        {
          transitionTimingFunction,
          transitionDuration: `${ animationDuration }ms`,
          ...style
        }
      }
      ref={ ref }
    >
      { innerChildren }
    </div>
  )
})

AnimatedContainer.displayName = 'AnimatedContainer'

export default AnimatedContainer
