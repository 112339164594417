import { put, all, call, takeLatest } from 'redux-saga/effects'
import { REQUEST_ABOUT_US, receivedAboutUs, requestAboutUsFailed } from '../actions/aboutUs.action'
import { request } from '../../apiServices/http.service'
import { getAboutUs } from '../../apiServices/aboutUs.service'


export function* requestAboutUs() {
  const { response, error } = yield call(request, getAboutUs())

  if (response) {
    yield put(receivedAboutUs(response.about.body))
  } else {
    put(requestAboutUsFailed(error))
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(REQUEST_ABOUT_US, requestAboutUs),
  ])
}
