import { RECEIVED_ABOUT_US, REQUEST_ABOUT_US_FAILED } from "../actions/aboutUs.action";
import { aboutUsContent } from "../../mocks/aboutUs";

export interface State {
  body: string
  error: string
  showAppStoreIcon: boolean
  showGooglePlayIcon: boolean
  linkToAppStore: string
  linkToGooglePlay: string
}

const initialState: State = {
  body: aboutUsContent,
  error: null,
  showAppStoreIcon: true,
  showGooglePlayIcon: true,
  linkToAppStore: '',
  linkToGooglePlay: ''
};

export default function aboutUsReducer(
  state: State = initialState,
  action: { type: string, body: string, error: string }
): Partial<State> {
  switch (action.type) {
    case RECEIVED_ABOUT_US:
      return { body: action.body, showAppStoreIcon: true, showGooglePlayIcon: true };

    case REQUEST_ABOUT_US_FAILED:
      return { ...state, error: action.error };

    default:
      return state;
  }
}
