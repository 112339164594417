// Common
import React, { FunctionComponent } from 'react';
import cn from 'classnames'
import styles from './index.module.scss'
import { Control } from 'react-hook-form'

// Hooks
import { useController } from 'react-hook-form'

interface IComponentProps {
  name: string;
  control: Control
  className?: string;
  placeholder?: string;
  rows?: number;
  cols?: number;
  resize?: 'none' | 'both' | 'horizontal' | 'vertical'
}

const Textarea: FunctionComponent<IComponentProps> = ({
  name,
  control,
  className,
  placeholder,
  rows,
  cols,
  resize = 'vertical'
}): React.ReactElement => {

  const {
    field,
    fieldState: { error }
  } = useController({
    name,
    control
  })

  return (
    <div className={ cn(styles['container'], className) }>
      <textarea
        className={ styles['textarea'] }
        placeholder={ placeholder }
        rows={ rows }
        cols={ cols }
        style={ { resize } }
        { ...field }
      />

      { error &&
        <div className={ styles['error'] }>
          { error.message }
        </div>
      }
    </div>
  )
}

export default Textarea
