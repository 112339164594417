// Common
import React, { FunctionComponent, ReactElement } from 'react'
import styles from './index.module.scss';

// Components
import Icon from 'components/icon'
import Image from 'components/image'

// Models
import { Event } from 'models/event.model';

// Hooks
import { useRef, useCallback, useEffect } from 'react'
import useContextMenu from 'hooks/useContextMenu'
import { useDispatch } from 'react-redux'
import { useSelector } from 'redux/store'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// Actions
import actions from 'redux/actions/calendar-month-events.action-creators'
import { getEventDate } from 'helpers/date';

interface IComponentProps {
  event: Event,
  onEdit: () => void
}

const EventComponent: FunctionComponent<IComponentProps> = ({ event, onEdit }): ReactElement => {
  const [t] = useTranslation()

  const dispatch = useDispatch()

  const deleted = useRef(false)

  const navigate = useNavigate()

  const deleteEvent = useCallback(() => {
    dispatch(actions.deleteItem(event))
    deleted.current = true
  }, [deleted, event, dispatch])

  const { error, pending } = useSelector(({ calendarMonthEvents }) => calendarMonthEvents)

  useEffect(() => {
    if (deleted.current && !pending && !error) {
      dispatch(actions.clear())
    }
  }, [error, pending, deleted, dispatch])

  const handleOpen = useCallback(() => {
    navigate('/events/' + event.id)
  }, [navigate, event])

  const contextMenu = useContextMenu([
    { title: 'Edit', action: onEdit },
    { title: 'Delete', action: deleteEvent }
  ])

  return (
    <div
      className={ styles['item'] }
      onClick={ handleOpen }
    >
      <div className={ styles['item-meta'] }>
        <Image
          className={ styles['item-meta-image'] }
          src={ event.imageUrl }
        />

        <div className={ styles['item-meta-info'] }>
          <div className={ styles['item-meta-info-title'] } >
            { event.title }
          </div>

          <div className={ styles['item-meta-info-address'] }>
            163 Jewas Street
          </div>

          <div className={ styles['item-meta-info-date'] }>
            { getEventDate(t, event.from) }
          </div>
        </div>
      </div>

      <div className={ styles['item-preview'] }>
        <div className={ styles['item-preview-title'] }>
          { event.title }
        </div>

        <div className={ styles['item-preview-description'] }>
          { event.description }
        </div>
      </div>

      <Icon
        name='kebab'
        className={ styles['item-kebab-icon'] }
        size={ 16 }
        { ...contextMenu }
      />
    </div>
  )
}

export default EventComponent
