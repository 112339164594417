import { Upload } from 'types/upload'
import { User } from './user.model'
import { Attachment } from 'types/attachment'

export class ChatMessage {
  id: number
  reactions: { userId: number, read: boolean}[]
  text: string
  roomId: number
  createdAt: Date
  sender: User
  uploads: Upload[]
  attachments: Attachment[]

  constructor(data?) {
    this.createdAt = new Date(data?.createdAt)
    this.id = data?.id
    this.reactions = (data?.reactions || []).map(reaction => ({ userId: reaction.userId, read: reaction.read }))
    this.roomId = data?.roomId || data?.room?.id
    this.sender = new User(data?.sender || {})
    this.text = data?.text
    this.uploads = data?.uploads
    this.attachments = data?.attachments
  }

  asPayloadJSON() {
    return {
      text: this.text,
      roomId: this.roomId,
      attachments: (this.uploads || []).map(upload => upload.asPayloadJSON())
    }
  }
}

export interface ChatMessagesFilters {
  page?: number,
  perPage: number,
  roomId: number,
}
