import { put, all, call, takeLatest } from 'redux-saga/effects'
import {
  REQUEST_LANGUAGES_LIST,
  receivedLanguagesList,
  requestLanguagesListFailed,
} from '../actions/language.action'
import { getLanguagesList } from '../../apiServices/languages.service'
import { request } from '../../apiServices/http.service'
import Language from '../../apiServices/models/language.model'
import { getCurrentLanguage, setCurrentLanguage } from '../../helpers/language'

export function* requestLanguages() {
  const { response, error } = yield call(request, getLanguagesList())
  if (response) {
    const languages = Language.mapObjectsToEntities<Language>(response.locales)
    const currentLanguage = getCurrentLanguage() || languages.find(({ iso3 }) => iso3.toLocaleLowerCase() === 'eng')

    setCurrentLanguage(currentLanguage)
    yield put(receivedLanguagesList(languages))
  } else {
    yield put(requestLanguagesListFailed(error))
  }
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(REQUEST_LANGUAGES_LIST, requestLanguages),
  ])
}
