// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slider_container__IISqu {
  height: 0.4rem;
  border-radius: 0.2rem;
  background-color: rgba(49, 12, 56, 0.1);
  position: relative;
}

.slider_control__R1JCk {
  position: absolute;
  height: 1.6rem;
  width: 1.6rem;
  border-radius: 0.8rem;
  background-color: #491E4D;
  top: -0.6rem;
  cursor: pointer;
  transform: translateX(-0.8rem);
}
.slider_control__R1JCk.slider_consuming__El4nJ {
  transition: left 0.7s linear;
}`, "",{"version":3,"sources":["webpack://./src/components/slider/index.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,qBAAA;EACA,uCAAA;EACA,kBAAA;AACF;;AAEA;EACE,kBAAA;EACA,cAAA;EACA,aAAA;EACA,qBAAA;EACA,yBAAA;EACA,YAAA;EACA,eAAA;EACA,8BAAA;AACF;AACE;EACE,4BAAA;AACJ","sourcesContent":[".container {\n  height: .4rem;\n  border-radius: .2rem;\n  background-color: rgba(49, 12, 56, .1);\n  position: relative;\n}\n\n.control {\n  position: absolute;\n  height: 1.6rem;\n  width: 1.6rem;\n  border-radius: .8rem;\n  background-color: #491E4D;\n  top: -.6rem;\n  cursor: pointer;\n  transform: translateX(-.8rem);\n  \n  &.consuming {\n    transition: left .7s linear;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `slider_container__IISqu`,
	"control": `slider_control__R1JCk`,
	"consuming": `slider_consuming__El4nJ`
};
export default ___CSS_LOADER_EXPORT___;
