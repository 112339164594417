// Hooks
import { useMemo, useRef } from 'react'
import { Injectable } from 'types/injectable.interface'
import { InjectionToken } from 'types/injection-token.type'
import { Injector } from 'types/injector.class'

const globalInjector = new Injector()

export function useInject<T extends Injectable>(constructor: new (injector: Injector) => T, injectionToken?: InjectionToken): T {
  const instanceRef = useRef<T>()

  const classInstance = useMemo(() => {
    const instance = globalInjector.get(constructor, injectionToken)

    instanceRef.current = instance

    return instance
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [injectionToken])

  // useEffect(() => {
  //   const instance = instanceRef.current

  //   return () => {
  //     instance.detach?.()
  //   }
  // }, [])

  return classInstance
}
